<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-danger">
          <div class="card-icon">
            <md-icon>style</md-icon>
          </div>
          <h4 class="title">
            Categories

            <div class="pull-right">
              <md-button
                class="md-just-icon md-success md-dense md-raised md-simple"
                @click.prevent="AddCategories"
              >
                <md-icon>add</md-icon>
                <md-tooltip class="md-sm" md-direction="top"
                  >Add Category</md-tooltip
                >
              </md-button>

              <!-- <md-button
                :disabled="emptylist"
                @click.native="handleDelete()"
                class="md-just-icon md-danger md-dense md-raised md-simple"
              >
                <md-icon>delete</md-icon>
                <md-tooltip class="md-sm" md-direction="top">Delete</md-tooltip>
              </md-button> -->

              <md-button
                :disabled="emptylist"
                class="md-just-icon md-info md-dense md-raised md-simple"
                @click="getCategories"
              >
                <md-icon>refresh</md-icon>
                <md-tooltip class="md-sm" md-direction="top"
                  >Refresh List</md-tooltip
                >
              </md-button>
            </div>
          </h4>
        </md-card-header>

        <clip-loader
          :loading="loading"
          :color="color"
          size="25px"
        ></clip-loader>

        <md-card-content>
          <md-table-empty-state
            v-if="emptylist"
            md-label="No data found"
            :md-description="`Empty table there's no data`"
          >
          </md-table-empty-state>

          <md-table
            v-else
            :value="queriedData"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
            @md-selected="onSelect"
            table-header-color="green"
            class="table-striped table-hover"
          >
            <md-table-toolbar class="toolbar" md-alignment="space-between">
              <div class="md-toolbar-section-start">
                <md-field style="width: 50px" class="mr-3">
                  <label for="pages">Per page</label>
                  <md-select
                    v-model="pagination.pageSize"
                    @change="handlePageSizeChange($event)"
                    name="pages"
                    style="width: 50px"
                  >
                    <md-option
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                      {{ item }}
                    </md-option>
                  </md-select>
                </md-field>
              </div>

              <!-- <div class="md-toolbar-section-end">
                <md-field style="width: 270px">
                  <md-input
                    type="search"
                    clearable
                    style="width: 250px"
                    placeholder="Search..."
                    v-model="searchQuery"
                  >
                  </md-input>
                </md-field>
              </div> -->
            </md-table-toolbar>
            <md-table-toolbar
              slot="md-table-alternate-header"
              slot-scope="{ count }"
              style="margin-top: -20px"
            >
              <div class="md-toolbar-section-start">
                {{ getAlternateLabel(count) }}
              </div>
            </md-table-toolbar>

            <md-table-row
              slot="md-table-row"
              slot-scope="{ item }"
              md-selectable="multiple"
            >
              <md-table-cell md-label="Title">{{ item.title }}</md-table-cell>
              <md-table-cell md-label="Tag Count">{{
                item.tagCount
              }}</md-table-cell>

              <md-table-cell md-label="Status">
                <md-switch
                  v-model="item.isActive"
                  @change="handleActivate(item)"
                  class="md-sm"
                >
                  {{ item.isActive == true ? "Active" : "InActive" }}</md-switch
                >
              </md-table-cell>

              <md-table-cell md-label="Actions">
                <md-button
                  class="md-just-icon md-primary md-simple"
                  @click.native="EditCategories(item)"
                >
                  <md-icon>edit</md-icon>
                  <md-tooltip class="md-sm" md-direction="top"
                    >Edit Category</md-tooltip
                  >
                </md-button>
                <md-button
                  class="md-just-icon md-primary md-simple"
                  @click.native="Tags(item)"
                >
                  <md-icon>dvr</md-icon>
                  <md-tooltip class="md-sm" md-direction="top"
                    >View Tags</md-tooltip
                  >
                </md-button>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ comma(total) }} entries
            </p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.page"
            :per-page="pagination.pageSize"
            :total="total"
            @pagechange="handlePageChange"
          >
          </pagination>
        </md-card-actions>
        <Add ref="AddCategory" :getCategories="getCategories" />
        <Update ref="UpdateCategory" :getCategories="getCategories" />
      </md-card>
    </div>
  </div>
</template>
<script>
import Add from "./AddCategory";
import Update from "./UpdateCategory.vue";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import { Pagination, commaSeparator } from "@/componentItems";
import Fuse from "fuse.js";
// import _ from "lodash";
// import * as notifications from "../../../utils/notifications";
///import Swal from "sweetalert2";

export default {
  props: {
    profileCard: {
      type: String,
      default: "./img/faces/card-profile1-square.jpg",
    },
  },
  mixins: [commaSeparator],
  components: {
    Add,
    Update,
    ClipLoader,
    Pagination,
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      return this.tableData;
    },
    to() {
      let highBound = this.from + this.pagination.pageSize;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.pageSize * (this.pagination.page - 1);
    },
    total() {
      return this.pagination.total;
    },
  },
  created() {
    this.getCategories();
  },
  data() {
    return {
      currentSort: "name",
      currentSortOrder: "asc",
      pagination: {
        pageSize: 5,
        page: 1,
        perPageOptions: [5, 10, 25, 50, 100],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: ["title", "email", "age"],
      tableData: [],
      searchedData: [],
      fuseSearch: null,
      selected: [],

      emptylist: false,
      loading: false,
      color: "#3AB982",
      size: "40px",
    };
  },
  methods: {
    onSelect(items) {
      this.selected = items;
    },
    getAlternateLabel(count) {
      let plural = "";

      if (count > 1) {
        plural = "s";
      }

      return `${count} item${plural} selected`;
    },
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "desc") {
          return a[sortBy].localeCompare(b[sortBy]);
        }
        return b[sortBy].localeCompare(a[sortBy]);
      });
    },
    AddCategories() {
      this.$refs.AddCategory.ShowAddModal();
    },
    Tags(item) {
      this.$router.push(`/tags/categories/${item.tagCategoryId}/tags`);
    },

    EditCategories(item) {
      this.$refs.UpdateCategory.ShowUpdateModal(item);
      this.$store.dispatch("getCategory", item.tagCategoryId);
    },

    getCategories() {
      this.loading = true;
      const params = {
        page: this.pagination.page - 1,
        pageSize: this.pagination.pageSize,
      };
      this.$store
        .dispatch("getCategories", params)
        .then((res) => {
          if (res.data.paging.count === 0) {
            this.emptylist = true;
          } else {
            this.tableData = res.data.data;
            this.pagination.total = res.data.paging.count;
          }
        })
        .then(() => {
          // Fuse search initialization.
          this.fuseSearch = new Fuse(this.tableData, {
            keys: ["title"],
            threshold: 0.3,
          });
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    handlePageChange(value) {
      this.pagination.page = value;
      this.getCategories();
    },
    handlePageSizeChange(event) {
      this.pagination.pageSize = event.target.value;
      this.pagination.page = 1;
      this.getCategories();
    },

    handleActivate(item) {
      this.loading = true;

      const params = {
        id: item.tagCategoryId,
        status: item.isActive,
      };

      this.$store
        .dispatch("activateCategories", params)
        .then((res) => {
          if (!res.data.message.includes("successfully")) {
            throw res;
          }
          this.$notify({
            message: `<small><b>${res.data.message}</b></small>`,
            icon: "add_alert",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "success",
          });
        })
        .catch((error) => {
          this.$notify({
            message: `<small><b>${error.data.message}</b></small>`,
            icon: "add_alert",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "danger",
          });
        })
        .finally(() => {
          this.loading = false;
          this.getCategories();
        });
    },
  },

  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData;
      if (value !== "") {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result;
    },
  },
};
</script>
<style lang="css">
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
</style>
<style lang="css" scoped>
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
.item-status {
  background-color: #4caf50;
  padding: 5px;
  color: white;
  border-radius: 10%;
}
.img-width {
  width: 50px;
}

.md-table[table-header-color="green"] .md-table-head .md-table-head-label {
  font-weight: bold;
}
</style>
<style lang="css">
.md-table-head-label {
  font-weight: bold;
}
</style>