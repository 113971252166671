<template>
  <modal
    id="Notification"
    ref="Notification"
    v-if="notificationModal"
    @close="HideNotificationModal"
  >
    <template slot="header">
      <h4 class="modal-title">Push Notification</h4>
      <md-button
        class="md-simple md-danger md-just-icon md-round modal-default-button"
        @click="HideNotificationModal"
      >
        <md-icon>clear</md-icon>
      </md-button>
    </template>

    <template slot="body">
      <ValidationObserver ref="observer">
        <form @submit.prevent="sendNotification" ref="notificationForm">
          <div class="md-layout">
            <div class="md-layout-item md-size-100 md-small-size-100">
              <ValidationProvider
                name="Title"
                ref="Title"
                :rules="{
                  required: true,
                  max: 40,
                }"
                v-slot="{ errors, passed, failed }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Title</label>
                  <md-input v-model="form.title" maxlength="40"></md-input>
                </md-field>
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>

              <ValidationProvider
                name="Message"
                ref="Message"
                :rules="{
                  required: false,
                  max: 50,
                }"
                v-slot="{ errors, passed, failed }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Message</label>
                  <md-textarea
                    v-model="form.message"
                    maxlength="50"
                  ></md-textarea>
                  <!-- <md-icon>description</md-icon> -->
                </md-field>
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </template>

    <template slot="footer">
      <clip-loader
        :loading="loading"
        :color="color"
        size="20px"
        class="mt-4 mr-5 pull-right"
      ></clip-loader>

      <md-button
        @click="HideNotificationModal"
        class="md-raised md-danger md-sm mt-4 mr-5 pull-left"
      >
        Close
      </md-button>

      <md-button
        @click="$refs.notificationForm.requestSubmit()"
        class="md-raised md-sm md-success mt-4"
      >
        Send
      </md-button>
    </template>
  </modal>
</template>

<script>
import { Modal } from "@/componentItems";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import {
  ValidationObserver,
  ValidationProvider,
} from "vee-validate/dist/vee-validate.full";
import * as notifications from "../../../utils/notifications";

export default {
  components: {
    ClipLoader,
    Modal,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    getlist: {
      type: Function,
      default: () => {},
    },
    category: {
      type: String,
    },
    Image: {
      type: String,
    },
    contentId: {
      type: String,
    },
  },

  created() {},

  data() {
    return {
      notificationModal: false,
      loading: false,
      color: "#3AB982",
      size: "40px",

      form: {
        title: "",
        message: "",
      },
      image: "",
    };
  },
  methods: {
    ShowNotificationModal() {
      this.notificationModal = true;
      //this.form.title = item ? item.name || item.author || item.title : "";
    },
    HideNotificationModal() {
      var self = this;
      self.notificationModal = false;

      Object.keys(this.form).forEach((key) => {
        self.form[key] = "";
      });
    },
    HideAddData() {
      var self = this;

      Object.keys(this.form).forEach((key) => {
        self.form[key] = "";
      });
    },

    sendNotification(e) {
      e.preventDefault();
      this.$refs.observer.validate().then((success) => {
        if (!success) {
          return;
        }

        this.loading = true;

        const formData = {
          title: this.form.title,
          message: this.form.message,
          image: this.Image,
          category: this.category,
          contentId: this.contentId,
        };
        console.log(formData);

        this.$store
          .dispatch("sendNotifications", formData)
          .then((res) => {
            if (!res.data.message.includes("successfully")) {
              throw res;
            }

            notifications.Success({
              title: `<small><b>${res.data.message}</b></small>`,
              buttonsStyling: false,
              confirmButtonClass: "md-button md-success",
            });
          })
          .catch((err) => {
            notifications.Error(err.data.message);
          })
          .finally(() => {
            this.loading = false;
            this.getlist();
            this.HideNotificationModal();
          });
        this.$nextTick(() => {
          this.$refs.observer.reset();
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.img-size {
  width: 45%;
  height: 45%;
}
</style>
