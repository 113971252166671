<template>
  <modal
    id="Updatechurch"
    ref="Updatechurch"
    v-if="UpdatechurchModal"
    @close="HideUpdateModal"
  >
    <template slot="header">
      <h4 class="modal-title">Update Church</h4>
      <md-button
        class="md-simple md-danger md-just-icon md-round modal-default-button"
        @click="HideUpdateModal"
      >
        <md-icon>clear</md-icon>
      </md-button>
    </template>

    <template slot="body">
      <ValidationObserver ref="observer">
        <form
          @submit.prevent="Update"
          ref="UpdatechurchForm"
          id="Updatechurch-form"
        >
          <div class="md-layout">
            <div class="md-layout-item md-size-100 md-small-size-100">
              <div class="file-input img-size">
                <template v-if="!imageCircle">
                  <div class="image-container">
                    <img :src="avatarImg" title="" />
                  </div>
                </template>
                <div class="image-container" v-else>
                  <img :src="imageCircle" />
                </div>
                <div class="button-container">
                  <md-button
                    class="md-danger md-raised md-sm"
                    @click="removeImage('circle')"
                    v-if="imageCircle"
                  >
                    <md-icon>clear</md-icon>Remove</md-button
                  >
                  <md-button class="md-sm md-success md-raised md-fileinput">
                    <template v-if="!imageCircle">Add Photo</template>
                    <template v-else>Change</template>
                    <input type="file" name="circle" @change="onFileChange" />
                  </md-button>
                </div>
                <h6 class="text-danger">{{ imageError }}</h6>
              </div>
            </div>

            <div class="md-layout-item md-size-100 md-small-size-100">
              <ValidationProvider
                name="Title"
                ref="title"
                :rules="{
                  required: true,
                  regex: /^[a-zA-Z0-9 '.-]*$/,
                }"
                v-slot="{ errors, passed, failed }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Title</label>
                  <md-input
                    v-model="form.title"
                    type="text"
                    required
                  ></md-input>
                  <slide-y-down-transition>
                    <md-icon class="error" v-show="failed">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success" v-show="passed">done</md-icon>
                  </slide-y-down-transition>
                </md-field>
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>

              <ValidationProvider
                name="Description"
                ref="description"
                :rules="{
                  min: 0,
                  max: 500,
               
                }"
                v-slot="{ errors, passed, failed }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Description </label>
                  <md-textarea
                    type="text"
                    data-vv-name="description"
                    v-model="form.description"
                       maxlength="500"
                    md-autogrow
                  ></md-textarea>
                  <slide-y-down-transition>
                    <md-icon class="error" v-show="failed">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success" v-show="passed">done</md-icon>
                  </slide-y-down-transition>
                </md-field>
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </template>

    <template slot="footer">
      <div class="md-layout-item md-size-100 text-right">
        <clip-loader
          :loading="loading"
          :color="color"
          size="20px"
          class="mt-4 mr-5 pull-right"
        ></clip-loader>
        <md-button
          @click="HideUpdateModal"
          class="md-raised md-danger md-sm mt-4 mr-5 pull-left"
        >
          Close
        </md-button>

        <md-button
          type="submit"
          @click="$refs.UpdatechurchForm.requestSubmit()"
          class="md-raised md-sm md-success mt-4"
        >
          Update Church
        </md-button>
      </div>
    </template>
  </modal>
</template>

<script>
import { Modal } from "@/componentItems";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import { SlideYDownTransition } from "vue2-transitions";
import {
  ValidationObserver,
  ValidationProvider,
} from "vee-validate/dist/vee-validate.full";

export default {
  components: {
    ClipLoader,
    Modal,
    ValidationProvider,
    ValidationObserver,
    SlideYDownTransition,
  },
  props: {
    getChurches: {
      type: Function,
      default: () => {},
    },
    avatarImg: {
      type: String,
      default: "../../img/no-image.png",
    },
  },
  data() {
    return {
      UpdatechurchModal: false,
      loading: false,
      color: "#3AB982",
      size: "40px",
      currentChurch: "",
      form: {
        title: "",
        description: "",
      },
      avatarFileName: "",
      imageError: "",
      imageCircle: "",
      imageCircleFile: File,
    };
  },

  methods: {
    ShowUpdateModal(item) {
      this.UpdatechurchModal = true;
      this.currentChurch = item.churchId;
      this.form.title = item.title;
      this.form.description = item.description;
      this.imageCircle = item.coverArt.url;
      console.log(item);
    },
    HideUpdateModal() {
      var self = this;
      self.UpdatechurchModal = false;

      Object.keys(this.form).forEach((key) => {
        self.form[key] = "";
      });
      this.imageCircle = "";
      this.imageError = "";
    },

    Update(e) {
      e.preventDefault();
      this.$refs.observer.validate().then((success) => {
        if (!success) {
          return;
        }
        let filesArray = [];
        let filesNamesArray = [];

        filesArray.push(this.imageCircleFile);
        filesNamesArray.push(this.avatarFileName);
        const params = {
          id: this.currentChurch,
          title: this.form.title,
          description: this.form.description,
          files: filesArray,
          fileNames: filesNamesArray,
          category: "Church",
        };

        console.log(params);
        this.loading = true;
        this.$store
          .dispatch("editChurch", params)
          .then((res) => {
            if (!res.data.message.includes("successfully")) {
              throw res;
            }
            this.$notify({
              message: `${res.data.message}`,
              icon: "add_alert",
              horizontalAlign: "right",
              verticalAlign: "top",
              type: "success",
            });
          })
          .catch((error) => {
            this.$notify({
              message: `${error.data.message}`,
              icon: "add_alert",
              horizontalAlign: "right",
              verticalAlign: "top",
              type: "danger",
            });
          })
          .finally(() => {
            this.loading = false;

            this.HideUpdateModal();
            this.getChurches();
          });

        this.$nextTick(() => {
          this.$refs.observer.reset();
        });
      });
    },

    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      console.log(files[0].name);
      if (e.target.name) {
        this.createImage(files[0], "circle");
        this.avatarFileName = files[0].name;
      } else {
        this.createImage(files[0]);
      }
    },
    createImage(file, type) {
      let reader = new FileReader();
      let vm = this;

      reader.onload = (e) => {
        if (type === "circle") {
          vm.imageCircle = e.target.result;
          vm.imageCircleFile = file;
          vm.imageError = "";
        } else {
          vm.imageProfile = e.target.result;
        }
      };
      reader.readAsDataURL(file);
    },
    removeImage: function (type) {
      if (type === "circle") {
        this.imageCircle = "";
        this.imageCircleFile = "";
      } else {
        this.imageProfile = "";
      }
    },
  },
};
</script>

<style>
</style>