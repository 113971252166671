import axios from "../../axios-auth";

const state = {
  idToken: localStorage.getItem("token") || "",
  userId: null,
  user: null,
};
const mutations = {
  authUser(state, userData) {
    state.idToken = userData.token;
    state.userId = userData.userId;
  },
  storeUser(state, user) {
    state.user = user;
  },
  clearAuthData(state) {
    state.idToken = null;
    state.userId = null;
  },
};
const actions = {
  login({ commit }, authData) {
    return new Promise((resolve, reject) => {
      axios
        .post("/auth/login", {
          email: authData.email,
          password: authData.password,
        })
        .then((res) => {
          console.log(res.data.success)
          if(res.data.success){
            commit("authUser", {
              token: res.data.data.token,
            });
            localStorage.setItem("token", res.data.data.token);
            resolve(res);

          }else{
            reject("Invalid Credentials");
          }
          
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },

  logout() {
    return new Promise((resolve) => {
      window.localStorage.clear();
      resolve();
    });
  },
};
const getters = {
  user(state) {
    return state.user;
  },
  // isAuthenticated(state) {
  //   return state.idToken !== null;
  // },
  isAuthenticated: (state) => !!state.idToken,
};

export default { state, mutations, actions, getters };
