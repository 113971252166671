import { render, staticRenderFns } from "./PlaylistDetails.vue?vue&type=template&id=47bd2a12&scoped=true"
import script from "./PlaylistDetails.vue?vue&type=script&lang=js"
export * from "./PlaylistDetails.vue?vue&type=script&lang=js"
import style0 from "./PlaylistDetails.vue?vue&type=style&index=0&id=47bd2a12&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47bd2a12",
  null
  
)

export default component.exports