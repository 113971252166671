import Vue from "vue";
import Vuex from "vuex";
import Auth from "./modules/Auth";
import Artists from "./modules/Artists";
import Song from "./modules/Song";
import Genre from "./modules/Genre";
import Notifications from "./modules/Notifications";
import Admins from "./modules/Admins";
import Plans from "./modules/Plans";
import User from "./modules/User";
import Roles from "./modules/Roles";
import Church from "./modules/Church";
import Playlist from "./modules/Playlist";
import Podcast from "./modules/Podcast";
import Sermon from "./modules/Sermon";
import Album from "./modules/Album";
import Search from "./modules/Search";
import Tags from "./modules/Tags";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    Auth,
    Artists,
    Album,
    Sermon,
    Church,
    Playlist,
    Podcast,
    Song,
    Genre,
    Notifications,
    Admins,
    Plans,
    User,
    Roles,
    Search,
    Tags,
  },
});
