<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <tabs
        :tab-name="['Add Tags', 'View Tags']"
        class="page-subcategories"
        plain
        color-button="danger"
      >
        <!-- here you can add your content for tab-content -->
        <template slot="tab-pane-1">
          <div class="md-layout-item md-size-60 mx-auto">
            <md-card>
              <md-card-header class="md-card-header-icon md-card-header-danger">
                <div class="card-icon">
                  <md-icon>local_offer</md-icon>
                </div>
                <h4 class="title">Add Tag</h4>
              </md-card-header>

              <ValidationObserver ref="observer">
                <form
                  @submit.prevent="Add"
                  ref="AddpodcastForm"
                  id="Addpodcast-Form"
                >
                  <md-card-content>
                    <div class="md-layout">
                      <div
                        class="md-layout-item md-size-80 md-small-size-100"
                        v-for="(item, index) in Tags"
                        :key="index"
                      >
                        <ValidationProvider
                          name="Title"
                          ref="title"
                          :rules="{
                            required: true,
                            regex: /^[a-zA-Z0-9 '.-]*$/,
                          }"
                          v-slot="{ errors, passed, failed }"
                        >
                          <md-field
                            :class="[
                              { 'md-error': failed },
                              { 'md-valid': passed },
                            ]"
                          >
                            <label>Title</label>
                            <md-input
                              v-model="item.title"
                              type="text"
                              required
                            ></md-input>
                            <slide-y-down-transition>
                              <md-icon class="error" v-show="failed"
                                >close</md-icon
                              >
                            </slide-y-down-transition>
                            <slide-y-down-transition>
                              <md-icon class="success" v-show="passed"
                                >done</md-icon
                              >
                            </slide-y-down-transition>
                          </md-field>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </ValidationProvider>
                      </div>
                      <div class="md-layout-item md-size-10 md-small-size-100">
                        <md-button
                          class="
                            md-sm
                            md-raised
                            md-success
                            md-just-icon
                            md-simple-icon
                            md-round
                          "
                          @click.prevent="addField"
                        >
                          <md-icon>add</md-icon>
                          <md-tooltip class="md-sm" md-direction="top"
                            >Add</md-tooltip
                          >
                        </md-button>
                      </div>
                      <div class="md-layout-item md-size-10 md-small-size-100">
                        <md-button
                          class="
                            md-sm
                            md-raised
                            md-danger
                            md-just-icon
                            md-simple-icon
                            md-round
                          "
                          @click.prevent="removeField"
                        >
                          <md-icon>clear</md-icon>
                          <md-tooltip class="md-sm" md-direction="top"
                            >Remove</md-tooltip
                          >
                        </md-button>
                      </div>
                    </div>
                  </md-card-content>

                  <md-card-actions>
                    <clip-loader
                      :loading="loading"
                      :color="color"
                      size="32px"
                    ></clip-loader>
                    <md-button
                      @click="HideAddData"
                      class="md-sm md-dense md-raised md-danger"
                      >Cancel</md-button
                    >

                    <md-button
                      class="md-sm md-dense md-raised md-success"
                      @click="$refs.AddpodcastForm.requestSubmit()"
                      >Submit</md-button
                    >
                  </md-card-actions>
                </form>
              </ValidationObserver>
            </md-card>
          </div></template
        >
        <template slot="tab-pane-2">
          <div
            class="md-layout-item md-size-70 mx-auto"
            style="background-color: transparent"
          >
            <md-card>
              <md-card-header class="md-card-header-icon md-card-header-danger">
                <div class="card-icon">
                  <md-icon>local_offer</md-icon>
                </div>
                <h4 class="title">
                  Tags
                  <div class="pull-right">
                    <md-button
                      :disabled="emptylist"
                      class="md-just-icon md-info md-dense md-raised md-simple"
                      @click="getTags"
                    >
                      <md-icon>refresh</md-icon>
                      <md-tooltip class="md-sm" md-direction="top"
                        >Refresh List</md-tooltip
                      >
                    </md-button>
                  </div>
                </h4>
              </md-card-header>

              <clip-loader
                :loading="loading"
                :color="color"
                size="25px"
              ></clip-loader>

              <md-card-content>
                <md-table-empty-state
                  v-if="emptylist"
                  md-label="No data found"
                  :md-description="`Empty table there's no data`"
                >
                </md-table-empty-state>

                <md-table
                  v-else
                  :value="queriedData"
                  :md-sort.sync="currentSort"
                  :md-sort-order.sync="currentSortOrder"
                  :md-sort-fn="customSort"
                  @md-selected="onSelect"
                  table-header-color="green"
                  class="table-striped table-hover"
                >
                  <md-table-toolbar
                    class="toolbar"
                    md-alignment="space-between"
                  >
                    <div class="md-toolbar-section-start">
                      <md-field style="width: 130px">
                        <label for="pages">Per page</label>
                        <md-select v-model="pagination.perPage" name="pages">
                          <md-option
                            v-for="item in pagination.perPageOptions"
                            :key="item"
                            :label="item"
                            :value="item"
                          >
                            {{ item }}
                          </md-option>
                        </md-select>
                      </md-field>
                    </div>
                  </md-table-toolbar>
                  <md-table-toolbar
                    slot="md-table-alternate-header"
                    slot-scope="{ count }"
                    style="margin-top: -20px"
                  >
                    <div class="md-toolbar-section-start">
                      {{ getAlternateLabel(count) }}
                    </div>
                  </md-table-toolbar>

                  <md-table-row
                    slot="md-table-row"
                    slot-scope="{ item }"
                    md-selectable="multiple"
                  >
                    <md-table-cell md-label="Tag">{{ item.tag }}</md-table-cell>
                    <md-table-cell md-label="Actions">
                      <md-button
                        class="md-just-icon md-primary md-simple"
                        @click.native="EditTags(item)"
                      >
                        <md-icon>edit</md-icon>
                        <md-tooltip class="md-sm" md-direction="top"
                          >Edit Tag</md-tooltip
                        >
                      </md-button>
                    </md-table-cell>
                  </md-table-row>
                </md-table>
              </md-card-content>
              <md-card-actions md-alignment="space-between">
                <div class="">
                  <p class="card-category">
                    Showing {{ from + 1 }} to {{ to }} of
                    {{ comma(total) }} entries
                  </p>
                </div>
                <PaginationB
                  class="pagination-no-border pagination-success"
                  v-model="pagination.currentPage"
                  :per-page="pagination.perPage"
                  :total="total"
                >
                </PaginationB>
              </md-card-actions>
              <Update ref="UpdateTag" :getTags="getTags" />
            </md-card>
          </div>
        </template>
      </tabs>
    </div>
  </div>
</template>


<script>
import Update from "./UpdateTag.vue";
import { Tabs, PaginationB, commaSeparator } from "@/componentItems";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import { SlideYDownTransition } from "vue2-transitions";
import {
  ValidationObserver,
  ValidationProvider,
} from "vee-validate/dist/vee-validate.full";
import Fuse from "fuse.js";
import * as notifications from "../../../utils/notifications";

export default {
  name: "Add-podcast",
  components: {
    Update,
    PaginationB,
    Tabs,
    ClipLoader,
    ValidationProvider,
    ValidationObserver,
    SlideYDownTransition,
  },
  mixins: [commaSeparator],
  props: {
    profileCard: {
      type: String,
      default: "../../img/no-image-new.png",
    },
    avatarImg: {
      type: String,
      default: "../../img/no-image.png",
    },
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
  created() {
    this.getTags();
  },

  data() {
    return {
      emptylist: false,
      loading: false,
      color: "#3AB982",
      size: "40px",

      currentSort: "name",
      currentSortOrder: "asc",
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: ["name", "email"],
      tableData: [],
      searchedData: [],
      selected: [],
      fuseSearch: null,

      Tags: [
        {
          title: "",
        },
      ],
      tagsData: [],
    };
  },

  methods: {
    onSelect(items) {
      this.selected = items;
    },
    getAlternateLabel(count) {
      let plural = "";

      if (count > 1) {
        plural = "s";
      }

      return `${count} item${plural} selected`;
    },
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "desc") {
          return a[sortBy].localeCompare(b[sortBy]);
        }
        return b[sortBy].localeCompare(a[sortBy]);
      });
    },
    HideAddData() {
      const Items = this.Tags.map((t) => t);
      for (var i = 0, j = Items.length; i < j; i++) {
        Object.keys(Items[i]).forEach((key) => {
          Items[i][key] = "";
        });
      }

      this.$router.push("/categories");
    },

    addField() {
      this.Tags.push({
        title: "",
      });
    },

    removeField(index) {
      this.Tags.splice(index, 1);
    },

    EditTags(item) {
      this.$refs.UpdateTag.ShowUpdateModal(item);
    },

    Add(e) {
      e.preventDefault();
      this.$refs.observer.validate().then((success) => {
        if (!success) {
          return;
        }
        let loader = this.$loading.show({
          container: this.fullPage ? null : this.$refs.formContainer,
          canCancel: false,
          onCancel: this.onCancel,
          color: "#eb4034",
        });

        const Items = this.Tags.map((t) => t.title);
        const formData = {
          tags: Items,
          categoryId: this.$route.params.id,
        };

        // console.log(formData);
        this.$store
          .dispatch("addTag", formData)
          .then((res) => {
            if (!res.data.message.includes("successfully")) {
              throw res;
            }
            loader.hide();
            notifications.Success({
              title: `<small><b>${res.data.message}</b></small>`,
              buttonsStyling: false,
              confirmButtonClass: "md-button md-success",
            });
          })
          .catch((err) => {
            notifications.Error(err.data.message);
          })
          .finally(() => {
            this.loading = false;
            this.getTags();
            this.HideAddData();
          });
        this.$nextTick(() => {
          this.$refs.observer.reset();
        });
      });
    },

    getTags() {
      this.loading = true;
      const params = {
        id: this.$route.params.id,
      };
      this.$store
        .dispatch("getTags", params)
        .then((res) => {
          if (res.data.data.length <= 0) {
            this.emptylist = true;
          } else {
            this.tableData = res.data.data;
          }
        })
        .then(() => {
          // Fuse search initialization.
          this.fuseSearch = new Fuse(this.tableData, {
            keys: ["title"],
            threshold: 0.3,
          });
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },

  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData;
      if (value !== "") {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result;
    },
  },
};
</script>


<style lang="scss" scoped>
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
.md-scrollbar {
  width: 100%;
  max-height: 200px;
  overflow: auto;
}
</style>
