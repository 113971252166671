var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-size-80 mx-auto"},[_c('ValidationObserver',{ref:"observer"},[_c('form',{ref:"AddArtistForm",attrs:{"id":"AddArtist-form"},on:{"submit":function($event){$event.preventDefault();return _vm.addArtist.apply(null, arguments)}}},[_c('md-card',{attrs:{"md-with-hover":""}},[_c('md-card-header',{staticClass:"md-card-header-icon md-card-header-danger"},[_c('div',{staticClass:"card-icon"},[_c('md-icon',[_vm._v("perm_identity")])],1),_c('h4',{staticClass:"title"},[_vm._v("Add Artist")])]),_c('md-card-content',[_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-size-30"},[_c('div',{staticClass:"file-input img-size"},[(!_vm.imageCircle)?[_c('div',{staticClass:"image-container"},[_c('img',{attrs:{"src":_vm.avatarImg,"title":""}})])]:_c('div',{staticClass:"image-container"},[_c('img',{attrs:{"src":_vm.imageCircle}})]),_c('div',{staticClass:"button-container"},[(_vm.imageCircle)?_c('md-button',{staticClass:"md-danger md-raised md-sm",on:{"click":function($event){return _vm.removeImage('circle')}}},[_c('md-icon',[_vm._v("clear")]),_vm._v("Remove")],1):_vm._e(),_c('md-button',{staticClass:"md-sm md-success md-raised md-fileinput"},[(!_vm.imageCircle)?[_vm._v("Add Photo")]:[_vm._v("Change")],_c('input',{attrs:{"type":"file","name":"circle","accept":"image/*"},on:{"change":_vm.onFileChange}})],2)],1),_c('h6',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.imageError))])],2)]),_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-70"},[_c('ValidationProvider',{ref:"Artist Name ",attrs:{"name":"Artist Name ","data-vv-name":"text","rules":{
                    required: true,
                    regex: /^[a-zA-Z '.-]*$/,
                  }},scopedSlots:_vm._u([{key:"default",fn:function({ errors, passed, failed }){return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',[_vm._v("Artist Name")]),_c('md-input',{attrs:{"data-vv-name":"artistName","type":"text","name":"artistName"},model:{value:(_vm.artistName),callback:function ($$v) {_vm.artistName=$$v},expression:"artistName"}}),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(failed),expression:"failed"}],staticClass:"error"},[_vm._v("close")])],1),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(passed),expression:"passed"}],staticClass:"success"},[_vm._v("done")])],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('ValidationProvider',{ref:"Description ",attrs:{"name":"Description ","data-vv-name":"text","rules":{
                    min: 0,
                    max: 500,
                  }},scopedSlots:_vm._u([{key:"default",fn:function({ errors, passed, failed }){return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',[_vm._v("Description")]),_c('md-textarea',{attrs:{"maxlength":"500","type":"text"},model:{value:(_vm.favDescription),callback:function ($$v) {_vm.favDescription=$$v},expression:"favDescription"}}),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(failed),expression:"failed"}],staticClass:"error"},[_vm._v("close")])],1),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(passed),expression:"passed"}],staticClass:"success"},[_vm._v("done")])],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('div',[_c('br'),_c('multiselect',{attrs:{"name":"country","options":_vm.countryArray,"placeholder":"Select Country","label":"name","track-by":"countryId","limit":1,"searchable":true,"show-labels":true,"close-on-select":true,"clear-on-select":true,"deselect-label":"Remove","select-label":"Choose Country","open-direction":"bottom"},model:{value:(_vm.country),callback:function ($$v) {_vm.country=$$v},expression:"country"}}),_c('pre',{staticClass:"language-json text-danger"},[_c('code',[_vm._v(_vm._s(_vm.countryError))])]),_c('br')],1),_c('ValidationProvider',{ref:"identityID",attrs:{"name":"National ID","rules":{
                    required: false,
                    min: 5,
                    max: 8,
                    regex: /^[0-9]*$/,
                  }},scopedSlots:_vm._u([{key:"default",fn:function({ errors, passed, failed }){return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',[_vm._v("National Id")]),_c('md-input',{attrs:{"type":"number"},model:{value:(_vm.idNumber),callback:function ($$v) {_vm.idNumber=$$v},expression:"idNumber"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('br')],1)])]),_c('md-card-actions',[_c('md-button',{staticClass:"md-sm md-dense md-raised md-danger",nativeOn:{"click":function($event){return _vm.goBack()}}},[_vm._v("Cancel")]),_c('md-button',{staticClass:"md-sm md-dense md-raised md-success",on:{"click":function($event){return _vm.$refs.AddArtistForm.requestSubmit()}}},[_vm._v("Submit")])],1)],1)],1)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }