<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-danger">
          <div class="card-icon">
            <md-icon>album</md-icon>
          </div>
          <h4 class="title">
            Albums

            <div class="pull-right">
              <md-button
                class="md-just-icon md-success md-dense md-raised md-simple"
                @click.prevent="AddAlbum"
              >
                <md-icon>add</md-icon>
                <md-tooltip class="md-sm" md-direction="top"
                  >Add Album</md-tooltip
                >
              </md-button>

              <md-button
                :disabled="emptylist"
                @click.native="handleDelete()"
                class="md-just-icon md-danger md-dense md-raised md-simple"
              >
                <md-icon>delete</md-icon>
                <md-tooltip class="md-sm" md-direction="top">Delete</md-tooltip>
              </md-button>

              <!-- <md-button
                :disabled="emptylist"
                class="md-just-icon md-success md-dense md-raised md-simple"
                @click="activateSermon"
              >
                <md-icon>lock_open</md-icon>
                <md-tooltip class="md-sm" md-direction="top"
                  >Activate Sermon</md-tooltip
                >
              </md-button>

              <md-button
                :disabled="emptylist"
                class="md-just-icon md-danger md-dense md-raised md-simple"
                @click="deactivateSermon"
              >
                <md-icon>lock</md-icon>
                <md-tooltip class="md-sm" md-direction="top"
                  >Deactivate Sermon</md-tooltip
                >
              </md-button> -->

              <md-button
                :disabled="emptylist"
                class="md-just-icon md-info md-dense md-raised md-simple"
                @click="getAlbums"
              >
                <md-icon>refresh</md-icon>
                <md-tooltip class="md-sm" md-direction="top"
                  >Refresh List</md-tooltip
                >
              </md-button>
            </div>
          </h4>
        </md-card-header>

        <clip-loader
          :loading="loading"
          :color="color"
          size="25px"
        ></clip-loader>
        <md-card-content>
          <md-table-empty-state
            v-if="emptylist"
            md-label="No data found"
            :md-description="`Empty table there's no data`"
          >
          </md-table-empty-state>
          <md-table
            v-else
            :value="queriedData"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
            @md-selected="onSelect"
            table-header-color="green"
            class="table-striped table-hover"
          >
            <md-table-toolbar class="toolbar" md-alignment="space-between">
              <div class="md-toolbar-section-start">
                <md-field style="width: 50px" class="mr-3">
                  <label for="pages">Per page</label>
                  <md-select
                    v-model="pagination.pageSize"
                    @change="handlePageSizeChange($event)"
                    name="pages"
                    style="width: 50px"
                  >
                    <md-option
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                      {{ item }}
                    </md-option>
                  </md-select>
                </md-field>
              </div>

              <div class="md-toolbar-section-end">
                <!-- <md-field style="width: 270px">
                  <md-input
                    type="search"
                    clearable
                    style="width: 250px"
                    placeholder="Search..."
                    v-model="searchQuery"
                  >
                  </md-input>
                </md-field> -->
              </div>
            </md-table-toolbar>
            <md-table-toolbar
              slot="md-table-alternate-header"
              slot-scope="{ count }"
              style="margin-top: -20px"
            >
              <div class="md-toolbar-section-start">
                {{ getAlternateLabel(count) }}
              </div>
            </md-table-toolbar>

            <md-table-row
              slot="md-table-row"
              slot-scope="{ item }"
              md-selectable="multiple"
              md-auto-select
            >
              <md-table-cell md-label="Title">{{ item.title }}</md-table-cell>

              <md-table-cell md-label="Likes">{{
                item.likeCount
              }}</md-table-cell>
              <md-table-cell md-label="Tracks">{{
                item.trackCount
              }}</md-table-cell>
              <md-table-cell md-label="Release">{{
                item.yearOfRelease
              }}</md-table-cell>
              <md-table-cell md-label="Top Album">
                <md-switch
                  v-model="item.isTop"
                  @change="handleTop(item)"
                  class="md-sm"
                >
                  {{ item.isTop == true ? "Yes" : "No" }}</md-switch
                >
              </md-table-cell>
              <md-table-cell md-label="Popular Album">
                <md-switch
                  v-model="item.isPopular"
                  @change="handlePopular(item)"
                >
                  {{ item.isPopular == true ? "Yes" : "No" }}</md-switch
                >
              </md-table-cell>
              <md-table-cell md-label="Actions">
                <md-button
                  class="md-just-icon md-primary md-simple"
                  @click.native="getAlbum(item)"
                >
                  <md-icon>edit</md-icon>
                  <md-tooltip class="md-sm" md-direction="top"
                    >Edit Album</md-tooltip
                  >
                </md-button>
                <md-button
                  class="md-just-icon md-primary md-simple"
                  @click.native="getAlbumSongs(item)"
                >
                  <md-icon>dvr</md-icon>
                  <md-tooltip class="md-sm" md-direction="top"
                    >View Album Songs</md-tooltip
                  >
                </md-button>
                <md-button
                  @click.native="HandleTag(item)"
                  class="md-just-icon md-primary md-dense md-raised md-simple"
                >
                  <md-icon>local_offer</md-icon>
                  <md-tooltip class="md-sm" md-direction="top">Tag</md-tooltip>
                </md-button>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ comma(total) }} entries
            </p>
          </div>

          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.page"
            :per-page="pagination.pageSize"
            :total="total"
            @pagechange="handlePageChange"
          >
          </pagination>
        </md-card-actions>
        <Add ref="Addalbum" :getAlbums="getAlbums" />
        <Update ref="Updatealbum" :getAlbums="getAlbums" />
        <Tag
          ref="AddTag"
          :category="category"
          :currentItem="currentItem"
          :getlist="getAlbums"
        />
      </md-card>
    </div>
  </div>
</template>
<script>
import Add from "./Add";
import Update from "./Update";
import Tag from "../Tags/TagContent.vue";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import { Pagination, commaSeparator } from "@/componentItems";
import _ from "lodash";
import * as notifications from "../../../utils/notifications";
// import Fuse from "fuse.js";
///import Swal from "sweetalert2";

export default {
  props: {
    profileCard: {
      type: String,
      default: "./img/faces/card-profile1-square.jpg",
    },
  },
  components: {
    Tag,
    Add,
    Update,
    Pagination,
    ClipLoader,
  },
  mixins: [commaSeparator],
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      return this.tableData;
    },
    to() {
      let highBound = this.from + this.pagination.pageSize;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.pageSize * (this.pagination.page - 1);
    },
    total() {
      return this.pagination.total;
    },
  },
  created() {
    this.getAlbums();
  },
  data() {
    return {
      currentSort: "name",
      currentSortOrder: "asc",
      pagination: {
        pageSize: 5,
        page: 1,
        perPageOptions: [5, 10, 25, 50, 100],
        total: 0,
      },
      footerTable: [],
      searchQuery: "",
      propsToSearch: ["name", "email"],
      tableData: [],
      searchedData: [],
      fuseSearch: null,
      emptylist: false,
      currentAdmin: "",
      editcurrentAdmin: false,
      loading: false,
      color: "#3AB982",
      selected: [],
      category: "Album",
      currentItem: "",
    };
  },
  methods: {
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "desc") {
          return a[sortBy].localeCompare(b[sortBy]);
        }
        return b[sortBy].localeCompare(a[sortBy]);
      });
    },

    onSelect(items) {
      this.selected = items;
    },
    getAlternateLabel(count) {
      let plural = "";

      if (count > 1) {
        plural = "s";
      }

      return `${count} item${plural} selected`;
    },
    AddAlbum() {
      this.$refs.Addalbum.ShowAddModal();
    },

    getAlbum(item) {
      console.log(item);
      this.$refs.Updatealbum.ShowUpdateModal(item);
      this.$store.dispatch("getAlbum", item.albumId);
    },

    getAlbumSongs(item) {
      this.$router.push(`/albumsong/${item.albumId}/song`);
    },
    HandleTag(item) {
      this.$refs.AddTag.ShowTagModal(item);
      this.currentItem = item.albumId;
    },

    getAlbums() {
      this.loading = true;
      const params = {
        page: this.pagination.page - 1,
        pageSize: this.pagination.pageSize,
      };
      this.$store
        .dispatch("getAlbums", params)
        .then((res) => {
          if (res.data.paging.count === 0) {
            this.emptylist = true;
          } else {
            this.tableData = res.data.data;
            this.pagination.total = res.data.paging.count;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    handlePageChange(value) {
      this.pagination.page = value;
      this.getAlbums();
    },
    handlePageSizeChange(event) {
      this.pagination.pageSize = event.target.value;
      this.pagination.page = 1;
      this.getAlbums();
    },

    handleTop(item) {
      this.loading = true;
      const params = {
        id: item.albumId,
        status: item.isTop,
      };

      this.$store
        .dispatch("TopAlbum", params)
        .then((res) => {
          if (!res.data.message.includes("successfully")) {
            throw res;
          }
          this.$notify({
            message: `<small><b>${res.data.message}</b></small>`,
            icon: "add_alert",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "success",
          });
        })
        .catch((error) => {
          this.$notify({
            message: `<small><b>${error.data.message}</b></small>`,
            icon: "add_alert",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "danger",
          });
        })
        .finally(() => {
          this.loading = false;
          this.getAlbums();
        });
    },

    handlePopular(item) {
      this.loading = true;
      const params = {
        id: item.albumId,
        status: item.isPopular,
      };

      this.$store
        .dispatch("PopularAlbum", params)
        .then((res) => {
          if (!res.data.message.includes("successfully")) {
            throw res;
          }
          this.$notify({
            message: `<small><b>${res.data.message}</b></small>`,
            icon: "add_alert",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "success",
          });
        })
        .catch((error) => {
          this.$notify({
            message: `<small><b>${error.data.message}</b></small>`,
            icon: "add_alert",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "danger",
          });
        })
        .finally(() => {
          this.loading = false;
          this.getAlbums();
        });
    },

    handleDelete() {
      if (this.selected.length > 0) {
        this.loading = true;

        //Populate the selected data
        let indexToDelete = this.selected.map((tableRow) => tableRow.albumId);

        /** Use chunk to creeate an array of elements split into groups the length of size and then send it to a loop */
        var formData = _.chunk(indexToDelete, 1);
        for (var i = 0, j = formData.length; i < j; i++) {
          //Populate the the array to get the albumId
          const Items = formData[i].map((t) => t);

          //call the key value back
          const params = Items[0];

          this.$store
            .dispatch("deleteAlbum", params)
            .then((res) => {
              if (!res.message.includes("successfully")) {
                throw res;
              }
              this.$notify({
                message: `${res.message}`,
                icon: "add_alert",
                horizontalAlign: "right",
                verticalAlign: "top",
                type: "success",
              });
            })
            .catch((error) => {
              notifications.Error(error.message);
            })
            .finally(() => {
              this.loading = false;
              this.getAlbums();
            });
        }
      } else {
        this.$notify({
          message: "Select an Item from the List",
          icon: "add_alert",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "danger",
        });
      }
    },
  },

  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData;
      if (value !== "") {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result;
    },
  },
};
</script>
<style lang="css">
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
</style>
<style lang="css" scoped>
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
.item-status {
  background-color: #4caf50;
  padding: 5px;
  color: white;
  border-radius: 10%;
}
.img-width {
  width: 50px;
}

.md-table[table-header-color="green"] .md-table-head .md-table-head-label {
  font-weight: bold;
}
</style>
<style lang="css">
.md-table-head-label {
  font-weight: bold;
}
</style>