<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <tabs
        :tab-name="['Add Episodes', 'View Episodes']"
        color-button="md-info"
      >
        <!-- here you can add your content for tab-content -->
        <template slot="tab-pane-1">
          <md-card>
            <ValidationObserver ref="observer">
              <form
                @submit.prevent="Add"
                ref="AddpodcastForm"
                id="Addpodcast-Form"
              >
                <md-card-content>
                  <div class="md-layout">
                    <div
                      class="md-layout-item md-size-80 md-small-size-100"
                      v-for="(item, index) in Episodes"
                      :key="index"
                    >
                      <collapse
                        :collapse="[`Add Episode`]"
                        icon="keyboard_arrow_down"
                        color-collapse="primary"
                      >
                        <template slot="md-collapse-pane-1">
                          <ValidationProvider
                            name="Title"
                            ref="title"
                            :rules="{
                              required: true,
                              regex: /^[a-zA-Z0-9 '.-]*$/,
                            }"
                            v-slot="{ errors, passed, failed }"
                          >
                            <md-field
                              :class="[
                                { 'md-error': failed },
                                { 'md-valid': passed },
                              ]"
                            >
                              <label>Title</label>
                              <md-input
                                v-model="item.title"
                                type="text"
                                required
                              ></md-input>
                              <slide-y-down-transition>
                                <md-icon class="error" v-show="failed"
                                  >close</md-icon
                                >
                              </slide-y-down-transition>
                              <slide-y-down-transition>
                                <md-icon class="success" v-show="passed"
                                  >done</md-icon
                                >
                              </slide-y-down-transition>
                            </md-field>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </ValidationProvider>

                          <ValidationProvider
                            name="Description"
                            ref="description"
                            :rules="{
                              min: 0,
                              max: 500,
                              regex: /^[a-zA-Z0-9 '.-]*$/,
                            }"
                            v-slot="{ errors, passed, failed }"
                          >
                            <md-field
                              :class="[
                                { 'md-error': failed },
                                { 'md-valid': passed },
                              ]"
                            >
                              <label>Description </label>
                              <md-textarea
                                v-model="item.description"
                                md-autogrow
                                maxlength="500"
                                type="text"
                              ></md-textarea>
                              <slide-y-down-transition>
                                <md-icon class="error" v-show="failed"
                                  >close</md-icon
                                >
                              </slide-y-down-transition>
                              <slide-y-down-transition>
                                <md-icon class="success" v-show="passed"
                                  >done</md-icon
                                >
                              </slide-y-down-transition>
                            </md-field>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </ValidationProvider>

                          <ValidationProvider
                            name="Sequence"
                            ref="sequence"
                            :rules="{
                              required: true,
                              regex: /^[0-9 '.-]*$/,
                            }"
                            v-slot="{ errors, passed, failed }"
                          >
                            <md-field
                              :class="[
                                { 'md-error': failed },
                                { 'md-valid': passed },
                              ]"
                            >
                              <label>Sequence</label>
                              <md-input
                                v-model="item.sequence"
                                type="number"
                                required
                              ></md-input>
                              <slide-y-down-transition>
                                <md-icon class="error" v-show="failed"
                                  >close</md-icon
                                >
                              </slide-y-down-transition>
                              <slide-y-down-transition>
                                <md-icon class="success" v-show="passed"
                                  >done</md-icon
                                >
                              </slide-y-down-transition>
                            </md-field>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </ValidationProvider>

                          <ValidationProvider
                            name="Podcast"
                            ref="podcast"
                            :rules="{
                              required: true,
                            }"
                            v-slot="{ errors, passed, failed }"
                          >
                            <md-field
                              md-clearable
                              :class="[
                                { 'md-error': failed },
                                { 'md-valid': passed },
                              ]"
                            >
                              <label>Podcast audio</label>
                              <md-file
                                type="file"
                                required
                                accept="audio/*"
                                v-model="item.audio"
                                @change="(e) => onAudioChange(e, index)"
                              />
                              <md-button class="md-just-icon md-simple">
                                <md-icon>info</md-icon>
                                <md-tooltip class="md-sm" md-direction="top"
                                  >Valid extension: mp3.Valid size:less than (<b
                                    >30 M.B</b
                                  >)</md-tooltip
                                >
                              </md-button>
                            </md-field>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </ValidationProvider>
                        </template>
                      </collapse>
                    </div>
                    <div class="md-layout-item md-size-10 md-small-size-100">
                      <md-button
                        class="md-sm md-raised md-success md-just-icon md-simple-icon md-round"
                        @click.prevent="addField"
                      >
                        <md-icon>add</md-icon>
                        <md-tooltip class="md-sm" md-direction="top"
                          >Add</md-tooltip
                        >
                      </md-button>
                    </div>
                    <div class="md-layout-item md-size-10 md-small-size-100">
                      <md-button
                        class="md-sm md-raised md-danger md-just-icon md-simple-icon md-round"
                        @click.prevent="removeField"
                      >
                        <md-icon>clear</md-icon>
                        <md-tooltip class="md-sm" md-direction="top"
                          >Remove</md-tooltip
                        >
                      </md-button>
                    </div>
                  </div>
                </md-card-content>

                <md-card-actions md-alignment="space-between">
                  <md-button
                    @click="HideAddData"
                    class="md-sm md-dense md-raised md-danger"
                    >Cancel</md-button
                  >

                  <clip-loader
                    :loading="loading"
                    :color="color"
                    size="32px"
                  ></clip-loader>

                  <md-button
                    class="md-sm md-dense md-raised md-success"
                    @click="$refs.AddpodcastForm.requestSubmit()"
                    >Submit</md-button
                  >
                </md-card-actions>
              </form>
            </ValidationObserver>
          </md-card></template
        >
        <template slot="tab-pane-2">
          <div class="md-layout-item" style="background-color: transparent">
            <md-card>
              <md-card-header class="md-card-header-icon md-card-header-danger">
                <div class="card-icon">
                  <md-icon>podcasts</md-icon>
                </div>
                <h4 class="title">
                  Podcast Episodes
                  <div class="pull-right">
                    <md-button
                      :disabled="emptylist"
                      @click.native="handleDelete()"
                      class="md-just-icon md-danger md-dense md-raised md-simple"
                    >
                      <md-icon>delete</md-icon>
                      <md-tooltip class="md-sm" md-direction="top"
                        >Delete</md-tooltip
                      >
                    </md-button>

                    <md-button
                      :disabled="emptylist"
                      class="md-just-icon md-info md-dense md-raised md-simple"
                      @click="getPodcastEpisode"
                    >
                      <md-icon>refresh</md-icon>
                      <md-tooltip class="md-sm" md-direction="top"
                        >Refresh List</md-tooltip
                      >
                    </md-button>
                  </div>
                </h4>
              </md-card-header>

              <clip-loader
                :loading="loading"
                :color="color"
                size="25px"
              ></clip-loader>

              <md-card-content>
                <md-table-empty-state
                  v-if="emptylist"
                  md-label="No data found"
                  :md-description="`Empty table there's no data`"
                >
                </md-table-empty-state>

                <md-table
                  v-else
                  :value="queriedData"
                  :md-sort.sync="currentSort"
                  :md-sort-order.sync="currentSortOrder"
                  :md-sort-fn="customSort"
                  @md-selected="onSelect"
                  table-header-color="green"
                  class="table-striped table-hover"
                >
                  <md-table-toolbar
                    class="toolbar"
                    md-alignment="space-between"
                  >
                    <div class="md-toolbar-section-start">
                      <md-field style="width: 130px">
                        <label for="pages">Per page</label>
                        <md-select v-model="pagination.perPage" name="pages">
                          <md-option
                            v-for="item in pagination.perPageOptions"
                            :key="item"
                            :label="item"
                            :value="item"
                          >
                            {{ item }}
                          </md-option>
                        </md-select>
                      </md-field>
                    </div>

                    <div class="md-toolbar-section-end">
                      <md-field style="width: 270px">
                        <md-input
                          type="search"
                          clearable
                          style="width: 250px"
                          placeholder="Search..."
                          v-model="searchQuery"
                        >
                        </md-input>
                      </md-field>
                    </div>
                  </md-table-toolbar>
                  <md-table-toolbar
                    slot="md-table-alternate-header"
                    slot-scope="{ count }"
                    style="margin-top: -20px"
                  >
                    <div class="md-toolbar-section-start">
                      {{ getAlternateLabel(count) }}
                    </div>
                  </md-table-toolbar>

                  <md-table-row
                    slot="md-table-row"
                    slot-scope="{ item }"
                    md-selectable="multiple"
                  >
                    <md-table-cell md-label="Title">{{
                      item.title
                    }}</md-table-cell>
                    <md-table-cell md-label="Description">
                      {{ item.description }}
                    </md-table-cell>
                    <md-table-cell md-label="Sequence">
                      {{ item.sequence }}
                    </md-table-cell>
                    <md-table-cell md-label="Plays">{{
                      item.playCount
                    }}</md-table-cell>
                    <md-table-cell md-label="Likes">{{
                      item.likeCount
                    }}</md-table-cell>
                    <md-table-cell md-label="Status">
                      <md-switch
                        v-model="item.isActive"
                        @change="ActivateEpisode(item)"
                        class="md-sm"
                      >
                        {{
                          item.isActive == true ? "Active" : "InActive"
                        }}</md-switch
                      >
                    </md-table-cell>
                  </md-table-row>
                </md-table>
              </md-card-content>
              <md-card-actions md-alignment="space-between">
                <div class="">
                  <p class="card-category">
                    Showing {{ from + 1 }} to {{ to }} of
                    {{ comma(total) }} entries
                  </p>
                </div>
                <PaginationB
                  class="pagination-no-border pagination-success"
                  v-model="pagination.currentPage"
                  :per-page="pagination.perPage"
                  :total="total"
                >
                </PaginationB>
              </md-card-actions>
            </md-card>
          </div>
        </template>
      </tabs>
    </div>
  </div>
</template>


<script>
import { Collapse, Tabs, PaginationB, commaSeparator } from "@/componentItems";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import { SlideYDownTransition } from "vue2-transitions";
import {
  ValidationObserver,
  ValidationProvider,
} from "vee-validate/dist/vee-validate.full";
import Fuse from "fuse.js";
import _ from "lodash";
import * as notifications from "../../../utils/notifications";

export default {
  name: "Add-podcast",
  components: {
    PaginationB,
    Tabs,
    Collapse,
    ClipLoader,
    ValidationProvider,
    ValidationObserver,
    SlideYDownTransition,
  },
  mixins: [commaSeparator],
  props: {
    profileCard: {
      type: String,
      default: "../../img/no-image-new.png",
    },
    avatarImg: {
      type: String,
      default: "../../img/no-image.png",
    },
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
  created() {
    this.getPodcastEpisode();
  },

  data() {
    return {
      emptylist: false,
      loading: false,
      color: "#3AB982",
      size: "40px",

      currentSort: "name",
      currentSortOrder: "asc",
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: ["name", "email"],
      tableData: [],
      searchedData: [],
      selected: [],
      fuseSearch: null,

      form: {
        coverArt: "",
        title: "",
        description: "",
        author: "",
      },
      Episodes: [
        {
          title: "",
          description: "",
          sequence: "",
          audio: undefined,
        },
      ],
      artistArray: [],
      audioname: "",
      audio: "",
      imageCircle: "",
      imageProfile: "",
      avatarFileName: "",
      profileFileName: "",
    };
  },

  methods: {
    onSelect(items) {
      this.selected = items;
    },
    getAlternateLabel(count) {
      let plural = "";

      if (count > 1) {
        plural = "s";
      }

      return `${count} item${plural} selected`;
    },
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "desc") {
          return a[sortBy].localeCompare(b[sortBy]);
        }
        return b[sortBy].localeCompare(a[sortBy]);
      });
    },
    HideAddData() {
      const Items = this.Episodes.map((t) => t);
      for (var i = 0, j = Items.length; i < j; i++) {
        Object.keys(Items[i]).forEach((key) => {
          Items[i][key] = "";
        });
      }

      this.imageCircle = "";
      this.audio = "";
      this.$router.push("/podcasts");
    },

    createAudio(file) {
      let reader = new FileReader();
      let vm = this;

      reader.onload = (e) => {
        vm.audio = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    onAudioChange(e, index) {
      let files = e.target.files || e.dataTransfer.files;
      this.createAudio(files[0]);
      this.audioname = files[0].name;

      //Access the currentEpisode
      const currentEpisode = this.Episodes[index];
      this.Episodes[index] = {
        ...currentEpisode,
        files,
      };
    },

    addField() {
      this.Episodes.push({
        title: "",
        description: "",
        sequence: "",
        audio: "",
      });
    },

    removeField(index) {
      this.Episodes.splice(index, 1);
    },

    Add(e) {
      e.preventDefault();
      this.$refs.observer.validate().then((success) => {
        if (!success) {
          return;
        }
        this.loading = true;
        let loader = this.$loading.show({
          container: this.fullPage ? null : this.$refs.formContainer,
          canCancel: false,
          onCancel: this.onCancel,
          color: "#eb4034",
        });

        const Items = this.Episodes.map((t) => t);

        for (var i = 0, j = Items.length; i < j; i++) {
          const formData = {
            id: this.$route.params.id,
            title: Items[i].title,
            description: Items[i].description,
            sequence: Items[i].sequence,
            files: Items[i].files,
            category: "PodcastEpisode",
          };

          // console.log(formData);
          this.$store
            .dispatch("addPodcastEpisode", formData)
            .then((res) => {
              if (!res.data.message.includes("successfully")) {
                throw res;
              }
              loader.hide();
              notifications.Success({
                title: `<small><b>${res.data.message}</b></small>`,
                buttonsStyling: false,
                confirmButtonClass: "md-button md-success",
              });
            })
            .catch((err) => {
              notifications.Error(err.data.message);
            })
            .finally(() => {
              this.loading = false;
              this.getPodcastEpisode();
              this.HideAddData();
            });
          this.$nextTick(() => {
            this.$refs.observer.reset();
          });
        }
      });
    },

    getPodcastEpisode() {
      this.loading = true;
      const params = {
        id: this.$route.params.id,
      };
      this.$store
        .dispatch("getPodcastEpisode", params)
        .then((res) => {
          if (res.data.data.length <= 0) {
            this.emptylist = true;
          } else {
            this.tableData = res.data.data;
          }
        })
        .then(() => {
          // Fuse search initialization.
          this.fuseSearch = new Fuse(this.tableData, {
            keys: ["title"],
            threshold: 0.3,
          });
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    ActivateEpisode(item) {
      this.loading = true;
      const params = {
        id: item.podcastEpisodeId,
        status: item.isActive,
      };

      this.$store
        .dispatch("activatePodcastEpisode", params)
        .then((res) => {
          if (!res.data.message.includes("successfully")) {
            throw res;
          }
          this.$notify({
            message: `<small><b>${res.data.message}</b></small>`,
            icon: "add_alert",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "success",
          });
        })
        .catch((error) => {
          this.$notify({
            message: `<small><b>${error.data.message}</b></small>`,
            icon: "add_alert",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "danger",
          });
        })
        .finally(() => {
          this.loading = false;
          this.getPodcastEpisode();
        });
    },
    handleDelete() {
      if (this.selected.length > 0) {
        this.loading = true;

        //Populate the selected data
        let indexToDelete = this.selected.map(
          (tableRow) => tableRow.podcastEpisodeId
        );

        /** Use chunk to creeate an array of elements split into groups the length of size and then send it to a loop */
        var formData = _.chunk(indexToDelete, 1);
        for (var i = 0, j = formData.length; i < j; i++) {
          //Populate the the array to get the podcastEpisodeId
          const Items = formData[i].map((t) => t);

          //call the key value back
          const params = Items[0];

          this.$store
            .dispatch("deletePodcastEpisode", params)
            .then((res) => {
              if (!res.message.includes("successfully")) {
                throw res;
              }
              this.$notify({
                message: `${res.message}`,
                icon: "add_alert",
                horizontalAlign: "right",
                verticalAlign: "top",
                type: "success",
              });
            })
            .catch((error) => {
              notifications.Error(error.message);
            })
            .finally(() => {
              this.loading = false;
              this.getPodcastEpisode();
            });
        }
      } else {
        this.$notify({
          message: "Select an Item from the List",
          icon: "add_alert",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "danger",
        });
      }
    },
  },

  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData;
      if (value !== "") {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result;
    },
  },
};
</script>


<style lang="scss" scoped>
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
.md-scrollbar {
  width: 100%;
  max-height: 200px;
  overflow: auto;
}
</style>
