<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-danger">
          <div class="card-icon">
            <md-icon>album</md-icon>
          </div>
          <h4 class="title">
            Album Songs

            <div class="pull-right">
              <md-button
                :disabled="emptylist"
                class="md-just-icon md-info md-dense md-raised md-simple"
                @click="getAlbumSongs"
              >
                <md-icon>refresh</md-icon>
                <md-tooltip class="md-sm" md-direction="top"
                  >Refresh List</md-tooltip
                >
              </md-button>
            </div>
          </h4>
        </md-card-header>

        <clip-loader
          :loading="loading"
          :color="color"
          size="25px"
        ></clip-loader>
        <md-card-content>
          <md-table-empty-state
            v-if="emptylist"
            md-label="No data found"
            :md-description="`Empty table there's no data`"
          >
          </md-table-empty-state>
          <md-table
            v-else
            :value="queriedData"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
            table-header-color="green"
            class="paginated-table table-striped table-hover"
          >
            <md-table-toolbar class="mb-3">
              <md-field style="width: 130px">
                <label for="pages">Per page</label>
                <md-select v-model="pagination.perPage" name="pages">
                  <md-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                    {{ item }}
                  </md-option>
                </md-select>
              </md-field>

              <md-field style="width: 270px">
                <md-input
                  type="search"
                  class="mb-3"
                  clearable
                  style="width: 200px"
                  placeholder="Search..."
                  v-model="searchQuery"
                >
                </md-input>
              </md-field>
            </md-table-toolbar>

            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <!-- <md-table-cell md-label="Image">
                <img :src="Object(item.coverArt).url" class="imgUrl"
              /></md-table-cell> -->

              <md-table-cell md-label="Image">
                <div v-if="true" type="hidden" hidden>
                  {{
                    (image = (obj = Object.assign({}, item.coverArt))["url"])
                  }}
                </div>

                <img :src="image" class="imgUrl" />
              </md-table-cell>
              <md-table-cell md-label="Title" md-sort-by="title">{{
                item.title
              }}</md-table-cell>
              <md-table-cell md-label="Author">
                {{ item.author }}
              </md-table-cell>
              <md-table-cell md-label="Description">
                {{ item.description }}
              </md-table-cell>
              <md-table-cell md-label="Type">{{ item.type }}</md-table-cell>
              <md-table-cell md-label="Tone Code">{{
                item.toneCode === null ? "null" : item.toneCode
              }}</md-table-cell>
              <md-table-cell md-label="Likes">{{
                item.likeCount
              }}</md-table-cell>
              <md-table-cell md-label="Plays">{{
                item.playCount
              }}</md-table-cell>
              <md-table-cell md-label="Top song">
                {{ item.isTop == true ? "Yes" : "No" }}
              </md-table-cell>
              <md-table-cell md-label="Popular">
                {{ item.isPopular == true ? "Yes" : "No" }}
              </md-table-cell>

              <md-table-cell md-label="Status">
                {{ item.isActive == true ? "Active" : "InActive" }}
              </md-table-cell>

              <!-- <md-table-cell md-label="Actions">
                <md-button
                  class="md-just-icon md-rose small-alert-modal md-simple mr-1"
                  @click.native="Music(item)"
                >
                  <md-icon>play_arrow</md-icon>
                  <md-tooltip class="md-sm" md-direction="top"
                    >Play Song</md-tooltip
                  >
                </md-button>
              </md-table-cell> -->
            </md-table-row>
          </md-table>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ comma(total) }} entries
            </p>
          </div>

          <PaginationB
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          >
          </PaginationB>
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>
<script>
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import { PaginationB, commaSeparator } from "@/componentItems";
import Fuse from "fuse.js";
///import Swal from "sweetalert2";

export default {
  props: {
    profileCard: {
      type: String,
      default: "./img/faces/card-profile1-square.jpg",
    },
  },
  components: {
    PaginationB,
    ClipLoader,
  },
  mixins: [commaSeparator],
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
  created() {
    this.getAlbumSongs();
  },
  data() {
    return {
      currentSort: "name",
      currentSortOrder: "asc",
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 25, 50],
        total: 0,
      },
      footerTable: [],
      searchQuery: "",
      propsToSearch: ["name", "email"],
      tableData: [],
      searchedData: [],
      fuseSearch: null,
      emptylist: false,
      currentAdmin: "",
      editcurrentAdmin: false,
      loading: false,
      color: "#3AB982",
      selected: [],
    };
  },
  methods: {
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "desc") {
          return a[sortBy].localeCompare(b[sortBy]);
        }
        return b[sortBy].localeCompare(a[sortBy]);
      });
    },

    onSelect(items) {
      this.selected = items;
    },
    getAlternateLabel(count) {
      let plural = "";

      if (count > 1) {
        plural = "s";
      }

      return `${count} item${plural} selected`;
    },

    getAlbumSongs() {
      this.loading = true;
      const params = {
        id: this.$route.params.id,
      };
      this.$store
        .dispatch("getAlbumSongs", params)
        .then((res) => {
          this.tableData = res.data.data;
        })
        .then(() => {
          this.fuseSearch = new Fuse(this.tableData, {
            keys: ["title", "author", "type", "description"],
            threshold: 0.3,
          });
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    handlePageChange(value) {
      this.pagination.page = value;
      this.getAlbumSongs();
    },
    handlePageSizeChange(event) {
      this.pagination.pageSize = event.target.value;
      this.pagination.page = 1;
      this.getAlbumSongs();
    },
  },

  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData;
      if (value !== "") {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result;
    },
  },
};
</script>
<style lang="css">
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
</style>
<style lang="css" scoped>
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
.item-status {
  background-color: #4caf50;
  padding: 5px;
  color: white;
  border-radius: 10%;
}
.img-width {
  width: 50px;
}

.md-table[table-header-color="green"] .md-table-head .md-table-head-label {
  font-weight: bold;
}
</style>
<style lang="css">
.md-table-head-label {
  font-weight: bold;
}
</style>