var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper",class:[{ 'nav-open': _vm.$sidebar.showSidebar }]},[_c('notifications'),_c('side-bar',{attrs:{"active-color":_vm.sidebarBackground,"background-image":_vm.sidebarBackgroundImage,"data-background-color":_vm.sidebarBackgroundColor,"loading":_vm.sidebarLoading}},[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{ name: 'Dashboard', icon: 'dashboard', path: '/dashboard' }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Artists',
          icon: 'mic',
          path: '/artists',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Playlist',
          icon: 'playlist_play',
          path: '/playlist',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Songs',
          icon: 'library_music',
          path: '/song',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Church',
          icon: 'home_work',
          path: '/church',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Sermon',
          icon: 'library_books',
          path: '/sermon',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Albums',
          icon: 'album',
          path: '/albums',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Podcasts',
          icon: 'podcasts',
          path: '/podcasts',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Categories',
          icon: 'style',
          path: '/categories',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Users',
          icon: 'people',
          path: '/users',
        }}})],1)],2),_c('div',{staticClass:"main-panel"},[_c('top-navbar'),_c('div',{class:{ content: !_vm.$route.meta.hideContent },on:{"click":_vm.toggleSidebar}},[_c('zoom-center-transition',{attrs:{"duration":100,"mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }