<template>
  <div>
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25"
      >
        <stats-card header-color="">
          <template slot="header">
            <div class="card-icon" style="background-color: transparent">
              <img class="img" :src="newActiveUsers" />
            </div>
            <p class="category">New Users</p>
            <h3 class="title">
              <animated-number :value="0"></animated-number>
            </h3>
          </template>

          <template slot="footer">
            <div class="stats">
              <md-icon>update</md-icon>
              Just Updated
            </div>
          </template>
        </stats-card>
      </div>

      <div
        class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25"
      >
        <stats-card header-color="">
          <template slot="header">
            <div class="card-icon" style="background-color: transparent">
              <img class="img" :src="newFavouriteArtists" />
            </div>
            <p class="category">New Favourite Artists</p>
            <h3 class="title">
              <animated-number :value="0"></animated-number>
            </h3>
          </template>

          <template slot="footer">
            <div class="stats">
              <md-icon>update</md-icon>
              Just Updated
            </div>
          </template>
        </stats-card>
      </div>
      <div
        class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25"
      >
        <stats-card header-color="">
          <template slot="header">
            <div class="card-icon" style="background-color: transparent">
              <img class="img" :src="newFavouriteSongs" />
            </div>
            <p class="category">New Favourite Songs</p>
            <h3 class="title">
              <animated-number :value="0"></animated-number>
            </h3>
          </template>

          <template slot="footer">
            <div class="stats">
              <md-icon>update</md-icon>
              Just Updated
            </div>
          </template>
        </stats-card>
      </div>
      <div
        class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25"
      >
        <stats-card header-color="">
          <template slot="header">
            <div class="card-icon" style="background-color: transparent">
              <img class="img" :src="newFavouritePlaylist" />
            </div>
            <p class="category">New Favourite Playlist</p>
            <h3 class="title">
              <animated-number :value="0"></animated-number>
            </h3>
          </template>

          <template slot="footer">
            <div class="stats">
              <md-icon>update</md-icon>
              Just Updated
            </div>
          </template>
        </stats-card>
      </div>
      <div
        class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25"
      >
        <stats-card header-color="">
          <template slot="header">
            <div class="card-icon" style="background-color: transparent">
              <img class="img" :src="newActiveUsers" />
            </div>
            <p class="category">New Active Users</p>
            <h3 class="title">
              <animated-number :value="0"></animated-number>
            </h3>
          </template>

          <template slot="footer">
            <div class="stats">
              <md-icon>update</md-icon>
              Just Updated
            </div>
          </template>
        </stats-card>
      </div>
      <div
        class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25"
      >
        <stats-card header-color="">
          <template slot="header">
            <div class="card-icon" style="background-color: transparent">
              <img class="img" :src="songsPlayedToday" />
            </div>
            <p class="category">Song Played Today</p>
            <h3 class="title">
              <animated-number :value="0"></animated-number>
            </h3>
          </template>

          <template slot="footer">
            <div class="stats">
              <md-icon>update</md-icon>
              Just Updated
            </div>
          </template>
        </stats-card>
      </div>
      <div
        class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25"
      >
        <stats-card header-color="">
          <template slot="header">
            <div class="card-icon" style="background-color: transparent">
              <img class="img" :src="videoPlayedToday" />
            </div>
            <p class="category">Video Played Today</p>
            <h3 class="title">
              <animated-number :value="0"></animated-number>
            </h3>
          </template>

          <template slot="footer">
            <div class="stats">
              <md-icon>update</md-icon>
              Just Updated
            </div>
          </template>
        </stats-card>
      </div>
    </div>
    <div class="md-layout"></div>
  </div>
</template>

<script>
import users from "./users";
import Fuse from "fuse.js";
import swal from "sweetalert2";

import { StatsCard, AnimatedNumber } from "../../componentItems/";

export default {
  components: {
    StatsCard,
    AnimatedNumber,
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
  data() {
    return {
      currentSort: "name",
      currentSortOrder: "asc",
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 30],
        total: 0,
      },
      footerTable: ["Name", "Amount", "Current Status"],
      searchQuery: "",
      propsToSearch: ["name", "agentCode"],
      tableData: users,
      searchedData: [],
      fuseSearch: null,
      cardUserImage: "./img/sidebar-2.jpg",
      newFavouriteSongs: "./img/db-fev-song-icon.png",
      newActiveUsers: "./img/db-member-icon.png",
      newFavouritePlaylist: "./img/db-fev-plylist-icon.png",
      newFavouriteArtists: "./img/db-member-icon.png",
      videoPlayedToday: "./img/db-member-icon.png",
      songsPlayedToday: "./img/db-member-icon.png",
    };
  },
  methods: {
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "desc") {
          return a[sortBy].localeCompare(b[sortBy]);
        }
        return b[sortBy].localeCompare(a[sortBy]);
      });
    },
    handleLike(item) {
      swal({
        title: `You liked ${item.name}`,
        buttonsStyling: false,
        type: "success",
        confirmButtonClass: "md-button md-success",
      });
    },
    handleEdit(item) {
      swal({
        title: `You want to edit ${item.name}`,
        buttonsStyling: false,
        confirmButtonClass: "md-button md-info",
      });
    },
    handleDelete(item) {
      swal({
        title: "Are you sure?",
        text: `You won't be able to revert this!`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "md-button md-success btn-fill",
        cancelButtonClass: "md-button md-danger btn-fill",
        confirmButtonText: "Yes, delete it!",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteRow(item);
          swal({
            title: "Deleted!",
            text: `You deleted ${item.name}`,
            type: "success",
            confirmButtonClass: "md-button md-success btn-fill",
            buttonsStyling: false,
          });
        }
      });
    },
    deleteRow(item) {
      let indexToDelete = this.tableData.findIndex(
        (tableRow) => tableRow.id === item.id
      );
      if (indexToDelete >= 0) {
        this.tableData.splice(indexToDelete, 1);
      }
    },
  },
  mounted() {
    // Fuse search initialization.
    this.fuseSearch = new Fuse(this.tableData, {
      keys: ["name", "agentCode"],
      threshold: 0.3,
    });
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData;
      if (value !== "") {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result;
    },
  },
};
</script>

<style lang="css" scoped>
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
.iconsBackground {
}
</style>
