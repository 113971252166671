var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.notificationModal)?_c('modal',{ref:"Notification",attrs:{"id":"Notification"},on:{"close":_vm.HideNotificationModal}},[_c('template',{slot:"header"},[_c('h4',{staticClass:"modal-title"},[_vm._v("Push Notification")]),_c('md-button',{staticClass:"md-simple md-danger md-just-icon md-round modal-default-button",on:{"click":_vm.HideNotificationModal}},[_c('md-icon',[_vm._v("clear")])],1)],1),_c('template',{slot:"body"},[_c('ValidationObserver',{ref:"observer"},[_c('form',{ref:"notificationForm",on:{"submit":function($event){$event.preventDefault();return _vm.sendNotification.apply(null, arguments)}}},[_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-size-100 md-small-size-100"},[_c('ValidationProvider',{ref:"Title",attrs:{"name":"Title","rules":{
                required: true,
                max: 40,
              }},scopedSlots:_vm._u([{key:"default",fn:function({ errors, passed, failed }){return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',[_vm._v("Title")]),_c('md-input',{attrs:{"maxlength":"40"},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1200358018)}),_c('ValidationProvider',{ref:"Message",attrs:{"name":"Message","rules":{
                required: false,
                max: 50,
              }},scopedSlots:_vm._u([{key:"default",fn:function({ errors, passed, failed }){return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',[_vm._v("Message")]),_c('md-textarea',{attrs:{"maxlength":"50"},model:{value:(_vm.form.message),callback:function ($$v) {_vm.$set(_vm.form, "message", $$v)},expression:"form.message"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2811769695)})],1)])])])],1),_c('template',{slot:"footer"},[_c('clip-loader',{staticClass:"mt-4 mr-5 pull-right",attrs:{"loading":_vm.loading,"color":_vm.color,"size":"20px"}}),_c('md-button',{staticClass:"md-raised md-danger md-sm mt-4 mr-5 pull-left",on:{"click":_vm.HideNotificationModal}},[_vm._v(" Close ")]),_c('md-button',{staticClass:"md-raised md-sm md-success mt-4",on:{"click":function($event){return _vm.$refs.notificationForm.requestSubmit()}}},[_vm._v(" Send ")])],1)],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }