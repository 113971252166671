var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.AddAlbumModal)?_c('modal',{ref:"Addalbum",attrs:{"id":"Addalbum"},on:{"close":_vm.HideAddModal}},[_c('template',{slot:"header"},[_c('h4',{staticClass:"modal-title"},[_vm._v("Add Album")]),_c('md-button',{staticClass:"md-simple md-danger md-just-icon md-round modal-default-button",on:{"click":_vm.HideAddModal}},[_c('md-icon',[_vm._v("clear")])],1)],1),_c('template',{slot:"body"},[_c('ValidationObserver',{ref:"observer"},[_c('form',{ref:"AddalbumForm",attrs:{"id":"Addalbum-form"},on:{"submit":function($event){$event.preventDefault();return _vm.Add.apply(null, arguments)}}},[_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-size-100 md-small-size-100"},[_c('ValidationProvider',{ref:"title",attrs:{"name":"Title","rules":{
                required: true,
                regex: /^[a-zA-Z0-9 '.-]*$/,
              }},scopedSlots:_vm._u([{key:"default",fn:function({ errors, passed, failed }){return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',[_vm._v("Title")]),_c('md-input',{attrs:{"type":"text","required":""},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}}),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(failed),expression:"failed"}],staticClass:"error"},[_vm._v("close")])],1),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(passed),expression:"passed"}],staticClass:"success"},[_vm._v("done")])],1)],1),_c('br'),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1157294104)}),_c('ValidationProvider',{ref:"description",attrs:{"name":"Description","rules":{
                min: 0,
                max: 500,
              }},scopedSlots:_vm._u([{key:"default",fn:function({ errors, passed, failed }){return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('br'),_c('label',[_vm._v("Description ")]),_c('md-textarea',{attrs:{"md-autogrow":"","maxlength":"500","type":"text"},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(failed),expression:"failed"}],staticClass:"error"},[_vm._v("close")])],1),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(passed),expression:"passed"}],staticClass:"success"},[_vm._v("done")])],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3984018496)})],1),_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-100"},[_c('br'),_c('multiselect',{attrs:{"name":"artists","options":_vm.artistArray,"placeholder":"Select Artist","label":"name","track-by":"artistId","limit":1,"searchable":true,"show-labels":true,"close-on-select":true,"clear-on-select":true,"deselect-label":"Remove","select-label":"Choose Artist","open-direction":"bottom"},model:{value:(_vm.form.artistId),callback:function ($$v) {_vm.$set(_vm.form, "artistId", $$v)},expression:"form.artistId"}})],1),_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-100"},[_c('br'),_c('ValidationProvider',{ref:"year",attrs:{"name":"Year","rules":{
                required: true,
                regex: /^[0-9]*$/,
              }},scopedSlots:_vm._u([{key:"default",fn:function({ errors, passed, failed }){return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',[_vm._v("Year of Release")]),_c('md-select',{attrs:{"name":"year","id":"year","type":"number","required":""},model:{value:(_vm.form.yearOfRelease),callback:function ($$v) {_vm.$set(_vm.form, "yearOfRelease", $$v)},expression:"form.yearOfRelease"}},_vm._l((_vm.years),function(year){return _c('md-option',{key:year,attrs:{"value":year}},[_vm._v(" "+_vm._s(year)+" ")])}),1),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(failed),expression:"failed"}],staticClass:"error"},[_vm._v("close")])],1),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(passed),expression:"passed"}],staticClass:"success"},[_vm._v("done")])],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2558136861)})],1)])])])],1),_c('template',{slot:"footer"},[_c('div',{staticClass:"md-layout-item md-size-100 text-right"},[_c('md-button',{staticClass:"md-raised md-danger md-sm mt-4 mr-5 pull-left",on:{"click":_vm.HideAddModal}},[_vm._v(" Close ")]),_c('clip-loader',{staticClass:"mt-4 mr-5 pull-right",attrs:{"loading":_vm.loading,"color":_vm.color,"size":"20px"}}),_c('md-button',{staticClass:"md-raised md-sm md-success mt-4",attrs:{"type":"submit"},on:{"click":function($event){return _vm.$refs.AddalbumForm.requestSubmit()}}},[_vm._v(" Create ")])],1)])],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }