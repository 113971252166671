import axios from "axios";
import { getGenres } from "../../utils/api";

const state = {
  loading: true,
  Genres: {},
  Genre: {},
  error: {},
};

const mutations = {
  request(state) {
    state.loading = true;
  },
  GenresList(state, ListItems) {
    state.loading = false;
    state.Genres = ListItems.items;
  },

  Genre(state, ListItems) {
    state.loading = false;
    state.Genre = ListItems.items;
  },

  error(state, error) {
    state.loading = false;
    state.error = error;
  },
};

const actions = {
  getGenres({ commit }) {
    return new Promise((resolve, reject) => {
      commit("request");
      getGenres()
        .then((res) => {
          commit("GenresList", {
            items: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },

  // getSongs({ commit }) {
  //   return new Promise((resolve, reject) => {
  //     commit("request");
  //     axios
  //       .get("http://localhost:3000/db")
  //       .then((res) => {
  //         commit("SongsList", {
  //           items: res.data.song,
  //         });
  //         resolve(res);
  //       })
  //       .catch((err) => {
  //         commit("error", err);
  //         reject(err);
  //       });
  //   });
  // },

  addGenre({ commit }, form) {
    return new Promise((resolve, reject) => {
      commit("request");
      axios
        .post("http://localhost:3000/song", form)
        .then((res) => {
          commit("GenresList", {
            items: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },

  getGenre({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit("request");
      axios
        .get(`http://localhost:3000/db/${id}`)
        .then((res) => {
          commit("Genre", {
            items: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },

  delete({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit("request");
      axios
        .delete(`http://localhost:3000/song/${id}`)
        .then((res) => {
          commit("GenresList", {
            items: res.data.data,
          });
          resolve(res);
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },
};

const getters = {
  // getList: (state) => {
  //   return state.Songs;
  // },
};

export default { state, mutations, actions, getters };
