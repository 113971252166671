<template>
  <modal
    id="Addalbum"
    ref="Addalbum"
    v-if="AddAlbumModal"
    @close="HideAddModal"
  >
    <template slot="header">
      <h4 class="modal-title">Add Album</h4>
      <md-button
        class="md-simple md-danger md-just-icon md-round modal-default-button"
        @click="HideAddModal"
      >
        <md-icon>clear</md-icon>
      </md-button>
    </template>

    <template slot="body">
      <ValidationObserver ref="observer">
        <form @submit.prevent="Add" ref="AddalbumForm" id="Addalbum-form">
          <div class="md-layout">
            <div class="md-layout-item md-size-100 md-small-size-100">
              <ValidationProvider
                name="Title"
                ref="title"
                :rules="{
                  required: true,
                  regex: /^[a-zA-Z0-9 '.-]*$/,
                }"
                v-slot="{ errors, passed, failed }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Title</label>
                  <md-input
                    v-model="form.title"
                    type="text"
                    required
                  ></md-input>
                  <slide-y-down-transition>
                    <md-icon class="error" v-show="failed">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success" v-show="passed">done</md-icon>
                  </slide-y-down-transition>
                </md-field>
                <br />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>

              <ValidationProvider
                name="Description"
                ref="description"
                :rules="{
                  min: 0,
                  max: 500,
                }"
                v-slot="{ errors, passed, failed }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <br />
                  <label>Description </label>
                  <md-textarea
                    v-model="form.description"
                    md-autogrow
                    maxlength="500"
                    type="text"
                  ></md-textarea>
                  <slide-y-down-transition>
                    <md-icon class="error" v-show="failed">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success" v-show="passed">done</md-icon>
                  </slide-y-down-transition>
                </md-field>
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </div>

            <div class="md-layout-item md-small-size-100 md-size-100">
              <br />
              <multiselect
                v-model="form.artistId"
                name="artists"
                :options="artistArray"
                placeholder="Select Artist"
                label="name"
                track-by="artistId"
                :limit="1"
                :searchable="true"
                :show-labels="true"
                :close-on-select="true"
                :clear-on-select="true"
                deselect-label="Remove"
                select-label="Choose Artist"
                open-direction="bottom"
              >
              </multiselect>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-100">
              <br />
              <ValidationProvider
                name="Year"
                ref="year"
                :rules="{
                  required: true,
                  regex: /^[0-9]*$/,
                }"
                v-slot="{ errors, passed, failed }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Year of Release</label>
                  <!-- <md-input
                    v-model="form.yearOfRelease"
                    type="number"
                    required
                  ></md-input> -->

                  <md-select
                    v-model="form.yearOfRelease"
                    name="year"
                    id="year"
                    type="number"
                    required
                  >
                    <md-option v-for="year in years" :key="year" :value="year">
                      {{ year }}
                    </md-option>
                  </md-select>

                  <slide-y-down-transition>
                    <md-icon class="error" v-show="failed">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success" v-show="passed">done</md-icon>
                  </slide-y-down-transition>
                </md-field>
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </template>

    <template slot="footer">
      <div class="md-layout-item md-size-100 text-right">
        <md-button
          @click="HideAddModal"
          class="md-raised md-danger md-sm mt-4 mr-5 pull-left"
        >
          Close
        </md-button>
        <clip-loader
          :loading="loading"
          :color="color"
          size="20px"
          class="mt-4 mr-5 pull-right"
        ></clip-loader>
        <md-button
          type="submit"
          @click="$refs.AddalbumForm.requestSubmit()"
          class="md-raised md-sm md-success mt-4"
        >
          Create
        </md-button>
      </div>
    </template>
  </modal>
</template>

<script>
import { Modal } from "@/componentItems";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import { SlideYDownTransition } from "vue2-transitions";
import {
  ValidationObserver,
  ValidationProvider,
} from "vee-validate/dist/vee-validate.full";

export default {
  components: {
    ClipLoader,
    Modal,
    ValidationProvider,
    ValidationObserver,
    SlideYDownTransition,
  },
  props: {
    getAlbums: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    Artist() {
      return this.artistArray;
    },
    years() {
      const year = new Date().getFullYear();
      return Array.from(
        { length: year - 1900 },
        (value, index) => 1901 + index
      );
    },
  },
  created() {
    this.getAllArtists();
  },
  data() {
    return {
      AddAlbumModal: false,
      loading: false,
      color: "#3AB982",
      size: "40px",
      form: {
        coverArt: "",
        title: "",
        description: "",
        yearOfRelease: "",
        artistId: [],
        albumId: [],
      },
      artistArray: [],
      imageCircle: "",
      imageProfile: "",
      avatarFileName: "",
      profileFileName: "",
    };
  },
  methods: {
    ShowAddModal() {
      this.AddAlbumModal = true;
    },
    HideAddModal() {
      var self = this;
      self.AddAlbumModal = false;

      Object.keys(this.form).forEach((key) => {
        self.form[key] = "";
      });
      this.imageCircle = "";
    },

    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      if (e.target.name) {
        this.createImage(files[0], "circle");
        this.avatarFileName = files[0].name;
      } else {
        this.createImage(files[0]);
        this.profileFileName = files[0].name;
      }
    },
    createImage(file, type) {
      let reader = new FileReader();
      let vm = this;

      reader.onload = (e) => {
        if (type === "circle") {
          vm.imageCircle = e.target.result;
        } else {
          vm.imageProfile = e.target.result;
        }
      };
      reader.readAsDataURL(file);
    },
    removeImage: function (type) {
      if (type === "circle") {
        this.imageCircle = "";
      } else {
        this.imageProfile = "";
      }
    },
    onResponsiveInverted() {
      if (window.innerWidth < 768) {
        this.responsive = true;
      } else {
        this.responsive = false;
      }
    },

    getAllArtists() {
      const params = {
        page: 0,
        pageSize: 10,
      };
      this.$store
        .dispatch("getAllArtists", params)
        .then((res) => {
          this.artistArray = res;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    Add(e) {
      e.preventDefault();
      this.$refs.observer.validate().then((success) => {
        if (!success) {
          return;
        }

        console.log(this.form.artistId["artistId"]);

        const formData = {
          title: this.form.title,
          description: this.form.description,
          yearOfRelease: Number(this.form.yearOfRelease),
          artistId: this.form.artistId["artistId"],
          category: "Album",
        };

        console.log(formData);
        this.$store
          .dispatch("addAlbum", formData)
          .then((res) => {
            if (!res.data.message.includes("successfully")) {
              throw res;
            }
            this.$notify({
              message: `<small><b>${res.data.message}</b></small>`,
              icon: "add_alert",
              horizontalAlign: "center",
              verticalAlign: "top",
              type: "success",
            });
          })
          .catch((err) => {
            this.$notify({
              message: `${err.data.message}`,
              icon: "add_alert",
              horizontalAlign: "right",
              verticalAlign: "top",
              type: "danger",
            });
          })
          .finally(() => {
            this.loading = false;
            this.getAlbums();
            this.HideAddModal();
          });
        this.$nextTick(() => {
          this.$refs.observer.reset();
        });
      });
    },
  },

  mounted() {
    this.onResponsiveInverted();
    window.addEventListener("resize", this.onResponsiveInverted);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResponsiveInverted);
  },
};
</script>

<style lang="css" scoped>
.img-size {
  width: 45%;
  height: 45%;
}
</style>