import axios from "../../axios-auth";
import filesAxios from "../../axios-files";
import { AUTH_REQUEST } from "../actions/types";

import { s3Url } from "../../utils/api.js";
import { s3Callback } from "../../utils/api.js";

const state = {
  loading: false,
};
const mutations = {
  [AUTH_REQUEST](state) {
    state.loading = true;
  },
};
const actions = {
  getPlaylists({ commit }, params) {
    return new Promise((resolve, reject) => {
      if (params && params.isActive) {
        axios
          .get(
            `/playlist?page=${params.page}&pageSize=${params.pageSize}&isActive=${params.isActive}`
          )
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            commit("error", err);
            reject(err);
          });
      } else {
        axios
          .get(`/playlist?page=${params.page}&pageSize=${params.pageSize}`)
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            commit("error", err);
            reject(err);
          });
      }
    });
  },

  getAllPlaylists({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/playlist?page=${params.page}&pageSize=${params.pageSize}`)
        .then((res) => {
          commit(AUTH_REQUEST);
          axios
            .get(`/playlist?page=0&pageSize=${res.data.paging.count}`)
            .then((res) => {
              resolve(res.data.data);
            });
        })

        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },
  updatePlaylist({ commit }, formData) {
    return new Promise((resolve, reject) => {
      console.log(formData);
      commit(AUTH_REQUEST);

      axios
        .put(`/playlist/${formData.id}/edit`, {
          title: formData.title,
          type: formData.type,
          description: formData.description,
          songsId: formData.songsId,
        })
        .then((res) => {
          ///console.log(res.data.data[0].playlistId);
          let playlistId = res.data.data[0].playlistId;
          console.log(playlistId);

          async function processFiles() {
            for (let i = 0; i < formData.files.length; i++) {
              try {
                const { data } = await axios.post(s3Url, {
                  id: playlistId,
                  file_name: formData.files[i].name,
                  category: formData.category,
                });

                const { task_id: taskId, url } = data;

                await filesAxios.put(url, formData.files[i]);

                const resp = await axios.post(`${s3Callback}${taskId}`);

                console.log(resp);
                ////return resp;
              } catch (error) {
                console.log(error);
              }
            }
            resolve("success");
          }

          processFiles();
        })

        .catch((err) => {
          reject(err);
        });
    });
  },

  updatePlaylistSongs({ commit }, formData) {
    return new Promise((resolve, reject) => {
      console.log(formData);
      commit(AUTH_REQUEST);

      axios
        .put(`/playlist/${formData.id}/addSongs?pageSize=100`, {
          songsId: formData.songsId,
        })
        // .then((res) => {
        //   //
        //   if (formData.tagIds.length > 0) {
        //     axios
        //       .put(`/tags/tagContent`, {
        //         contentId: formData.id,
        //         tagIds: formData.tagIds,
        //         category: formData.category,
        //       })
        //       .then((res) => {
        //         console.log(res);
        //         return res;
        //       });
        //   } else {
        //     console.log(res);
        //     resolve(res);
        //   }
        // })
        .then((res) => {
          console.log(res);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updateTagContent({ commit }, formData) {
    return new Promise((resolve, reject) => {
      console.log(formData);
      commit(AUTH_REQUEST);

      axios
        .put(`/tags/tagContent`, {
          songsId: formData.songsId,
        })
        .then((res) => {
          console.log(res);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  addPlaylist({ commit }, formData) {
    return new Promise((resolve, reject) => {
      console.log(formData);
      commit(AUTH_REQUEST);

      axios
        .post("/playlist", {
          title: formData.title,
          type: formData.type,
          description: formData.description,
          songsId: formData.songsId,
        })
        .then((res) => {
          console.log(res.data.data.playlistId);
          let playlistId = res.data.data.playlistId;

          // if (formData.tagIds.length > 0) {
          //   axios.put(`/tags/tagContent`, {
          //     contentId: playlistId,
          //     tagIds: formData.tagIds,
          //     category: formData.category,
          //   });
          // }

          async function processFiles() {
            for (let i = 0; i < formData.files.length; i++) {
              try {
                const { data } = await axios.post(s3Url, {
                  id: playlistId,
                  file_name: formData.files[i].name,
                  category: formData.category,
                });

                const { task_id: taskId, url } = data;

                await filesAxios.put(url, formData.files[i]);

                const resp = await axios.post(`${s3Callback}${taskId}`);

                console.log(resp);
                ////return resp;
              } catch (error) {
                console.log(error);
              }
            }
            resolve("success");
          }

          processFiles();
        })

        .catch((err) => {
          reject(err);
        });
    });
  },

  activatePlaylist({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit(AUTH_REQUEST);
      axios
        .put(`/playlist/${params.id}/activate`, {
          status: params.status,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },

  TopPlaylist({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit(AUTH_REQUEST);
      axios
        .put(`/playlist/${params.id}/markAsTop`, {
          status: params.status,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },

  PopularPlaylist({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit(AUTH_REQUEST);
      axios
        .put(`/playlist/${params.id}/markAsPopular`, {
          status: params.status,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },

  getPlaylist({ commit }, playlistParam) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/playlist/${playlistParam.currentId}`)
        .then((res) => {
          // console.log(res);
          resolve(res.data);
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },
  // getPlaylistSongs({ commit }, playlistParam) {
  //   return new Promise((resolve, reject) => {
  //     axios
  //       .get(`/playlist/${playlistParam.currentId}/songs`)
  //       .then((res) => {
  //         console.log(res);
  //         resolve(res.data);
  //       })
  //       .catch((err) => {
  //         commit("error", err);
  //         reject(err);
  //       });
  //   });
  // },

  getPlaylistSongs({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit("request");
      axios
        .get(
          `/playlist/${params.currentId}/songs?page=${params.page}&pageSize=${params.pageSize}`
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },

  deletePlaylistSong({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit("request");
      axios
        .put(`playlist/${params.id}/removeSongs`, {
          songsId: params.songId,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },
  deletePlaylist({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit("request");
      axios
        .delete(`playlist/${params}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },
  addSongToPlaylist({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/playlist/${params.playlistId}/addSongs`, {
          songsId: params.songsId,
        })
        .then((res) => {
          console.log(res);
          resolve(res.data);
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },
};
const getters = {};

export default { state, mutations, actions, getters };
