import api from "../../axios-auth";

const state = {
  loading: true,
  Notification: [],
  error: {},
};

const mutations = {
  request(state) {
    state.loading = true;
  },
  Notificationlist(state, ListItems) {
    state.loading = false;
    state.Notification = ListItems.items;
  },
  error(state, error) {
    state.loading = false;
    state.error = error;
  },
};

const actions = {
  sendNotifications({ commit }, formData) {
    return new Promise((resolve, reject) => {
      commit("request");
      api
        .post(`/misc/pushNotification`, {
          title: formData.title,
          message: formData.message,
          image: formData.image,
          category: formData.category,
          contentId: formData.contentId,
        })
        .then((res) => {
          commit("Notificationlist", {
            items: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },
};

const getters = {};

export default { state, mutations, actions, getters };
