<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <tabs
        :tab-name="['Edit Playlist', 'Add / Remove Songs']"
        color-button="primary"
        header-color="blue"
      >
        <template slot="header-title">
          <div class="card-icon">
            <md-icon>credit_score</md-icon>
          </div>
          <h4 class="title">Playlist Details</h4>
        </template>

        <!-- here you can add your content for tab-content -->

        <template slot="tab-pane-1">
          <!-- Edit Playlist -->
          <ValidationObserver ref="observer">
            <form
              @submit.prevent="updatePlaylist"
              ref="updatePlaylistForm"
              id="updatePlaylist-form"
            >
              <div class="md-layout-item md-size-105 mx-auto mt-4">
                <div class="md-layout">
                  <div class="md-layout-item md-size-30">
                    <h4 class="card-title"></h4>
                    <div class="file-input img-size">
                      <template v-if="!imageCircle">
                        <div class="image-container">
                          <img :src="avatarImg" title="" />
                        </div>
                      </template>
                      <div class="image-container" v-else>
                        <img :src="imageCircle" />
                      </div>
                      <div class="button-container">
                        <md-button
                          class="md-danger md-raised md-sm"
                          @click="removeImage('circle')"
                          v-if="imageCircle"
                        >
                          <md-icon>clear</md-icon>Remove</md-button
                        >
                        <md-button
                          class="md-sm md-success md-raised md-fileinput"
                        >
                          <template v-if="!imageCircle">Add Photo</template>
                          <template v-else>Change</template>
                          <input
                            type="file"
                            name="circle"
                            accept="image/*"
                            @change="onFileChange"
                          />
                        </md-button>
                      </div>
                      <h6 class="text-danger"></h6>
                    </div>
                    <br /><br />
                  </div>
                  <div class="md-layout-item md-small-size-100 md-size-70">
                    <ValidationProvider
                      name="Playlist Title "
                      ref="Playlist Title "
                      data-vv-name="text"
                      rules="required"
                      v-slot="{ errors, passed, failed }"
                    >
                      <md-field
                        :class="[
                          { 'md-error': failed },
                          { 'md-valid': passed },
                        ]"
                      >
                        <label>Playlist Title</label>
                        <md-input
                          v-model="playlistTitle"
                          type="text"
                        ></md-input>
                        <slide-y-down-transition>
                          <md-icon class="error" v-show="failed">close</md-icon>
                        </slide-y-down-transition>
                        <slide-y-down-transition>
                          <md-icon class="success" v-show="passed"
                            >done</md-icon
                          >
                        </slide-y-down-transition>
                      </md-field>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>

                    <ValidationProvider
                      name="Description "
                      ref="Description "
                      :rules="{
                        min: 0,
                        max: 500,
                      }"
                      v-slot="{ errors, passed, failed }"
                      ><md-field
                        :class="[
                          { 'md-error': failed },
                          { 'md-valid': passed },
                        ]"
                      >
                        <label> Description</label>
                        <md-textarea
                          v-model="playlistDescription"
                          maxlength="500"
                          type="text"
                        ></md-textarea>
                        <slide-y-down-transition>
                          <md-icon class="error" v-show="failed">close</md-icon>
                        </slide-y-down-transition>
                        <slide-y-down-transition>
                          <md-icon class="success" v-show="passed"
                            >done</md-icon
                          >
                        </slide-y-down-transition>
                      </md-field>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                    <br /><br />
                    <ValidationProvider
                      name="Type"
                      ref="type"
                      :rules="{
                        required: true,
                      }"
                      v-slot="{ errors, passed, failed }"
                    >
                      <div
                        :class="[
                          { 'md-error': failed },
                          { 'md-valid': passed },
                        ]"
                      >
                        <span><b>Playlist Type :</b></span>
                        <div>
                          <md-radio v-model="playlistType" :value="false"
                            >Audio</md-radio
                          >
                          <md-radio v-model="playlistType" :value="true"
                            >Video</md-radio
                          >
                        </div>
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </div>

                  <div
                    class="md-layout-item md-small-size-100 md-size-16"
                  ></div>

                  <!-- <div class="md-layout-item md-size-100 text-right">
                            <md-button
                              class="md-raised md-default mt-4"
                              @click.native="goBack()"
                              >Cancel</md-button
                            >
                            <md-button
                              class="md-raised md-success mt-4"
                              @click="$refs.updatePlaylistForm.requestSubmit()"
                              >Submit</md-button
                            >
                          </div> -->
                </div>

                <md-card-actions>
                  <md-button
                    @click.native="goBack()"
                    class="md-sm md-dense md-raised md-danger"
                    >Cancel</md-button
                  >

                  <md-button
                    class="md-sm md-dense md-raised md-success"
                    @click="$refs.updatePlaylistForm.requestSubmit()"
                    >Submit</md-button
                  >
                </md-card-actions>
              </div>
            </form>
          </ValidationObserver>
        </template>

        <template slot="tab-pane-2">
          <md-card>
            <md-card-header class="md-card-header-icon md-card-header-green">
              <h4 class="title">
                <b>{{ playlistTitle }}</b>
                <div class="pull-right">
                  <md-button
                    @click="classicModalShow()"
                    class="md-just-icon md-success md-dense md-raised md-simple"
                  >
                    <md-icon>add</md-icon>
                    <md-tooltip class="md-sm" md-direction="top"
                      >Add Song to Playlist</md-tooltip
                    >
                  </md-button>

                  <md-button
                    @click="getPlaylistSongs()"
                    class="md-just-icon md-info md-dense md-raised md-simple"
                  >
                    <md-icon>refresh</md-icon>
                    <md-tooltip class="md-sm" md-direction="top"
                      >Refresh Page</md-tooltip
                    >
                  </md-button>
                </div>
              </h4>
            </md-card-header>

            <md-card-content>
              <md-table :value="queriedData" class="table-striped table-hover">
                <md-table-row slot="md-table-row" slot-scope="{ item }">
                  <md-table-cell md-label="Image">
                    <div v-if="true" type="hidden" hidden>
                      {{
                        (image = (obj = Object.assign({}, item.coverArt))[
                          "url"
                        ])
                      }}
                    </div>

                    <img :src="image" class="imgUrl" />
                  </md-table-cell>

                  <md-table-cell md-label="Title"
                    >{{ item.title }} <br />

                    <h6>{{ item.author }}</h6>
                  </md-table-cell>

                  <md-table-cell md-label="Action">
                    <md-button
                      @click="deleteChip(item)"
                      class="md-just-icon md-simple md-danger"
                    >
                      <md-icon>delete</md-icon>
                      <md-tooltip md-direction="top">Remove</md-tooltip>
                    </md-button>
                  </md-table-cell>
                </md-table-row>
              </md-table>
            </md-card-content>
            <md-card-actions md-alignment="space-between">
              <div class="">
                <p class="card-category">
                  Showing {{ from + 1 }} to {{ to }} of
                  {{ comma(total) }} entries
                </p>
              </div>
              <pagination
                class="pagination-no-border pagination-success"
                v-model="pagination.page"
                :per-page="pagination.pageSize"
                :total="total"
                @pagechange="handlePageChange"
              >
              </pagination>
            </md-card-actions>
          </md-card>
          <modal v-if="classicModal" @close="classicModalHide">
            <template slot="header">
              <h4 class="modal-title">Add Songs to Playlist</h4>
              <md-button
                @click="refreshList"
                class="md-just-icon md-info md-dense md-raised md-simple"
              >
                <md-icon class="md-sm">refresh </md-icon>
                <md-tooltip class="md-sm" md-direction="top"
                  >Refresh</md-tooltip
                >
              </md-button>
              <md-button
                class="md-simple md-just-icon md-round modal-default-button"
                @click="classicModalHide"
              >
                <md-icon>clear</md-icon>
              </md-button>
            </template>

            <template slot="body">
              <div class="md-layout-item md-small-size-100 md-size-70">
                <form @submit.prevent="updatePlaylistSongs" ref="UpdateForm">
                  <multiselect
                    v-model="newSongs"
                    :options="songTypeArray"
                    multiple
                    placeholder="Add Songs"
                    label="title"
                    track-by="songId"
                    :limit="5"
                    :searchable="true"
                    :show-labels="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :loading="loadingSongs"
                    @search-change="searchItem"
                    @open="onOpen"
                    @close="onClose"
                    deselect-label="Remove  Song"
                    select-label="Select Song"
                    open-direction="bottom"
                    ref="multiselectB"
                  >
                    <template slot="afterList">
                      <div
                        v-observe-visibility="visibilityChanged"
                        v-if="hasNextPage"
                      ></div>
                    </template>

                    <template #clear="props">
                      <div
                        class="multiselect__clear"
                        v-if="newSongs.length"
                        @mousedown.prevent.stop="clearAll(props.search)"
                      ></div>
                    </template>
                  </multiselect>
                </form>
              </div>
            </template>

            <template slot="footer">
              <md-button
                type="submit"
                class="md-simple md-success"
                @click="$refs.UpdateForm.requestSubmit()"
                >Submit</md-button
              >

              <md-button class="md-danger md-simple" @click="classicModalHide"
                >Close</md-button
              >
            </template>
          </modal>
        </template>
      </tabs>
    </div>
  </div>
</template>

<script>
import { SlideYDownTransition } from "vue2-transitions";
import { Tabs, Modal, Pagination, commaSeparator } from "@/componentItems";
import {
  ValidationObserver,
  ValidationProvider,
  extend,
} from "vee-validate/dist/vee-validate.full";
import * as notifications from "../../../utils/notifications";

// can customize default error messages
extend("required", {
  message: (field) => "The " + `${field}` + " field is required. ",
});
export default {
  props: {
    profileCard: {
      type: String,
      default: "../../img/no-image-new.png",
    },
    avatarImg: {
      type: String,
      default: "../../img/no-image.png",
    },
  },

  components: {
    ValidationProvider,
    ValidationObserver,
    SlideYDownTransition,

    Pagination,
    Modal,
    Tabs,
  },
  mixins: [commaSeparator],
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */

    hasNextPage() {
      let from = this.songPagination.pageSize * (this.songPagination.page - 1);
      let total = this.songPagination.total;

      let NextPage = from + this.songPagination.pageSize;
      if (total < NextPage) {
        NextPage = total;
      }
      return NextPage;

      // return this.songPagination.page + 1 <= this.songPagination.total;
    },
    queriedData() {
      return this.currentSongs;
    },
    to() {
      let highBound = this.from + this.pagination.pageSize;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.pageSize * (this.pagination.page - 1);
    },
    total() {
      return this.pagination.total;
    },
  },

  data() {
    return {
      currentSort: "name",
      currentSortOrder: "asc",
      pagination: {
        pageSize: 10,
        page: 1,
        perPageOptions: [10, 25, 50, 100],
        total: 0,
      },
      searchQuery: "",
      tableData: "",
      searchedData: [],
      selectedSongs: [],
      fuseSearch: null,
      emptylist: false,
      currentArtist: "",
      editcurrentArtist: false,
      cardUserImage: "",
      playlistTitle: "",
      aboutArtist: "",
      playlistDescription: "",
      description: "",
      status: "",
      addedOn: "",
      modifiedOn: "",
      loading: false,
      loadingSongs: false,
      color: "#3AB982",
      imageCircle: "",
      imageCircleFile: "",
      imageProfile: "",
      notifyUser: true,
      playlistType: false,
      songsId: [],
      tagsId: [],
      topPlaylist: true,
      selectedGenre: [],
      songsArray: [],
      tagsArray: [],
      currentSongs: [],
      newSongs: [],
      songTypeArray: [],
      newSongsError: "",
      avatarFileName: "",
      playlistId: "",
      avatarFileChanged: false,
      classicModal: false,
      songCount: "",
      songPagination: {
        pageSize: 500,
        page: 1,
        total: 100000,
      },
    };
  },

  methods: {
    async onOpen() {
      this.$refs.multiselectB.activate();
      // this.refreshList();
    },
    onClose() {
      this.$refs.multiselectB.deactivate();
    },
    visibilityChanged(reached) {
      if (reached) {
        // console.log("EndOfList", reached);
        return this.fetchNextPage();
      }
    },

    smallAlertModalHide: function () {
      this.smallAlertModal = false;
    },
    onSelect(items) {
      this.selected = items;
    },
    getAlternateLabel(count) {
      let plural = "";
      if (count > 1) {
        plural = "s";
      }
      return `${count} song${plural} selected`;
    },

    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "desc") {
          return a[sortBy].localeCompare(b[sortBy]);
        }
        return b[sortBy].localeCompare(a[sortBy]);
      });
    },

    classicModalShow: function () {
      this.classicModal = true;
    },
    classicModalHide: function () {
      this.classicModal = false;
      this.newSongs = "";
    },

    selectPlaylistItem() {},
    updatePlaylist() {
      if (this.playlistTitle == "") {
        console.log("empty playlist name");
      } else if (this.playlistDescription == "") {
        console.log("empty description");
      } else {
        this.loading = true;
        let loader = this.$loading.show({
          // Optional parameters
          container: this.fullPage ? null : this.$refs.formContainer,
          canCancel: false,
          onCancel: this.onCancel,
          color: "#eb4034",
        });
        let filesArray = [];

        if (this.avatarFileChanged == false) {
          console.log("No file");
        } else {
          filesArray.push(this.imageCircleFile);
          console.log("is file");
        }

        let playType = this.playlistType ? "Video" : "Audio";

        this.newSongs.forEach((item) => {
          this.selectedSongs.push(item.songId);
        });

        this.$store
          .dispatch("updatePlaylist", {
            title: this.playlistTitle,
            description: this.playlistDescription,
            type: playType,
            category: "Playlist",
            files: filesArray,
            id: this.$route.params.id,
          })
          .then((res) => {
            console.log(res);
            loader.hide();
            this.loading = false;
            notifications.Success({
              title: "Playlist Songs Updated Successfully",
              buttonsStyling: false,
              confirmButtonClass: "md-button md-success",
            });
          })
          .finally(() => {
            this.loading = false;
            this.HideAddData();
          })
          .catch((err) => {
            notifications.Error(err);
          });
      }
    },
    updatePlaylistSongs() {
      let tagIdsArray = [];
      // console.log(this.newSongs);
      // console.log(this.tagsId.length);
      this.tagsId.forEach((tag) => {
        tagIdsArray.push(tag.id);
      });
      console.log(tagIdsArray);
      if (this.newSongs.length < 1 && this.tagsId.length < 1) {
        console.log("empty selections");
      } else {
        this.newSongsError = "Select a new Song to add to Playlist";
        this.loading = true;
        let loader = this.$loading.show({
          // Optional parameters
          container: this.fullPage ? null : this.$refs.formContainer,
          canCancel: false,
          onCancel: this.onCancel,
          color: "#eb4034",
        });
        this.newSongs.forEach((item) => {
          this.selectedSongs.push(item.songId);
        });
        this.$store
          .dispatch("updatePlaylistSongs", {
            songsId: this.selectedSongs,
            id: this.$route.params.id,
            tagIds: tagIdsArray,
            category: "Playlist",
          })
          .then((res) => {
            console.log(res);
            loader.hide();
            this.loading = false;
            notifications.Success({
              title: "Playlist songs updated successfully",
              buttonsStyling: false,
              confirmButtonClass: "md-button md-success",
            });
          })
          .finally(() => {
            this.loading = false;
            loader.hide();
            this.HideAddData();
          })
          .catch((err) => {
            notifications.Error(err);
            loader.hide();
          });
      }
    },
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.avatarFileChanged = true;
      if (e.target.name) {
        this.imageCircleFile = files[0];
        this.createImage(files[0], "circle");
        this.avatarFileName = files[0].name;
      } else {
        this.createImage(files[0]);
        ///this.profileFileName = files[0].name;
      }
    },
    createImage(file, type) {
      let reader = new FileReader();
      let vm = this;

      reader.onload = (e) => {
        if (type === "circle") {
          vm.imageCircle = e.target.result;
        } else {
          vm.imageProfile = e.target.result;
        }
      };
      reader.readAsDataURL(file);
    },
    removeImage: function (type) {
      if (type === "circle") {
        this.imageCircle = "";
      } else {
        this.imageProfile = "";
      }
    },
    // getAllSongs() {
    //   const params = {
    //     page: 0,
    //     pageSize: 10,
    //   };
    //   this.$store
    //     .dispatch("getAllSongs", params)
    //     .then((res) => {
    //       let playType = this.playlistType ? "Video" : "Audio";

    //       let songTypeArray = res.filter(function (song) {
    //         return song.type == playType;
    //       });

    //       this.songTypeArray = songTypeArray;
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },

    getAllSongs() {
      this.loadingSongs = true;
      const params = {
        isActive: true,
        page: 0,
        pageSize: this.songPagination.pageSize,
      };

      this.$store
        .dispatch("getAllSongs", params)
        .then((res) => {
          // this.songs = res;
          let playType = this.playlistType ? "Video" : "Audio";

          let songsArray = res.filter(function (song) {
            return song.type == playType;
          });

          this.songTypeArray = songsArray;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loadingSongs = false;
        });
    },

    searchItem(searchQuery) {
      this.loadingSongs = true;
      const params = {
        searchTerm: searchQuery,
        column: "title",
        category: "songs",
      };
      // console.log(params);
      this.$store
        .dispatch("partialSearch", params)
        .then((res) => {
          this.songTypeArray = res.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loadingSongs = false;
        });
    },
    fetchNextPage() {
      this.songPagination.pageSize += 100;
      this.getAllSongs();
    },

    clearAll() {
      this.newSongs = [];
    },

    refreshList() {
      this.songPagination.page = 1;
      this.getAllSongs();
    },

    getPlaylist() {
      this.loading = true;
      this.$store
        .dispatch("getPlaylist", {
          currentId: this.$route.params.id,
        })
        .then((res) => {
          this.loading = false;

          let playlist = res.data.data[0];
          const {
            title,
            description,
            isActive,
            isTop,
            type,
            coverArt,
            playlistId,
            trackCount,
          } = playlist;

          this.playlistTitle = title;
          this.playlistDescription = description;
          this.topPlaylist = isTop;
          this.imageCircle = coverArt.url;
          this.status = isActive;
          this.playlistId = playlistId;
          this.songCount = trackCount;

          if (type.toLowerCase() == "audio") {
            this.playlistType = false;
          } else if (type.toLowerCase() == "video") {
            this.playlistType = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getCategories() {
      this.loading = true;
      const params = {
        page: this.pagination.page - 1,
        pageSize: this.pagination.pageSize,
      };
      this.$store
        .dispatch("getCategories", params)
        .then((res) => {
          if (res.data.paging.count === 0) {
            this.emptylist = true;
          } else {
            let tags = [];

            let categories = res.data.data;

            categories.forEach((category) => {
              category["tags"].forEach((tag) => {
                tags.push({ tag: tag.tag, id: tag.tagId });
              });
            });

            this.tagsArray = tags;

            this.pagination.total = res.data.paging.count;
          }
        })

        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getPlaylistSongs() {
      this.loading = true;

      const params = {
        currentId: this.$route.params.id,
        page: this.pagination.page - 1,
        pageSize: this.pagination.pageSize,
      };

      this.$store
        .dispatch("getPlaylistSongs", params)
        .then((res) => {
          this.loading = false;
          this.businessData = res.data;
          // res.data.forEach((item) => {
          //   this.currentSongs.push({ title: item.title, id: item.songId });
          // });

          this.currentSongs = res.data;
          this.pagination.total = this.songCount;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    handlePageChange(value) {
      this.pagination.page = value;
      this.getPlaylistSongs();
    },
    goBack() {
      this.$router.back();
    },
    deleteChip(item) {
      let index = this.currentSongs.indexOf(item);
      let songToRemoveArray = [];
      if (index >= 0) {
        this.currentSongs.splice(index, 1);
      }
      songToRemoveArray.push(item.songId);
      const params = {
        songId: songToRemoveArray,
        id: this.$route.params.id,
      };
      this.deletePlaylistSong(params);
    },
    deletePlaylistSong(params) {
      this.$store
        .dispatch("deletePlaylistSong", params)
        .then((res) => {
          console.log(res);
          notifications.Success({
            title: "Playlist song removed successfully",
            buttonsStyling: false,
            confirmButtonClass: "md-button md-success",
          });
        })
        .catch((error) => {
          console.log(error);
          notifications.Error(error);
        })
        .finally(() => {
          this.loading = false;
          this.HideShowPlaylist();
        });
    },
    clickChip(chip) {
      console.log(chip.id);
    },
    HideAddData() {
      this.$router.push("/playlist");
    },
    HideShowPlaylist() {
      this.$router.push(`/playlist/${this.playlistId}`);
    },
  },

  created() {
    this.getCategories();
    this.getPlaylist();
    this.getPlaylistSongs();
  },
  mounted() {
    setTimeout(() => {
      this.getAllSongs();
    }, 1000);
  },

  watch: {
    currentSongs: function () {},
    // songsArray: function () {
    //   var songPos = this.songsArray
    //     .map(function (option) {
    //       return option.songsId;
    //     })
    //     .indexOf(this.currentSongs);
    //   console.log(this.songsArray[songPos]);
    // this.currentSongs = this.songsArray[songPos];
    // console.log(this.currentSongs);
    // console.log(this.songsArray[songPos]);
    //},
  },
};
</script>

<style lang="scss" scoped>
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
// .spacing {
//   margin: "10px";
//   padding: "10px";
// }
.item-status {
  background-color: #4caf50;
  padding: 5px;
  color: white;
  border-radius: 10%;
}
.img-width {
  width: 50px;
}
.img-size {
  width: 75%;
  height: 75%;
}

// .md-table[table-header-color="green"] .md-table-head .md-table-head-label {
//   font-weight: bold;
// }
.md-chip {
  background-color: tomato !important;
}

// .toolbar {
//   margin-bottom: 10px;
// }

.md-table[table-header-color="green"] .md-table-head .md-table-head-label {
  font-weight: bold;
}
.md-field {
  // width: 35%;
  // min-height: 48px;
  margin: 4px 0 10px;
  padding-top: 16px;
  display: flex;
  position: relative;
  font-family: inherit;
}

.title {
  color: rgb(0, 0, 0);
  font-weight: 700;
}
.author {
  color: rgb(0, 0, 0);
  font-weight: 700;
}

.imgUrl {
  width: 48px;
  height: 48px;
  border-radius: 10%;
}

.text-overflow-handle {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 120px;
  height: 1.2em;
  white-space: nowrap;
}
// .playlistCard {
//   display: flex;
//   flex-direction: column;
//   position: relative;
//   width: 100%;
//   margin: 30px 0;
//   overflow: unset;
//   font-size: 0.875rem;
//   // box-shadow: 0 1px 4px 0 rgb(0 0 0 / 14%);
//   box-shadow: 0 1px 4px 0 grey;
//   border-radius: 6px;
//   color: #333;
//   background: #fff;
// }
</style>

