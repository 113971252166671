<template>
  <modal
    id="AddPodcast"
    ref="AddPodcast"
    v-if="AddPodcastModal"
    @close="HidePodcastModal"
  >
    <template slot="header">
      <h4 class="modal-title">Add Podcast</h4>
      <md-button
        class="md-simple md-danger md-just-icon md-round modal-default-button"
        @click="HidePodcastModal"
      >
        <md-icon>clear</md-icon>
      </md-button>
    </template>

    <template slot="body">
      <ValidationObserver ref="observer">
        <form @submit.prevent="Add" ref="AddpodcastForm" id="Addpodcast-Form">
          <div class="md-layout">
            <div class="md-layout-item md-size-100 md-small-size-100">
              <div class="file-input img-size">
                <template v-if="!imageCircle">
                  <div class="image-container">
                    <img src="/img/no-image.png" title="" />
                  </div>
                </template>
                <div class="image-container" v-else>
                  <img :src="imageCircle" />
                </div>
                <div class="button-container">
                  <md-button
                    class="md-danger md-raised md-sm"
                    @click="removeImage('circle')"
                    v-if="imageCircle"
                  >
                    <md-icon>clear</md-icon>Remove</md-button
                  >
                  <md-button class="md-sm md-success md-raised md-fileinput">
                    <template v-if="!imageCircle">Add Photo</template>
                    <template v-else>Change</template>
                    <input type="file" name="circle" @change="onFileChange" />
                  </md-button>
                </div>
                <h6 class="text-danger">{{ imageError }}</h6>
              </div>
            </div>

            <div class="md-layout-item md-size-100 md-small-size-100">
              <ValidationProvider
                name="Title"
                ref="title"
                :rules="{
                  required: true,
                  regex: /^[a-zA-Z0-9 '.-]*$/,
                }"
                v-slot="{ errors, passed, failed }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Title</label>
                  <md-input
                    v-model="form.title"
                    type="text"
                    required
                  ></md-input>
                  <slide-y-down-transition>
                    <md-icon class="error" v-show="failed">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success" v-show="passed">done</md-icon>
                  </slide-y-down-transition>
                </md-field>
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>

              <ValidationProvider
                name="Description"
                ref="description"
                :rules="{
                  min: 0,
                  max: 500,
               
                }"
                v-slot="{ errors, passed, failed }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Description </label>
                  <md-textarea
                    v-model="form.description"
                    md-autogrow
                    maxlength="500"
                    type="text"
                  ></md-textarea>
                  <slide-y-down-transition>
                    <md-icon class="error" v-show="failed">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success" v-show="passed">done</md-icon>
                  </slide-y-down-transition>
                </md-field>
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </div>

            <div class="md-layout-item md-small-size-100 md-size-100">
              <ValidationProvider
                name="Author"
                ref="author"
                :rules="{
                  required: true,
                  regex: /^[a-zA-Z0-9 '.-]*$/,
                }"
                v-slot="{ errors, passed, failed }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Author</label>
                  <md-input
                    v-model="form.author"
                    type="text"
                    required
                  ></md-input>
                  <slide-y-down-transition>
                    <md-icon class="error" v-show="failed">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success" v-show="passed">done</md-icon>
                  </slide-y-down-transition>
                </md-field>
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </template>

    <template slot="footer">
      <div class="md-layout-item md-size-100 text-right">
        <md-button
          @click="HidePodcastModal"
          class="md-raised md-danger md-sm mt-4 mr-5 pull-left"
        >
          Close
        </md-button>
        <clip-loader
          :loading="loading"
          :color="color"
          size="20px"
          class="mt-4 mr-5 pull-right"
        ></clip-loader>
        <md-button
          type="submit"
          @click="$refs.AddpodcastForm.requestSubmit()"
          class="md-raised md-sm md-success mt-4"
        >
          Create
        </md-button>
      </div>
    </template>
  </modal>
</template>

<script>
import { Modal } from "@/componentItems";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import { SlideYDownTransition } from "vue2-transitions";
import {
  ValidationObserver,
  ValidationProvider,
} from "vee-validate/dist/vee-validate.full";
import * as notifications from "../../../utils/notifications";

export default {
  components: {
    ClipLoader,
    Modal,
    // Collapse,
    ValidationProvider,
    ValidationObserver,
    SlideYDownTransition,
  },
  props: {
    getPodcasts: {
      type: Function,
      default: () => {},
    },
  },

  data() {
    return {
      AddPodcastModal: false,
      loading: false,
      color: "#3AB982",
      size: "40px",

      form: {
        coverArt: "",
        title: "",
        description: "",
        author: "",
      },
      Episodes: [
        {
          form: {
            title: "",
            description: "",
            sequence: "",
          },
        },
      ],
      artistArray: [],
      audioname: "",
      audio: [],
      imageCircle: "",
      imageProfile: "",
      avatarFileName: "",
      profileFileName: "",
      imageError: "",
    };
  },
  methods: {
    ShowAddModal() {
      this.AddPodcastModal = true;
    },
    HidePodcastModal() {
      var self = this;
      self.AddPodcastModal = false;

      Object.keys(this.form).forEach((key) => {
        self.form[key] = "";
      });
      this.imageCircle = "";
      this.imageError = "";
      this.audio = "";
    },

    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      if (e.target.name) {
        this.createImage(files[0], "circle");
        this.avatarFileName = files[0];
      } else {
        this.createImage(files[0]);
        this.profileFileName = files[0].name;
      }
    },
    createImage(file, type) {
      let reader = new FileReader();
      let vm = this;

      reader.onload = (e) => {
        if (type === "circle") {
          vm.imageCircle = e.target.result;
        } else {
          vm.imageProfile = e.target.result;
        }
      };
      reader.readAsDataURL(file);
    },
    removeImage: function (type) {
      if (type === "circle") {
        this.imageCircle = "";
      } else {
        this.imageProfile = "";
      }
    },
    onResponsiveInverted() {
      if (window.innerWidth < 768) {
        this.responsive = true;
      } else {
        this.responsive = false;
      }
    },

    // createAudio(file) {
    //   let reader = new FileReader();
    //   let vm = this;
    //   reader.onload = (e) => {
    //     vm.audio = e.target.result;
    //   };
    //   reader.readAsDataURL(file);
    // },
    // onAudioChange(e) {
    //   let files = e.target.files || e.dataTransfer.files;
    //   this.createAudio(files[0]);
    //   this.audioname = files[0].name;
    // },

    // addField(value, fieldType) {
    //   fieldType.push({ value: "" });
    // },

    // removeField(index, fieldType) {
    //   fieldType.splice(index, 1);
    // },

    Add(e) {
      e.preventDefault();
      this.$refs.observer.validate().then((success) => {
        if (!success) {
          return;
        }

        if (this.avatarFileName == "") {
          this.imageError = "Avatar is required!";
        } else {
          this.loading = true;
          this.imageError = "";
          let loader = this.$loading.show({
            // Optional parameters
            container: this.fullPage ? null : this.$refs.formContainer,
            canCancel: false,
            onCancel: this.onCancel,
            color: "#eb4034",
          });

          const formData = {
            title: this.form.title,
            description: this.form.description,
            author: this.form.author,
            files: this.avatarFileName,
            category: "Podcast",
          };

          // console.log(formData);
          this.$store
            .dispatch("addPodcast", formData)
            .then((res) => {
              if (!res.data.message.includes("successfully")) {
                throw res;
              }
              loader.hide();
              notifications.Success({
                title: `<small><b>${res.data.message}</b></small>`,
                buttonsStyling: false,
                confirmButtonClass: "md-button md-success",
              });
            })
            .catch((err) => {
              notifications.Error(err.data.message);
              loader.hide();
            })
            .finally(() => {
              this.loading = false;
              loader.hide();
              this.getPodcasts();
              this.HidePodcastModal();
            });
          this.$nextTick(() => {
            this.$refs.observer.reset();
          });
        }
      });
    },
  },

  mounted() {
    this.onResponsiveInverted();
    window.addEventListener("resize", this.onResponsiveInverted);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResponsiveInverted);
  },
};
</script>

<style lang="css" scoped>
.img-size {
  width: 45%;
  height: 45%;
}
.md-content {
  width: 100%;
  max-height: 200px;
  overflow: auto;
}
</style>