<template>
  <modal
    id="UpdateCategory"
    ref="UpdateCategory"
    v-if="UpdateCategoryModal"
    @close="HideCategoryModal"
  >
    <template slot="header">
      <h4 class="modal-title">Edit Category</h4>
      <md-button
        class="md-simple md-danger md-just-icon md-round modal-default-button"
        @click="HideCategoryModal"
      >
        <md-icon>clear</md-icon>
      </md-button>
    </template>

    <template slot="body">
      <ValidationObserver ref="observer">
        <form @submit.prevent="Update" ref="UpdateCategoryForm">
          <div class="md-layout">
            <div class="md-layout-item md-size-100 md-small-size-100">
              <ValidationProvider
                name="Title"
                ref="title"
                :rules="{
                  required: true,
                  regex: /^[a-zA-Z0-9 '.-]*$/,
                }"
                v-slot="{ errors, passed, failed }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Title</label>
                  <md-input
                    v-model="form.title"
                    type="text"
                    required
                  ></md-input>
                  <slide-y-down-transition>
                    <md-icon class="error" v-show="failed">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success" v-show="passed">done</md-icon>
                  </slide-y-down-transition>
                </md-field>
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </template>

    <template slot="footer">
      <div class="md-layout-item md-size-100 text-right">
        <md-button
          @click="HideCategoryModal"
          class="md-raised md-danger md-sm mt-4 mr-5 pull-left"
        >
          Close
        </md-button>
        <clip-loader
          :loading="loading"
          :color="color"
          size="20px"
          class="mt-4 mr-5 pull-right"
        ></clip-loader>
        <md-button
          type="submit"
          @click="$refs.UpdateCategoryForm.requestSubmit()"
          class="md-raised md-sm md-success mt-4"
        >
          Update
        </md-button>
      </div>
    </template>
  </modal>
</template>

<script>
import { Modal } from "@/componentItems";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import { SlideYDownTransition } from "vue2-transitions";
import {
  ValidationObserver,
  ValidationProvider,
} from "vee-validate/dist/vee-validate.full";
import * as notifications from "../../../utils/notifications";

export default {
  components: {
    ClipLoader,
    Modal,
    ValidationProvider,
    ValidationObserver,
    SlideYDownTransition,
  },
  props: {
    getCategories: {
      type: Function,
      default: () => {},
    },
  },

  data() {
    return {
      UpdateCategoryModal: false,
      loading: false,
      color: "#3AB982",
      size: "40px",

      form: {
        title: "",
      },

      loadMore: true,
      currentItem: "",
    };
  },
  methods: {
    ShowUpdateModal(item) {
      this.UpdateCategoryModal = true;
      this.currentItem = item.tagCategoryId;
      this.form.title = item.title;
    },
    HideCategoryModal() {
      var self = this;
      self.UpdateCategoryModal = false;

      Object.keys(this.form).forEach((key) => {
        self.form[key] = "";
      });
    },

    Update(e) {
      e.preventDefault();
      this.$refs.observer.validate().then((success) => {
        if (!success) {
          return;
        }

        this.loading = true;
        this.imageError = "";
        let loader = this.$loading.show({
          container: this.fullPage ? null : this.$refs.formContainer,
          canCancel: false,
          onCancel: this.onCancel,
          color: "#eb4034",
        });

        const formData = {
          id: this.currentItem,
          title: this.form.title,
        };

        // console.log(formData);
        this.$store
          .dispatch("editCategory", formData)
          .then((res) => {
            if (!res.data.message.includes("successfully")) {
              throw res;
            }
            loader.hide();
            notifications.Success({
              title: `<small><b>${res.data.message}</b></small>`,
              buttonsStyling: false,
              confirmButtonClass: "md-button md-success",
            });
          })
          .catch((err) => {
            notifications.Error(err.data.message);
            loader.hide();
          })
          .finally(() => {
            this.loading = false;
            loader.hide();
            this.getCategories();
            this.HideCategoryModal();
          });
        this.$nextTick(() => {
          this.$refs.observer.reset();
        });
      });
    },
  },
};
</script>

<style lang="css" scoped>
</style>