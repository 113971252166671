import api from "../../axios-auth";
// import filesAxios from "../../axios-files";
// import { s3Url } from "../../utils/api.js";

const state = {
  loading: true,
  Users: [],
  error: {},
};

const mutations = {
  request(state) {
    state.loading = true;
  },
  UsersList(state, ListItems) {
    state.loading = false;
    state.Users = ListItems.items;
  },

  error(state, error) {
    state.loading = false;
    state.error = error;
  },
};
const actions = {
  getUsers({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit("request");
     
      api
        .get(`/customers?page=${params.page}&pageSize=${params.pageSize}`)
        .then((res) => {
          commit("UsersList", {
            items: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },

  getUser({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit("request");
      api
        .get(`/customers/${params.id}`)
        .then((res) => {
          commit("UsersList", {
            items: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },
  activateUser({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit("request");
      api
        .put(`/customers/${params.id}/activate`, {
          status: params.status,
        })
        .then((res) => {
          commit("UsersList", {
            items: res,
          });
          resolve(res);
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },
};
const getters = {};

export default { state, mutations, actions, getters };
