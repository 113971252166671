<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-danger">
          <div class="card-icon">
            <md-icon>home_work</md-icon>
          </div>

          <h4 class="title">
            Church
            <div class="pull-right">
              <md-button
                class="md-just-icon md-success md-dense md-raised md-simple"
                @click.prevent="OpenAddChurch"
              >
                <md-icon>add</md-icon>
                <md-tooltip class="md-sm" md-direction="top"
                  >Add Church</md-tooltip
                >
              </md-button>

              <md-button
                :disabled="emptylist"
                @click.native="handleDelete()"
                class="md-just-icon md-danger md-dense md-raised md-simple"
              >
                <md-icon>delete</md-icon>
                <md-tooltip class="md-sm" md-direction="top">Delete</md-tooltip>
              </md-button>

              <md-button
                @click="getChurches"
                class="md-just-icon md-info md-dense md-raised md-simple"
              >
                <md-icon>refresh</md-icon>
                <md-tooltip class="md-sm" md-direction="top"
                  >Refresh Page</md-tooltip
                >
              </md-button>
            </div>
          </h4>
        </md-card-header>
        <clip-loader
          :loading="loading"
          :color="color"
          size="32px"
        ></clip-loader>
        <md-card-content>
          <md-table-empty-state
            v-if="emptylist"
            md-label="No data found"
            :md-description="`Empty table there's no data`"
          >
          </md-table-empty-state>
          <md-table
            v-else
            :value="queriedData"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
            @md-selected="onSelect"
            table-header-color="green"
            class="table-striped table-hover"
          >
            <md-table-toolbar class="toolbar" md-alignment="space-between">
              <div class="md-toolbar-section-start">
                <md-field style="width: 50px" class="mr-3">
                  <label for="pages">Per page</label>
                  <md-select
                    v-model="pagination.pageSize"
                    @change="handlePageSizeChange($event)"
                    name="pages"
                    style="width: 50px"
                  >
                    <md-option
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                      {{ item }}
                    </md-option>
                  </md-select>
                </md-field>
              </div>

              <div class="md-toolbar-section-end">
                <ValidationObserver ref="observer">
                  <form
                    @submit.prevent="searchItem"
                    @keyup.enter.prevent="searchItem"
                    ref="searchForm"
                    id="searchForm"
                  >
                    <ValidationProvider
                      :rules="{
                        required: true,
                      }"
                      v-slot="{ passed, failed }"
                    >
                      <md-field
                        style="width: 270px"
                        :class="[
                          { 'md-error': failed },
                          { 'md-valid': passed },
                        ]"
                      >
                        <md-input
                          type="search"
                          clearable
                          style="width: 250px"
                          placeholder="Search..."
                          v-model="searchQuery"
                        >
                        </md-input>
                        <md-button
                          :disabled="emptylist"
                          class="
                            md-just-icon md-success md-dense md-raised md-simple
                          "
                          @click="$refs.searchForm.requestSubmit()"
                        >
                          <md-icon>search</md-icon>
                          <md-tooltip class="md-sm" md-direction="top"
                            >Search</md-tooltip
                          >
                        </md-button>
                      </md-field>
                    </ValidationProvider>
                  </form>
                </ValidationObserver>
              </div>
            </md-table-toolbar>
            <md-table-toolbar
              slot="md-table-alternate-header"
              slot-scope="{ count }"
              style="margin-top: -20px"
            >
              <div class="md-toolbar-section-start">
                {{ getAlternateLabel(count) }}
              </div>
            </md-table-toolbar>

            <md-table-row
              slot="md-table-row"
              slot-scope="{ item }"
              md-selectable="multiple"
            >
              <md-table-cell md-label="Image">
                <div v-if="true" type="hidden" hidden>
                  {{
                    (image = (obj = Object.assign({}, item.coverArt))["url"])
                  }}
                </div>

                <img :src="image" class="imgUrl"
              /></md-table-cell>
              <md-table-cell md-label="Title">{{ item.title }}</md-table-cell>
              <md-table-cell md-label="Description">
                <!-- <span>{{ item.description.length == true ? item.description | truncate(60, "...") : Null }}</span> -->
                <span>{{ item.description | truncate(60, "...") }}</span>
              </md-table-cell>
              <md-table-cell md-label="Followers">{{
                item.followerCount
              }}</md-table-cell>
              <md-table-cell md-label="Sermons">{{
                item.sermonCount
              }}</md-table-cell>
              <md-table-cell md-label="Likes">{{
                item.likeCount
              }}</md-table-cell>
              <md-table-cell md-label="IsTop">
                <md-switch v-model="item.isTop" @change="handleTop(item)">
                  {{ item.isTop == true ? "Yes" : "No" }}</md-switch
                >
              </md-table-cell>
              <!-- <md-table-cell md-label="Popular">
                <md-switch
                  v-model="item.isPopular"
                  @change="handlePopular(item)"
                >
                  {{ item.isPopular == true ? "Yes" : "No" }}</md-switch
                >
              </md-table-cell> -->
              <md-table-cell md-label="Status">
                <md-switch
                  v-model="item.isActive"
                  @change="handleActivate(item)"
                >
                  {{ item.isActive == true ? "Active" : "InActive" }}</md-switch
                >
              </md-table-cell>

              <md-table-cell md-label="Actions">
                <md-button
                  class="md-just-icon md-info md-simple"
                  @click.native="getChurch(item)"
                >
                  <md-icon>dvr</md-icon>
                  <md-tooltip class="md-sm" md-direction="top"
                    >Edit Church</md-tooltip
                  >
                </md-button>
                <md-button
                  @click.native="HandleTag(item)"
                  class="md-just-icon md-primary md-dense md-raised md-simple"
                >
                  <md-icon>local_offer</md-icon>
                  <md-tooltip class="md-sm" md-direction="top">Tag</md-tooltip>
                </md-button>
                <md-button
                  @click.native="HandleNotification(item)"
                  class="md-just-icon md-success md-simple"
                >
                  <md-icon>send</md-icon>
                  <md-tooltip class="md-sm" md-direction="top"
                    >Push Notification</md-tooltip
                  >
                </md-button>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ comma(total) }} entries
            </p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.page"
            :per-page="pagination.pageSize"
            :total="total"
            @pagechange="handlePageChange"
          >
          </pagination>
        </md-card-actions>
        <Tag
          ref="AddTag"
          :category="category"
          :currentItem="currentItem"
          :getlist="getChurches"
        />
        <PushNotification
          ref="Notification"
          :category="'churches'"
          :contentId="currentItem"
          :Image="Image"
          :getlist="getChurches"
        />
      </md-card>
      <Add ref="Addchurch" :getChurches="getChurches" />

      <Update ref="Updatechurch" :getChurches="getChurches" />
    </div>
  </div>
</template>

<script>
import Add from "./Add";
import Update from "./Update";
import Tag from "../Tags/TagContent.vue";
import PushNotification from "../Notifications/Notification.vue";
import { Pagination, commaSeparator } from "@/componentItems";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import _ from "lodash";
import * as notifications from "../../../utils/notifications";
import {
  ValidationObserver,
  ValidationProvider,
} from "vee-validate/dist/vee-validate.full";

export default {
  name: "Church",
  components: {
    Tag,
    Add,
    Update,
    Pagination,
    ClipLoader,
    PushNotification,
    ValidationObserver,
    ValidationProvider,
  },

  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */

    queriedData() {
      return this.tableData;
    },
    to() {
      let highBound = this.from + this.pagination.pageSize;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.pageSize * (this.pagination.page - 1);
    },
    total() {
      return this.pagination.total;
    },
  },
  mixins: [commaSeparator],
  created() {
    this.getChurches();
  },
  data() {
    return {
      currentSort: "name",
      currentSortOrder: "asc",
      pagination: {
        pageSize: 5,
        page: 1,
        perPageOptions: [5, 10, 25, 50, 100],
        total: 0,
      },
      hidden: false,
      searchQuery: "",
      propsToSearch: ["title", "email", "age"],
      tableData: [],
      searchedData: [],
      fuseSearch: null,
      selected: [],

      emptylist: false,
      loading: false,
      color: "#3AB982",
      size: "40px",
      category: "Church",
      currentItem: "",
      Image: "",
      Title: "",
    };
  },
  methods: {
    onSelect(items) {
      this.selected = items;
    },
    getAlternateLabel(count) {
      let plural = "";
      if (count > 1) {
        plural = "s";
      }
      return `${count} song${plural} selected`;
    },
    // trucateText(value, length = 30) {
    //   return value.length <= length
    //     ? value
    //     : value.substring(0, length) + "...";
    // },

    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "desc") {
          return a[sortBy].localeCompare(b[sortBy]);
        }
        return b[sortBy].localeCompare(a[sortBy]);
      });
    },

    OpenAddChurch() {
      this.$refs.Addchurch.ShowAddModal();
    },

    getChurch(item) {
      this.$refs.Updatechurch.ShowUpdateModal(item);
      this.$store.dispatch("getChurch", item.churchId);
    },
    HandleTag(item) {
      this.$refs.AddTag.ShowTagModal(item);
      this.currentItem = item.churchId;
    },
    HandleNotification(item) {
      this.$refs.Notification.ShowNotificationModal(item);
      this.currentItem = item.churchId;
      this.Image = item.coverArt.url;
      this.Title = item.title;
    },

    getChurches() {
      this.loading = true;
      const params = {
        page: this.pagination.page - 1,
        pageSize: this.pagination.pageSize,
      };
      this.$store
        .dispatch("getChurches", params)
        .then((res) => {
          if (res.data.paging.count === 0) {
            this.emptylist = true;
          } else {
            this.tableData = res.data.data;
            this.pagination.total = res.data.paging.count;
          }
        })

        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          if (this.tableData.length > 0) {
            this.emptylist = false;
          }
          this.loading = false;
          this.searchQuery = "";
        });
    },

    searchItem() {
      if (this.searchQuery == "") {
        this.$notify({
          message: "Search field is empty",
          icon: "add_alert",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "danger",
        });
      } else {
        this.loading = true;
        const formData = {
          searchTerm: this.searchQuery,
          category: this.category,
          page: this.pagination.page - 1,
          pageSize: this.pagination.pageSize,
        };
        this.$store
          .dispatch("searchItem", formData)
          .then((res) => {
            if (res.data.length === 0) {
              this.emptylist = true;
            } else {
              this.tableData = res.data;
            }
          })
          .catch((err) => {
            this.$notify({
              message: `<small><b>${err.response.data.message}</b></small>`,
              icon: "add_alert",
              horizontalAlign: "right",
              verticalAlign: "top",
              type: "danger",
            });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    handlePageChange(value) {
      this.pagination.page = value;
      this.getChurches();
    },
    handlePageSizeChange(event) {
      this.pagination.pageSize = event.target.value;
      this.pagination.page = 1;
      this.getChurches();
    },

    handleActivate(item) {
      this.loading = true;

      const params = {
        id: item.churchId,
        status: item.isActive,
      };

      this.$store
        .dispatch("activateChurch", params)
        .then((res) => {
          if (!res.data.message.includes("successfully")) {
            throw res;
          }
          this.$notify({
            message: `<small><b>${res.data.message}</b></small>`,
            icon: "add_alert",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "success",
          });
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleTop(item) {
      this.loading = true;
      const params = {
        id: item.churchId,
        status: item.isTop,
      };

      this.$store
        .dispatch("TopChurch", params)
        .then((res) => {
          if (!res.data.message.includes("successfully")) {
            throw res;
          }
          this.$notify({
            message: `<small><b>${res.data.message}</b></small>`,
            icon: "add_alert",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "success",
          });
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
          this.getChurches();
        });
    },
    handlePopular(item) {
      this.loading = true;
      const params = {
        id: item.churchId,
        status: item.isPopular,
      };

      this.$store
        .dispatch("PopularChurch", params)
        .then((res) => {
          if (!res.data.message.includes("successfully")) {
            throw res;
          }
          this.$notify({
            message: `<small><b>${res.data.message}</b></small>`,
            icon: "add_alert",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "success",
          });
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleDeactivate() {
      if (this.selected.length > 0) {
        // this.loading = true;
        const params = {
          id: this.selected[0].songId,
          status: false,
        };
        console.log(params);

        // this.$store
        //   .dispatch("deleteArtist")
        //   .then(() => {
        //     this.loading = false;
        //     let code = 200;

        //     if (code) {
        //       this.$notify({
        //         message: "Song Deactivated Successfully",
        //         icon: "add_alert",
        //         horizontalAlign: "right",
        //         verticalAlign: "top",
        //         type: "success",
        //       });
        //     }
        //   })
        //   .catch((error) => {
        //     console.log(error);
        //   });
      } else {
        this.$notify({
          message: "Select an Item from the List",
          icon: "add_alert",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "danger",
        });
      }
    },

    handleDelete() {
      if (this.selected.length > 0) {
        this.loading = true;

        //Populate the selected data
        let indexToDelete = this.selected.map((tableRow) => tableRow.churchId);

        /** Use chunk to creeate an array of elements split into groups the length of size and then send it to a loop */
        var formData = _.chunk(indexToDelete, 1);
        for (var i = 0, j = formData.length; i < j; i++) {
          //Populate the the array to get the churchId
          const Items = formData[i].map((t) => t);

          //call the key value back
          const params = Items[0];

          this.$store
            .dispatch("deleteChurch", params)
            .then((res) => {
              if (!res.message.includes("successfully")) {
                throw res;
              }
              this.$notify({
                message: `${res.message}`,
                icon: "add_alert",
                horizontalAlign: "right",
                verticalAlign: "top",
                type: "success",
              });
            })
            .catch((error) => {
              notifications.Error(error.message);
            })
            .finally(() => {
              this.loading = false;
              this.getChurches();
            });
        }
      } else {
        this.$notify({
          message: "Select an Item from the List",
          icon: "add_alert",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "danger",
        });
      }
    },
  },
};
</script>
<style >
.truncate:hover {
  position: absolute;
  max-width: none;
  z-index: 100;
  background: #f5f5f5;
  overflow: visible;
  top: -6px;
  padding: 5px 20px 5px 0;
}
</style>


<style lang="scss" scoped>
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
.toolbar {
  margin-bottom: 10px;
}

// .md-field {
//   width: 30%;
//   min-height: 48px;
//   margin: 4px 0 24px;
//   padding-top: 16px;
//   display: flex;
//   position: relative;
//   font-family: inherit;
// }
.md-table[table-header-color="green"] .md-table-head .md-table-head-label {
  font-weight: bold;
}
.imgUrl {
  width: 48px;
  height: 48px;
  border-radius: 10%;
}
.text-overflow-handle {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>