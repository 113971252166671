<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-65 mx-auto md-small-size-65">
      <ValidationObserver ref="observer">
        <form
          @submit.prevent="updateSermon"
          @keyup.enter.prevent="updateSermon"
        >
          <md-card md-with-hover>
            <md-card-header class="md-card-header-icon md-card-header-danger">
              <div class="card-icon">
                <md-icon>assignment</md-icon>
              </div>
              <h4 class="title">Edit Sermon</h4>
            </md-card-header>
            <md-card-content>
              <div class="md-layout">
                <div class="md-layout-item md-size-30 md-small-size-100">
                  <div class="file-input img-size">
                    <template v-if="!imageCircle">
                      <div class="image-container">
                        <img :src="avatarImg" title="" />
                      </div>
                    </template>
                    <div class="image-container" v-else>
                      <img :src="imageCircle" />
                    </div>
                    <div class="button-container">
                      <md-button
                        class="md-danger md-raised md-sm"
                        @click="removeImage('circle')"
                        v-if="imageCircle"
                      >
                        <md-icon>clear</md-icon>Remove</md-button
                      >
                      <md-button
                        class="md-sm md-success md-raised md-fileinput"
                      >
                        <template v-if="!imageCircle">Add Photo</template>
                        <template v-else>Change</template>
                        <input
                          type="file"
                          name="circle"
                          accept="image/*"
                          @change="onFileChange"
                        />
                      </md-button>
                    </div>
                    <h6 class="text-danger">{{ imageError }}</h6>
                  </div>
                </div>

                <div class="md-layout-item md-size-70 md-small-size-100">
                  <ValidationProvider
                    name="Sermon Title "
                    ref="Sermon Title "
                    :rules="{
                      required: true,
                    }"
                    v-slot="{ errors, passed, failed }"
                  >
                    <md-field
                      :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                    >
                      <label>Sermon Title</label>
                      <md-input
                        v-model="sermonTitle"
                        data-vv-name="sermonTitle"
                        type="text"
                        required
                        name="sermonTitle"
                      >
                      </md-input>

                      <slide-y-down-transition>
                        <md-icon class="error" v-show="failed">close</md-icon>
                      </slide-y-down-transition>
                      <slide-y-down-transition>
                        <md-icon class="success" v-show="passed">done</md-icon>
                      </slide-y-down-transition>
                    </md-field>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>

                  <ValidationProvider
                    name="Sermon Author "
                    ref="Sermon Author "
                    data-vv-name="text"
                    :rules="{
                      required: true,
                    }"
                    v-slot="{ errors, passed, failed }"
                  >
                    <md-field
                      :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                    >
                      <label>Sermon Author</label>
                      <md-input
                        v-model="sermonAuthor"
                        data-vv-name="sermonAuthor"
                        type="text"
                        required
                        name="sermonAuthor"
                      >
                      </md-input>

                      <slide-y-down-transition>
                        <md-icon class="error" v-show="failed">close</md-icon>
                      </slide-y-down-transition>
                      <slide-y-down-transition>
                        <md-icon class="success" v-show="passed">done</md-icon>
                      </slide-y-down-transition>
                    </md-field>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>

                  <md-field>
                    <label>Description</label>
                    <md-textarea
                      v-model="sermonDescription"
                      type="text"
                    ></md-textarea>
                  </md-field>

                  <br />
                  <ValidationProvider
                    name="Type"
                    ref="type"
                    :rules="{
                      required: true,
                    }"
                    v-slot="{ errors, passed, failed }"
                  >
                    <div
                      class="ml-5"
                      :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                    >
                      <span><b>Sermon Type :</b></span>
                      <div>
                        <md-radio v-model="sermonType" :value="false"
                          >Audio</md-radio
                        >
                        <md-radio v-model="sermonType" :value="true"
                          >Video</md-radio
                        >
                      </div>
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </div>
                <div class="md-layout-item md-small-size-100 md-size-100"></div>

                <div class="md-layout-item md-small-size-100 md-size-100">
                  <ValidationProvider
                    name="church "
                    ref="church "
                    :rules="{
                      required: true,
                    }"
                    v-slot="{ errors, passed, failed }"
                  >
                    <label class="artist-label">Church </label>
                    <multiselect
                      v-model="churches"
                      name="church"
                      :options="churchArray"
                      placeholder="Select Church"
                      label="title"
                      track-by="churchId"
                      :limit="1"
                      :searchable="true"
                      :show-labels="true"
                      :close-on-select="true"
                      :clear-on-select="true"
                      deselect-label="Remove"
                      select-label="Choose Church"
                      open-direction="bottom"
                      :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                    >
                      <slide-y-down-transition>
                        <md-icon class="error" v-show="failed">close</md-icon>
                      </slide-y-down-transition>
                      <slide-y-down-transition>
                        <md-icon class="success" v-show="passed">done</md-icon>
                      </slide-y-down-transition>
                    </multiselect>

                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                  <br />
                </div>

                <!-- files -->
                <div
                  class="md-layout-item md-small-size-100 md-size-100"
                  v-if="sermonType == false"
                >
                  <ValidationProvider
                    name="Audio"
                    ref="audio"
                    :rules="{
                      required: false,
                    }"
                    v-slot="{ errors, passed, failed }"
                  >
                    <md-field
                      :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                    >
                      <label>Audio</label>
                      <md-file
                        type="file"
                        id="audiofile"
                        ref="audiofile"
                        name="audio"
                        accept="audio/*"
                        v-model="audioname"
                        @change="onAudioChange"
                      />
                      <md-button class="md-just-icon md-simple">
                        <md-icon>info</md-icon>
                        <md-tooltip class="md-sm" md-direction="top"
                          >Valid extension: mp3,3gp.Valid size:less than (<b
                            >30 M.B</b
                          >)</md-tooltip
                        >
                      </md-button>
                      <slide-y-down-transition>
                        <md-icon class="error" v-show="failed">close</md-icon>
                      </slide-y-down-transition>
                      <slide-y-down-transition>
                        <md-icon class="success" v-show="passed">done</md-icon>
                      </slide-y-down-transition>
                    </md-field>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </div>

                <div
                  class="md-layout-item md-small-size-100 md-size-100"
                  v-if="sermonType == true"
                >
                  <ValidationProvider
                    name="Video"
                    ref="video"
                    :rules="{
                      required: false,
                    }"
                    v-slot="{ errors, passed, failed }"
                  >
                    <md-field
                      :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                    >
                      <label>Video</label>
                      <md-file
                        type="file"
                        id="videofile"
                        ref="videofile"
                        name="video"
                        accept="video/*"
                        v-model="videoname"
                        @change="onVideoChange"
                      />
                      <md-button class="md-just-icon md-simple">
                        <md-icon>info</md-icon>
                        <md-tooltip class="md-sm" md-direction="top"
                          >Valid extension: mp3,3gp.Valid size:less than (<b
                            >30 M.B</b
                          >)</md-tooltip
                        >
                      </md-button>
                      <slide-y-down-transition>
                        <md-icon class="error" v-show="failed">close</md-icon>
                      </slide-y-down-transition>
                      <slide-y-down-transition>
                        <md-icon class="success" v-show="passed">done</md-icon>
                      </slide-y-down-transition>
                    </md-field>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </div>

                <!-- files -->
              </div>
            </md-card-content>
            <md-card-actions md-alignment="space-between">
              <md-button
                @click.native="goBack()"
                class="md-sm md-dense md-raised md-danger"
                >Cancel</md-button
              >

              <!-- <clip-loader
                :loading="loading"
                :color="color"
                size="32px"
              ></clip-loader> -->

              <md-button
                class="md-sm md-dense md-raised md-success"
                type="submit"
                >Submit</md-button
              >
            </md-card-actions>
          </md-card>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import { SlideYDownTransition } from "vue2-transitions";
// import ClipLoader from "vue-spinner/src/ClipLoader.vue";

import {
  ValidationObserver,
  ValidationProvider,
  extend,
} from "vee-validate/dist/vee-validate.full";
import * as notifications from "../../../utils/notifications";

extend("required", {
  message: (field) => "The " + `${field}` + " field is required. ",
});
export default {
  props: {
    profileCard: {
      type: String,
      default: "../../img/no-image-new.png",
    },
    avatarImg: {
      type: String,
      default: "../../img/no-image.png",
    },
  },

  components: {
    ValidationProvider,
    ValidationObserver,
    SlideYDownTransition,
    // ClipLoader,
  },
  created() {
    this.getSermon();
    this.getChurches();
  },

  data() {
    return {
      currentSort: "name",
      currentSortOrder: "asc",
      searchQuery: "",
      tableData: "",
      searchedData: [],
      churchArray: [],
      fuseSearch: null,
      emptylist: false,
      currentSermon: "",
      editcurrentSermon: false,
      cardUserImage: "",
      sermonName: "",
      churches: [],
      aboutSermon: "",
      favDescription: "",
      facebookUrl: "",
      instagramUrl: "",
      twitterUrl: "",
      status: "",
      topSermon: true,
      addedOn: "",
      modifiedOn: "",
      loading: false,
      color: "#3AB982",
      imageCircle: "",
      imageCircleFile: File,
      avatarFileChanged: false,
      imageProfile: "",
      notifyUser: true,
      avatarFileName: "",
      profileFileName: "",
      sermonTitle: "",
      sermonType: false,
      sermonAuthor: "",
      sermonDescription: "",
      churchId: "",
      audioname: "",
      videoname: "",
      audio: "",
      audioFile: "",
      video: "",
      videoFile: "",
      imageError: "",
      audioFileChanged: false,
      videoFileChanged: false,
    };
  },

  methods: {
    getChurches() {
      const params = {
        page: 0,
        pageSize: 100,
      };
      this.$store
        .dispatch("getChurches", params)
        .then((res) => {
          this.churchArray = res.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getSermon() {
      this.loading = false;

      this.$store
        .dispatch("getSermon", {
          currentId: this.$route.params.id,
        })
        .then((res) => {
          this.loading = false;

          let sermon = res.data[0];

          const {
            title,
            description,
            isActive,
            isTop,
            author,
            type,
            coverArt,
            churchId,
            multiMedia,
          } = sermon;

          this.imageCircle = coverArt.url;
          this.churchId = churchId;
          ///this.churches.push(churchId)

          this.sermonTitle = title;
          this.sermonDescription = description;

          this.topSermon = isTop;
          this.status = isActive;
          this.sermonAuthor = author;

          let str = `${title}`;
          if (type.toLowerCase() == "audio") {
            this.sermonType = false;
            this.audioname = multiMedia.url
              ? `${str.replace(/\s+/g, "-").toLowerCase()}.mp3`
              : `${str.replace(/\s+/g, "-").toLowerCase()}.mp3`;
          } else if (type.toLowerCase() == "video") {
            this.sermonType = true;
            this.videoname = multiMedia.url
              ? `${str.replace(/\s+/g, "-").toLowerCase()}.mp4`
              : `${str.replace(/\s+/g, "-").toLowerCase()}.mp4`;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    goBack() {
      this.$router.back();
    },
    onAudioChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      this.createAudio(files[0]);
      this.audioname = files[0].name;
      this.audioFileChanged = true;
    },
    onVideoChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      this.createVideo(files[0]);
      this.videoname = files[0].name;
      this.videoFileChanged = true;
    },

    createAudio(file) {
      let reader = new FileReader();
      let vm = this;
      reader.onload = (e) => {
        vm.audio = e.target.result;
        vm.audioFile = file;
      };
      reader.readAsDataURL(file);
    },
    createVideo(file) {
      let reader = new FileReader();
      let vm = this;
      reader.onload = (e) => {
        vm.video = e.target.result;
        vm.videoFile = file;
      };
      reader.readAsDataURL(file);
    },
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      this.avatarFileChanged = true;
      if (e.target.name) {
        this.createImage(files[0], "circle");
        this.avatarFileName = files[0].name;
        this.imageError = "";
      } else {
        this.createImage(files[0]);
        this.profileFileName = files[0].name;
        this.imageError = "";
      }
    },
    createImage(file, type) {
      let reader = new FileReader();
      let vm = this;

      reader.onload = (e) => {
        if (type === "circle") {
          vm.imageCircle = e.target.result;
          vm.imageCircleFile = file;
        } else {
          vm.imageProfile = e.target.result;
        }
      };
      reader.readAsDataURL(file);
    },
    removeImage: function (type) {
      if (type === "circle") {
        this.imageCircle = "";
      } else {
        this.imageProfile = "";
      }
    },
    HideAddData() {
      this.$router.push("/sermon");
    },
    updateSermon(e) {
      if (e.which !== 13 || e.keyCode !== 13 || e.key !== "Enter") {
        e.preventDefault();
        if (this.sermonTitle == "") {
          console.log("empty sermon name");
        } else if (this.sermonDescription == "") {
          console.log("empty description");
        } else {
          this.loading = true;
          this.imageError = "";
          let filesArray = [];
          let typeSermon = "";
          let churchId = "";
          let loader = this.$loading.show({
            // Optional parameters
            container: this.fullPage ? null : this.$refs.formContainer,
            canCancel: false,
            onCancel: this.onCancel,
            color: "#eb4034",
          });
          ///console.log(this.$refs.sermonAvatarCircle);

          if (this.avatarFileChanged == false) {
            console.log("No image file added ");
          } else {
            filesArray.push(this.imageCircleFile);
            console.log("is file");
          }

          // if audio or video file is changed

          if (this.audioFileChanged || this.videoFileChanged) {
            if (this.sermonType) {
              typeSermon = "video";
              filesArray.push(this.videoFile);
            } else {
              typeSermon = "audio";
              filesArray.push(this.audioFile);
            }
          } else {
            typeSermon = this.sermonType == true ? "Video" : "Audio";
          }

          console.log(
            this.sermonTitle +
              "" +
              this.sermonType +
              this.sermonAuthor +
              "" +
              this.sermonDescription +
              "" +
              typeSermon
          );

          console.log(filesArray);
          ///console.log(this.churches.churchId);

          if (this.churches == null || this.churches == undefined) {
            churchId = "";
          } else {
            churchId = this.churches.churchId;
          }

          this.$store
            .dispatch("updateSermon", {
              id: this.$route.params.id,
              title: this.sermonTitle,
              author: this.sermonAuthor,
              description: this.sermonDescription,
              files: filesArray,
              type: typeSermon,
              churchId: churchId,
              category: "Sermon",
            })
            .then((res) => {
              console.log(res);

              this.loading = false;
              loader.hide();
              notifications.Success({
                title: "Sermon  updated successfully",
                buttonsStyling: false,
                confirmButtonClass: "md-button md-success",
              });
            })
            .then(() => {
              this.loading = false;
              loader.hide();
              this.HideAddData();
            })
            .catch((err) => {
              notifications.Error(err);
              loader.hide();
              this.loading = false;
            });
        }
      }
    },
  },

  watch: {
    churchArray: function () {
      var churchPos = this.churchArray
        .map(function (option) {
          return option.churchId;
        })
        .indexOf(this.churchId);
      this.churches = this.churchArray[churchPos];
    },
  },
  mounted() {
    setTimeout(() => {
      this.getSermon();
      this.getChurches();
    }, 800);
  },
};
</script>
<style lang="css">
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
</style>
<style lang="css" scoped>
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
.artist-label {
  font-size: 10px;
  font-weight: 400;
  color: #aaaaaa !important;
  line-height: 1.4;
  top: 26px;
}
.spacing {
  margin: "10px";
  padding: "10px";
}
.item-status {
  background-color: #4caf50;
  padding: 5px;
  color: white;
  border-radius: 10%;
}
/* .img-width {
  width: 50px;
}
.img-size {
  width: 80%;
  height: 80%;
} */

.md-table[table-header-color="green"] .md-table-head .md-table-head-label {
  font-weight: bold;
}
</style>
