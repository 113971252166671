//import axios from "../../axios-auth";
import { getAdmins, getAdmin } from "../../utils/api";

const state = {};
const mutations = {};
const actions = {
  getAdmins({ commit }) {
    return new Promise((resolve, reject) => {
      getAdmins()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },

  getAdmin({ commit }) {
    return new Promise((resolve, reject) => {
      getAdmin()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },
};
const getters = {};

export default { state, mutations, actions, getters };
