import { render, staticRenderFns } from "./SermonList.vue?vue&type=template&id=aa1d3422&scoped=true"
import script from "./SermonList.vue?vue&type=script&lang=js"
export * from "./SermonList.vue?vue&type=script&lang=js"
import style0 from "./SermonList.vue?vue&type=style&index=0&id=aa1d3422&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aa1d3422",
  null
  
)

export default component.exports