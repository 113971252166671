<template>
  <modal
    id="AddCategory"
    ref="AddCategory"
    v-if="AddCategoryModal"
    @close="HideCategoryModal"
  >
    <template slot="header">
      <h4 class="modal-title">Add Category</h4>
      <md-button
        class="md-simple md-danger md-just-icon md-round modal-default-button"
        @click="HideCategoryModal"
      >
        <md-icon>clear</md-icon>
      </md-button>
    </template>

    <template slot="body">
      <ValidationObserver ref="observer">
        <form @submit.prevent="Add" ref="AddCategoryForm">
          <div class="md-layout">
            <div
              class="md-layout-item md-size-80 md-small-size-100"
              v-for="(item, index) in Category"
              :key="index"
            >
              <ValidationProvider
                name="Title"
                ref="title"
                :rules="{
                  required: true,
                  regex: /^[a-zA-Z0-9 '.-]*$/,
                }"
                v-slot="{ errors, passed, failed }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Title</label>
                  <md-input
                    v-model="item.title"
                    type="text"
                    required
                  ></md-input>
                  <slide-y-down-transition>
                    <md-icon class="error" v-show="failed">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success" v-show="passed">done</md-icon>
                  </slide-y-down-transition>
                </md-field>
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-size-10 md-small-size-100">
              <md-button
                class="
                  md-sm
                  md-raised
                  md-success
                  md-just-icon
                  md-simple-icon
                  md-round
                "
                @click.prevent="addField"
              >
                <md-icon>add</md-icon>
                <md-tooltip class="md-sm" md-direction="top">Add</md-tooltip>
              </md-button>
            </div>
            <div class="md-layout-item md-size-10 md-small-size-100">
              <md-button
                class="
                  md-sm md-raised md-danger md-just-icon md-simple-icon md-round
                "
                @click.prevent="removeField"
              >
                <md-icon>clear</md-icon>
                <md-tooltip class="md-sm" md-direction="top">Remove</md-tooltip>
              </md-button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </template>

    <template slot="footer">
      <div class="md-layout-item md-size-100 text-right">
        <md-button
          @click="HideCategoryModal"
          class="md-raised md-danger md-sm mt-4 mr-5 pull-left"
        >
          Close
        </md-button>
        <clip-loader
          :loading="loading"
          :color="color"
          size="20px"
          class="mt-4 mr-5 pull-right"
        ></clip-loader>
        <md-button
          type="submit"
          @click="$refs.AddCategoryForm.requestSubmit()"
          class="md-raised md-sm md-success mt-4"
        >
          Create
        </md-button>
      </div>
    </template>
  </modal>
</template>

<script>
import { Modal } from "@/componentItems";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import { SlideYDownTransition } from "vue2-transitions";
import {
  ValidationObserver,
  ValidationProvider,
} from "vee-validate/dist/vee-validate.full";
import * as notifications from "../../../utils/notifications";

export default {
  components: {
    ClipLoader,
    Modal,
    // Collapse,
    ValidationProvider,
    ValidationObserver,
    SlideYDownTransition,
  },
  props: {
    getCategories: {
      type: Function,
      default: () => {},
    },
  },

  data() {
    return {
      AddCategoryModal: false,
      loading: false,
      color: "#3AB982",
      size: "40px",

      Category: [
        {
          title: "",
        },
      ],
    };
  },
  methods: {
    ShowAddModal() {
      this.AddCategoryModal = true;
    },
    HideCategoryModal(index) {
      var self = this;
      self.AddCategoryModal = false;
      self.Category.splice(index, 1);

      const Items = self.Category.map((t) => t);
      for (var i = 0, j = Items.length; i < j; i++) {
        Object.keys(Items[i]).forEach((key) => {
          Items[i][key] = "";
        });
      }
    },
    addField() {
      this.Category.push({
        title: "",
      });
    },

    removeField(index) {
      this.Category.splice(index, 1);
    },

    Add(e) {
      e.preventDefault();
      this.$refs.observer.validate().then((success) => {
        if (!success) {
          return;
        }

        let loader = this.$loading.show({
          container: this.fullPage ? null : this.$refs.formContainer,
          canCancel: false,
          onCancel: this.onCancel,
          color: "#eb4034",
        });

        const Items = this.Category.map((t) => t.title);
        const formData = {
          titles: Items,
        };

        this.$store
          .dispatch("addCategory", formData)
          .then((res) => {
            if (!res.data.message.includes("successfully")) {
              throw res;
            }
            loader.hide();
            notifications.Success({
              title: `<small><b>${res.data.message}</b></small>`,
              buttonsStyling: false,
              confirmButtonClass: "md-button md-success",
            });
          })
          .catch((err) => {
            notifications.Error(err.data.message);
            loader.hide();
          })
          .finally(() => {
            this.loading = false;
            loader.hide();
            this.getCategories();
            this.HideCategoryModal();
          });
        this.$nextTick(() => {
          this.$refs.observer.reset();
        });
      });
    },
  },
};
</script>

<style lang="css" scoped>
.md-content {
  width: 100%;
  max-height: 200px;
  overflow: auto;
}
</style>