<template>
  <div class="md-layout">
    <div class="md-layout-item md-small-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-danger">
          <div class="card-text">
            <h4 class="title">Change Your Password</h4>
          </div>
        </md-card-header>

        <form class="form-horizontal" @submit.prevent="onSubmit">
          <md-card-content>
            <clip-loader
              :loading="loading"
              :color="color"
              size="25px"
            ></clip-loader>
            <br />
            <br />
            <div class="md-layout">
              <label class="md-layout-item md-size-15 md-form-label">
                Old Password
              </label>
              <div class="md-layout-item">
                <md-field>
                  <md-input v-model="oldPassword" type="password"></md-input>
                </md-field>
              </div>
            </div>
            <div class="md-layout">
              <label class="md-layout-item md-size-15 md-form-label">
                New Password
              </label>
              <div class="md-layout-item">
                <md-field>
                  <md-input v-model="newPassword" type="password"></md-input>
                </md-field>
              </div>
            </div>
            <div class="md-layout">
              <label class="md-layout-item md-size-15 md-form-label">
                Confirm New Password
              </label>
              <div class="md-layout-item">
                <md-field>
                  <md-input
                    v-model="newPasswordConfirm"
                    type="password"
                  ></md-input>
                </md-field>
              </div>
            </div>
          </md-card-content>

          <md-card-actions md-alignment="right">
            <md-button class="md-success" @click="onSubmit"
              >Change Password</md-button
            >
          </md-card-actions>
        </form>
      </md-card>
    </div>
  </div>
</template>
<script>
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

export default {
  components: {
    ClipLoader,
  },
  data() {
    return {
      emailadress1: "",
      oldPassword: "",
      newPassword: "",
      newPasswordConfirm: "",
      msg: "",
      loading: false,
      color: "#3AB982",
    };
  },

  methods: {
    onSubmit() {},
  },
};
</script>