import Vue from "vue";
import VueRouter from "vue-router";

import store from "../store";

import ChangePasswords from "../pages/Dashboard/ChangePasswords.vue";
import ResetPassExternal from "../pages/auth/ResetPassword.vue";
import Dashboard from "../pages/Dashboard/Dashboard.vue";
import ArtistsList from "../pages/Dashboard/Artists/ArtistsList.vue";
import UserList from "../pages/Dashboard/Users/UserList.vue";
import UserDetails from "../pages/Dashboard/Users/UserDetails.vue";
import NewUser from "../pages/Dashboard/Users/NewUser.vue";
import ArtistsDetails from "../pages/Dashboard/Artists/ArtistsDetails.vue";
import NewArtist from "../pages/Dashboard/Artists/NewArtist.vue";
import Playlist from "../pages/Dashboard/Playlist/Playlist.vue";
import PlaylistDetail from "../pages/Dashboard/Playlist/PlaylistDetails.vue";
import Newplaylist from "../pages/Dashboard/Playlist/Newplaylist.vue";
// import NotificationsList from "../pages/Dashboard/Notifications/NotificationsList.vue";
// import NewNotification from "../pages/Dashboard/Notifications/NewNotification.vue";
import Song from "../pages/Dashboard/Songs/SongItem.vue";
import ChurchList from "../pages/Dashboard/Church/ChurchList.vue";
import AlbumList from "../pages/Dashboard/Album/AlbumList.vue";
import AlbumSong from "../pages/Dashboard/Album/AlbumSongs.vue";

import BibleList from "../pages/Dashboard/Bible/BibleList.vue";
import DailyList from "../pages/Dashboard/Daily/DailyList.vue";
import PodcastList from "../pages/Dashboard/Podcast/PodcastList.vue";
import Episodes from "../pages/Dashboard/Podcast/Episodes.vue";
import SermonList from "../pages/Dashboard/Sermon/SermonList.vue";
import SermonDetails from "../pages/Dashboard/Sermon/SermonDetails.vue";
import NewSermon from "../pages/Dashboard/Sermon/NewSermon.vue";
import AddSong from "../pages/Dashboard/Songs/AddSong.vue";
import EditSong from "../pages/Dashboard/Songs/EditSong";

import Genre from "../pages/Dashboard/Genre/GenreItem.vue";
import SigninPage from "../pages/auth/signin.vue";
import Planlist from "../pages/Dashboard/Plans/Planlist.vue";
import PlansDetail from "../pages/Dashboard/Plans/PlansDetail.vue";
import NewPlan from "../pages/Dashboard/Plans/NewPlan.vue";
import AdminsList from "../pages/Dashboard/Admins/AdminsList.vue";
import AdminsDetail from "../pages/Dashboard/Admins/AdminsDetail.vue";
import NewAdmin from "../pages/Dashboard/Admins/NewAdmin.vue";
import RolesList from "../pages/Dashboard/Roles/RolesList.vue";
import RolesDetail from "../pages/Dashboard/Roles/RolesDetail.vue";
import Categories from "../pages/Dashboard/Tags/CategoriesList.vue";
import Tags from "../pages/Dashboard/Tags/TagsList.vue";

// Authpages
import AuthLayout from "../pages/Layout/AuthLayout.vue";
// Dashboard pages
import DashboardLayout from "../pages/Layout/DashboardLayout.vue";
import NotFound from "../pages/Layout/NotFound.vue";

Vue.use(VueRouter);

let authPages = {
  path: "/",
  component: AuthLayout,
  name: "Authentication",
  children: [
    {
      path: "/login",
      name: "Login",
      component: SigninPage,
    },
    {
      path: "/resetPasswords",
      name: "reset",
      component: ResetPassExternal,
    },
  ],
};

const routes = [
  { path: "/", redirect: "/login" },
  authPages,
  {
    path: "/dashboard",
    component: DashboardLayout,
    meta: {
      authGuard: true,
    },
    children: [
      {
        path: "/",
        name: "Dashboard",
        components: { default: Dashboard },
      },
      {
        path: "/artists",
        name: "Artists",
        components: { default: ArtistsList },
      },
      {
        path: "/artists/add",
        name: "Add Artist",
        components: { default: NewArtist },
      },
      {
        path: "/users",
        name: "Users",
        components: { default: UserList },
      },
      {
        path: "/users/add",
        name: "Add User",
        components: { default: NewUser },
      },
      {
        path: "/users/:id",
        name: "Edit User",
        components: { default: UserDetails },
      },
      {
        path: "/artists/:id",
        name: "Edit Artist",
        components: { default: ArtistsDetails },
      },
      {
        path: "/playlist/add",
        name: "Add Playlist ",
        components: { default: Newplaylist },
      },
      // {
      //   path: "/notifications",
      //   name: "Notifications",
      //   components: { default: NotificationsList },
      // },
      // {
      //   path: "/notifications/add",
      //   name: "Add Notification",
      //   components: { default: NewNotification },
      // },
      {
        path: "/church",
        name: "Church",
        components: { default: ChurchList },
      },
      {
        path: "/albums",
        name: "Albums",
        components: { default: AlbumList },
      },
      {
        path: "/albumsong/:id/song",
        name: "Album Songs",
        components: { default: AlbumSong },
      },

      {
        path: "/bible",
        name: "Bible",
        components: { default: BibleList },
      },
      {
        path: "/dailies",
        name: "Daily",
        components: { default: DailyList },
      },
      {
        path: "/podcasts",
        name: "Podcasts",
        components: { default: PodcastList },
      },
      {
        path: "/podcasts/:id/episode",
        name: "Episodes",
        components: { default: Episodes },
      },
      {
        path: "/sermon",
        name: "Sermons",
        components: { default: SermonList },
      },
      {
        path: "/sermon/add",
        name: "Add Sermon",
        components: { default: NewSermon },
      },
      {
        path: "/sermon/:id",
        name: "Edit Sermon",
        components: { default: SermonDetails },
      },
      {
        path: "/song",
        name: "Songs",
        components: { default: Song },
      },

      {
        path: "/song/add",
        name: "Add Song",
        props: true,
        components: { default: AddSong },
      },
      {
        path: "/song/:id",
        name: "Update Song",
        props: true,
        components: { default: EditSong },
      },
      {
        path: "/categories",
        name: "Categories",
        components: { default: Categories },
      },
     
      {
        path: "/tags/categories/:id/tags",
        name: "Tags",
        components: { default: Tags},
      },
      {
        path: "/genre",
        name: "Genre",
        components: { default: Genre },
      },

      {
        path: "/playlist",
        name: "Playlists",
        components: { default: Playlist },
      },
      {
        path: "/playlist/:id",
        name: "Playlist Detail",
        components: { default: PlaylistDetail },
      },

      {
        path: "/changePassword",
        name: "Change Password",
        components: { default: ChangePasswords },
      },
      {
        path: "/plans",
        name: "Plans",
        components: { default: Planlist },
      },
      {
        path: "/plans/:id",
        name: "Plans Detail",
        components: { default: PlansDetail },
      },
      {
        path: "/plans/add",
        name: "Add Plan ",
        components: { default: NewPlan },
      },
      {
        path: "/admins",
        name: "Admins",
        components: { default: AdminsList },
      },
      {
        path: "/admins/:id",
        name: "Admin Details",
        components: { default: AdminsDetail },
      },
      {
        path: "/admins/add",
        name: "Add Admin",
        components: { default: NewAdmin },
      },
      {
        path: "/roles",
        name: "Roles",
        components: { default: RolesList },
      },
      {
        path: "/roles/:id/:name",
        name: "Roles Detail",
        components: { default: RolesDetail },
      },
    ],
  },
  {
    path: "*",
    name: "Not Found",
    components: { default: NotFound },
  },
];

let router = new VueRouter({
  mode: "history",
  routes,
  store,
  linkExactActiveClass: "nav-item active",
});

router.beforeEach((to, from, next) => {
  const loggedInUserDetail = !!localStorage.getItem("token");
  if (to.matched.some((m) => m.meta.authGuard) && !loggedInUserDetail)
    next({ name: "login" });
  else next();
});

export default router;
