export default [
  {
    id: 1,
    name: "Noelia O'Kon",
    nickname: "asperiores",
    email: "otho.smitham@example.com",
    salary: "13098.00",
    age: 1,
    agentCode: 39,
    noOfLoans: 100,
    status: "Approved",
    totalPrincipal: "3,000,000 Ksh",
    lastLoanDate: "2021 - 01 - 31",
  },
  {
    id: 2,
    name: "Mr. Enid Von PhD",
    nickname: "alias",
    email: "pollich.cecilia@example.com",
    salary: "35978.00",
    age: 26,
    agentCode: 2,
    noOfLoans: 110,
    totalPrincipal: "3,000,000 Ksh",
    lastLoanDate: "2021 - 01 - 31",
    status: "Waiting Approval",
  },
  {
    id: 3,
    name: "Colton Koch",
    nickname: "id",
    email: "little.myrna@example.net",
    salary: "26278.00",
    age: 48,
    agentCode: 3,
    noOfLoans: 100,
    status: "Approved",
    totalPrincipal: "3,000,000 Ksh",
    lastLoanDate: "2021 - 01 - 31",
  },
  {
    id: 4,
    name: "Gregory Vandervort",
    nickname: "vel",
    email: "dock47@example.org",
    salary: "25537.00",
    age: 27,
    agentCode: 4,
    noOfLoans: 1000,
    totalPrincipal: "3,000,000 Ksh",
    lastLoanDate: "2021 - 01 - 31",
    status: "Approved",
  },
  {
    id: 5,
    name: "Miss Rahsaan Heaney IV",
    nickname: "qui",
    email: "ugrady@example.org",
    salary: "49003.00",
    age: 21,
    agentCode: 5,
    noOfLoans: 1000,
    totalPrincipal: "3,000,000 Ksh",
    lastLoanDate: "2021 - 01 - 31",
    status: "Waiting Approval",
  },
  {
    id: 6,
    name: "Ms. Crystel Zemlak IV",
    nickname: "reiciendis",
    email: "amari.rau@example.com",
    salary: "12383.00",
    age: 48,
    agentCode: 5,
    noOfLoans: 1000,
    totalPrincipal: "3,000,000 Ksh",
    lastLoanDate: "2021 - 01 - 31",
    status: "Waiting Approval",
  },
  {
    id: 7,
    name: "Nona McDermott",
    nickname: "quaerat",
    email: "adrien.baumbach@example.org",
    salary: "18512.00",
    age: 31,
    agentCode: 7,
    noOfLoans: 1000,
    totalPrincipal: "3,000,000 Ksh",
    lastLoanDate: "2021 - 01 - 31",
    status: "Approved",
  },
  {
    id: 8,
    name: "Miss Genoveva Murazik V",
    nickname: "rerum",
    email: "ohettinger@example.net",
    salary: "31209.00",
    age: 28,
    agentCode: 8,
    noOfLoans: 1000,
    totalPrincipal: "3,000,000 Ksh",
    lastLoanDate: "2021 - 01 - 31",
    status: "Waiting Approval",
  },
  {
    id: 9,
    name: "Beulah Huels",
    nickname: "non",
    email: "stefan99@example.com",
    salary: "36920.00",
    age: 53,
    agentCode: 9,
    noOfLoans: 1000,
    totalPrincipal: "3,000,000 Ksh",
    lastLoanDate: "2021 - 01 - 31",
    status: "Approved",
  },
  {
    id: 10,
    name: "Zoe Klein",
    nickname: "ex",
    email: "ejacobson@example.org",
    salary: "35616.00",
    age: 27,
    agentCode: 10,
    noOfLoans: 1000,
    totalPrincipal: "3,000,000 Ksh",
    lastLoanDate: "2021 - 01 - 31",
    status: "Waiting Approval",
  },
  {
    id: 11,
    name: "Vickie Kiehn",
    nickname: "assumenda",
    email: "ayost@example.com",
    salary: "30790.00",
    age: 29,
    agentCode: 11,
    noOfLoans: 1000,
    totalPrincipal: "3,000,000 Ksh",
    lastLoanDate: "2021 - 01 - 31",
    status: "Waiting Approval",
  },
  {
    id: 12,
    name: "Elwyn Herzog",
    nickname: "praesentium",
    email: "ckassulke@example.net",
    salary: "35785.00",
    age: 27,
    agentCode: 12,
    noOfLoans: 1000,
    totalPrincipal: "3,000,000 Ksh",
    lastLoanDate: "2021 - 01 - 31",
    status: "Approved",
  },
  {
    id: 13,
    name: "Selena Hettinger",
    nickname: "et",
    email: "bashirian.hyman@example.net",
    salary: "31836.00",
    age: 35,
    agentCode: 13,
    noOfLoans: 1000,
    totalPrincipal: "3,000,000 Ksh",
    lastLoanDate: "2021 - 01 - 31",
    status: "Approved",
  },
  {
    id: 14,
    name: "Edwin Beier",
    nickname: "eos",
    email: "janis71@example.org",
    salary: "11902.00",
    age: 38,
    agentCode: 14,
    noOfLoans: 1000,
    totalPrincipal: "3,000,000 Ksh",
    lastLoanDate: "2021 - 01 - 31",
    status: "Waiting Approval",
  },
  {
    id: 15,
    name: "Lexi Braun MD",
    nickname: "autem",
    email: "dusty74@example.net",
    salary: "11927.00",
    age: 45,
    agentCode: 15,
    noOfLoans: 1000,
    totalPrincipal: "3,000,000 Ksh",
    lastLoanDate: "2021 - 01 - 31",
  },
  {
    id: 16,
    name: "Norah",
    nickname: "asperiores",
    email: "otho.smitham@example.com",
    salary: "13098.00",
    age: 39,
    agentCode: 16,
    noOfLoans: 1000,
    totalPrincipal: "3,000,000 Ksh",
    lastLoanDate: "2021 - 01 - 31",
  },
  {
    id: 17,
    name: "Mr. Enid",
    nickname: "alias",
    email: "pollich.cecilia@example.com",
    salary: "35978.00",
    age: 26,
    agentCode: 17,
    noOfLoans: 1000,
    totalPrincipal: "3,000,000 Ksh",
    lastLoanDate: "2021 - 01 - 31",
  },
  {
    id: 18,
    name: " Koch",
    nickname: "id",
    email: "little.myrna@example.net",
    salary: "26278.00",
    age: 48,
    agentCode: 18,
    noOfLoans: 1000,
    totalPrincipal: "3,000,000 Ksh",
    lastLoanDate: "2021 - 01 - 31",
  },
  {
    id: 19,
    name: "Gregory ",
    nickname: "vel",
    email: "dock47@example.org",
    salary: "25537.00",
    age: 27,
    agentCode: 19,
    noOfLoans: 1000,
    totalPrincipal: "3,000,000 Ksh",
    lastLoanDate: "2021 - 01 - 31",
  },
  {
    id: 20,
    name: "Miss Rahsaan ",
    nickname: "qui",
    email: "ugrady@example.org",
    salary: "49003.00",
    age: 21,
    agentCode: 20,
    noOfLoans: 1000,
    totalPrincipal: "3,000,000 Ksh",
    lastLoanDate: "2021 - 01 - 31",
  },
  {
    id: 21,
    name: "Ms. Crystel ",
    nickname: "reiciendis",
    email: "amari.rau@example.com",
    salary: "12383.00",
    age: 48,
    agentCode: 21,
    noOfLoans: 1000,
    totalPrincipal: "3,000,000 Ksh",
    lastLoanDate: "2021 - 01 - 31",
  },
  {
    id: 22,
    name: "Nona ",
    nickname: "quaerat",
    email: "adrien.baumbach@example.org",
    salary: "18512.00",
    age: 31,
    agentCode: 22,
    noOfLoans: 1000,
    totalPrincipal: "3,000,000 Ksh",
    lastLoanDate: "2021 - 01 - 31",
  },
  {
    id: 23,
    name: "Miss Genoveva ",
    nickname: "rerum",
    email: "ohettinger@example.net",
    salary: "31209.00",
    age: 28,
    agentCode: 23,
    noOfLoans: 1000,
    totalPrincipal: "3,000,000 Ksh",
    lastLoanDate: "2021 - 01 - 31",
  },
  {
    id: 24,
    name: "Beulah ",
    nickname: "non",
    email: "stefan99@example.com",
    salary: "36920.00",
    age: 53,
    agentCode: 24,
    noOfLoans: 1000,
    totalPrincipal: "3,000,000 Ksh",
    lastLoanDate: "2021 - 01 - 31",
  },
  {
    id: 25,
    name: "Zoe ",
    nickname: "ex",
    email: "ejacobson@example.org",
    salary: "35616.00",
    age: 27,
    agentCode: 25,
    noOfLoans: 1000,
    totalPrincipal: "3,000,000 Ksh",
    lastLoanDate: "2021 - 01 - 31",
  },
  {
    id: 26,
    name: "Vickie ",
    nickname: "assumenda",
    email: "ayost@example.com",
    salary: "30790.00",
    age: 29,
    agentCode: 26,
    noOfLoans: 1000,
    totalPrincipal: "3,000,000 Ksh",
    lastLoanDate: "2021 - 01 - 31",
  },
  {
    id: 27,
    name: "Elwyn ",
    nickname: "praesentium",
    email: "ckassulke@example.net",
    salary: "35785.00",
    age: 27,
    agentCode: 27,
    noOfLoans: 1000,
    totalPrincipal: "3,000,000 Ksh",
    lastLoanDate: "2021 - 01 - 31",
  },
  {
    id: 28,
    name: "Selena ",
    nickname: "et",
    email: "bashirian.hyman@example.net",
    salary: "31836.00",
    age: 35,
    agentCode: 28,
    noOfLoans: 1000,
    totalPrincipal: "3,000,000 Ksh",
    lastLoanDate: "2021 - 01 - 31",
  },
  {
    id: 29,
    name: " Beier",
    nickname: "eos",
    email: "janis71@example.org",
    salary: "11902.00",
    age: 38,
    agentCode: 29,
    noOfLoans: 1000,
    totalPrincipal: "3,000,000 Ksh",
    lastLoanDate: "2021 - 01 - 31",
  },
  {
    id: 30,
    name: " Braun MD",
    nickname: "autem",
    email: "dusty74@example.net",
    salary: "11927.00",
    age: 45,
    agentCode: 30,
    noOfLoans: 1000,
    totalPrincipal: "3,000,000 Ksh",
    lastLoanDate: "2021 - 01 - 31",
  },
];
