<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <div class="md-layout-item md-medium-size-100 md-size-100"></div>
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-danger">
          <div class="card-icon">
            <md-icon>table_chart</md-icon>
          </div>
        </md-card-header>
        <md-card-content>
          <div class="md-layout">
            <clip-loader
              :loading="loading"
              :color="color"
              size="25px"
            ></clip-loader>
            <div class="md-layout-item md-small-size-50 md-size-50">
              <md-field>
                <label>Plan Name</label>
                <md-input v-model="title" type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-50 spacing">
              <label>Type</label>
              <v-select :options="typeOptions" :value="type"></v-select>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-50 spacing">
              <md-field>
                <label>Price</label>
                <md-input v-model="price" type="text"></md-input>
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100 md-size-50 spacing">
              <md-field>
                <label>Duration</label>
                <md-input v-model="duration" type="number"></md-input>
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100 md-size-50 spacing">
              <md-field>
                <label>Url</label>
                <md-input v-model="url" type="text"></md-input>
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100 md-size-33">
              <label>Status</label>
              <md-switch v-model="status"></md-switch>
            </div>

            <div class="md-layout-item md-small-size-100 md-size-50 spacing">
              <label>Description</label>
              <ckeditor
                :editor="editor"
                v-model="description"
                :config="editorConfig"
              ></ckeditor>
            </div>
            <br /><br />

            <br />

            <div class="md-layout-item md-size-100 text-right">
              <md-button
                class="md-raised md-default mt-4"
                @click.native="goBack()"
                >Cancel</md-button
              >
              <md-button class="md-raised md-success mt-4">Submit</md-button>
            </div>
          </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

export default {
  components: {
    ClipLoader,
  },
  props: {
    profileCard: {
      type: String,
      default: "./img/faces/card-profile1-square.jpg",
    },
  },

  data() {
    return {
      currentSort: "name",
      currentSortOrder: "asc",
      searchQuery: "",
      tableData: "",
      searchedData: [],
      fuseSearch: null,
      emptylist: false,
      currentPlan: "",
      editcurrentPlan: false,
      cardUserImage: "",
      title: "",
      price: "",
      duration: "",
      url: "",
      status: "",
      description: "",
      type: "",
      loading: false,
      color: "#3AB982",
      updatePassword: false,
      phone: "",
      emailAddress: "",
      name: "",
      typeOptions: [],
      editor: ClassicEditor,

      editorConfig: {
        // The configuration of the editor.
        height: 1000,
      },
    };
  },
  methods: {
    goBack() {
      this.$router.back();
    },
  },

  created() {},
};
</script>
<style lang="css">
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
</style>
<style lang="css" scoped>
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
.spacing {
  margin: "10px";
  padding: "10px";
}
.item-status {
  background-color: #4caf50;
  padding: 5px;
  color: white;
  border-radius: 10%;
}
.img-width {
  width: 50px;
}

.md-table[table-header-color="green"] .md-table-head .md-table-head-label {
  font-weight: bold;
}
</style>
