import axios from "../../axios-auth";
import filesAxios from "../../axios-files";
import { AUTH_REQUEST } from "../actions/types";

import { s3Url } from "../../utils/api.js";
import { s3Callback } from "../../utils/api.js";

const state = {
  loading: false,
};
const mutations = {
  [AUTH_REQUEST](state) {
    state.loading = true;
  },
};
const actions = {
  getSermons({ commit }, params) {
    return new Promise((resolve, reject) => {
      if (params && params.isActive) {
        axios
          .get(
            `/sermons?page=${params.page}&pageSize=${params.pageSize}&isActive=${params.isActive}`
          )
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            commit("error", err);
            reject(err);
          });
      } else {
        axios
          .get(`/sermons?page=${params.page}&pageSize=${params.pageSize}`)
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            commit("error", err);
            reject(err);
          });
      }
    });
  },

  getSermon({ commit }, sermonParam) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/sermons/${sermonParam.currentId}`)
        .then((res) => {
          console.log(res);
          resolve(res.data);
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },

  activateSermon({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit(AUTH_REQUEST);
      axios
        .put(`/sermons/${params.id}/activate`, {
          status: params.status,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },

  TopSermon({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit(AUTH_REQUEST);
      axios
        .put(`/sermons/${params.id}/markAsTop`, {
          status: params.status,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },

  PopularSermon({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit(AUTH_REQUEST);
      axios
        .put(`/sermons/${params.id}/markAsPopular`, {
          status: params.status,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },

  addSermon({ commit }, formData) {
    return new Promise((resolve, reject) => {
      // console.log(formData);
      commit(AUTH_REQUEST);

      axios
        .post("/sermons", {
          title: formData.title,
          author: formData.author,
          description: formData.description,
          type: formData.type,
          churchId: formData.churchId,
        })
        .then((res) => {
          // console.log(res.data.data);
          let sermonId = res.data.data.sermonId;
          let respStatus = [];
          // console.log(sermonId);

          //
          async function processFiles() {
            for (let i = 0; i < formData.files.length; i++) {
              try {
                const { data } = await axios.post(s3Url, {
                  id: sermonId,
                  file_name: formData.files[i].name,
                  category: formData.category,
                });

                const { task_id: taskId, url } = data;

                await filesAxios.put(url, formData.files[i]);

                const resp = await axios.post(`${s3Callback}${taskId}`);
                // console.log(resp.data.status);

                if (resp.data.status == "Enqueue") {
                  respStatus.push(201);
                } else {
                  respStatus.push(400);
                }

                if (i == formData.files.length - 1) {
                  let statusAll = respStatus.every(function (element) {
                    return element === 201;
                  });
                  if (statusAll) {
                    resolve("success");
                  } else {
                    reject("Upload Error Occurred");
                  }
                }

                ////console.log(resp);
                ////resolve(resp);
              } catch (error) {
                reject(error);
                console.log(error);
              }
            }
          }

          if (formData.files.length > 0) {
            processFiles();
          } else {
            resolve("success");
          }
        })

        .catch((err) => {
          reject(err);
        });
    });
  },

  // addSermonItem({ commit }, formData) {
  //   return new Promise((resolve, reject) => {
  //     commit(AUTH_REQUEST);

  //     axios
  //       .post("/sermons", {
  //         title: formData.title,
  //         author: formData.author,
  //         description: formData.description,
  //         type: formData.type,
  //         churchId: formData.churchId,
  //       })
  //       .then((resp) => {
  //         let sermonId = resp.data.data.sermonId;

  //         async function processFiles() {
  //           for (let i = 0; i < formData.files.length; i++) {
  //             const { data } = await axios.post(s3Url, {
  //               id: sermonId,
  //               file_name: formData.files[i].name,
  //               category: formData.category,
  //             });

  //             const { task_id: taskId, url } = data;
  //             await filesAxios.put(url, formData.files[i]);

  //             await axios.post(`${s3Callback}${taskId}`).then((res) => {
  //               if (res.data.status == "Enqueue") {
  //                 console.log(res);
  //                 resolve(res);
  //               }
  //             });
  //           }
  //         }

  //         return processFiles();
  //       })
  //       .catch((err) => {
  //         commit("error", err);
  //         reject(err);
  //       });
  //   });
  // },

  updateSermon({ commit }, formData) {
    return new Promise((resolve, reject) => {
      console.log(formData);
      commit(AUTH_REQUEST);

      axios
        .put(`/sermons/${formData.id}/edit`, {
          title: formData.title,
          author: formData.author,
          description: formData.description,
          type: formData.type,
          churchId: formData.churchId,
        })
        .then((res) => {
          console.log(res.data.data);
          let sermonId = res.data.data.sermonId;
          let respStatus = [];

          //
          console.log(sermonId);

          //
          async function processFiles() {
            console.log(formData.files.length);
            for (let i = 0; i < formData.files.length; i++) {
              try {
                const { data } = await axios.post(s3Url, {
                  id: sermonId,
                  file_name: formData.files[i].name,
                  category: formData.category,
                });

                const { task_id: taskId, url } = data;

                await filesAxios.put(url, formData.files[i]);

                const resp = await axios.post(`${s3Callback}${taskId}`);
                ///return resp;

                console.log(resp.data.status);

                if (resp.data.status == "Enqueue") {
                  respStatus.push(201);
                } else {
                  respStatus.push(400);
                }

                if (i == formData.files.length - 1) {
                  let statusAll = respStatus.every(function (element) {
                    return element === 201;
                  });
                  if (statusAll) {
                    resolve("success");
                  } else {
                    reject("Upload Error Occurred");
                  }
                }
              } catch (error) {
                reject(error);
                console.log(error);
              }
            }
          }

          if (formData.files.length > 0) {
            processFiles();
          } else {
            resolve("success");
          }
        })

        .catch((err) => {
          reject(err);
        });
    });
  },

  deleteSermon({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit("request");
      axios
        .delete(`/sermons/${params}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },
  getNextCatalog({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("https://api.npoint.io/b2700f4e22d2d07f4568")
        .then((res) => {
          console.log(res);
          resolve(res.data);
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },
};
const getters = {};

export default { state, mutations, actions, getters };
