<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-danger">
          <div class="card-icon">
            <md-icon>perm_identity</md-icon>
          </div>
        </md-card-header>
        <div class="md-card-avatar">
          <img class="img" :src="cardUserImage" />
        </div>
        <md-card-content>
          <div class="md-layout">
            <div class="md-layout-item md-small-size-50 md-size-50">
              <md-field>
                <label>Admin Name</label>
                <md-input v-model="name" type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-50 spacing">
              <md-field>
                <label>Email</label>
                <md-input v-model="emailAddress" type="email"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-50 spacing">
              <md-field>
                <label>Mobile Number</label>
                <md-input v-model="phone" type="phone"></md-input>
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100 md-size-33">
              <label>Status</label>
              <md-switch v-model="status"></md-switch>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-50 spacing">
              <label>Do you want to Update Password ? </label> <br />
              <md-radio v-model="updatePassword" :value="true">Yes</md-radio>
              <md-radio v-model="updatePassword" :value="false">No</md-radio>
            </div>
            <div
              class="md-layout-item md-small-size-100 md-size-50 spacing"
              v-if="updatePassword"
            >
              <md-field>
                <label>Password</label>
                <md-input v-model="password" type="password"></md-input>
              </md-field>
            </div>
            <br /><br />

            <br />

            <div class="md-layout-item md-size-100 text-right">
              <md-button
                class="md-raised md-default mt-4"
                @click.native="goBack()"
                >Cancel</md-button
              >
              <md-button class="md-raised md-success mt-4">Submit</md-button>
            </div>
          </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    profileCard: {
      type: String,
      default: "./img/faces/card-profile1-square.jpg",
    },
  },

  data() {
    return {
      currentSort: "name",
      currentSortOrder: "asc",
      searchQuery: "",
      tableData: "",
      searchedData: [],
      fuseSearch: null,
      emptylist: false,
      currentadmin: "",
      editcurrentadmin: false,
      cardUserImage: "",
      adminName: "",
      aboutadmin: "",
      favDescription: "",
      facebookUrl: "",
      instagramUrl: "",
      twitterUrl: "",
      status: "",
      topadmin: "",
      addedOn: "",
      modifiedOn: "",
      loading: false,
      color: "#3AB982",
      updatePassword: false,
      phone: "",
      emailAddress: "",
      name: "",
    };
  },
  methods: {
    getadmin() {},
    goBack() {
      this.$router.back();
    },
  },

  created() {
    ///this.getadmin();
  },
};
</script>
<style lang="css">
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
</style>
<style lang="css" scoped>
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
.spacing {
  margin: "10px";
  padding: "10px";
}
.item-status {
  background-color: #4caf50;
  padding: 5px;
  color: white;
  border-radius: 10%;
}
.img-width {
  width: 50px;
}

.md-table[table-header-color="green"] .md-table-head .md-table-head-label {
  font-weight: bold;
}
</style>
