<template>
  <md-toolbar
    md-elevation="0"
    class="md-success"
    :class="{
      'md-toolbar-absolute md-white md-fixed-top ': $route.meta.navbarAbsolute,
    }"
  >
    <div class="md-toolbar-row">
      <div class="md-toolbar-section-start">
        <h3 class="md-title">{{ $route.name }}</h3>
      </div>
      <div class="md-toolbar-section-end">
        <md-button
          class="md-just-icon md-round md-simple md-toolbar-toggle"
          :class="{ toggled: $sidebar.showSidebar }"
          @click="toggleSidebar"
        >
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </md-button>

        <div class="md-collapse">
          <md-list>
            <li class="md-list-item">
              <a
                href="#"
                class="
                  md-list-item-router md-list-item-container md-button-clean
                  dropdown
                "
              >
                <div class="md-list-item-content">
                  <drop-down direction="down">
                    <md-button
                      slot="title"
                      class="md-button md-just-icon md-simple"
                      data-toggle="dropdown"
                    >
                      <md-icon style="color: #fff !important">person</md-icon>

                      <p class="hidden-lg hidden-md">Person</p>
                    </md-button>

                    <ul class="dropdown-menu dropdown-menu-right">
                      <li v-if="auth">
                        <router-link to="/changePassword"
                          >Change Password</router-link
                        >
                      </li>
                      <li v-if="auth">
                        <a href="#" @click="onLogout">Log Out</a>
                      </li>
                    </ul>
                  </drop-down>
                </div>
              </a>
            </li>
          </md-list>
        </div>
      </div>
    </div>
  </md-toolbar>
</template>

<script>
export default {
  data() {
    return {
      selectedEmployee: "",
      employees: [],
      auth: this.$store.getters.isAuthenticated,
    };
  },
  computed: {
    email() {
      return !this.$store.getters.user ? false : this.$store.getters.user.email;
    },
  },
  methods: {
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },
    onLogout() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/login");
      });
    },
    onResetPass() {
      this.$store.dispatch("logout");
    },
  },
};
</script>
<style scoped>
.topBarStyle {
  color: white;
}
</style>
