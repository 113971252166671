<template>
  <div
    class="md-layout text-center"
    style="
      background-image: url(./img/login-logo1.png);
      background-repeat: no-repeat;
      background-size: contain;
    "
  >
    <div
      class="md-layout-item md-size-33 md-medium-size-50 md-small-size-70 md-xsmall-size-100"
    >
      <form @submit.prevent="onSubmit">
        <login-card header-color="danger">
          <template slot="title">
            <h4 class="title">Log in</h4>
          </template>

          <template slot="description">
            <clip-loader
              :loading="loading"
              :color="color"
              :size="size"
            ></clip-loader>
            <div style="color: red; font-weight: bold">{{ errorMsg }}</div>
          </template>

          <template slot="inputs">
            <ValidationObserver ref="observer">
              <ValidationProvider
                name="email"
                ref="email"
                rules="required|email"
                v-slot="{ errors, passed, failed }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <md-icon>email</md-icon>
                  <label>Email</label>
                  <md-input
                    v-model="email"
                    data-vv-name="email"
                    type="email"
                    name="email"
                    required
                  >
                  </md-input>
                </md-field>
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>

              <ValidationProvider
                name="password"
                ref="password"
                rules="required|min:6"
                v-slot="{ errors, passed, failed }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <md-icon>lock_outline</md-icon>
                  <label>Password</label>
                  <md-input
                    v-model="password"
                    data-vv-name="password"
                    type="password"
                    name="password"
                    required
                  ></md-input>
                </md-field>

                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </ValidationObserver>
          </template>

          <template slot="footer">
            <md-button
              slot="footer"
              class="md-simple md-success md-lg"
              type="submit"
            >
              Login
            </md-button>
            &nbsp;&nbsp;
            <router-link slot="footer" to="/resetPasswords">
              <small>Reset Password</small>
            </router-link>
          </template>
        </login-card>
      </form>
    </div>
  </div>
</template>

<script>
import { LoginCard } from "../../componentItems/";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import {
  ValidationObserver,
  ValidationProvider,
  extend,
} from "vee-validate/dist/vee-validate.full";

// can customize default error messages
extend("required", {
  message: (field) => "The " + `${field}` + " field is required. ",
});

export default {
  components: {
    LoginCard,
    ClipLoader,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      firstname: null,
      email: "",
      password: "",
      loading: false,
      color: "#eb4034",
      size: "20px",
      errorMsg: "",
    };
  },
  methods: {
    onSubmit() {
      this.loading = true;
      this.errorMsg = "";
      const formData = {
        email: this.email,
        password: this.password,
      };
      console.log(formData);
      this.$store
        .dispatch("login", {
          email: formData.email,
          password: formData.password,
        })
        .then((res) => {
          console.log(res);
          this.$router.push("/dashboard");
        })
        .catch((error) => {
          this.loading = false;
          this.errorMsg = error;
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
/* .signin-form {
    width: 400px;
    margin: 30px auto;
    border: 1px solid #eee;
    padding: 20px;
    box-shadow: 0 2px 3px #ccc;
  }

  .input {
    margin: 10px auto;
  }

  .input label {
    display: block;
    color: #4e4e4e;
    margin-bottom: 6px;
  }

  .input input {
    font: inherit;
    width: 100%;
    padding: 6px 12px;
    box-sizing: border-box;
    border: 1px solid #ccc;
  }

  .input input:focus {
    outline: none;
    border: 1px solid #521751;
    background-color: #eee;
  }

  .submit button {
    border: 1px solid #521751;
    color: #521751;
    padding: 10px 20px;
    font: inherit;
    cursor: pointer;
  }

  .submit button:hover,
  .submit button:active {
    background-color: #521751;
    color: white;
  }

  .submit button[disabled],
  .submit button[disabled]:hover,
  .submit button[disabled]:active {
    border: 1px solid #ccc;
    background-color: transparent;
    color: #ccc;
    cursor: not-allowed;
  } */
</style>