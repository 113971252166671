import axios from "../../axios-auth";
import filesAxios from "../../axios-files";
import { AUTH_REQUEST } from "../actions/types";
import { s3Url, s3Callback } from "../../utils/api.js";

const state = {
  loading: false,
};
const mutations = {
  [AUTH_REQUEST](state) {
    state.loading = true;
  },
};
const actions = {
  getAllArtists({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/artists?page=${params.page}&pageSize=${params.pageSize}`)
        .then((res) => {
          commit(AUTH_REQUEST);

          ///console.log(res.data.paging.count);
          axios
            .get(`/artists?page=0&pageSize=${res.data.paging.count}`)
            .then((res) => {
              resolve(res.data.data);
            });
        })

        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },
  getArtists({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/artists?page=${params.page}&pageSize=${params.pageSize}`)
        .then((res) => {
          ///console.log(res);
          resolve(res.data);
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },

  getArtist({ commit }, artistParam) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/artists/${artistParam.currentId}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },

  getCountries({ commit }, params) {
    return new Promise((resolve, reject) => {
      // "artists/countries?page=0&pageSize=300"
      axios
        .get(
          `/artists/countries?page=${params.page}&pageSize=${params.pageSize}`
        )
        .then((res) => {
          commit(AUTH_REQUEST);
          axios
            .get(`/artists/countries?page=0&pageSize=${res.data.paging.count}`)
            .then((res) => {
              resolve(res.data.data);
            });
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },

  addArtist({ commit }, formData) {
    return new Promise((resolve, reject) => {
      console.log(formData);
      commit(AUTH_REQUEST);

      axios
        .post("/artists", {
          name: formData.name,
          description: formData.description,
          idNumber: formData.idNumber,
          countryId: formData.country,
        })
        .then((res) => {
          let artistId = res.data.data.artistId;

          // if (formData.tagIds.length > 0) {
          //   axios.put(`/tags/tagContent`, {
          //     contentId: artistId,
          //     tagIds: formData.tagIds,
          //     category: formData.category,
          //   });
          // }

          let task_id = [];
          let urls = [];

          //
          async function processFiles() {
            let result;

            let uploadResult = [];
            for (let i = 0; i < formData.fileNames.length; i++) {
              result = await axios
                .post(s3Url, {
                  id: artistId,
                  file_name: formData.fileNames[i],
                  category: formData.category,
                })
                .catch((error) => {
                  reject("Media upload Error occurred");
                  console.log(error);
                });

              if (result.status !== 201) {
                reject("Error occurred");
              } else {
                urls.push(result.data.url);
                task_id.push(result.data.task_id);
              }
            }

            for (let i = 0; i < formData.fileNames.length; i++) {
              ///console.log(formData.files[i].split(',')[1])
              result = await filesAxios.put(urls[i], formData.files[i]);
              uploadResult[i] = result;
            }
            return uploadResult;
          }

          async function doFilesUpload() {
            let callBack;

            let resultCallback = [];
            let result = await processFiles();

            if (result != undefined || result != null) {
              console.log(result.length);
              console.log(result.length == formData.fileNames.length);

              if (result.length == formData.fileNames.length) {
                /// console.log("processing");
                for (let i = 0; i < formData.fileNames.length; i++) {
                  ////console.log("processing " + s3Callback + task_id[i]);
                  callBack = axios.post(s3Callback + `${task_id[i]}`);
                  resultCallback[i] = callBack;
                }
                console.log(resultCallback);

                resolve(resultCallback);
              } else {
                reject("An Error has Occurred");
              }
            }
          }

          //
          doFilesUpload();
        })

        .catch((err) => {
          reject(err);
        });
    });
  },
  //
  updateArtist({ commit }, formData) {
    return new Promise((resolve, reject) => {
      console.log(formData);
      commit(AUTH_REQUEST);

      axios
        .put(`/artists/${formData.id}/edit`, {
          name: formData.name,
          description: formData.description,
          idNumber: formData.idNumber,
          countryId: formData.country,
        })
        .then((res) => {
          let artistId = res.data.data.artistId;
          console.log(artistId);

          // // tags
          // if (formData.tagIds.length > 0) {
          //   axios.put(`/tags/tagContent`, {
          //     contentId: artistId,
          //     tagIds: formData.tagIds,
          //     category: formData.category,
          //   });
          // }
          // //
          async function processFiles() {
            for (let i = 0; i < formData.files.length; i++) {
              try {
                const { data } = await axios.post(s3Url, {
                  id: artistId,
                  file_name: formData.files[i].name,
                  category: formData.category,
                });

                const { task_id: taskId, url } = data;

                await filesAxios.put(url, formData.files[i]);

                const resp = await axios.post(`${s3Callback}${taskId}`);

                console.log(resp);
                ////return resp;
              } catch (error) {
                console.log(error);
              }
            }
            resolve("success");
          }
          if (formData.files.length > 0) {
            processFiles();
          } else {
            resolve("success");
          }
        })

        .catch((err) => {
          reject(err);
        });
    });
  },
  //

  activateArtist({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit(AUTH_REQUEST);
      axios
        .put(`/artists/${params.id}/activate`, {
          status: params.status,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },
  TopArtist({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit(AUTH_REQUEST);
      axios
        .put(`/artists/${params.id}/markAsTop`, {
          status: params.status,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },
  PopularArtist({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit(AUTH_REQUEST);
      axios
        .put(`/artists/${params.id}/markAsPopular`, {
          status: params.status,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },

  deleteArtist({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/artists/${params}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },
};
const getters = {};

export default { state, mutations, actions, getters };
