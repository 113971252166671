import axios from "axios";
import api from "../../axios-auth";
import filesAxios from "../../axios-files";
import { s3Url } from "../../utils/api.js";
import { s3Callback } from "../../utils/api.js";

const state = {
  loading: true,
  Songs: [],
  error: {},
};

const mutations = {
  request(state) {
    state.loading = true;
  },
  SongsList(state, ListItems) {
    state.loading = false;
    state.Songs = ListItems.items;
  },

  error(state, error) {
    state.loading = false;
    state.error = error;
  },
};

const actions = {
  getSongs({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit("request");

      if (params && params.isActive) {
        api
          .get(
            `/songs?page=${params.page}&pageSize=${params.pageSize}&isActive=${params.isActive}`
          )
          .then((res) => {
            commit("SongsList", {
              items: res.data,
            });
            resolve(res);
          })
          .catch((err) => {
            commit("error", err);
            reject(err);
          });
      } else {
        api
          .get(`/songs?page=${params.page}&pageSize=${params.pageSize}`)
          .then((res) => {
            commit("SongsList", {
              items: res.data,
            });
            resolve(res);
          })
          .catch((err) => {
            commit("error", err);
            reject(err);
          });
      }
    });
  },
  getAllSongs({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit("request");
      api
        .get(`/songs?page=${params.page}&pageSize=${params.pageSize}`)
        .then((res) => {
          commit("SongsList", {
            items: res.data,
          });
          resolve(res.data.data);

          // console.log(res.data.paging.count);
          // api
          //   .get(`/songs?page=0&pageSize=${res.data.paging.count}`)
          //   .then((res) => {
          //     commit("SongsList", {
          //       items: res.data,
          //     });
          //     resolve(res.data.data);
          //   });
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },

  addSongPage({ commit }, formData) {
    return new Promise((resolve, reject) => {
      // console.log("passhere");
      api
        .post("/songs", {
          title: formData.title,
          author: formData.author,
          toneCode: formData.toneCode,
          description: formData.description,
          lyrics: formData.lyrics,
          artists: formData.artists,
          type: formData.type,
          featuredArtists: formData.featuredArtistsIds,
          albumId: formData.albumId,
        })
        .then((res) => {
          //let task_id = [];
          //let urls = [];
          // console.log(res.data);

          ///return;
          resolve(res);
          let songId = res.data.data.songId;
          let respStatus = [];
          // console.log(songId);

          async function processFiles() {
            for (let i = 0; i < formData.files.length; i++) {
              try {
                const { data } = await axios.post(s3Url, {
                  id: songId,
                  file_name: formData.files[i].name,
                  category: formData.category,
                });

                const { task_id: taskId, url } = data;

                await filesAxios.put(url, formData.files[i]);

                const resp = await axios.post(`${s3Callback}${taskId}`);
                // console.log(resp.data.status);
                if (resp.data.status == "Enqueue") {
                  respStatus.push(201);
                } else {
                  respStatus.push(400);
                }

                if (i == formData.files.length - 1) {
                  let statusAll = respStatus.every(function (element) {
                    return element === 201;
                  });
                  if (statusAll) {
                    resolve("success");
                  } else {
                    reject("Upload Error Occurred");
                  }
                }
                ////return resp;
              } catch (error) {
                console.log(error);
              }
            }
          }
          if (formData.files.length > 0) {
            processFiles();
            resolve("success");
          } else {
            resolve("success");
          }
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },

  updateSong({ commit }, formData) {
    return new Promise((resolve, reject) => {
      commit("request");
      api
        .put(`/songs/${formData.id}/edit`, {
          title: formData.title,
          author: formData.author,
          toneCode: formData.toneCode,
          description: formData.description,
          lyrics: formData.lyrics,
          artists: formData.artists,
          type: formData.type,
          featuredArtists: formData.featuredArtists,
          albumId: formData.albumId,
        })
        .then((res) => {
          console.log(res.data);
          let songId = res.data.data.songId;
          let respStatus = [];

          console.log(songId);
          async function processFiles() {
            for (let i = 0; i < formData.files.length; i++) {
              try {
                const { data } = await axios.post(s3Url, {
                  id: songId,
                  file_name: formData.files[i].name,
                  category: formData.category,
                });

                const { task_id: taskId, url } = data;

                await filesAxios.put(url, formData.files[i]);

                const resp = await axios.post(`${s3Callback}${taskId}`);
                console.log(resp);

                if (resp.data.status == "Enqueue") {
                  respStatus.push(201);
                } else {
                  respStatus.push(400);
                }

                if (i == formData.files.length - 1) {
                  let statusAll = respStatus.every(function (element) {
                    return element === 201;
                  });
                  if (statusAll) {
                    resolve("success");
                  } else {
                    reject("Upload Error Occurred");
                  }
                }
                ////return resp;
              } catch (error) {
                console.log(error);
              }
            }
          }
          if (formData.files.length > 0) {
            processFiles();
            resolve("success");
          } else {
            resolve("success");
          }
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },

  getSong({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit("request");
      api
        .get(`/songs/${id}`)
        .then((res) => {
          commit("SongsList", {
            items: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },
  activateSong({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit("request");
      api
        .put(`/songs/${params.id}/activate`, {
          status: params.status,
        })
        .then((res) => {
          commit("SongsList", {
            items: res,
          });
          resolve(res);
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },
  TopSong({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit("request");
      api
        .put(`/songs/${params.id}/markAsTop`, {
          status: params.status,
        })
        .then((res) => {
          commit("SongsList", {
            items: res,
          });
          resolve(res);
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },
  PopularSong({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit("request");
      api
        .put(`/songs/${params.id}/markAsPopular`, {
          status: params.status,
        })
        .then((res) => {
          commit("SongsList", {
            items: res,
          });
          resolve(res);
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },

  deleteSong({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit("request");
      api
        .delete(`/songs/${params}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },
};

const getters = {};

export default { state, mutations, actions, getters };
