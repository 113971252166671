<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-65 mx-auto md-small-size-65">
      <ValidationObserver ref="observer">
        <form
          @submit.prevent="addPlaylist"
          ref="createPlaylistForm"
          id="createPlaylist-form"
        >
          <md-card md-with-hover>
            <md-card-header class="md-card-header-icon md-card-header-danger">
              <div class="card-icon">
                <md-icon>playlist_play</md-icon>
              </div>
              <h4 class="title">Add Playlist</h4>
            </md-card-header>
            <md-card-content>
              <div class="md-layout">
                <div class="md-layout-item md-size-30 md-small-size-100">
                  <div class="file-input img-size">
                    <template v-if="!imageCircle">
                      <div class="image-container">
                        <img :src="avatarImg" title="" />
                      </div>
                    </template>
                    <div class="image-container" v-else>
                      <img :src="imageCircle" />
                    </div>
                    <div class="button-container">
                      <md-button
                        class="md-danger md-raised md-sm"
                        @click="removeImage('circle')"
                        v-if="imageCircle"
                      >
                        <md-icon>clear</md-icon>Remove</md-button
                      >
                      <md-button
                        class="md-sm md-success md-raised md-fileinput"
                      >
                        <template v-if="!imageCircle">Add Photo</template>
                        <template v-else>Change</template>
                        <input
                          type="file"
                          name="circle"
                          accept="image/*"
                          @change="onFileChange"
                        />
                      </md-button>
                    </div>
                    <h6 class="text-danger">{{ imageError }}</h6>
                  </div>
                  <br /><br />
                </div>

                <div class="md-layout-item md-small-size-100 md-size-70">
                  <ValidationProvider
                    name="Playlist Title "
                    ref="Playlist Title "
                    data-vv-name="text"
                    :rules="{
                      required: true,
                      regex: /^[a-zA-Z0-9 '.-]*$/,
                    }"
                    v-slot="{ errors, passed, failed }"
                  >
                    <md-field
                      :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                    >
                      <label>Playlist Title</label>
                      <md-input v-model="playlistTitle" type="text"></md-input>
                      <slide-y-down-transition>
                        <md-icon class="error" v-show="failed">close</md-icon>
                      </slide-y-down-transition>
                      <slide-y-down-transition>
                        <md-icon class="success" v-show="passed">done</md-icon>
                      </slide-y-down-transition>
                    </md-field>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>

                  <ValidationProvider
                    name="Description "
                    ref="Description "
                    :rules="{
                      min: 0,
                      max: 500,
                    }"
                    v-slot="{ errors, passed, failed }"
                    ><md-field
                      :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                    >
                      <label> Description</label>
                      <md-textarea
                        v-model="playlistDescription"
                        maxlength="500"
                        type="text"
                      ></md-textarea>
                      <slide-y-down-transition>
                        <md-icon class="error" v-show="failed">close</md-icon>
                      </slide-y-down-transition>
                      <slide-y-down-transition>
                        <md-icon class="success" v-show="passed">done</md-icon>
                      </slide-y-down-transition>
                    </md-field>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                  <br /><br />
                </div>

                <div class="md-layout-item md-small-size-100 md-size-30 mt-1">
                  <ValidationProvider
                    name="Type"
                    ref="type"
                    :rules="{
                      required: true,
                    }"
                    v-slot="{ errors, passed, failed }"
                  >
                    <div
                      class="ml-5"
                      :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                    >
                      <span><b>Playlist Type :</b></span>
                      <div>
                        <md-radio
                          v-on:change="sortPlaylist"
                          v-model="playlistType"
                          :value="false"
                          >Audio</md-radio
                        >
                        <md-radio
                          v-on:change="sortPlaylist"
                          v-model="playlistType"
                          :value="true"
                          >Video</md-radio
                        >
                      </div>
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </div>

                <div class="md-layout-item md-small-size-100 md-size-70">
                  <label class="labelIcon">
                    <b>Assign Songs to Playlist</b>

                    <md-button
                      @click="refreshList"
                      class="md-just-icon md-info md-dense md-raised md-simple"
                    >
                      <md-icon class="md-sm">refresh </md-icon>
                      <md-tooltip class="md-sm" md-direction="top"
                        >Refresh</md-tooltip
                      >
                    </md-button>
                  </label>

                  <multiselect
                    multiple
                    placeholder="Assign Songs"
                    track-by="songId"
                    label="title"
                    v-model="songsId"
                    :loading="loadingSongs"
                    :limit="5"
                    :options="this.songTypeArray"
                    :searchable="true"
                    :show-labels="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :hide-selected="true"
                    :show-no-results="false"
                    @search-change="searchItem"
                    @open="onOpen"
                    @close="onClose"
                    deselect-label="Remove Song"
                    select-label="Select Song"
                    open-direction="bottom"
                    ref="multiselect"
                  >
                    <template slot="afterList">
                      <div
                        v-observe-visibility="{
                          callback: reachedEndOfList,
                          throttle: 300,
                          throttleOptions: {
                            leading: 'visible',
                          },
                        }"
                        v-if="hasNextPage"
                      />
                    </template>

                    <template #clear="props">
                      <div
                        class="multiselect__clear"
                        v-if="songsId.length"
                        @mousedown.prevent.stop="clearAll(props.search)"
                      ></div>
                    </template>
                  </multiselect>
                  <h6 class="text-danger">{{ songError }}</h6>

                  <br />
                  <!-- 
                  <label> <b> Tag(s)</b> </label>
                  <multiselect
                    v-model="tagsId"
                    :options="this.tagsArray"
                    multiple
                    placeholder="Select Tag(s)"
                    label="tag"
                    track-by="id"
                    :limit="5"
                    :searchable="true"
                    :show-labels="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    deselect-label="Remove Tag"
                    select-label="Select Tag"
                    open-direction="top"
                  >
                  </multiselect> -->
                </div>
                <div class="md-layout-item md-small-size-100 md-size-30"></div>
              </div>
            </md-card-content>
            <md-card-actions>
              <md-button
                @click.native="goBack()"
                class="md-sm md-dense md-raised md-danger"
                >Cancel</md-button
              >

              <md-button
                class="md-sm md-dense md-raised md-success"
                @click="$refs.createPlaylistForm.requestSubmit()"
                >Submit</md-button
              >
            </md-card-actions>
          </md-card>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import { SlideYDownTransition } from "vue2-transitions";

import {
  ValidationObserver,
  ValidationProvider,
  extend,
} from "vee-validate/dist/vee-validate.full";
import * as notifications from "../../../utils/notifications";

// can customize default error messages
extend("required", {
  message: (field) => "The " + `${field}` + " field is required. ",
});
export default {
  props: {
    profileCard: {
      type: String,
      default: "../../img/no-image-new.png",
    },
    avatarImg: {
      type: String,
      default: "../../img/no-image.png",
    },
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    SlideYDownTransition,
  },

  data() {
    return {
      currentSort: "name",
      currentSortOrder: "asc",
      searchQuery: "",
      tableData: "",
      searchedData: [],
      songsId: [],
      selectedSongs: [],
      tagsId: [],
      tagsArray: [],
      fuseSearch: null,
      emptylist: false,
      currentArtist: "",
      editcurrentArtist: false,
      cardUserImage: "",
      playlistTitle: "",
      aboutArtist: "",
      playlistDescription: "",
      facebookUrl: "",
      instagramUrl: "",
      twitterUrl: "",
      status: "",
      topPlaylist: true,
      addedOn: "",
      modifiedOn: "",
      loading: false,
      loadingSongs: false,
      color: "#3AB982",
      imageCircle: "",
      imageCircleFile: File,
      imageProfile: "",
      selectedGenre: [],
      songsArray: [],
      songTypeArray: [],
      playlistType: false,
      avatarFileName: "",
      type: "",
      songError: "",
      imageError: "",
      notifyUser: true,
      pagination: {
        pageSize: 500,
        page: 1,
        perPageOptions: [5, 10, 25, 50, 100],
        total: 100000,
      },
    };
  },

  computed: {
    hasNextPage() {
      let from = this.pagination.pageSize * (this.pagination.page - 1);
      let total = this.pagination.total;

      let NextPage = from + this.pagination.pageSize;
      if (total < NextPage) {
        NextPage = total;
      }
      return NextPage;

      // return this.pagination.page + 1 <= this.pagination.total;
    },
  },
  methods: {
    async onOpen() {
      this.$refs.multiselect.activate();
      // this.refreshList();
    },
    onClose() {
      this.$refs.multiselect.deactivate();
    },
    reachedEndOfList(reached) {
      if (reached) {
        return this.fetchNextPage();
      }
    },
    getCategories() {
      this.loading = true;
      const params = {
        page: this.pagination.page - 1,
        pageSize: this.pagination.pageSize,
      };
      this.$store
        .dispatch("getCategories", params)
        .then((res) => {
          if (res.data.paging.count === 0) {
            this.emptylist = true;
          } else {
            let tags = [];

            let categories = res.data.data;

            categories.forEach((category) => {
              category["tags"].forEach((tag) => {
                tags.push({ tag: tag.tag, id: tag.tagId });
              });
            });

            this.tagsArray = tags;

            this.pagination.total = res.data.paging.count;
          }
        })

        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    HideAddData() {
      this.$router.push("/playlist");
    },
    sortPlaylist() {
      this.songId = [];
      let playType = this.playlistType ? "Video" : "Audio";
      this.songTypeArray = this.songsArray.filter(function (song) {
        return song.type == playType;
      });
    },
    addPlaylist() {
      this.$refs.observer.validate().then((success) => {
        if (!success) {
          return;
        }

        if (this.avatarFileName == true) {
          this.imageError = "Avatar is required!";
        } else if (this.songsId == "") {
          this.songError = "This field is required!";
        } else {
          this.loading = true;
          this.songError = "";
          this.imageError = "";
          let filesArray = [];
          // tags
          let tagIdsArray = [];
          this.tagsId.forEach((tag) => {
            tagIdsArray.push(tag.id);
          });
          console.log(tagIdsArray);
          //
          let loader = this.$loading.show({
            // Optional parameters
            container: this.fullPage ? null : this.$refs.formContainer,
            canCancel: false,
            onCancel: this.onCancel,
            color: "#eb4034",
          });

          filesArray.push(this.imageCircleFile);
          let playType = this.playlistType ? "Video" : "Audio";

          this.songsId.forEach((item) => {
            this.selectedSongs.push(item.songId);
          });

          this.$store
            .dispatch("addPlaylist", {
              title: this.playlistTitle,
              description: this.playlistDescription,
              type: playType,
              songsId: this.selectedSongs,
              files: filesArray,
              category: "Playlist",
              tagIds: tagIdsArray,
            })
            .then((res) => {
              console.log(res);
              this.loading = false;

              loader.hide();

              notifications.Success({
                title: "Playlist created successfully",
                buttonsStyling: false,
                confirmButtonClass: "md-button md-success",
              });
            })
            .finally(() => {
              this.loading = false;
              loader.hide();
              this.HideAddData();
            })
            .catch((err) => {
              notifications.Error(err);
              loader.hide();
            });
        }

        this.$nextTick(() => {
          this.$refs.observer.reset();
        });
      });
    },

    getAllSongs() {
      this.loadingSongs = true;
      const params = {
        isActive: true,
        page: 0,
        pageSize: this.pagination.pageSize,
      };

      this.$store
        .dispatch("getAllSongs", params)
        .then((res) => {
          this.songsArray = res;
          this.playlistType = false;
          this.sortPlaylist();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loadingSongs = false;
        });
    },

    searchItem(searchQuery) {
      this.loadingSongs = true;
      const params = {
        searchTerm: searchQuery,
        column: "title",
        category: "songs",
      };
      // console.log(params);
      this.$store
        .dispatch("partialSearch", params)
        .then((res) => {
          this.songsArray = res.data;
          this.playlistType = false;
          this.sortPlaylist();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loadingSongs = false;
        });
    },

    fetchNextPage() {
      this.pagination.pageSize += 100;
      this.getAllSongs();
    },

    clearAll() {
      this.songsId = [];
    },

    refreshList() {
      this.pagination.page = 1;
      this.getAllSongs();
    },

    getPlaylistSongs() {
      this.loading = true;
      this.$store
        .dispatch("getPlaylistSongs", {
          currentId: this.$route.params.id,
        })
        .then((res) => {
          this.loading = false;
          console.log(res.data[0]);
          this.businessData = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    goBack() {
      this.$router.back();
    },
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      if (e.target.name) {
        this.imageCircleFile = files[0];
        this.createImage(files[0], "circle");
        this.avatarFileName = files[0].name;
        this.imageError = "";
      } else {
        this.createImage(files[0]);
        this.profileFileName = files[0].name;
        this.imageError = "";
      }
    },
    createImage(file, type) {
      let reader = new FileReader();
      let vm = this;

      reader.onload = (e) => {
        if (type === "circle") {
          vm.imageCircle = e.target.result;
        } else {
          vm.imageProfile = e.target.result;
        }
      };
      reader.readAsDataURL(file);
    },
    removeImage: function (type) {
      if (type === "circle") {
        this.imageCircle = "";
        this.imageCircleFile = "";
      } else {
        this.imageProfile = "";
      }
    },
  },
  created() {
    this.getCategories();
  },
  mounted() {
    setTimeout(() => {
      this.getAllSongs();
    }, 1000);
  },
};
</script>
<style lang="css">
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
</style>
<style lang="css" scoped>
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
.spacing {
  margin: "10px";
  padding: "10px";
}
.item-status {
  background-color: #4caf50;
  padding: 5px;
  color: white;
  border-radius: 10%;
}
.img-width {
  width: 50px;
}
.img-size {
  width: 75%;
  height: 75%;
}

.md-table[table-header-color="green"] .md-table-head .md-table-head-label {
  font-weight: bold;
}

.labelIcon {
  display: flex;
  flex: 1;
  margin: auto;
  align-items: center;
}
</style>
