<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-danger">
          <div class="card-icon">
            <md-icon>table_chart</md-icon>
          </div>
          <h4 class="title">Plans</h4>
          <clip-loader
            :loading="loading"
            :color="color"
            size="25px"
          ></clip-loader>
        </md-card-header>
        <md-card-content>
          <md-table-empty-state
            v-if="emptylist"
            md-label="No data found"
            :md-description="`Empty table there's no data`"
          >
          </md-table-empty-state>
          <md-table
            v-else
            :value="queriedData"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
            @md-selected="onSelect"
            table-header-color="green"
            class="table-striped table-hover"
          >
            <md-table-toolbar>
              <md-field style="width: 500px" class="md-toolbar-section-start">
                <md-input
                  type="search"
                  class="mb-3"
                  clearable
                  placeholder="Search by Title and Type"
                  v-model="searchQuery"
                >
                </md-input>
              </md-field>

              <div class="md-toolbar-section-end">
                <md-button
                  class="md-just-icon md-success md-dense md-raised md-simple"
                  title="Add"
                  style="margin-right: 5px"
                  @click="newPlan()"
                >
                  <md-icon>add</md-icon>
                  <md-tooltip class="md-sm" md-direction="top"
                    >Add Plan</md-tooltip
                  >
                </md-button>
                {{ " " }}
                <md-button
                  :disabled="emptylist"
                  class="md-just-icon md-danger md-dense md-raised md-simple"
                  title="Delete"
                  style="margin-right: 5px"
                  @click="deletePlan"
                >
                  <md-icon>delete</md-icon>
                  <md-tooltip class="md-sm" md-direction="top"
                    >Delete Plan</md-tooltip
                  >
                </md-button>
                {{ " " }}
                <md-button
                  :disabled="emptylist"
                  class="md-just-icon md-success md-dense md-raised md-simple"
                  title="Activate"
                  style="margin-right: 5px"
                  @click="activatePlan"
                >
                  <md-icon>lock_open</md-icon>
                  <md-tooltip class="md-sm" md-direction="top"
                    >Activate Plan</md-tooltip
                  >
                </md-button>
                {{ " " }}
                <md-button
                  :disabled="emptylist"
                  class="md-just-icon md-default md-dense md-raised md-simple"
                  title="Deactivate"
                  style="margin-right: 5px"
                  @click="deactivatePlan"
                >
                  <md-icon>lock</md-icon>
                  <md-tooltip class="md-sm" md-direction="top"
                    >Deactivate Plan</md-tooltip
                  >
                </md-button>
                {{ " " }}
                <md-button
                  :disabled="emptylist"
                  class="md-just-icon md-primary md-dense md-raised md-simple"
                  title="Refresh"
                  style="margin-right: 5px"
                >
                  <md-icon>refresh</md-icon>
                  <md-tooltip class="md-sm" md-direction="top"
                    >Refresh Plans</md-tooltip
                  >
                </md-button>
              </div>
            </md-table-toolbar>
            <md-table-toolbar
              slot="md-table-alternate-header"
              slot-scope="{ count }"
              style="margin-top: -20px"
            >
              <div class="md-toolbar-section-start">
                {{ getAlternateLabel(count) }}
              </div>
            </md-table-toolbar>

            <md-table-row
              slot="md-table-row"
              slot-scope="{ item }"
              md-selectable="multiple"
              md-auto-select
              @click.native="editPlan(item)"
            >
              <md-table-cell md-label="Name">{{ item.title }}</md-table-cell>
              <md-table-cell md-label="Email">{{ item.type }}</md-table-cell>
              <md-table-cell md-label="Duration">{{
                item.duration
              }}</md-table-cell>
              <md-table-cell md-label="Price">
                <span>{{ item.price }}</span>
              </md-table-cell>
              <md-table-cell md-label="Status">
                <span class="item-status">{{
                  item.status === true ? "Active" : "Inactive"
                }}</span>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          >
          </pagination>
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>

<script>
import { Pagination } from "../../../componentItems";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

import Fuse from "fuse.js";
///import Swal from "sweetalert2";

export default {
  props: {
    profileCard: {
      type: String,
      default: "./img/faces/card-profile1-square.jpg",
    },
  },
  components: {
    Pagination,
    ClipLoader,
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
  data() {
    return {
      currentSort: "name",
      currentSortOrder: "asc",
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      footerTable: [],
      searchQuery: "",
      propsToSearch: ["name", "email"],
      tableData: [],
      searchedData: [],
      fuseSearch: null,
      emptylist: false,
      currentPlan: "",
      editcurrentPlan: false,
      loading: false,
      color: "#3AB982",
      selected: [],
    };
  },
  methods: {
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "desc") {
          return a[sortBy].localeCompare(b[sortBy]);
        }
        return b[sortBy].localeCompare(a[sortBy]);
      });
    },
    editPlan(item) {
      this.editcurrentPlan = true;
      this.currentPlan = item.id;
      this.$router.push({
        name: "Plans Detail",
        params: { id: item.id },
      });
    },

    onSelect(items) {
      this.selected = items;
    },
    getAlternateLabel(count) {
      let plural = "";

      if (count > 1) {
        plural = "s";
      }

      return `${count} item${plural} selected`;
    },

    deletePlan() {
      if (this.selected.length > 0) {
        this.loading = true;
        this.$store
          .dispatch("deleteArtist")
          .then((res) => {
            this.loading = false;
            let code = res.data.code;

            if (code) {
              this.$notify({
                message: "Plan Deleted Successfully",
                icon: "add_alert",
                horizontalAlign: "right",
                verticalAlign: "top",
                type: "success",
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.$notify({
          message: "Select an Item from the List",
          icon: "add_alert",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "danger",
        });
      }
    },
    activatePlan() {
      if (this.selected.length > 0) {
        this.loading = true;
        this.$store
          .dispatch("deleteArtist")
          .then(() => {
            this.loading = false;
            let code = 200;

            if (code) {
              this.$notify({
                message: "Plan Activated Successfully",
                icon: "add_alert",
                horizontalAlign: "right",
                verticalAlign: "top",
                type: "success",
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.$notify({
          message: "Select an Item from the List",
          icon: "add_alert",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "danger",
        });
      }
    },
    deactivatePlan() {
      if (this.selected.length > 0) {
        this.loading = true;
        this.$store
          .dispatch("deleteArtist")
          .then(() => {
            this.loading = false;
            let code = 200;

            if (code) {
              this.$notify({
                message: "Plan Deactivated Successfully",
                icon: "add_alert",
                horizontalAlign: "right",
                verticalAlign: "top",
                type: "success",
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.$notify({
          message: "Select an Item from the List",
          icon: "add_alert",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "danger",
        });
      }
    },
    newPlan() {
      this.$router.push({
        name: "Add Plan ",
      });
    },
  },

  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData;
      if (value !== "") {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result;
    },
  },
  created() {
    this.loading = true;
    this.$store
      .dispatch("getPlans")
      .then((res) => {
        this.loading = false;
        this.tableData = res;
      })
      .then(() => {
        // Fuse search initialization.
        this.fuseSearch = new Fuse(this.tableData, {
          keys: ["title", "type"],
          threshold: 0.3,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>
<style lang="css">
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
</style>
<style lang="css" scoped>
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
.item-status {
  background-color: #4caf50;
  padding: 5px;
  color: white;
  border-radius: 10%;
}
.img-width {
  width: 50px;
}

.md-table[table-header-color="green"] .md-table-head .md-table-head-label {
  font-weight: bold;
}
</style>
<style lang="css">
.md-table-head-label {
  font-weight: bold;
}
</style>
