import api from "../../axios-auth";
import filesAxios from "../../axios-files";

import { AUTH_REQUEST } from "../actions/types";

import { s3Url } from "../../utils/api.js";
import { s3Callback } from "../../utils/api.js";

const state = {
  loading: false,
  Church: [],
  error: {},
};

const mutations = {
  request(state) {
    state.loading = true;
  },
  [AUTH_REQUEST](state) {
    state.loading = true;
  },
  ChurchList(state, ListItems) {
    state.loading = false;
    state.Church = ListItems.items;
  },

  error(state, error) {
    state.loading = false;
    state.error = error;
  },
};

const actions = {
  getAllChurches({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit("request");
      api
        .get(`/church?page=${params.page}&pageSize=${params.pageSize}`)
        .then((res) => {
          commit("ChurchList", {
            items: res.data,
          });
          api
            .get(`/church?page=0&pageSize=${res.data.paging.count}`)
            .then((res) => {
              commit("ChurchList", {
                items: res.data,
              });
              resolve(res);
            });
        })

        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },
  getChurches({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit("request");
      api
        .get(`/church?page=${params.page}&pageSize=${params.pageSize}`)
        .then((res) => {
          commit("ChurchList", {
            items: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },

  // addChurch({ commit }, formData) {
  //   return new Promise((resolve, reject) => {
  //     commit("request");
  //     api
  //       .post("/church", formData)
  //       .then((res) => {
  //         commit("ChurchList", {
  //           items: res.data,
  //         });
  //         resolve(res);
  //       })
  //       .catch((err) => {
  //         commit("error", err);
  //         reject(err);
  //       });
  //   });
  // },
  //
  addChurch({ commit }, formData) {
    return new Promise((resolve, reject) => {
      console.log(formData);
      commit(AUTH_REQUEST);

      api
        .post("/church", {
          title: formData.title,
          description: formData.description,
        })
        .then((res) => {
          console.log(res.data);
          let churchId = res.data.data.churchId;
          let task_id = [];
          let urls = [];

          //
          async function processFiles() {
            let result;

            let uploadResult = [];
            for (let i = 0; i < formData.fileNames.length; i++) {
              result = await api
                .post(s3Url, {
                  id: churchId,
                  file_name: formData.fileNames[i],
                  category: formData.category,
                })
                .catch((error) => {
                  reject("Media upload Error occurred");
                  console.log(error);
                });

              if (result.status !== 201) {
                reject("Error occurred");
              } else {
                urls.push(result.data.url);
                task_id.push(result.data.task_id);
              }
            }

            for (let i = 0; i < formData.fileNames.length; i++) {
              result = await filesAxios.put(urls[i], formData.files[i]);
              uploadResult[i] = result;
            }
            return uploadResult;
          }

          async function doFilesUpload() {
            let callBack;

            let resultCallback = [];
            let result = await processFiles();

            if (result != undefined || result != null) {
              console.log(result.length);
              console.log(result.length == formData.fileNames.length);

              if (result.length == formData.fileNames.length) {
                console.log("processing");
                for (let i = 0; i < formData.fileNames.length; i++) {
                  console.log("processing " + s3Callback + task_id[i]);
                  callBack = api.post(s3Callback + `${task_id[i]}`);
                  resultCallback[i] = callBack;
                }
                console.log(resultCallback);

                resolve(resultCallback);
              } else {
                reject("An Error has Occurred");
              }
            }
          }

          //
          doFilesUpload();
        })

        .catch((err) => {
          reject(err);
        });
    });
  },

  //

  editChurch({ commit }, formData) {
    return new Promise((resolve, reject) => {
      commit("request");
      api
        .put(`/church/${formData.id}/edit`, {
          title: formData.title,
          description: formData.description,
        })
        .then((res) => {
          console.log(res.data);
          let churchId = res.data.data.churchId;
          let task_id = [];
          let urls = [];

          //
          async function processFiles() {
            let result;

            let uploadResult = [];
            for (let i = 0; i < formData.fileNames.length; i++) {
              result = await api
                .post(s3Url, {
                  id: churchId,
                  file_name: formData.fileNames[i],
                  category: formData.category,
                })
                .catch((error) => {
                  reject("Media upload Error occurred");
                  console.log(error);
                });

              if (result.status !== 201) {
                reject("Error occurred");
              } else {
                urls.push(result.data.url);
                task_id.push(result.data.task_id);
              }
            }

            for (let i = 0; i < formData.fileNames.length; i++) {
              result = await filesAxios.put(urls[i], formData.files[i]);
              uploadResult[i] = result;
            }
            return uploadResult;
          }

          async function doFilesUpload() {
            let callBack;

            let resultCallback = [];
            let result = await processFiles();

            if (result != undefined || result != null) {
              console.log(result.length);
              console.log(result.length == formData.fileNames.length);

              if (result.length == formData.fileNames.length) {
                console.log("processing");
                for (let i = 0; i < formData.fileNames.length; i++) {
                  console.log("processing " + s3Callback + task_id[i]);
                  callBack = api.post(s3Callback + `${task_id[i]}`);
                  resultCallback[i] = callBack;
                }
                console.log(resultCallback);

                resolve(resultCallback);
              } else {
                reject("An Error has Occurred");
              }
            }
          }

          //
          doFilesUpload();
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },

  getChurch({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit("request");
      api
        .get(`/church/${id}`)
        .then((res) => {
          commit("ChurchList", {
            items: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },
  activateChurch({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit("request");
      api
        .put(`/church/${params.id}/activate`, {
          status: params.status,
        })
        .then((res) => {
          commit("ChurchList", {
            items: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },
  TopChurch({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit("request");
      api
        .put(`/church/${params.id}/markAsTop`, {
          status: params.status,
        })
        .then((res) => {
          commit("ChurchList", {
            items: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },
  PopularChurch({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit("request");
      api
        .put(`/church/${params.id}/markAsPopular`, {
          status: params.status,
        })
        .then((res) => {
          commit("ChurchList", {
            items: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },

  deleteChurch({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit("request");
      api
        .delete(`/church/${params}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },
};

const getters = {};

export default { state, mutations, actions, getters };
