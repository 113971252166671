<template>
  <div class="md-layout text-center">
    <div
      class="md-layout-item md-size-33 md-medium-size-50 md-small-size-70 md-xsmall-size-100"
    >
      <form @submit.prevent="onSubmit" method="post" novalidate="true">
        <login-card header-color="blue">
          <br /><br />
          <h4 slot="title" class="title">Reset Password</h4>
          <p class="md-form-group" slot="inputs">
            <ul :style="{ listStyleType: none,color:redColor }">
              <li v-for="error in errorslist" :key="error">{{ error }}</li>
            </ul>
           
          </p>
           <p v-if="success" slot="inputs" :style="{color:greenColor }">{{success}}</p>

          <md-field class="md-form-group" slot="inputs">
            <md-icon>email</md-icon>
            <label>Email...</label>
            <md-input v-model="emailadress1" type="email" id="email"></md-input>
          </md-field>

          <md-button
            slot="footer"
            class="md-simple md-success md-lg"
            type="submit"
          >
            Reset </md-button
          >| <router-link slot="footer" to="/login">Login</router-link>
        </login-card>
      </form>
    </div>
  </div>
</template>
<script>
import { LoginCard } from "../../componentItems/";
export default {
  components: {
    LoginCard,
  },
  data() {
    return {
      emailadress1: null,
      errorslist: [],
      none: "none",
      redColor: "red",
      greenColor: "green",
      success: false,
    };
  },
  methods: {
    onSubmit() {
      this.errorslist = [];
      this.success = false;
      if (!this.emailadress1) {
        this.errorslist.push("Email required.");
      } else if (!this.validEmail(this.emailadress1)) {
        this.errorslist.push("A Valid email is required.");
      } else {
        this.success = "New Password sent to  Email";
      }
    },

    validEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
  },
};
</script>
<style></style>
