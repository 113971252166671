<template>
  <modal
    id="Uploadsong"
    ref="Uploadsong"
    v-if="uploadModal"
    @close="uploadModalHide"
  >
    <template slot="header">
      <h4 class="modal-title">Upload Song(s)</h4>
      <md-button
        class="md-simple md-just-icon md-danger modal-default-button"
        @click="uploadModalHide"
      >
        <md-icon>clear</md-icon>
      </md-button>
    </template>

    <template slot="body">
      <tabs :tab-name="['Step 1', 'Step 2']" flex-column color-button="info">
        <template slot="tab-pane-1">
          <h4 class="card-title">Click to download the csv file</h4>
          <div class="file-input img-circle">
            <vue-blob-json-csv file-type="csv" file-name="Song" :data="data">
              <div class="button-container">
                <md-button class="md-simple md-sm notice-modal md-info">
                  <i class="fa fa-download"></i> <b>csv download</b>
                </md-button>
              </div>
            </vue-blob-json-csv>
          </div>
        </template>

        <template slot="tab-pane-2">
          <h4 class="title">
            Choose the file after you have filled the required data and click
            upload file
          </h4>
          <br />

          <form @submit.prevent="Member" @keyup.enter.prevent="Song">
            <ValidationProvider
              name="Song file"
              :rules="{
                required: true,
              }"
              v-slot="{ errors, passed, failed }"
            >
              <md-field
                :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
              >
                <label>Choose file</label>
                <md-file
                  type="file"
                  v-model="files"
                  id="Songfile"
                  ref="Songfile"
                  name="Song file"
                  required
                >
                </md-file>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="failed">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="passed">done</md-icon>
                </slide-y-down-transition>
              </md-field>
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>

            <br />
            <div class="button-container">
              <clip-loader
                :loading="loading"
                :color="color"
                size="25px"
                class="pull-left"
              ></clip-loader>
              <md-button
                type="submit"
                class="md-simple md-sm notice-modal md-info"
              >
                <i class="fa fa-upload"></i> <b>Upload File</b>
              </md-button>
            </div>
          </form>
        </template>
      </tabs>
    </template>

    <template slot="footer">
      <md-button
        class="md-sm md-default"
        style="margin-top: 10px"
        @click="uploadModalHide"
        >Exit</md-button
      >
    </template>
  </modal>
</template>

<script>
import { Modal, Tabs } from "@/componentItems";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import { SlideYDownTransition } from "vue2-transitions";
import { ValidationProvider } from "vee-validate/dist/vee-validate.full";
import Swal from "sweetalert2";
export default {
  components: {
    Modal,
    Tabs,
    ClipLoader,
    ValidationProvider,
    SlideYDownTransition,
  },

  data() {
    return {
      uploadModal: false,
      files: "",
      loading: false,
      color: "#3AB982",
      size: "40px",

      data: [
        {
          coverArt: "",
          title: "",
          author: "",
          description: "",
          lyrics: "",
          toneCode: "",
          video: "",
          artist: [],
        },
      ],
    };
  },

  methods: {
    ShowUploadModal() {
      this.uploadModal = true;
    },

    uploadModalHide() {
      this.uploadModal = false;
    },
    Song() {
      /* return first object in FileList */
      var file = document.getElementById("Songfile").files[0];
      var self = this;

      if (file !== undefined && file.size !== 0) {
        this.$papa.parse(file, {
          header: true,
          download: true,
          complete: function (results) {
            self.files = results.data;
            self.UploadSong();
          },
        });
      }
    },
    UploadSong() {
      this.loading = true;
      var formData = this.files;
      console.log(formData);
      Swal.fire({
        title: `Song Uploaded`,
        buttonsStyling: false,
        confirmButtonClass: "md-button md-info",
      });
    },
  },
};
</script>

<style>
</style>