<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-danger">
          <div class="card-icon">
            <md-icon>library_music</md-icon>
          </div>

          <h4 class="title">
            Songs
            <div class="pull-right">
              <md-button
                class="md-just-icon md-success md-dense md-raised md-simple"
                @click.prevent="Addsong"
              >
                <md-icon>add</md-icon>
                <md-tooltip class="md-sm" md-direction="top"
                  >Add Song</md-tooltip
                >
              </md-button>

              <md-button
                :disabled="emptylist"
                @click.native="handleDelete()"
                class="md-just-icon md-danger md-dense md-raised md-simple"
              >
                <md-icon>delete</md-icon>
                <md-tooltip class="md-sm" md-direction="top">Delete</md-tooltip>
              </md-button>

              <md-button
                @click="refreshList"
                class="md-just-icon md-info md-dense md-raised md-simple"
              >
                <md-icon>refresh</md-icon>
                <md-tooltip class="md-sm" md-direction="top"
                  >Refresh Page</md-tooltip
                >
              </md-button>
            </div>
          </h4>
        </md-card-header>

        <clip-loader
          :loading="loading"
          :color="color"
          size="32px"
        ></clip-loader>

        <md-card-content>
          <md-table-empty-state
            v-if="emptylist"
            md-label="No data found"
            :md-description="`Empty table there's no data`"
          >
          </md-table-empty-state>
          <md-table
            v-else
            :value="queriedData"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
            @md-selected="onSelect"
            table-header-color="green"
            class="table-striped table-hover"
          >
            <md-table-toolbar class="toolbar" md-alignment="space-between">
              <div class="md-toolbar-section-start">
                <md-field style="width: 50px" class="mr-3">
                  <label for="pages">Per page</label>
                  <md-select
                    v-model="pagination.pageSize"
                    @change="handlePageSizeChange($event)"
                    name="pages"
                    style="width: 50px"
                  >
                    <md-option
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                      {{ item }}
                    </md-option>
                  </md-select>
                </md-field>

                <md-field style="width: 80px">
                  <label for="status">Status</label>
                  <md-select
                    name="status"
                    id="status"
                    ref="status"
                    v-model="isActive"
                    @change="onStatusChange($event)"
                    md-dense
                  >
                    <md-option
                      v-for="(status, index) in statusOptions"
                      :key="index"
                      :label="status.isActive"
                      :value="status.option"
                    >
                      {{ status.isActive }}
                    </md-option>
                  </md-select>
                </md-field>
              </div>

              <div class="md-toolbar-section-end">
                <md-field style="width: 80px" class="mr-3">
                  <label for="type">Type</label>
                  <md-select
                    v-model="column"
                    name="type"
                    id="type"
                    style="width: 80px"
                    clearable
                    md-dense
                  >
                    <md-option value="title">title</md-option>
                    <md-option value="author">author</md-option>
                  </md-select>
                </md-field>

                <ValidationObserver ref="observer">
                  <form
                    @submit.prevent="searchItem"
                    @keyup.enter.prevent="searchItem"
                    ref="searchForm"
                    id="searchForm"
                  >
                    <ValidationProvider
                      :rules="{
                        required: true,
                      }"
                      v-slot="{ passed, failed }"
                    >
                      <md-field
                        style="width: 270px"
                        :class="[
                          { 'md-error': failed },
                          { 'md-valid': passed },
                        ]"
                      >
                        <md-input
                          type="search"
                          clearable
                          style="width: 250px"
                          placeholder="Search..."
                          v-model="searchQuery"
                        >
                        </md-input>
                        <md-button
                          :disabled="emptylist"
                          class="
                            md-just-icon md-success md-dense md-raised md-simple
                          "
                          @click="$refs.searchForm.requestSubmit()"
                        >
                          <md-icon>search</md-icon>
                          <md-tooltip class="md-sm" md-direction="top"
                            >Search</md-tooltip
                          >
                        </md-button>
                      </md-field>
                    </ValidationProvider>
                  </form>
                </ValidationObserver>
              </div>
            </md-table-toolbar>
            <md-table-toolbar
              slot="md-table-alternate-header"
              slot-scope="{ count }"
              style="margin-top: -20px"
            >
              <div class="md-toolbar-section-start">
                {{ getAlternateLabel(count) }}
              </div>
            </md-table-toolbar>

            <md-table-row
              slot="md-table-row"
              slot-scope="{ item }"
              md-selectable="multiple"
            >
              <!-- <md-table-cell md-label="Image">
                <img :src="Object(item.coverArt).url" class="imgUrl"
              /></md-table-cell> -->

              <md-table-cell md-label="Image">
                <div v-if="true" type="hidden" hidden>
                  {{
                    (image = (obj = Object.assign({}, item.coverArt))["url"])
                  }}
                </div>

                <img :src="image" class="imgUrl" />
              </md-table-cell>
              <md-table-cell md-label="Title">{{ item.title }}</md-table-cell>
              <md-table-cell md-label="Author">
                {{ item.author }}
              </md-table-cell>
              <md-table-cell md-label="Type">{{ item.type }}</md-table-cell>
              <md-table-cell md-label="Plays">{{
                item.playCount
              }}</md-table-cell>
              <md-table-cell md-label="Likes">{{
                item.likeCount
              }}</md-table-cell>
              <md-table-cell md-label="Trending">
                <md-switch
                  v-model="item.isTop"
                  @change="handleTopSong(item)"
                  class="md-sm"
                >
                  {{ item.isTop == true ? "Yes" : "No" }}</md-switch
                >
              </md-table-cell>
              <md-table-cell md-label="What's New">
                <md-switch
                  v-model="item.isPopular"
                  @change="handlePopularSong(item)"
                  class="md-sm"
                >
                  {{ item.isPopular == true ? "Yes" : "No" }}</md-switch
                >
              </md-table-cell>

              <md-table-cell md-label="Status">
                <md-switch
                  v-model="item.isActive"
                  @change="handleActivate(item)"
                  class="md-sm"
                >
                  {{ item.isActive == true ? "Active" : "InActive" }}</md-switch
                >
              </md-table-cell>

              <md-table-cell md-label="Actions">
                <md-button
                  v-if="item.music !== null"
                  class="md-just-icon md-rose small-alert-modal md-simple mr-1"
                  @click.native="Music(item)"
                >
                  <md-icon>play_arrow</md-icon>
                  <md-tooltip md-direction="top">Play Song</md-tooltip>
                </md-button>

                <!-- <md-button v-else class="md-just-icon md-danger md-simple">
                  <md-icon>error_outline</md-icon>
                  <md-tooltip md-direction="top">No Media File</md-tooltip>
                </md-button> -->
                <md-badge
                  v-else
                  class="md-square m-1 mr-3"
                  md-content="No Media File"
                />

                <md-button
                  class="md-just-icon md-info md-simple"
                  @click.native="Updatesong(item)"
                >
                  <md-icon>dvr</md-icon>
                  <md-tooltip class="md-sm" md-direction="top"
                    >Edit Song</md-tooltip
                  >
                </md-button>

                <md-button
                  v-if="item.music !== null"
                  @click.native="HandleTag(item)"
                  class="md-just-icon md-primary md-dense md-raised md-simple"
                >
                  <md-icon>local_offer</md-icon>
                  <md-tooltip class="md-sm" md-direction="top">Tag</md-tooltip>
                </md-button>

                <md-button
                  v-if="item.music !== null"
                  @click.native="HandleNotification(item)"
                  class="md-just-icon md-success md-simple"
                >
                  <md-icon>send</md-icon>
                  <md-tooltip class="md-sm" md-direction="top"
                    >Push Notification</md-tooltip
                  >
                </md-button>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ comma(total) }} entries
            </p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.page"
            :per-page="pagination.pageSize"
            :total="total"
            @pagechange="handlePageChange"
          >
          </pagination>
        </md-card-actions>
        <Tag
          ref="AddTag"
          :category="category"
          :currentItem="currentItem"
          :getlist="getSongs"
        />

        <PushNotification
          ref="Notification"
          :category="'songs'"
          :contentId="currentItem"
          :Image="Image"
          :getlist="getSongs"
        />

        <!-- Audio Player -->
        <AudioModal
          v-if="smallAlertModal"
          :close="CloseMusic"
          :file="this.music"
        >
          <template slot="header">
            <h4 class="title">
              {{ this.musicAuthor }} -
              <small class="author"> {{ this.musicTitle }}</small>
            </h4>
          </template>
        </AudioModal>
      </md-card>

      <UploadSong ref="UploadSong" />
    </div>
  </div>
</template>

<script>
import Tag from "../Tags/TagContent.vue";
import UploadSong from "./Upload";
import PushNotification from "../Notifications/Notification.vue";
import { AudioModal, Pagination, commaSeparator } from "@/componentItems";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import _ from "lodash";
import * as notifications from "../../../utils/notifications";
import {
  ValidationObserver,
  ValidationProvider,
} from "vee-validate/dist/vee-validate.full";

export default {
  name: "SongTable",
  components: {
    Tag,
    AudioModal,
    Pagination,
    ClipLoader,
    UploadSong,
    PushNotification,
    ValidationObserver,
    ValidationProvider,
  },
  mixins: [commaSeparator],
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    statusOptions() {
      return this.Options;
    },
    queriedData() {
      return this.tableData;
    },
    to() {
      let highBound = this.from + this.pagination.pageSize;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.pageSize * (this.pagination.page - 1);
    },
    total() {
      return this.pagination.total;
    },
  },
  created() {
    this.getSongs();
  },
  data() {
    return {
      currentSort: "name",
      currentSortOrder: "asc",
      pagination: {
        pageSize: 5,
        page: 1,
        perPageOptions: [5, 10, 25, 50, 100],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: ["title", "email", "age"],
      Options: [
        { isActive: "Active", option: "true" },
        { isActive: "InActive", option: "false" },
      ],

      tableData: [],
      searchedData: [],
      fuseSearch: null,
      selected: [],
      smallAlertModal: false,
      emptylist: false,
      loading: false,
      color: "#3AB982",
      size: "40px",
      music: "",
      musicTitle: "",
      musicAuthor: "",
      category: "Song",
      currentItem: "",
      Image: "",
      Title: "",
      column: "",
      isActive: true,
    };
  },
  methods: {
    smallAlertModalHide: function () {
      this.smallAlertModal = false;
    },
    onSelect(items) {
      this.selected = items;
    },
    getAlternateLabel(count) {
      let plural = "";
      if (count > 1) {
        plural = "s";
      }
      return `${count} song${plural} selected`;
    },

    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "desc") {
          return a[sortBy].localeCompare(b[sortBy]);
        }
        return b[sortBy].localeCompare(a[sortBy]);
      });
    },

    OpenUpload() {
      this.$refs.UploadSong.ShowUploadModal();
    },

    Addsong(add) {
      this.$router.push({
        name: "Add Song",
        params: { id: add },
      });
    },

    Updatesong(item) {
      this.$router.push(`/song/${item.songId}`);
    },
    HandleTag(item) {
      this.$refs.AddTag.ShowTagModal(item);
      this.currentItem = item.songId;
    },
    HandleNotification(item) {
      this.$refs.Notification.ShowNotificationModal(item);
      this.currentItem = item.songId;
      this.Image = item.coverArt.url;
      this.Title = item.title;
    },
    Music(item) {
      this.loading = true;
      this.smallAlertModal = true;

      const title = item.title;
      const author = item.author;
      const data = Object(item.music).url;

      if (data) {
        this.loading = false;
        this.musicTitle = title;
        this.musicAuthor = author;
        this.music = data;
      } else if (!data) {
        this.music = "";
        this.musicTitle = "No data";
        this.musicAuthor = "Null";
        this.loading = false;
      } else {
        return null;
      }
    },
    CloseMusic() {
      this.smallAlertModal = false;
      this.music = "";
      this.musicTitle = "";
      this.musicAuthor = "";
      this.loading = false;
    },

    onStatusChange() {
      if (this.isActive) {
        this.getSongs();
      }
    },

    refreshList() {
      this.pagination.page = 1;
      this.getSongs();
    },

    getSongs() {
      this.loading = true;
      let params;

      if (this.isActive === String("false")) {
        params = {
          isActive: this.isActive,
          page: this.pagination.page - 1,
          pageSize: this.pagination.pageSize,
        };
      } else {
        params = {
          page: this.pagination.page - 1,
          pageSize: this.pagination.pageSize,
        };
      }

      // console.log(params);

      this.$store
        .dispatch("getSongs", params)
        .then((res) => {
          if (res.data.paging.count === 0) {
            this.emptylist = true;
          } else {
            this.tableData = res.data.data;
            this.pagination.total = res.data.paging.count;
          }
        })

        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          if (this.tableData.length > 0) {
            this.emptylist = false;
          }
          this.loading = false;
          this.searchQuery = "";
        });
    },

    handlePageChange(value) {
      this.pagination.page = value;
      this.getSongs();
    },
    handlePageSizeChange(event) {
      this.pagination.pageSize = event.target.value;
      this.pagination.page = 1;
      this.getSongs();
    },

    // searchItem() {
    //   if (this.searchQuery == "") {
    //     this.$notify({
    //       message: "Search field is empty",
    //       icon: "add_alert",
    //       horizontalAlign: "right",
    //       verticalAlign: "top",
    //       type: "danger",
    //     });
    //   } else {
    //     this.loading = true;
    //     const formData = {
    //       searchTerm: this.searchQuery,
    //       category: this.category,
    //       page: this.pagination.page - 1,
    //       pageSize: this.pagination.pageSize,
    //     };
    //     this.$store
    //       .dispatch("searchItem", formData)
    //       .then((res) => {
    //         if (res.data.length === 0) {
    //           this.emptylist = true;
    //         } else {
    //           this.tableData = res.data;
    //         }
    //       })
    //       .catch((err) => {
    //         this.$notify({
    //           message: `<small><b>${err.response.data.message}</b></small>`,
    //           icon: "add_alert",
    //           horizontalAlign: "right",
    //           verticalAlign: "top",
    //           type: "danger",
    //         });
    //       })
    //       .finally(() => {
    //         this.loading = false;
    //       });
    //   }
    // },

    searchItem() {
      if (this.searchQuery == "") {
        this.$notify({
          message: "Search field is empty",
          icon: "add_alert",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "danger",
        });
      } else if (this.column == "") {
        this.$notify({
          message: "Select Type",
          icon: "add_alert",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "danger",
        });
      } else {
        this.loading = true;
        const params = {
          searchTerm: this.searchQuery,
          column: this.column,
          category: "songs",
        };
        // console.log(params);
        this.$store
          .dispatch("partialSearch", params)
          .then((res) => {
            if (res.data.length === 0) {
              this.emptylist = true;
            } else {
              this.tableData = res.data;
            }
          })
          .catch((err) => {
            this.$notify({
              message: `<small><b>${err.response.data.message}</b></small>`,
              icon: "add_alert",
              horizontalAlign: "right",
              verticalAlign: "top",
              type: "danger",
            });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    handleActivate(item) {
      this.loading = true;

      const params = {
        id: item.songId,
        status: item.isActive,
      };

      this.$store
        .dispatch("activateSong", params)
        .then((res) => {
          if (!res.data.message.includes("successfully")) {
            throw res;
          }
          this.$notify({
            message: `<small><b>${res.data.message}</b></small>`,
            icon: "add_alert",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "success",
          });
        })
        .catch((error) => {
          this.$notify({
            message: `<small><b>${error.data.message}</b></small>`,
            icon: "add_alert",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "danger",
          });
        })
        .finally(() => {
          this.loading = false;
          this.getSongs();
        });
    },

    handleTopSong(item) {
      this.loading = true;

      const params = {
        id: item.songId,
        status: item.isTop,
      };

      this.$store
        .dispatch("TopSong", params)
        .then((res) => {
          if (!res.data.message.includes("successfully")) {
            throw res;
          }
          this.$notify({
            message: `<small><b>${res.data.message}</b></small>`,
            icon: "add_alert",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "success",
          });
        })
        .catch((error) => {
          this.$notify({
            message: `<small><b>${error.data.message}</b></small>`,
            icon: "add_alert",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "danger",
          });
        })
        .finally(() => {
          this.loading = false;
          this.getSongs();
        });
    },

    handlePopularSong(item) {
      this.loading = true;

      const params = {
        id: item.songId,
        status: item.isPopular,
      };

      this.$store
        .dispatch("PopularSong", params)
        .then((res) => {
          if (!res.data.message.includes("successfully")) {
            throw res;
          }
          this.$notify({
            message: `<small><b>${res.data.message}</b></small>`,
            icon: "add_alert",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "success",
          });
        })
        .catch((error) => {
          this.$notify({
            message: `<small><b>${error.data.message}</b></small>`,
            icon: "add_alert",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "danger",
          });
        })
        .finally(() => {
          this.loading = false;
          this.getSongs();
        });
    },

    handleDeactivate() {
      if (this.selected.length > 0) {
        // this.loading = true;
        const params = {
          id: this.selected[0].songId,
          status: false,
        };
        console.log(params);

        // this.$store
        //   .dispatch("deleteSong")
        //   .then(() => {
        //     this.loading = false;
        //     let code = 200;

        //     if (code) {
        //       this.$notify({
        //         message: "Song Deactivated Successfully",
        //         icon: "add_alert",
        //         horizontalAlign: "right",
        //         verticalAlign: "top",
        //         type: "success",
        //       });
        //     }
        //   })
        //   .catch((error) => {
        //     console.log(error);
        //   });
      } else {
        this.$notify({
          message: "Select an Item from the List",
          icon: "add_alert",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "danger",
        });
      }
    },

    handleDelete() {
      if (this.selected.length > 0) {
        this.loading = true;

        //Populate the selected data
        let indexToDelete = this.selected.map((tableRow) => tableRow.songId);

        /** Use chunk to creeate an array of elements split into groups the length of size and then send it to a loop */
        var formData = _.chunk(indexToDelete, 1);
        for (var i = 0, j = formData.length; i < j; i++) {
          //Populate the the array to get the artistId
          const Items = formData[i].map((t) => t);

          //call the key value back
          const params = Items[0];

          this.$store
            .dispatch("deleteSong", params)
            .then((res) => {
              if (!res.message.includes("successfully")) {
                throw res;
              }
              this.$notify({
                message: `${res.message}`,
                icon: "add_alert",
                horizontalAlign: "right",
                verticalAlign: "top",
                type: "success",
              });
            })
            .catch((error) => {
              notifications.Error(error.message);
            })
            .finally(() => {
              this.loading = false;
              this.getSongs();
            });
        }
      } else {
        this.$notify({
          message: "Select an Item from the List",
          icon: "add_alert",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "danger",
        });
      }
    },
  },
  watch: {
    isActive() {
      // if (value) {
      //   this.onStatusChange(value);
      // }
      this.onStatusChange(this.isActive);
    },
  },
};
</script>


<style lang="scss" scoped>
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
.toolbar {
  margin-bottom: 10px;
}
.md-table[table-header-color="green"] .md-table-head .md-table-head-label {
  font-weight: bold;
}
.md-field {
  // width: 35%;
  // min-height: 48px;
  margin: 4px 0 10px;
  padding-top: 16px;
  display: flex;
  position: relative;
  font-family: inherit;
}
.title {
  color: rgb(0, 0, 0);
  font-weight: 700;
}
.author {
  color: rgb(0, 0, 0);
  font-weight: 700;
}

.imgUrl {
  width: 48px;
  height: 48px;
  border-radius: 10%;
}
.text-overflow-handle {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 120px;
  height: 1.2em;
  white-space: nowrap;
}
</style>