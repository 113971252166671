<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-danger">
          <div class="card-icon">
            <md-icon>auto_stories</md-icon>
          </div>
          <h4 class="title">Bible</h4>
        </md-card-header>
        <md-card-content>
          <clip-loader
            :loading="loading"
            :color="color"
            size="25px"
          ></clip-loader>
          <div class="font-size:20px">
            <i class="fa fa-wrench" style="font-size: 50px" aria-hidden="true"
              ><br />Under <br />
              Construction</i
            >
          </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>
<script>
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

// import Fuse from "fuse.js";
///import Swal from "sweetalert2";

export default {
  props: {
    profileCard: {
      type: String,
      default: "./img/faces/card-profile1-square.jpg",
    },
  },
  components: {
    ClipLoader,
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
  data() {
    return {
      currentSort: "name",
      currentSortOrder: "asc",
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      footerTable: [],
      searchQuery: "",
      propsToSearch: ["name", "email"],
      tableData: [],
      searchedData: [],
      fuseSearch: null,
      emptylist: false,
      currentAdmin: "",
      editcurrentAdmin: false,
      loading: false,
      color: "#3AB982",
      selected: [],
    };
  },
  methods: {
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "desc") {
          return a[sortBy].localeCompare(b[sortBy]);
        }
        return b[sortBy].localeCompare(a[sortBy]);
      });
    },
    // editAdmin(item) {
    //     this.editcurrentAdmin = true;
    //     this.currentAdmin = item.id;
    //     this.$router.push({
    //       name: "Admins Detail",
    //       params: { id: item.id },
    //     });
    // },

    onSelect(items) {
      this.selected = items;
    },
    getAlternateLabel(count) {
      let plural = "";

      if (count > 1) {
        plural = "s";
      }

      return `${count} item${plural} selected`;
    },
  },

  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData;
      if (value !== "") {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result;
    },
  },
  created() {
    // this.loading = true;
    // this.$store
    //   .dispatch("getNotifications")
    //   .then((res) => {
    //     this.loading = false;
    //     this.tableData = res;
    //   })
    //   .then(() => {
    //     // Fuse search initialization.
    //     this.fuseSearch = new Fuse(this.tableData, {
    //       keys: ["entitytype", "entityName"],
    //       threshold: 0.3,
    //     });
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  },
};
</script>
<style lang="css">
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
</style>
<style lang="css" scoped>
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
.item-status {
  background-color: #4caf50;
  padding: 5px;
  color: white;
  border-radius: 10%;
}
.img-width {
  width: 50px;
}

.md-table[table-header-color="green"] .md-table-head .md-table-head-label {
  font-weight: bold;
}
</style>
<style lang="css">
.md-table-head-label {
  font-weight: bold;
}
</style>