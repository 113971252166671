<template>
  <modal id="AddTag" ref="AddTag" v-if="AddTagModal" @close="HideTagModal">
    <template slot="header">
      <h4 class="modal-title">Tag</h4>
      <md-button
        class="md-simple md-danger md-just-icon md-round modal-default-button"
        @click="HideTagModal"
      >
        <md-icon>clear</md-icon>
      </md-button>
    </template>

    <template slot="body">
      <ValidationObserver ref="observer">
        <form @submit.prevent="Add" ref="AddTagForm">
          <div class="md-layout">
            <div class="md-layout-item md-size-100 md-small-size-100">
              <ValidationProvider
                name="Category"
                ref="category"
                :rules="{
                  required: true,
                }"
                v-slot="{ errors, passed, failed }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Category</label>
                  <md-select v-model="form.category" md-dense name="Category">
                    <md-option
                      v-for="item in categoriesArray"
                      :key="item.tagCategoryId"
                      :label="item"
                      :value="item.tagCategoryId"
                      >{{ item.title }}
                    </md-option>
                  </md-select>
                </md-field>
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>

              <div v-if="form.category ? getTags() : null"></div>
              <ValidationProvider
                v-if="form.category"
                name="Tag"
                ref="tag"
                :rules="{
                  required: true,
                }"
                v-slot="{ errors, passed, failed }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Tag</label>
                  <md-select
                    v-model="form.tag"
                    name="Tag"
                    md-dense
                    required
                    multiple
                  >
                    <md-option
                      v-for="(item, index) in tagArray"
                      :key="index"
                      :label="item.tag"
                      :value="item.tagId"
                      >{{ item.tag }}
                    </md-option>
                  </md-select>
                </md-field>
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </template>

    <template slot="footer">
      <div class="md-layout-item md-size-100 text-right">
        <clip-loader
          :loading="loading"
          :color="color"
          size="20px"
          class="mt-4 mr-5 pull-right"
        ></clip-loader>
        <md-button
          @click="HideTagModal"
          class="md-raised md-danger md-sm mt-4 mr-5 pull-left"
        >
          Close
        </md-button>

        <md-button
          @click="$refs.AddTagForm.requestSubmit()"
          class="md-raised md-sm md-success mt-4"
        >
          Add
        </md-button>
      </div>
    </template>
  </modal>
</template>

<script>
import { Modal } from "@/componentItems";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import {
  ValidationObserver,
  ValidationProvider,
} from "vee-validate/dist/vee-validate.full";
import * as notifications from "../../../utils/notifications";

export default {
  components: {
    ClipLoader,
    Modal,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    getlist: {
      type: Function,
      default: () => {},
    },
    category: {
      type: String,
    },
    currentItem: {
      type: String,
    },
   
  },

  created() {
    this.getAllCategories();
    return this.getTags();
  },

  data() {
    return {
      AddTagModal: false,
      loading: false,
      color: "#3AB982",
      size: "40px",

      form: {
        category: "",
        tag: "",
      },
      categoriesArray: [],
      tagArray: "",
    };
  },
  methods: {
    ShowTagModal() {
      this.AddTagModal = true;
    },
    HideTagModal() {
      var self = this;
      self.AddTagModal = false;

      Object.keys(this.form).forEach((key) => {
        self.form[key] = "";
      });
    },
    HideAddData() {
      var self = this;

      Object.keys(this.form).forEach((key) => {
        self.form[key] = "";
      });
    },

    getAllCategories() {
      this.$store
        .dispatch("getAllCategories")
        .then((res) => {
          this.categoriesArray = res.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getTags() {
      const params = {
        id: this.form.category,
      };
      if (this.form.category !== "" || null) {
        this.$store
          .dispatch("getTags", params)
          .then((res) => {
            this.tagArray = res.data.data;
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.form.tag = null;
          });
      } else {
        null;
      }
    },

    Add(e) {
      e.preventDefault();
      this.$refs.observer.validate().then((success) => {
        if (!success) {
          return;
        }

        this.loading = true;

        const params = {
          tagIds: this.form.tag,
          category: this.category,
          contentId: this.currentItem,
        };
        console.log(params);

        this.$store
          .dispatch("TagItem", params)
          .then((res) => {
            if (!res.data.message.includes("successfully")) {
              throw res;
            }

            notifications.Success({
              title: `<small><b>${res.data.message}</b></small>`,
              buttonsStyling: false,
              confirmButtonClass: "md-button md-success",
            });
          })
          .catch((err) => {
            notifications.Error(err.data.message);
          })
          .finally(() => {
            this.loading = false;
            this.getlist();
            this.HideTagModal();
            
          });
        this.$nextTick(() => {
          this.$refs.observer.reset();
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.img-size {
  width: 45%;
  height: 45%;
}
</style>
