<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-danger">
          <div class="card-icon">
            <md-icon>people</md-icon>
          </div>
          <h4 class="title">Users</h4>
        </md-card-header>

        <clip-loader
          :loading="loading"
          :color="color"
          size="32px"
        ></clip-loader>

        <md-card-content>
          <md-table-empty-state
            v-if="emptylist"
            md-label="No data found"
            :md-description="`Empty table there's no data`"
          >
          </md-table-empty-state>
          <md-table
            v-else
            :value="queriedData"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
            @md-selected="onSelect"
            table-header-color="green"
            class="table-striped table-hover"
          >
            <md-table-toolbar class="toolbar" md-alignment="space-between">
              <div class="md-toolbar-section-start">
                <md-field style="width: 50px" class="mr-3">
                  <label for="pages">Per page</label>
                  <md-select
                    v-model="pagination.pageSize"
                    @change="handlePageSizeChange($event)"
                    name="pages"
                    style="width: 50px"
                  >
                    <md-option
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                      {{ item }}
                    </md-option>
                  </md-select>
                </md-field>
              </div>

              <div class="md-toolbar-section-end">
                <md-field style="width: 270px">
                  <md-input
                    type="search"
                    clearable
                    style="width: 250px"
                    placeholder="Search..."
                    v-model="searchQuery"
                  >
                  </md-input>
                </md-field>
              </div>
            </md-table-toolbar>
            <md-table-toolbar
              slot="md-table-alternate-header"
              slot-scope="{ count }"
              style="margin-top: -20px"
            >
              <div class="md-toolbar-section-start">
                {{ getAlternateLabel(count) }}
              </div>
            </md-table-toolbar>

            <md-table-row
              slot="md-table-row"
              slot-scope="{ item }"
              md-selectable="multiple"
              md-auto-select
            >
              <md-table-cell md-label="User Name">{{
                item.username
              }}</md-table-cell>
              <md-table-cell md-label="Full Name"
                >{{ item.firstName }} {{ item.lastName }}</md-table-cell
              >
              <md-table-cell md-label="Email">{{
                item.emailAddress
              }}</md-table-cell>
              <md-table-cell md-label="Gender">{{ item.gender }}</md-table-cell>
              <md-table-cell md-label="Mobile">{{ item.msisdn }}</md-table-cell>
              <md-table-cell md-label="Accepted Terms">{{
                item.acceptedTerms
              }}</md-table-cell>
              <md-table-cell md-label="Tone Count">{{
                item.toneCount
              }}</md-table-cell>

              <md-table-cell md-label="Status">
                <md-switch
                  v-model="item.isActive"
                  @change="handleActivate(item)"
                  class="md-sm"
                >
                  {{ item.isActive == true ? "Active" : "InActive" }}</md-switch
                >
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ comma(total) }} entries
            </p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.page"
            :per-page="pagination.pageSize"
            :total="total"
            @pagechange="handlePageChange"
          >
          </pagination>
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>
<script>
import { Pagination, commaSeparator } from "../../../componentItems";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import Fuse from "fuse.js";

export default {
  props: {
    profileCard: {
      type: String,
      default: "./img/faces/card-profile1-square.jpg",
    },
  },
  components: {
    Pagination,
    ClipLoader,
  },
  mixins: [commaSeparator],
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      return this.tableData;
    },
    to() {
      let highBound = this.from + this.pagination.pageSize;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.pageSize * (this.pagination.page - 1);
    },
    total() {
      return this.pagination.total;
    },
  },

  created() {
    this.getUsers();
  },
  data() {
    return {
      currentSort: "name",
      currentSortOrder: "asc",
      pagination: {
        pageSize: 5,
        page: 1,
        perPageOptions: [5, 10, 25, 50, 100],
        total: 0,
      },
      footerTable: [],
      searchQuery: "",
      propsToSearch: ["name", "email"],
      tableData: [],
      searchedData: [],
      fuseSearch: null,
      emptylist: false,
      currentUser: "",
      editcurrentUser: false,
      loading: false,
      color: "#3AB982",
      selected: [],
      selectedDate: new Date(),
    };
  },
  methods: {
    onSelect(items) {
      this.selected = items;
    },
    getAlternateLabel(count) {
      let plural = "";

      if (count > 1) {
        plural = "s";
      }

      return `${count} item${plural} selected`;
    },
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "desc") {
          return a[sortBy].localeCompare(b[sortBy]);
        }
        return b[sortBy].localeCompare(a[sortBy]);
      });
    },
    editUser(item) {
      this.editcurrentUser = true;
      this.currentUser = item.id;
      this.$router.push({
        name: "Edit User",
        params: { id: item.id },
      });
    },
    newUser() {
      this.$router.push({
        name: "Add User",
      });
    },
    getUsers() {
      this.loading = true;
      const params = {
        page: this.pagination.page - 1,
        pageSize: this.pagination.pageSize,
      };
      this.$store
        .dispatch("getUsers", params)
        .then((res) => {
          if (res.data.paging.count === 0) {
            this.emptylist = true;
          } else {
            this.tableData = res.data.data;
            this.pagination.total = res.data.paging.count;
          }
        })
        .then(() => {
          // Fuse search initialization.
          this.fuseSearch = new Fuse(this.tableData, {
            keys: ["title"],
            threshold: 0.3,
          });
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handlePageChange(value) {
      this.pagination.page = value;
      this.getUsers();
    },
    handlePageSizeChange(event) {
      this.pagination.pageSize = event.target.value;
      this.pagination.page = 1;
      this.getUsers();
    },

    handleActivate(item) {
      this.loading = true;
      const params = {
        id: item.userId,
        status: item.isActive,
      };

      this.$store
        .dispatch("activateUser", params)
        .then((res) => {
          if (!res.data.message.includes("successfully")) {
            throw res;
          }
          this.$notify({
            message: `<small><b>${res.data.message}</b></small>`,
            icon: "add_alert",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "success",
          });
        })
        .catch((error) => {
          this.$notify({
            message: `<small><b>${error.data.message}</b></small>`,
            icon: "add_alert",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "danger",
          });
        })
        .finally(() => {
          this.loading = false;
          this.getUsers();
        });
    },

    deleteUser() {
      if (this.selected.length > 0) {
        this.loading = true;
        this.$store
          .dispatch("deleteArtist")
          .then((res) => {
            this.loading = false;
            let code = res.data.code;

            if (code) {
              this.$notify({
                message: "User Deleted Successfully",
                icon: "add_alert",
                horizontalAlign: "right",
                verticalAlign: "top",
                type: "success",
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.$notify({
          message: "Select an Item from the List",
          icon: "add_alert",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "danger",
        });
      }
    },
    activateUser() {
      if (this.selected.length > 0) {
        this.loading = true;
        this.$store
          .dispatch("deleteArtist")
          .then(() => {
            this.loading = false;
            let code = 200;

            if (code) {
              this.$notify({
                message: "User Activated Successfully",
                icon: "add_alert",
                horizontalAlign: "right",
                verticalAlign: "top",
                type: "success",
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.$notify({
          message: "Select an Item from the List",
          icon: "add_alert",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "danger",
        });
      }
    },
    deactivateUser() {
      if (this.selected.length > 0) {
        this.loading = true;
        this.$store
          .dispatch("deleteArtist")
          .then(() => {
            this.loading = false;
            let code = 200;

            if (code) {
              this.$notify({
                message: "User Deactivated Successfully",
                icon: "add_alert",
                horizontalAlign: "right",
                verticalAlign: "top",
                type: "success",
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.$notify({
          message: "Select an Item from the List",
          icon: "add_alert",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "danger",
        });
      }
    },
  },

  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData;
      if (value !== "") {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>