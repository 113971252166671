<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-65 mx-auto md-small-size-65">
      <ValidationObserver ref="observer">
        <form
          @submit.prevent="addSermon"
          @keyup.enter.prevent="addSermon"
          ref="AddSermon"
        >
          <md-card md-with-hover>
            <md-card-header class="md-card-header-icon md-card-header-danger">
              <div class="card-icon">
                <md-icon>assignment</md-icon>
              </div>
              <h4 class="title">Add Sermon</h4>
            </md-card-header>
            <md-card-content>
              <div class="md-layout">
                <div class="md-layout-item md-size-30 md-small-size-100">
                  <div class="file-input img-size">
                    <template v-if="!imageCircle">
                      <div class="image-container">
                        <img :src="avatarImg" title="" />
                      </div>
                    </template>
                    <div class="image-container" v-else>
                      <img :src="imageCircle" />
                    </div>
                    <div class="button-container">
                      <md-button
                        class="md-danger md-raised md-sm"
                        @click="removeImage('circle')"
                        v-if="imageCircle"
                      >
                        <md-icon>clear</md-icon>Remove</md-button
                      >
                      <md-button
                        class="md-sm md-success md-raised md-fileinput"
                      >
                        <template v-if="!imageCircle">Add Photo</template>
                        <template v-else>Change</template>
                        <input
                          type="file"
                          name="circle"
                          accept="image/*"
                          @change="onFileChange"
                        />
                      </md-button>
                    </div>
                    <h6 class="text-danger">{{ imageError }}</h6>
                  </div>
                </div>

                <div class="md-layout-item md-size-70 md-small-size-100">
                  <ValidationProvider
                    name="Sermon Title "
                    ref="Sermon Title "
                    :rules="{
                      required: true,
                      regex: /^[a-zA-Z0-9 '.-]*$/,
                    }"
                    v-slot="{ errors, passed, failed }"
                  >
                    <md-field
                      :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                    >
                      <label>Sermon Title</label>
                      <md-input
                        v-model="sermonTitle"
                        data-vv-name="sermonTitle"
                        type="text"
                        required
                        name="sermonTitle"
                      >
                      </md-input>

                      <slide-y-down-transition>
                        <md-icon class="error" v-show="failed">close</md-icon>
                      </slide-y-down-transition>
                      <slide-y-down-transition>
                        <md-icon class="success" v-show="passed">done</md-icon>
                      </slide-y-down-transition>
                    </md-field>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>

                  <ValidationProvider
                    name="Sermon Author "
                    ref="Sermon Author "
                    data-vv-name="text"
                    :rules="{
                      required: true,
                      regex: /^[a-zA-Z0-9 '.-]*$/,
                    }"
                    v-slot="{ errors, passed, failed }"
                  >
                    <md-field
                      :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                    >
                      <label>Sermon Author</label>
                      <md-input
                        v-model="sermonAuthor"
                        data-vv-name="sermonAuthor"
                        type="text"
                        required
                        name="sermonAuthor"
                      >
                      </md-input>

                      <slide-y-down-transition>
                        <md-icon class="error" v-show="failed">close</md-icon>
                      </slide-y-down-transition>
                      <slide-y-down-transition>
                        <md-icon class="success" v-show="passed">done</md-icon>
                      </slide-y-down-transition>
                    </md-field>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>

                  <md-field>
                    <label>Description</label>
                    <md-textarea
                      v-model="sermonDescription"
                      type="text"
                    ></md-textarea>
                  </md-field>
                </div>

                <div class="md-layout-item md-small-size-100 md-size-60">
                  <br /><br />
                  <br />
                  <multiselect
                    v-model="churches"
                    name="church"
                    :options="ChurchesComputed"
                    placeholder="Select Church"
                    label="title"
                    track-by="churchId"
                    :limit="1"
                    :searchable="true"
                    :show-labels="true"
                    :close-on-select="true"
                    :clear-on-select="true"
                    deselect-label="Remove"
                    select-label="Choose Church"
                    open-direction="bottom"
                  >
                  </multiselect>
                  <h6 class="text-danger">{{ churchError }}</h6>
                </div>

                <div class="md-layout-item md-small-size-100 md-size-40">
                  <br /><br />

                  <div class="ml-5">
                    <span><b>Sermon Type:</b></span>
                    <div>
                      <!-- <md-radio v-model="sermonType" :value="false"
                          >Audio</md-radio
                        >
                        <md-radio v-model="sermonType" :value="true"
                          >Video</md-radio
                        > -->
                      <md-checkbox
                        v-model="sermonAudio"
                        @change="sermonAudioChange"
                        >Audio</md-checkbox
                      >
                      <md-checkbox
                        v-model="sermonVideo"
                        @change="sermonVideoChange"
                        >Video</md-checkbox
                      >
                    </div>
                  </div>
                </div>

                <!-- files -->
                <div
                  class="md-layout-item md-small-size-100 md-size-100"
                  v-if="sermonAudio == true"
                >
                  <ValidationProvider
                    name="Audio"
                    ref="audio"
                    :rules="{
                      required: true,
                    }"
                    v-slot="{ errors, passed, failed }"
                  >
                    <md-field
                      :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                    >
                      <label>Upload Audio file</label>
                      <md-file
                        type="file"
                        id="audiofile"
                        ref="audiofile"
                        name="audio"
                        accept="audio/*"
                        required
                        v-model="audioname"
                        @change="onAudioChange"
                      />
                      <md-button class="md-just-icon md-simple">
                        <md-icon>info</md-icon>
                        <md-tooltip class="md-sm" md-direction="top"
                          >Valid extension: mp3,3gp.Valid size:less than (<b
                            >30 M.B</b
                          >)</md-tooltip
                        >
                      </md-button>
                      <slide-y-down-transition>
                        <md-icon class="error" v-show="failed">close</md-icon>
                      </slide-y-down-transition>
                      <slide-y-down-transition>
                        <md-icon class="success" v-show="passed">done</md-icon>
                      </slide-y-down-transition>
                    </md-field>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </div>

                <div
                  class="md-layout-item md-small-size-100 md-size-100"
                  v-if="sermonVideo == true"
                >
                  <ValidationProvider
                    name="Video"
                    ref="video"
                    :rules="{
                      required: true,
                    }"
                    v-slot="{ errors, passed, failed }"
                  >
                    <md-field
                      :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                    >
                      <label>Upload Video file</label>
                      <md-file
                        type="file"
                        id="videofile"
                        ref="videofile"
                        name="video"
                        accept="video/*"
                        required
                        v-model="videoname"
                        @change="onVideoChange"
                      />
                      <md-button class="md-just-icon md-simple">
                        <md-icon>info</md-icon>
                        <md-tooltip class="md-sm" md-direction="top"
                          >Valid extension: mp3,3gp.Valid size:less than (<b
                            >30 M.B</b
                          >)</md-tooltip
                        >
                      </md-button>
                      <slide-y-down-transition>
                        <md-icon class="error" v-show="failed">close</md-icon>
                      </slide-y-down-transition>
                      <slide-y-down-transition>
                        <md-icon class="success" v-show="passed">done</md-icon>
                      </slide-y-down-transition>
                    </md-field>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </div>

                <!-- files -->
              </div>
            </md-card-content>
            <md-card-actions>
              <md-button
                @click.native="goBack()"
                class="md-sm md-dense md-raised md-danger"
                >Cancel</md-button
              >

              <md-button
                class="md-sm md-dense md-raised md-success"
                @click="$refs.AddSermon.requestSubmit()"
                >Submit</md-button
              >
            </md-card-actions>
          </md-card>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import { SlideYDownTransition } from "vue2-transitions";

import {
  ValidationObserver,
  ValidationProvider,
  extend,
} from "vee-validate/dist/vee-validate.full";
import * as notifications from "../../../utils/notifications";

// can customize default error messages
extend("required", {
  message: (field) => "The " + `${field}` + " field is required. ",
});
export default {
  props: {
    profileCard: {
      type: String,
      default: "../../img/no-image.png",
    },
    avatarImg: {
      type: String,
      default: "../../img/no-image.png",
    },
  },

  components: {
    ValidationProvider,
    ValidationObserver,
    SlideYDownTransition,
  },

  data() {
    return {
      currentSort: "name",
      currentSortOrder: "asc",
      searchQuery: "",
      tableData: "",
      searchedData: [],
      churchArray: [],
      churches: "",
      fuseSearch: null,
      emptylist: false,
      currentSermon: "",
      editcurrentSermon: false,

      facebookUrl: "",
      instagramUrl: "",
      twitterUrl: "",
      status: "",
      topSermon: true,
      addedOn: "",
      modifiedOn: "",
      loading: false,
      color: "#3AB982",
      imageCircle: "",
      imageCircleFile: File,
      imageProfile: "",
      notifyUser: true,
      avatarFileName: "",
      profileFileName: "",
      sermonTitle: "",
      sermonType: false,

      sermonVideo: false,
      sermonAudio: true,

      sermonAuthor: "",
      sermonDescription: "",
      churchId: "",
      audioname: "",
      videoname: "",
      audio: "",
      audioFile: File,
      video: "",
      videoFile: File,
      imageError: "",
      churchError: "",
    };
  },
  computed: {
    ChurchesComputed() {
      return this.churchArray;
    },
  },
  methods: {
    getChurches() {
      const params = {
        page: 0,
        pageSize: 10,
      };
      this.$store
        .dispatch("getAllChurches", params)
        .then((res) => {
          this.churchArray = res.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    sermonAudioChange() {
      if (this.sermonAudio == false) {
        this.audioname = "";
      }
    },

    sermonVideoChange() {
      if (this.sermonVideo == false) {
        this.videoname = "";
      }
    },

    onAudioChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      this.createAudio(files[0]);
      this.audioname = files[0].name;
    },
    onVideoChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      this.createVideo(files[0]);
      this.videoname = files[0].name;
    },

    createAudio(file) {
      let reader = new FileReader();
      let vm = this;
      reader.onload = (e) => {
        vm.audio = e.target.result;
        vm.audioFile = file;
      };
      reader.readAsDataURL(file);
    },
    createVideo(file) {
      let reader = new FileReader();
      let vm = this;
      reader.onload = (e) => {
        vm.video = e.target.result;
        vm.videoFile = file;
      };
      reader.readAsDataURL(file);
    },
    goBack() {
      this.$router.back();
    },
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      // console.log(files[0].name);
      if (e.target.name) {
        this.createImage(files[0], "circle");
        this.avatarFileName = files[0].name;
        this.imageError = "";
      } else {
        this.createImage(files[0]);
        this.profileFileName = files[0].name;
      }
    },
    createImage(file, type) {
      let reader = new FileReader();
      let vm = this;

      reader.onload = (e) => {
        if (type === "circle") {
          // console.log(e.target);
          vm.imageCircleFile = file;
          vm.imageCircle = e.target.result;

          /// clg
        } else {
          vm.imageProfile = e.target.result;
        }
      };
      reader.readAsDataURL(file);
    },
    removeImage: function (type) {
      if (type === "circle") {
        this.imageCircle = "";
      } else {
        this.imageProfile = "";
      }
    },
    HideAddData() {
      this.$router.push("/sermon");
    },

    addSermon(e) {
      e.preventDefault();

      this.$refs.observer.validate().then((success) => {
        if (!success) {
          return;
        }
        if (this.avatarFileName == "") {
          this.imageError = "Sermon Avatar is required!";
        } else if (this.churches == "") {
          this.churchError = "This field is required!";
        } else {
          this.imageError = "";
          this.churchError = "";
          this.loading = true;
          this.$emit("viewLoading", true);
          let loader = this.$loading.show({
            // Optional parameters
            container: this.fullPage ? null : this.$refs.formContainer,
            canCancel: false,
            onCancel: this.onCancel,
            color: "#eb4034",
          });
          // let filesArray = [];
          let filesAudio = [];
          let filesVideo = [];
          let typevideo = "";
          let typeaudio = "";
          let churchId = "";

          filesAudio.push(this.imageCircleFile);
          filesVideo.push(this.imageCircleFile);

          if (this.audioname !== "" && this.sermonAudio == true) {
            typeaudio = "audio";
            filesAudio.push(this.audioFile);
          }

          if (this.videoname != "" || this.sermonVideo == true) {
            typevideo = "video";
            filesVideo.push(this.videoFile);
          } else if (this.videoname == "" || this.sermonVideo == false) {
            typevideo = "";
          } else if (this.sermonAudio == true || this.sermonVideo == true) {
            typeaudio = "audio";
            filesAudio.push(this.audioFile);
            typevideo = "video";
            filesVideo.push(this.videoFile);
          } else {
            null;
          }

          if (this.churches == null || this.churches == undefined) {
            churchId = "";
          } else {
            churchId = this.churches.churchId;
          }

          let Items = [
            {
              type: typeaudio,
              files: filesAudio !== null ? filesAudio : null,
            },
            {
              type: typevideo,
              files: filesVideo !== null ? filesVideo : null,
            },
          ];

          for (var i = 0, j = Items.length; i < j; i++) {
            const formData = {
              title: this.sermonTitle,
              author: this.sermonAuthor,
              description: this.sermonDescription,
              files: Items[i].files,
              type: Items[i].type,
              churchId: churchId,
              category: "Sermon",
            };
            if (Items[i].type) {
              // console.log(formData);

              this.$store
                .dispatch("addSermon", formData)
                .then((res) => {
                  console.log(res);

                  this.loading = false;
                  loader.hide();
                  notifications.Success({
                    title: "Sermon  created successfully",
                    buttonsStyling: false,
                    confirmButtonClass: "md-button md-success",
                  });
                })
                .then(() => {
                  this.loading = false;
                  loader.hide();
                  this.HideAddData();
                })
                .catch((err) => {
                  notifications.Error(err);
                  loader.hide();
                });
            }
          }

          this.$nextTick(() => {
            this.$refs.observer.reset();
          });
        }
      });
    },
  },

  created() {
    this.getChurches();
  },
};
</script>
<style lang="css">
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
</style>
<style lang="css" scoped>
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
.spacing {
  margin: "10px";
  padding: "10px";
}
.item-status {
  background-color: #4caf50;
  padding: 5px;
  color: white;
  border-radius: 10%;
}
.img-width {
  width: 50px;
}
.img-size {
  width: 92%;
  height: 92%;
}

.md-table[table-header-color="green"] .md-table-head .md-table-head-label {
  font-weight: bold;
}
</style>
