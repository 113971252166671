//import axios from "../../axios-auth";
import { getRoles, getRole } from "../../utils/api";

const state = {};
const mutations = {};
const actions = {
  getRoles({ commit }) {
    return new Promise((resolve, reject) => {
      getRoles()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },

  getRole({ commit }) {
    return new Promise((resolve, reject) => {
      getRole()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },
};
const getters = {};

export default { state, mutations, actions, getters };
