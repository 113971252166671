<template>
  <div class="wrapper" :class="[{ 'nav-open': $sidebar.showSidebar }]">
    <notifications></notifications>
    <side-bar
      :active-color="sidebarBackground"
      :background-image="sidebarBackgroundImage"
      :data-background-color="sidebarBackgroundColor"
      :loading="sidebarLoading"
    >
      <template slot="links">
        <sidebar-item
          :link="{ name: 'Dashboard', icon: 'dashboard', path: '/dashboard' }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Artists',
            icon: 'mic',
            path: '/artists',
          }"
        >
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Playlist',
            icon: 'playlist_play',
            path: '/playlist',
          }"
        >
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Songs',
            icon: 'library_music',
            path: '/song',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Church',
            icon: 'home_work',
            path: '/church',
          }"
        >
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Sermon',
            icon: 'library_books',
            path: '/sermon',
          }"
        >
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Albums',
            icon: 'album',
            path: '/albums',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Podcasts',
            icon: 'podcasts',
            path: '/podcasts',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Categories',
            icon: 'style',
            path: '/categories',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Users',
            icon: 'people',
            path: '/users',
          }"
        >
        </sidebar-item>
        <!-- <sidebar-item
          :link="{
            name: 'Genres',
            icon: 'category',
            path: '/genre',
          }"
        >
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Bible',
            icon: 'auto_stories',
            path: '/bible',
          }"
        >
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Dailies',
            icon: 'note',
            path: '/dailies',
          }"
        >
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Plans',
            icon: 'table_chart',
            path: '/plans',
          }"
        >
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Notifications',
            icon: 'notifications',
            path: '/notifications',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Admins',
            icon: 'account_circle',
            path: '/admins',
          }"
        >
        </sidebar-item> -->

        <!-- <sidebar-item
          :link="{
            name: 'Roles',
            icon: 'filter_list',
            path: '/roles',
          }"
        >
        </sidebar-item> -->
      </template>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>
      <div
        :class="{ content: !$route.meta.hideContent }"
        @click="toggleSidebar"
      >
        <zoom-center-transition :duration="100" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </zoom-center-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import { ZoomCenterTransition } from "vue2-transitions";

export default {
  components: {
    TopNavbar,
    ContentFooter,
    ZoomCenterTransition,
  },
  data() {
    return {
      sidebarBackgroundColor: "white",
      sidebarBackground: "danger",
      sidebarBackgroundImage: "./img/sidebar-2.jpg",
      sidebarMini: true,
      sidebarImg: true,
      sidebarLoading: false,
    };
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },
  },
  mounted() {
    let docClasses = document.body.classList;
    let isWindows = navigator.platform.startsWith("Win");
    if (isWindows) {
      // if we are on windows OS we activate the perfectScrollbar function
      initScrollbar("sidebar");
      initScrollbar("sidebar-wrapper");
      initScrollbar("main-panel");

      docClasses.add("perfect-scrollbar-on");
    } else {
      docClasses.add("perfect-scrollbar-off");
    }
  },
  watch: {
    sidebarMini() {
      this.minimizeSidebar();
    },
  },
};
</script>
<style lang="scss">
$scaleSize: 0.95;
@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  to {
    opacity: 1;
  }
}
.main-panel .zoomIn {
  animation-name: zoomIn95;
}
@keyframes zoomOut95 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}
.main-panel .zoomOut {
  animation-name: zoomOut95;
}
</style>
