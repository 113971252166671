import { searchUrl } from "../../utils/api.js";
import api from "../../axios-auth";
const state = {
  loading: false,
  error: {},
};
const mutations = {
  request(state) {
    state.loading = true;
  },
  error(state, error) {
    state.loading = false;
    state.error = error;
  },
};
const actions = {
  searchItem({ commit }, formData) {
    return new Promise((resolve, reject) => {
      commit("request");
      searchUrl
        .post("/media/search", {
          searchTerm: formData.searchTerm,
          category: formData.category,
          page: formData.page,
          pageSize: formData.pageSize,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          commit("error", err.message);
          reject(err);
        });
    });
  },
  partialSearch({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit("request");
      api
        .get(`/${params.category}/partialSearch?column=${params.column}&searchKey=${params.searchTerm}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          commit("error", err.message);
          reject(err);
        });
    });
  },
};
const getters = {};

export default { state, mutations, actions, getters };
