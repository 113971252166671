import api from "../../axios-auth";
import filesAxios from "../../axios-files";
import { s3Url } from "../../utils/api.js";
import { s3Callback } from "../../utils/api.js";

const state = {
  loading: true,
  Podcasts: [],
  Episodes: [],
  error: {},
};

const mutations = {
  request(state) {
    state.loading = true;
  },
  PodcastList(state, ListItems) {
    state.loading = false;
    state.Podcasts = ListItems.items;
  },
  EpisodesList(state, ListItems) {
    state.loading = false;
    state.Episodes = ListItems.items;
  },

  error(state, error) {
    state.loading = false;
    state.error = error;
  },
};

const actions = {
  getAllPodcasts({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .get(`/podcasts?page=0&pageSize=10`)
        .then((res) => {
          commit("request");
          api
            .get(`/podcasts?page=0&pageSize=${res.data.paging.count}`)
            .then((res) => {
              resolve(res.data.data);
            });
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },

  getPodcasts({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit("request");
      api
        .get(`/podcasts?page=${params.page}&pageSize=${params.pageSize}`)
        .then((res) => {
          commit("PodcastList", {
            items: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },

  addPodcast({ commit }, formData) {
    return new Promise((resolve, reject) => {
      commit("request");
      const file = formData.files;
      let taskId;
      api
        .post("/podcasts", {
          title: formData.title,
          author: formData.author,
          description: formData.description,
        })
        .then((res) => {
          resolve(res);
          let Id = res.data.data[0].podcastId;
          return api.post(s3Url, {
            id: Id.toString(),
            file_name: file.name,
            category: formData.category,
          });
        })
        .then((resp) => {
          const { url: signedUrl, task_id } = resp.data;
          taskId = task_id;
          return filesAxios.put(signedUrl, file);
        })
        .then(() => {
          return api.post(`${s3Callback}${taskId}`, {});
        })
        .then((res) => {
          console.log(res);
          resolve(res);
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },

  addPodcastEpisode({ commit }, formData) {
    return new Promise((resolve, reject) => {
      commit("request");
      const file = formData.files[0];
      let taskId;
      api
        .post(`/podcasts/${formData.id}/addEpisodes`, {
          title: formData.title,
          description: formData.description,
          sequence: formData.sequence,
        })
        .then((res) => {
          resolve(res);
          const Id = formData.id;
          return api.post(s3Url, {
            id: Id.toString(),
            file_name: file.name,
            category: formData.category,
          });
        })
        .then((resp) => {
          const { url: signedUrl, task_id } = resp.data;
          taskId = task_id;
          return filesAxios.put(signedUrl, file);
        })
        .then(() => {
          return api.post(`${s3Callback}${taskId}`, {});
        })
        .then((result) => {
          console.log(result);
          resolve(result);
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },

  editPodcast({ commit }, formData) {
    return new Promise((resolve, reject) => {
      commit("request");
      const file = formData.files[0];
      let taskId;
      api
        .put(`/podcasts/${formData.id}/edit`, {
          title: formData.title,
          author: formData.author,
          description: formData.description,
        })
        .then((res) => {
          resolve(res);
          let Id = formData.id;
          return api.post(s3Url, {
            id: Id.toString(),
            file_name: file.name,
            category: formData.category,
          });
        })
        .then((resp) => {
          const { url: signedUrl, task_id } = resp.data;
          taskId = task_id;
          return filesAxios.put(signedUrl, file);
        })
        .then(() => {
          return api.post(`${s3Callback}${taskId}`, {});
        })
        .then((result) => {
          console.log(result);
          resolve(result);
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },

  getPodcast({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit("request");
      api
        .get(`/podcasts/${id}`)
        .then((res) => {
          commit("PodcastList", {
            items: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },

  getPodcastEpisode({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit("request");
      ///podcasts/764813560512512/episodes?page=0&pageSize=10
      api
        .get(`/podcasts/${params.id}/episodes?page=0&pageSize=10`)
        .then((res) => {
          commit("EpisodesList", {
            items: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },

  activatePodcast({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit("request");
      api
        .put(`/podcasts/${params.id}/activate`, {
          status: params.status,
        })
        .then((res) => {
          commit("PodcastList", {
            items: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },
  activatePodcastEpisode({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit("request");

      api
        .put(`/podcasts/episodes/${params.id}/activate`, {
          status: params.status,
        })
        .then((res) => {
          commit("EpisodesList", {
            items: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },

  TopPodcast({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit("request");
      api
        .put(`/podcasts/${params.id}/markAsTop`, {
          status: params.status,
        })
        .then((res) => {
          commit("PodcastList", {
            items: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },
  PopularPodcast({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit("request");
      api
        .put(`/podcasts/${params.id}/markAsPopular`, {
          status: params.status,
        })
        .then((res) => {
          commit("PodcastList", {
            items: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },

  deletePodcast({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit("request");
      api
        .delete(`/podcasts/${params}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },

  deletePodcastEpisode({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit("request");
      api
        .delete(`/podcasts/episodes/${params}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },
};

const getters = {};

export default { state, mutations, actions, getters };
