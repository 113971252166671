<template>
  <div>
    <md-empty-state
      class="md-accent"
      md-icon="error"
      md-label="(404) Page Not Found"
      md-description="We could not find the page you were looking for. Meanwhile, you may"
    >
      <div v-if="auth">
        <router-link to="/dashboard">
          <span class="hide-sm"> <b>Return to Dashboard</b> </span>
        </router-link>
      </div>
      <div v-else>
        <router-link to="/">
          <span class="hide-sm"> <b>Return to login</b> </span>
        </router-link>
      </div>
    </md-empty-state>
  </div>
</template>

<script>
export default {
  name: "EmptyStateColors",

  computed: {
    auth() {
      return this.$store.getters.isAuthenticated;
    },
  },
};
</script>

<style lang="scss" scoped>
div {
  text-align: center;
}

.md-empty-state {
  display: inline-block;
  vertical-align: middle;

  + .md-empty-state {
    margin-left: 16px;
  }
}
</style>