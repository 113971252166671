<template>
  <md-card
    class="md-card-tabs"
    :class="[
      { 'flex-column': flexColumn },
      { 'nav-pills-icons': navPillsIcons },
      { 'md-card-plain': plain },
    ]"
  >
    <md-card-header class="md-card-header-icon" :class="getClass(headerColor)">
      <slot name="header-title"></slot>
    </md-card-header>
    <md-card-content>
      <md-list class="nav-tabs">
        <md-list-item
          v-for="(item, index) in tabName"
          @click="switchPanel(tabName[index])"
          :key="item"
          :class="[
            { active: isActivePanel(tabName[index]) },
            { [getColorButton(colorButton)]: isActivePanel(tabName[index]) },
          ]"
        >
          {{ tabName[index] }}
          <md-icon v-if="navPillsIcons">{{ tabIcon[index] }}</md-icon>
        </md-list-item>
      </md-list>

      <transition name="fade" mode="out-in">
        <div class="tab-content">
          <template v-for="(item, index) in tabName">
            <template v-if="isActivePanel(tabName[index])">
              <div :class="getTabContent(index + 1)" :key="item">
                <slot :name="getTabContent(index + 1)">
                  This is the default text!
                </slot>
              </div>
            </template>
          </template>
        </div>
      </transition>
    </md-card-content>
    <!-- <md-card-actions md-alignment="space-between">
      <template v-for="(item, index) in tabName">
        <template v-if="isActivePanel(tabName[index])">
          <slot :name="getTabAction(index + 1)">
            This is the default text!
          </slot>
        </template>
      </template>
    </md-card-actions> -->
  </md-card>
</template>

<script>
export default {
  props: {
    flexColumn: Boolean,
    navPillsIcons: Boolean,
    plain: Boolean,
    tabName: Array,
    bizSelected: String,
    downloadSelected: String,
    username: String,
    ///clicked: Boolean,
    tabIcon: Array,
    colorButton: {
      type: String,
      default: "",
    },
    headerColor: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      activePanel: this.tabName[0],
    };
  },

  watch: {
    bizSelected: function () {
      this.switchPanel(this.tabName[1]);
    },
    downloadSelected: function () {
      this.switchPanel(this.tabName[2]);
    },
  },
  methods: {
    // clicked(value) {
    //   this.bizSelected = false;
    //   console.log("value"); // someValue
    // },
    switchPanel(panel) {
      this.activePanel = panel;
    },
    isActivePanel(panel) {
      return this.activePanel == panel;
    },
    getColorButton: function (colorButton) {
      return "md-" + colorButton + "";
    },
    getTabContent: function (index) {
      return "tab-pane-" + index + "";
    },
    getTabAction: function (index) {
      return "tab-action-" + index + "";
    },
    getClass: function (headerColor) {
      return "md-card-header-" + headerColor + "";
    },
  },
};
</script>

<style lang="css">
.nav-tabs {
  border-bottom: 0px solid #dee2e6;
  /* float: right; */
}
</style>
