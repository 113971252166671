import faker from "faker";

const Songs = () => ({
  id: faker.datatype.uuid(),
  imageUrl: faker.image.imageUrl(),
  title: faker.name.title(),
  duration: faker.time.recent(),
  free: faker.datatype.boolean(),
  likes: faker.datatype.number(),
  createdAt: faker.datatype.datetime(),
  updatedAt: faker.datatype.datetime(),
  status: faker.datatype.boolean(),
  popularAudio: faker.datatype.boolean(),
});

const Genres = () => ({
  id: faker.datatype.uuid(),
  title: faker.name.title(),
  status: faker.datatype.boolean(),
  createdAt: faker.datatype.datetime(),
  updatedAt: faker.datatype.datetime(),
});

const adminList = () => ({
  id: faker.datatype.uuid(),
  name: faker.name.firstName(),
  emailAddress: faker.internet.exampleEmail(),
  phone: faker.phone.phoneNumber(),
  status: faker.datatype.boolean(),
});
const admin = () => ({
  id: faker.datatype.uuid(),
  name: faker.name.firstName(),
  emailAddress: faker.internet.exampleEmail(),
  phone: faker.phone.phoneNumber(),
  status: faker.datatype.boolean(),
});

const NotificationList = () => ({
  id: faker.datatype.uuid(),
  entitytype: faker.database.type(),
  entityName: faker.database.type(),
  message: faker.lorem.paragraph(),
  sentCount: faker.datatype.number(),
  successCount: faker.datatype.number(),
});

const planList = () => ({
  id: faker.datatype.uuid(),
  title: faker.name.title(),
  type: faker.company.companyName(),
  duration: faker.datatype.number(),
  price: faker.datatype.number(),
  status: faker.datatype.boolean(),
});
const plan = () => ({
  id: faker.datatype.uuid(),
  title: faker.name.title(),
  type: faker.company.companyName(),
  duration: faker.datatype.number(),
  price: faker.datatype.number(),
  url: faker.internet.url(),
  description: faker.lorem.paragraph(),
  status: faker.datatype.boolean(),
});

const userList = () => ({
  id: faker.datatype.uuid(),
  name: faker.name.firstName(),
  emailAddress: faker.internet.exampleEmail(),
  phone: faker.phone.phoneNumber(),
  subscribed: faker.datatype.boolean(),
  addedOn: faker.datatype.datetime(),
  updatedOn: faker.datatype.datetime(),
  lastLoginDate: faker.datatype.datetime(),
  status: faker.datatype.boolean(),
});
const user = () => ({
  id: faker.datatype.uuid(),
  name: faker.name.firstName(),
  emailAddress: faker.internet.exampleEmail(),
  phone: faker.phone.phoneNumber(),
  genre: faker.datatype.array(),
  password: faker.internet.password(),
  status: faker.datatype.boolean(),
});

const roleList = () => ({
  id: faker.datatype.uuid(),
  name: faker.lorem.word(),
});

const role = () => ({
  id: faker.datatype.uuid(),
  name: faker.lorem.word(),
});

export const Songlists = [...Array(10).keys()].map(() => Songs());
export const Adminlists = [...Array(10).keys()].map(() => adminList());
export const adminItem = [...Array(1).keys()].map(() => admin());
export const NotificationLists = [...Array(10).keys()].map(() =>
  NotificationList()
);
export const planlists = [...Array(10).keys()].map(() => planList());
export const planItem = [...Array(1).keys()].map(() => plan());
export const userLists = [...Array(10).keys()].map(() => userList());
export const userItem = [...Array(1).keys()].map(() => user());
export const roleLists = [...Array(10).keys()].map(() => roleList());
export const roleItem = [...Array(10).keys()].map(() => role());
export const Genrelists = [...Array(10).keys()].map(() => Genres());
