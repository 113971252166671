<template>
  <modal
    id="Addgenre"
    ref="Addgenre"
    v-if="AddGenreModal"
    @close="HideAddModal"
  >
    <template slot="header">
      <h4 class="modal-title">Create Genre</h4>
      <md-button
        class="md-simple md-danger md-just-icon md-round modal-default-button"
        @click="HideAddModal"
      >
        <md-icon>clear</md-icon>
      </md-button>
    </template>

    <template slot="body">
      <ValidationObserver ref="observer">
        <form @submit.prevent="Add" ref="AddgenreForm" id="Addgenre-form">
          <ValidationProvider
            name="Genre"
            ref="genre"
            :rules="{
              required: true,
              regex: /^[a-zA-Z '.-]*$/,
            }"
            v-slot="{ errors, passed, failed }"
          >
            <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
              <md-icon>article</md-icon>
              <label>Genre </label>
              <md-input
                v-model="form.genre"
                data-vv-name="genre"
                type="text"
                name="Genre"
                required
              >
              </md-input>
              <slide-y-down-transition>
                <md-icon class="error" v-show="failed">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="passed">done</md-icon>
              </slide-y-down-transition>
            </md-field>
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>

          <ValidationProvider
            name="Status"
            ref="Status"
            :rules="{
              required: true,
            }"
            v-slot="{ errors, passed, failed }"
          >
            <div :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
              <md-switch v-model="form.status" class="md-success"
                >Status</md-switch
              >
            </div>
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </form>
      </ValidationObserver>
    </template>

    <template slot="footer">
      <div class="md-layout-item md-size-100 text-right">
        <clip-loader
          :loading="loading"
          :color="color"
          size="20px"
          class="mt-4 mr-5 pull-right"
        ></clip-loader>
        <md-button
          @click="HideAddModal"
          class="md-raised md-danger md-sm mt-4 mr-5 pull-left"
        >
          Close
        </md-button>

        <md-button
          type="submit"
          @click="$refs.AddgenreForm.requestSubmit()"
          class="md-raised md-sm md-success mt-4"
        >
          Create
        </md-button>
      </div>
    </template>
  </modal>
</template>

<script>
import { Modal } from "@/componentItems";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import { SlideYDownTransition } from "vue2-transitions";
import {
  ValidationObserver,
  ValidationProvider,
} from "vee-validate/dist/vee-validate.full";

export default {
  components: {
    ClipLoader,
    Modal,
    ValidationProvider,
    ValidationObserver,
    SlideYDownTransition,
  },
  props: {
    getGenres: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      AddGenreModal: false,
      loading: false,
      color: "#3AB982",
      size: "40px",

      form: {
        genre: "",
        status: null,
      },
    };
  },
  methods: {
    ShowAddModal() {
      this.AddGenreModal = true;
    },
    HideAddModal() {
      var self = this;
      self.AddGenreModal = false;

      Object.keys(this.form).forEach((key) => {
        self.form[key] = "";
      });
    },
    Add(e) {
      e.preventDefault();
      this.$refs.observer.validate().then((success) => {
        if (!success) {
          return;
        }
        this.loading = true;
        this.$store
          .dispatch("addLog", this.form)
          .then((res) => {
            if (!res.data.message.includes("successfully")) {
              throw res;
            }
            this.$notify({
              message: `${res.data.message}`,
              icon: "add_alert",
              horizontalAlign: "center",
              verticalAlign: "top",
              type: "success",
            });
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.loading = false;
            this.HideAddModal();
            this.getGenres();
          });

        this.$nextTick(() => {
          this.$refs.observer.reset();
        });
      });
    },
  },
};
</script>

<style>
</style>