<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-55 mx-auto md-small-size-65">
      <ValidationObserver ref="observer">
        <form @submit.prevent="addSongPage" ref="AddSongForm" id="AddSong-form">
          <md-card md-with-hover>
            <md-card-header class="md-card-header-icon md-card-header-danger">
              <div class="card-icon">
                <md-icon>library_music</md-icon>
              </div>
              <h4 class="title">Add Song</h4>
            </md-card-header>
            <md-card-content>
              <div class="md-layout">
                <div class="md-layout-item md-size-30 md-small-size-100">
                  <div class="file-input img-size">
                    <h5 class="text-danger" style="font-weight: bold">
                      {{ imageError }}
                    </h5>

                    <template v-if="!imageCircle">
                      <div class="image-container">
                        <img :src="avatarImg" title="" />
                      </div>
                    </template>
                    <div class="image-container" v-else>
                      <img :src="imageCircle" />
                    </div>
                    <div class="button-container">
                      <md-button
                        class="md-danger md-raised md-sm"
                        @click="removeImage('circle')"
                        v-if="imageCircle"
                        ><md-icon>clear</md-icon>Remove</md-button
                      >
                      <md-button
                        class="md-sm md-success md-raised md-fileinput"
                      >
                        <template v-if="!imageCircle">Add Photo</template>
                        <template v-else>Change</template>

                        <input
                          type="file"
                          name="circle"
                          @change="onFileChange"
                        />
                      </md-button>
                    </div>
                  </div>
                </div>

                <div class="md-layout-item md-size-70 md-small-size-100">
                  <ValidationProvider
                    name="Title"
                    ref="title"
                    :rules="{
                      required: true,
                      regex: /^[a-zA-Z0-9 '.-]*$/,
                    }"
                    v-slot="{ errors, passed, failed }"
                  >
                    <md-field
                      :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                    >
                      <label>Title</label>
                      <md-input
                        v-model="form.title"
                        type="text"
                        required
                      ></md-input>
                      <slide-y-down-transition>
                        <md-icon class="error" v-show="failed">close</md-icon>
                      </slide-y-down-transition>
                      <slide-y-down-transition>
                        <md-icon class="success" v-show="passed">done</md-icon>
                      </slide-y-down-transition>
                    </md-field>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>

                  <ValidationProvider
                    name="Author"
                    ref="author"
                    :rules="{
                      required: true,
                      regex: /^[a-zA-Z0-9 '.-]*$/,
                    }"
                    v-slot="{ errors, passed, failed }"
                  >
                    <md-field
                      :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                    >
                      <label>Author</label>
                      <md-input
                        v-model="form.author"
                        type="text"
                        required
                      ></md-input>
                      <slide-y-down-transition>
                        <md-icon class="error" v-show="failed">close</md-icon>
                      </slide-y-down-transition>
                      <slide-y-down-transition>
                        <md-icon class="success" v-show="passed">done</md-icon>
                      </slide-y-down-transition>
                    </md-field>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>

                  <ValidationProvider
                    name="Description"
                    ref="description"
                    :rules="{
                      min: 0,
                      max: 500,
                    }"
                    v-slot="{ errors, passed, failed }"
                  >
                    <md-field
                      :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                    >
                      <label>Description </label>
                      <md-textarea
                        v-model="form.description"
                        maxlength="500"
                        type="text"
                      ></md-textarea>
                      <slide-y-down-transition>
                        <md-icon class="error" v-show="failed">close</md-icon>
                      </slide-y-down-transition>
                      <slide-y-down-transition>
                        <md-icon class="success" v-show="passed">done</md-icon>
                      </slide-y-down-transition>
                    </md-field>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </div>

                <div class="md-layout-item md-small-size-100 md-size-100">
                  <br />
                  <ValidationProvider
                    name="Lyrics"
                    ref="Lyrics"
                    v-slot="{ errors, passed, failed }"
                  >
                    <div
                      :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                    >
                      <md-icon>subject</md-icon>
                      <label class="artist-label">Lyrics </label>
                      <ckeditor
                        :editor="editor"
                        v-model="form.lyrics"
                      ></ckeditor>
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </div>
                <br />
                <div class="md-layout-item md-small-size-100 md-size-100">
                  <br />
                  <!-- <small class="text-danger" style="font-weight:bold">{{ albumError }}</small> -->
                  <label class="artist-label">Album </label>
                  <multiselect
                    v-model="form.albumId"
                    name="artists"
                    :options="albumArray"
                    placeholder="Select Album"
                    label="title"
                    track-by="albumId"
                    :limit="1"
                    :searchable="true"
                    :show-labels="true"
                    :close-on-select="true"
                    :clear-on-select="true"
                    deselect-label="Remove"
                    select-label="Choose Album"
                    open-direction="bottom"
                  >
                  </multiselect>
                </div>

                <div class="md-layout-item md-small-size-100 md-size-50">
                  <br />
                  <ValidationProvider
                    name="Tone Code"
                    ref="toneCode"
                    :rules="{
                      max: 10,
                      regex: /^[0-9 '.-]*$/,
                    }"
                    v-slot="{ errors, passed, failed }"
                  >
                    <md-field
                      :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                    >
                      <md-icon>code</md-icon>
                      <label>Tone Code</label>
                      <md-input
                        v-model="form.toneCode"
                        data-vv-name="toneCode"
                        type="text"
                        name="tone Code"
                      >
                      </md-input>
                      <slide-y-down-transition>
                        <md-icon class="error" v-show="failed">close</md-icon>
                      </slide-y-down-transition>
                      <slide-y-down-transition>
                        <md-icon class="success" v-show="passed">done</md-icon>
                      </slide-y-down-transition>
                    </md-field>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </div>

                <div class="md-layout-item md-small-size-100 md-size-50">
                  <br />

                  <div class="ml-5">
                    <span><b>Song Type:</b></span>
                    <div>
                      <!-- <md-radio v-model="songType" :value="false"
                        >Audio</md-radio
                      >
                      <md-radio v-model="songType" :value="true"
                        >Video</md-radio
                      > -->

                      <md-checkbox v-model="songAudio" @change="songAudioChange"
                        >Audio</md-checkbox
                      >
                      <md-checkbox v-model="songVideo" @change="songVideoChange"
                        >Video</md-checkbox
                      >
                    </div>
                  </div>
                </div>

                <div
                  class="md-layout-item md-small-size-100 md-size-100"
                  v-if="songAudio == true"
                >
                  <ValidationProvider
                    name="Audio"
                    ref="audio"
                    :rules="{
                      required: true,
                    }"
                    v-slot="{ errors, passed, failed }"
                  >
                    <md-field
                      :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                    >
                      <label>Upload Audio file</label>
                      <md-file
                        type="file"
                        id="audiofile"
                        ref="audiofile"
                        name="audio"
                        required
                        placeholder="Upload Audio file"
                        accept="audio/*"
                        v-model="audioname"
                        @change="onAudioChange"
                      />
                      <md-button class="md-just-icon md-simple">
                        <md-icon>info</md-icon>
                        <md-tooltip class="md-sm" md-direction="top"
                          >Valid extension: mp3.Valid size:less than (<b
                            >30 M.B</b
                          >)</md-tooltip
                        >
                      </md-button>
                      <slide-y-down-transition>
                        <md-icon class="error" v-show="failed">close</md-icon>
                      </slide-y-down-transition>
                      <slide-y-down-transition>
                        <md-icon class="success" v-show="passed">done</md-icon>
                      </slide-y-down-transition>
                    </md-field>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </div>

                <div
                  class="md-layout-item md-small-size-100 md-size-100"
                  v-if="songVideo == true"
                >
                  <ValidationProvider
                    name="Video"
                    ref="video"
                    :rules="{
                      required: true,
                    }"
                    v-slot="{ errors, passed, failed }"
                  >
                    <md-field
                      :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                    >
                      <label>Upload Video file</label>
                      <md-file
                        type="file"
                        id="videofile"
                        ref="videofile"
                        name="video"
                        required
                        placeholder="Upload Video file"
                        accept="video/*"
                        v-model="videoname"
                        @change="onVideoChange"
                      />
                      <md-button class="md-just-icon md-simple">
                        <md-icon>info</md-icon>
                        <md-tooltip class="md-sm" md-direction="top"
                          >Valid extension: mp4.Valid size:less than (<b
                            >30 M.B</b
                          >)</md-tooltip
                        >
                      </md-button>
                      <slide-y-down-transition>
                        <md-icon class="error" v-show="failed">close</md-icon>
                      </slide-y-down-transition>
                      <slide-y-down-transition>
                        <md-icon class="success" v-show="passed">done</md-icon>
                      </slide-y-down-transition>
                    </md-field>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </div>

                <div class="md-layout-item md-small-size-100 md-size-100">
                  <br />
                  <label class="artist-label">Playlists</label>
                  <multiselect
                    v-model="newPlaylist"
                    name="newPlaylist"
                    :options="playlistArray"
                    multiple
                    placeholder="Select Playlists"
                    label="title"
                    track-by="playlistId"
                    :limit="5"
                    :searchable="true"
                    :show-labels="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    deselect-label="Remove"
                    select-label="Select"
                    open-direction="bottom"
                  >
                    <md-icon>people_alt</md-icon>
                    <label>Select Playlists</label>
                  </multiselect>
                  <!-- <small class="text-danger" style="font-weight: bold">{{
                    featuredArtistError
                  }}</small> -->
                </div>

                <div class="md-layout-item md-small-size-100 md-size-100">
                  <br />
                  <label class="artist-label">Main Artist </label>
                  <multiselect
                    v-model="artists"
                    name="artists"
                    :options="artistArray"
                    placeholder="Select Main artist"
                    label="name"
                    track-by="artistId"
                    :limit="1"
                    :searchable="true"
                    :show-labels="true"
                    :close-on-select="true"
                    :clear-on-select="true"
                    deselect-label="Remove"
                    select-label="Choose Artist"
                    open-direction="bottom"
                  >
                    <md-icon>person</md-icon>
                    <label>Artists</label>
                  </multiselect>
                  <h6 class="text-danger" style="font-weight: bold">
                    {{ mainArtistError }}
                  </h6>
                </div>

                <div class="md-layout-item md-small-size-100 md-size-100">
                  <br />
                  <label class="artist-label">Featured Artist </label>
                  <multiselect
                    v-model="featuredArtistsIds"
                    name="featuredArtistsIds"
                    :options="artistArray"
                    multiple
                    placeholder="Select Featured artist(s)"
                    label="name"
                    track-by="artistId"
                    :limit="5"
                    :searchable="true"
                    :show-labels="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    deselect-label="Remove"
                    select-label="Select"
                    open-direction="bottom"
                  >
                    <md-icon>people_alt</md-icon>
                    <label>Featured Artists</label>
                  </multiselect>
                  <small class="text-danger" style="font-weight: bold">{{
                    featuredArtistError
                  }}</small>
                </div>
              </div>
            </md-card-content>

            <md-card-actions md-alignment="space-between">
              <md-button
                @click="HideAddData"
                class="md-sm md-dense md-raised md-danger"
                >Cancel</md-button
              >

              <clip-loader
                :loading="loading"
                :color="color"
                size="32px"
              ></clip-loader>

              <md-button
                class="md-sm md-dense md-raised md-success"
                type="submit"
                >Submit</md-button
              >
            </md-card-actions>
          </md-card>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import { SlideYDownTransition } from "vue2-transitions";
import {
  ValidationObserver,
  ValidationProvider,
} from "vee-validate/dist/vee-validate.full";
import * as notifications from "../../../utils/notifications";
import _ from "lodash";

export default {
  name: "Add-song",
  components: {
    ClipLoader,
    ValidationProvider,
    ValidationObserver,
    SlideYDownTransition,
  },
  props: {
    profileCard: {
      type: String,
      default: "../../img/no-image-new.png",
    },
    avatarImg: {
      type: String,
      default: "../../img/no-image.png",
    },
  },

  created() {
    this.getAllArtist();
    this.getAllAlbums();
    this.getAllPlaylists();
  },
  data() {
    return {
      form: {
        coverArt: "",
        title: "",
        author: "",
        description: "",
        type: "",
        lyrics: "",
        toneCode: "",
        video: "",
        albumId: [],
      },
      artists: [],
      newPlaylist: [],
      playlistArray: [],
      selectedPlaylistIds: [],
      featuredArtistsIds: [],
      editor: ClassicEditor,
      artistArray: [],
      albumArray: [],
      selectedArtists: [],
      selectedAlbum: [],
      selectedFeaturedArtistIds: [],

      loading: false,
      color: "#3AB982",
      size: "40px",
      imageCircle: "",
      imageCircleFile: File,
      imageProfile: "",
      avatarFileName: "",
      profileFileName: "",
      audioname: "",
      videoname: "",
      audio: "",
      audioFile: "",
      video: "",
      videoFile: "",
      songId: "",
      songType: false,

      songVideo: false,
      songAudio: true,

      imageError: "",
      albumError: "",
      mainArtistError: "",
      featuredArtistError: "",
    };
  },

  methods: {
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      if (e.target.name) {
        console.log(files[0]);
        this.imageCircleFile = files[0];
        this.createImage(files[0], "circle");
        this.avatarFileName = files[0].name;
      } else {
        this.createImage(files[0]);
        this.profileFileName = files[0].name;
      }
    },
    createImage(file, type) {
      let reader = new FileReader();
      let vm = this;

      reader.onload = (e) => {
        if (type === "circle") {
          vm.imageCircle = e.target.result;
        } else {
          vm.imageProfile = e.target.result;
        }
      };
      reader.readAsDataURL(file);
    },
    removeImage: function (type) {
      if (type === "circle") {
        this.imageCircle = "";
        this.imageCircleFile = "";
      } else {
        this.imageProfile = "";
      }
    },
    onResponsiveInverted() {
      if (window.innerWidth < 768) {
        this.responsive = true;
      } else {
        this.responsive = false;
      }
    },

    songAudioChange() {
      if (this.songAudio == false) {
        this.audioname = "";
      }
    },

    songVideoChange() {
      if (this.songVideo == false) {
        this.videoname = "";
      }
    },

    onAudioChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      this.audioFile = files[0];

      this.createAudio(files[0]);
      this.audioname = files[0].name;
    },
    onVideoChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      this.videoFile = files[0];
      this.createVideo(files[0]);
      this.videoname = files[0].name;
    },

    createAudio(file) {
      let reader = new FileReader();
      let vm = this;
      reader.onload = (e) => {
        vm.audio = e.target.result;
      };
      reader.readAsDataURL(file);
    },

    createVideo(file) {
      let reader = new FileReader();
      let vm = this;
      reader.onload = (e) => {
        vm.video = e.target.result;
      };
      reader.readAsDataURL(file);
    },

    removeAudio: function () {
      this.form.audio = "";
      this.form.audioFile = "";
    },

    HideAddData() {
      var self = this;
      Object.keys(this.form).forEach((key) => {
        self.form[key] = "";
      });

      this.$router.push("/song");
    },

    getAllArtist() {
      const params = {
        page: 0,
        pageSize: 10,
      };
      this.$store
        .dispatch("getAllArtists", params)
        .then((res) => {
          this.artistArray = res;
          /////console.log(this.artistArray);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getAllAlbums() {
      this.loading = true;
      const params = {
        page: 0,
        pageSize: 10,
      };
      this.$store
        .dispatch("getAllAlbums", params)
        .then((res) => {
          this.albumArray = res;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getAllPlaylists() {
      const params = {
        page: 0,
        pageSize: 10,
      };
      this.$store
        .dispatch("getAllPlaylists", params)
        .then((res) => {
          this.playlistArray = res;
          // console.log(this.playlistArray);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    addSongPage(e) {
      e.preventDefault();
      console.log("passed here");
      console.log("mike");

      this.$refs.observer.validate().then((success) => {
        if (!success) {
          return;
        }

        if (this.avatarFileName == "") {
          console.log("empty avatar file name");
          this.imageError = "Song Avatar is required!";
        } else if (this.artists.length < 1) {
          console.log("empty artist name");
          this.mainArtistError = "Song is required!";
        } else {
          //this.loading = true;

          let loader = this.$loading.show({
            // Optional parameters
            container: this.fullPage ? null : this.$refs.formContainer,
            canCancel: false,
            onCancel: this.onCancel,
            color: "#eb4034",
          });
          // let filesArray = [];

          let filesAudio = [];
          let filesVideo = [];
          let typevideo = "";
          let typeaudio = "";

          filesAudio.push(this.imageCircleFile);
          filesVideo.push(this.imageCircleFile);

          if (this.audioname !== "" && this.songAudio == true) {
            typeaudio = "audio";
            filesAudio.push(this.audioFile);
          }

          if (this.videoname != "" || this.songVideo == true) {
            typevideo = "video";
            filesVideo.push(this.videoFile);
          } else if (this.videoname == "" || this.songVideo == false) {
            typevideo = "";
          } else if (this.songAudio == true || this.songVideo == true) {
            typeaudio = "audio";
            filesAudio.push(this.audioFile);
            typevideo = "video";
            filesVideo.push(this.videoFile);
          } else {
            null;
          }

          // let typeSongString;

          // filesArray.push(this.imageCircleFile);

          // if (this.songType) {
          //   typeSongString = "Video";
          //   filesArray.push(this.videoFile);
          // } else {
          //   typeSongString = "Audio";
          //   filesArray.push(this.audioFile);
          // }
          this.selectedArtists.push(Object.values(this.artists)[0]);

          Object.values(this.featuredArtistsIds).forEach((item) => {
            this.selectedFeaturedArtistIds.push(item.artistId);
          });

          // console.log(filesArray);
          // console.log(this.selectedFeaturedArtistIds);

          let Items = [
            {
              type: typeaudio,
              files: filesAudio !== null ? filesAudio : null,
            },
            {
              type: typevideo,
              files: filesVideo !== null ? filesVideo : null,
            },
          ];

          for (var i = 0, j = Items.length; i < j; i++) {
            const formData = {
              title: this.form.title,
              author: this.form.author,
              toneCode: this.form.toneCode,
              description: this.form.description,
              lyrics: this.form.lyrics,

              files: Items[i].files,
              type: Items[i].type,

              // type: typeSongString,
              // files: filesArray,
              artists: this.selectedArtists,
              albumId: Object.values(this.form.albumId)[0],
              featuredArtistsIds: this.selectedFeaturedArtistIds,
              category: "Song",
            };
            if (Items[i].type) {
              console.log(formData);

              this.$store
                .dispatch("addSongPage", formData)
                .then((res) => {
                  if (res) {
                    let songsId = [];
                    songsId.push(res.data.data.songId);

                    let SelectedPlaylist = this.newPlaylist.map(
                      (item) => item.playlistId
                    );

                    var formData = _.chunk(SelectedPlaylist, 1);
                    for (var i = 0, j = formData.length; i < j; i++) {
                      const Items = formData[i].map((t) => t);

                      const params = {
                        playlistId: Items[0],
                        songsId: songsId,
                      };

                      this.$store
                        .dispatch("addSongToPlaylist", params)
                        .then(() => {})
                        .finally(() => {})
                        .catch((err) => {
                          notifications.Error(err);
                        });
                    }
                  }

                  this.loading = false;
                  loader.hide();
                  notifications.Success({
                    title: "Song created successfully",
                    buttonsStyling: false,
                    confirmButtonClass: "md-button md-success",
                  });
                })
                .finally(() => {
                  this.loading = false;
                  loader.hide();
                  this.HideAddData();
                })
                .catch((err) => {
                  notifications.Error(err);
                });
            }
          }

          this.$nextTick(() => {
            this.$refs.observer.reset();
          });
        }
      });
    },
  },
  mounted() {
    this.onResponsiveInverted();
    window.addEventListener("resize", this.onResponsiveInverted);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResponsiveInverted);
  },
};
</script>
<style>
.ck-editor__editable_inline {
  max-height: 200px;
}
</style>
<style lang="scss" scoped>
.artist-label {
  font-size: 11px;
  font-weight: 400;
  color: #aaaaaa !important;
  line-height: 1.4;
  top: 26px;
}
.text-right {
  display: flex;
}
.Image-Background {
  padding: 15px 20px;
  line-height: 1.75em;
  position: relative;
  height: 26%;
}
.Image-Container {
  padding: 15px 20px;
  line-height: 1.75em;
  position: relative;
  height: 26%;
}
.img-size {
  width: 95%;
  height: 78%;
}
</style>