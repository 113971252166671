<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-danger">
          <div class="card-icon">
            <md-icon>filter_list</md-icon>
          </div>
        </md-card-header>
        <md-card-content>
          <clip-loader
            :loading="loading"
            :color="color"
            size="25px"
          ></clip-loader>
          <h4 class="card-title">
            Role Name: {{ " " }}{{ this.$route.params.name }}
          </h4>
          <div class="md-layout">
            <div class="md-layout-item md-small-size-50 md-size-100">
              <h4 class="card-title">
                <label>Capabilities</label>
              </h4>
            </div>

            <div class="md-layout-item md-small-size-50 md-size-25">
              <label class="md-layout-item md-size-15 md-form-label">
                Page
              </label>
              <div class="md-layout-item">
                <md-checkbox v-model="update_page">Update Page</md-checkbox>
              </div>
              <div class="md-layout-item">
                <md-checkbox v-model="list_page">List Page</md-checkbox>
              </div>
            </div>
            <div class="md-layout-item md-small-size-50 md-size-25">
              <label class="md-layout-item md-size-15 md-form-label">
                FAQ
              </label>
              <div class="md-layout-item">
                <md-checkbox v-model="update_faq">Update Faq</md-checkbox>
              </div>
              <div class="md-layout-item">
                <md-checkbox v-model="add_faq">Add Faq</md-checkbox>
              </div>
              <div class="md-layout-item">
                <md-checkbox v-model="delete_faq">Delete Faq</md-checkbox>
              </div>
              <div class="md-layout-item">
                <md-checkbox v-model="list_faq">List Faq</md-checkbox>
              </div>
            </div>

            <div class="md-layout-item md-small-size-50 md-size-25">
              <label class="md-layout-item md-size-15 md-form-label">
                System Emails
              </label>
              <div class="md-layout-item">
                <md-checkbox v-model="update_systememails"
                  >Update System Emails</md-checkbox
                >
              </div>
              <div class="md-layout-item">
                <md-checkbox v-model="list_systememails"
                  >List System Emails</md-checkbox
                >
              </div>
            </div>
            <div class="md-layout-item md-small-size-50 md-size-25">
              <label class="md-layout-item md-size-15 md-form-label">
                Song
              </label>
              <div class="md-layout-item">
                <md-checkbox v-model="update_song">Update Song</md-checkbox>
              </div>
              <div class="md-layout-item">
                <md-checkbox v-model="list_song">List Song</md-checkbox>
              </div>
              <div class="md-layout-item">
                <md-checkbox v-model="delete_song">Delete Song</md-checkbox>
              </div>
              <div class="md-layout-item">
                <md-checkbox v-model="list_song">List Song</md-checkbox>
              </div>
            </div>

            <div class="md-layout-item md-small-size-50 md-size-25">
              <label class="md-layout-item md-size-15 md-form-label">
                Setting
              </label>
              <div class="md-layout-item">
                <md-checkbox v-model="list_setting">List Setting</md-checkbox>
              </div>
              <div class="md-layout-item">
                <md-checkbox v-model="update_setting"
                  >Update Setting</md-checkbox
                >
              </div>
            </div>

            <div class="md-layout-item md-small-size-50 md-size-25">
              <label class="md-layout-item md-size-15 md-form-label">
                Capabilities
              </label>
              <div class="md-layout-item">
                <md-checkbox v-model="delete_Capabilities"
                  >Delete Capabilities</md-checkbox
                >
              </div>
              <div class="md-layout-item">
                <md-checkbox v-model="add_Capabilities"
                  >Add Capabilities</md-checkbox
                >
              </div>
              <div class="md-layout-item">
                <md-checkbox v-model="list_Capabilities"
                  >List Capabilities</md-checkbox
                >
              </div>
            </div>

            <div class="md-layout-item md-small-size-50 md-size-25">
              <label class="md-layout-item md-size-15 md-form-label">
                Playlist
              </label>
              <div class="md-layout-item">
                <md-checkbox v-model="delete_playlist"
                  >Delete Playlist</md-checkbox
                >
              </div>
              <div class="md-layout-item">
                <md-checkbox v-model="add_playlist">Add Playlist</md-checkbox>
              </div>
              <div class="md-layout-item">
                <md-checkbox v-model="update_playlist"
                  >Update Playlist</md-checkbox
                >
              </div>
              <div class="md-layout-item">
                <md-checkbox v-model="list_playlist">List Playlist</md-checkbox>
              </div>
            </div>
            <div class="md-layout-item md-small-size-50 md-size-25">
              <label class="md-layout-item md-size-15 md-form-label">
                Artist
              </label>
              <div class="md-layout-item">
                <md-checkbox v-model="add_artist">Add Artist</md-checkbox>
              </div>
              <div class="md-layout-item">
                <md-checkbox v-model="list_artist">List Artist</md-checkbox>
              </div>
              <div class="md-layout-item">
                <md-checkbox v-model="delete_artist">Delete Artist</md-checkbox>
              </div>
              <div class="md-layout-item">
                <md-checkbox v-model="update_artist">Update Artist</md-checkbox>
              </div>
            </div>
            <div class="md-layout-item md-small-size-50 md-size-25">
              <label class="md-layout-item md-size-15 md-form-label">
                Genre
              </label>
              <div class="md-layout-item">
                <md-checkbox v-model="add_genre">Add Genre</md-checkbox>
              </div>
              <div class="md-layout-item">
                <md-checkbox v-model="list_genre">List Genre</md-checkbox>
              </div>
              <div class="md-layout-item">
                <md-checkbox v-model="delete_genre">Delete Genre</md-checkbox>
              </div>
              <div class="md-layout-item">
                <md-checkbox v-model="update_genre">Update Genre</md-checkbox>
              </div>
            </div>
            <div class="md-layout-item md-small-size-50 md-size-25">
              <label class="md-layout-item md-size-15 md-form-label">
                Roles
              </label>
              <div class="md-layout-item">
                <md-checkbox v-model="list_roles">List Roles</md-checkbox>
              </div>
              <div class="md-layout-item">
                <md-checkbox v-model="update_roles">Update Roles</md-checkbox>
              </div>
            </div>
            <div class="md-layout-item md-small-size-50 md-size-25">
              <label class="md-layout-item md-size-15 md-form-label">
                Admin
              </label>
              <div class="md-layout-item">
                <md-checkbox v-model="update_admin">Update Admin</md-checkbox>
              </div>
              <div class="md-layout-item">
                <md-checkbox v-model="add_admin">Add Admin</md-checkbox>
              </div>
              <div class="md-layout-item">
                <md-checkbox v-model="delete_admin">Delete Admin</md-checkbox>
              </div>
              <div class="md-layout-item">
                <md-checkbox v-model="list_admin">List Admin</md-checkbox>
              </div>
            </div>
            <div class="md-layout-item md-small-size-50 md-size-25">
              <label class="md-layout-item md-size-15 md-form-label">
                User
              </label>
              <div class="md-layout-item">
                <md-checkbox v-model="add_user">Add User</md-checkbox>
              </div>
              <div class="md-layout-item">
                <md-checkbox v-model="delete_user">Delete User</md-checkbox>
              </div>
              <div class="md-layout-item">
                <md-checkbox v-model="list_user">List User</md-checkbox>
              </div>
              <div class="md-layout-item">
                <md-checkbox v-model="update_user">Update User</md-checkbox>
              </div>
            </div>

            <br /><br />

            <br />

            <div class="md-layout-item md-size-100 text-right">
              <md-button
                class="md-raised md-default mt-4"
                @click.native="goBack()"
                >Cancel</md-button
              >
              <md-button class="md-raised md-success mt-4">Submit</md-button>
            </div>
          </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

export default {
  components: {
    ClipLoader,
  },
  data() {
    return {
      currentSort: "name",
      currentSortOrder: "asc",
      searchQuery: "",
      tableData: "",
      searchedData: [],
      fuseSearch: null,
      emptylist: false,
      currentRole: "",
      editcurrentRole: false,
      cardUserImage: "",
      RoleName: "",
      topRole: "",
      addedOn: "",
      modifiedOn: "",
      loading: false,
      color: "#3AB982",
      name: "",
      //   roles
      update_page: false,
      list_page: false,
      update_faq: false,
      add_faq: false,
      list_faq: false,
      delete_faq: false,
      list_setting: false,
      update_setting: false,
      add_genre: false,
      list_genre: false,
      delete_genre: false,
      update_genre: false,
      update_systememails: false,
      list_systememails: false,
      delete_playlist: false,
      add_playlist: false,
      update_playlist: false,
      list_playlist: false,
      update_admin: false,
      add_admin: false,
      delete_admin: false,
      list_admin: false,
      add_song: false,
      update_song: false,
      delete_song: false,
      list_song: false,
      add_artist: false,
      list_artist: false,
      delete_artist: false,
      update_artist: false,
      add_user: false,
      delete_user: false,
      list_user: false,
      update_user: false,
      list_roles: false,
      update_roles: false,
      delete_Capabilities: false,
      add_Capabilities: false,
      list_Capabilities: false,
    };
  },
  methods: {
    getRole() {
      this.loading = true;
      this.$store
        .dispatch("getRole")
        .then((res) => {
          this.loading = false;
          console.log(res);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    goBack() {
      this.$router.back();
    },
  },

  created() {
    this.getRole();
  },
};
</script>
<style lang="css">
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
</style>
<style lang="css" scoped>
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
.spacing {
  margin: "10px";
  padding: "10px";
}
.item-status {
  background-color: #4caf50;
  padding: 5px;
  color: white;
  border-radius: 10%;
}
.img-width {
  width: 50px;
}

.md-table[table-header-color="green"] .md-table-head .md-table-head-label {
  font-weight: bold;
}
</style>
