<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-AudioContainer">
          <div class="modal-Audiobody text-center">
            <slot name="header"></slot>
            <slot name="body">
              <audio-player
                :loading="loading"
                :close="close"
                :file="file"
              ></audio-player>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import AudioPlayer from "./Audio.vue";
export default {
  props: {
    file: {
      type: String,
      default: null,
    },
    close: {
      type: Function,
      default: () => () => {},
    },
    loading: {
      default: null,
    },
  },
  components: {
    AudioPlayer,
  },
  methods: {
    closeModal: function () {
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
.modal-Audiobody {
  padding: 20px;
}
.modal-AudioContainer {
  max-width: 500px;
  margin: 0px auto;
  position: relative;
  background-color: transparent;
  transition: all 0.3s ease;
}
.modal-Audioheader {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 24px 0;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;

  .md-button .md-button-content i {
    font-size: 16px !important;
    opacity: 0.5;

    &:hover,
    &:focus {
      opacity: 1;
    }
  }
}
</style>

<style lang="scss" >
/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
