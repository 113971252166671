import api from "../../axios-auth";

const state = {
  loading: true,
  Categories: [],
  Tags: [],
  error: {},
};

const mutations = {
  request(state) {
    state.loading = true;
  },
  Categorieslist(state, ListItems) {
    state.loading = false;
    state.Categories = ListItems.items;
  },
  TagsList(state, ListItems) {
    state.loading = false;
    state.Tags = ListItems.items;
  },

  error(state, error) {
    state.loading = false;
    state.error = error;
  },
};

const actions = {
  getAllCategories({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .get(`/tags/categories?page=0&pageSize=10`)
        .then((res) => {
          commit("request");
          api
            .get(`/tags/categories?page=0&pageSize=${res.data.paging.count}`)
            .then((res) => {
              commit("Categorieslist", {
                items: res.data,
              });
              resolve(res);
            });
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },

  getCategories({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit("request");
      api
        .get(`/tags/categories?page=${params.page}&pageSize=${params.pageSize}`)
        .then((res) => {
          commit("Categorieslist", {
            items: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },
  getCategory({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit("request");
      api
        .get(`/tags/categories/${id}`)
        .then((res) => {
          commit("Categorieslist", {
            items: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },

  addCategory({ commit }, formData) {
    return new Promise((resolve, reject) => {
      commit("request");
      api
        .post(`/tags/categories`, formData)
        .then((res) => {
          commit("Categorieslist", {
            items: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },

  editCategory({ commit }, formData) {
    return new Promise((resolve, reject) => {
      commit("request");
      api
        .put(`/tags/categories/${formData.id}/edit`, {
          title: formData.title,
        })
        .then((res) => {
          commit("Categorieslist", {
            items: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },

  getTags({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit("request");
      api
        .get(`/tags/categories/${params.id}/tags`)
        .then((res) => {
          commit("TagsList", {
            items: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },

  addTag({ commit }, formData) {
    return new Promise((resolve, reject) => {
      commit("request");
      api
        .post("/tags", formData)
        .then((res) => {
          commit("TagsList", {
            items: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },

  editTag({ commit }, formData) {
    return new Promise((resolve, reject) => {
      commit("request");
      api
        .put(`/tags/${formData.id}/edit`, {
          tag: formData.tag,
          categoryId: formData.categoryId,
        })
        .then((res) => {
          commit("TagsList", {
            items: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },

  TagItem({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit("request");
      api
        .put(`/tags/tagContent`, {
          contentId: params.contentId,
          tagIds: params.tagIds,
          category: params.category,
        })
        .then((res) => {
          commit("TagsList", {
            items: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },

  activateCategories({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit("request");
      api
        .put(`/tags/categories/${params.id}/activate`, {
          status: params.status,
        })
        .then((res) => {
          commit("Categorieslist", {
            items: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },
};

const getters = {};

export default { state, mutations, actions, getters };
