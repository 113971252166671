<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-danger">
          <div class="card-icon">
            <md-icon>mic</md-icon>
          </div>
          <h4 class="title">
            Artists
            <div class="pull-right">
              <md-button
                class="md-just-icon md-success md-dense md-raised md-simple"
                @click="AddArtist()"
              >
                <md-icon>add</md-icon>
                <md-tooltip class="md-sm" md-direction="top"
                  >Add Artist</md-tooltip
                >
              </md-button>

              <md-button
                :disabled="emptylist"
                class="md-just-icon md-danger md-dense md-raised md-simple"
                @click="deleteArtist"
              >
                <md-icon>delete</md-icon>
                <md-tooltip class="md-sm" md-direction="top"
                  >Delete Artist</md-tooltip
                >
              </md-button>

              <md-button
                @click.prevent="getArtists"
                class="md-just-icon md-info md-dense md-raised md-simple"
              >
                <md-icon>refresh</md-icon>
                <md-tooltip class="md-sm" md-direction="top"
                  >Refresh List</md-tooltip
                >
              </md-button>
            </div>
          </h4>
        </md-card-header>

        <md-card-content>
          <md-table-empty-state
            v-if="emptylist"
            md-label="No data found"
            :md-description="`Empty table there's no data`"
          >
          </md-table-empty-state>
          <md-table
            v-else
            :value="queriedData"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
            @md-selected="onSelect"
            table-header-color="green"
            class="table-striped table-hover"
          >
            <md-table-toolbar class="toolbar" md-alignment="space-between">
              <div class="md-toolbar-section-start">
                <md-field style="width: 50px" class="mr-3">
                  <label for="pages">Per page</label>
                  <md-select
                    v-model="pagination.pageSize"
                    @change="handlePageSizeChange($event)"
                    name="pages"
                    style="width: 50px"
                  >
                    <md-option
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                      {{ item }}
                    </md-option>
                  </md-select>
                </md-field>
              </div>

              <div class="md-toolbar-section-end">
                <ValidationObserver ref="observer">
                  <form
                    @submit.prevent="searchItem"
                    ref="searchForm"
                    id="searchForm"
                  >
                    <ValidationProvider
                      :rules="{
                        required: true,
                      }"
                      v-slot="{ passed, failed }"
                    >
                      <md-field
                        style="width: 270px"
                        :class="[
                          { 'md-error': failed },
                          { 'md-valid': passed },
                        ]"
                      >
                        <md-input
                          type="search"
                          clearable
                          style="width: 250px"
                          placeholder="Search..."
                          v-model="searchQuery"
                        >
                        </md-input>
                        <md-button
                          :disabled="emptylist"
                          class="
                            md-just-icon md-success md-dense md-raised md-simple
                          "
                          @click.prevent="$refs.searchForm.requestSubmit()"
                        >
                          <md-icon>search</md-icon>
                          <md-tooltip class="md-sm" md-direction="top"
                            >Search</md-tooltip
                          >
                        </md-button>
                      </md-field>
                    </ValidationProvider>
                  </form>
                </ValidationObserver>
              </div>
            </md-table-toolbar>
            <md-table-toolbar
              slot="md-table-alternate-header"
              slot-scope="{ count }"
              style="margin-top: -20px"
            >
              <div class="md-toolbar-section-start">
                {{ getAlternateLabel(count) }}
              </div>
            </md-table-toolbar>

            <md-table-row
              slot="md-table-row"
              slot-scope="{ item }"
              md-selectable="multiple"
            >
              <md-table-cell md-label="Image">
                <div v-if="true" type="hidden" hidden>
                  {{
                    (image = (obj = Object.assign({}, item.profilePhoto))[
                      "url"
                    ])
                  }}
                </div>

                <img :src="image" class="imgUrl" />
              </md-table-cell>
              <md-table-cell md-label="Name">{{ item.name }}</md-table-cell>
              <!-- <md-table-cell md-label="Description">
                <span>{{ item.description }}</span>
              </md-table-cell> -->
              <md-table-cell md-label="Likes">{{
                item.likeCount
              }}</md-table-cell>
              <md-table-cell md-label="Followers">{{
                item.followerCount
              }}</md-table-cell>
              <md-table-cell md-label="Songs">{{
                item.trackCount
              }}</md-table-cell>
              <md-table-cell md-label="Country">{{
                item.countryName ? item.countryName : "null"
              }}</md-table-cell>
              <md-table-cell md-label="Top Artist">
                <md-switch
                  v-model="item.isTop"
                  @change="handleTopArtist(item)"
                  class="md-sm"
                >
                  {{ item.isTop ? "Yes" : "No" }}</md-switch
                >
              </md-table-cell>

              <md-table-cell md-label="Popular">
                <md-switch
                  v-model="item.isPopular"
                  @change="handlePopularArtist(item)"
                  class="md-sm"
                >
                  {{ item.isPopular ? "Yes" : "No" }}</md-switch
                >
              </md-table-cell>

              <md-table-cell md-label="Status">
                <md-switch
                  v-model="item.isActive"
                  @change="handleActivate(item)"
                  class="md-sm"
                >
                  {{ item.isActive == true ? "Active" : "InActive" }}</md-switch
                >
              </md-table-cell>

              <md-table-cell md-label="Actions">
                <md-button
                  class="md-just-icon md-info md-simple"
                  @click.native="editArtist(item)"
                >
                  <md-icon>dvr</md-icon>
                  <md-tooltip class="md-sm" md-direction="top"
                    >Edit Artist</md-tooltip
                  >
                </md-button>
                <md-button
                  @click.native="HandleTag(item)"
                  class="md-just-icon md-primary md-dense md-raised md-simple"
                >
                  <md-icon>local_offer</md-icon>
                  <md-tooltip class="md-sm" md-direction="top">Tag</md-tooltip>
                </md-button>

                <md-button
                  @click.native="HandleNotification(item)"
                  class="md-just-icon md-success md-simple"
                >
                  <md-icon>send</md-icon>
                  <md-tooltip class="md-sm" md-direction="top"
                    >Push Notification</md-tooltip
                  >
                </md-button>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ comma(total) }} entries
            </p>
          </div>

          <clip-loader
            :loading="loading"
            :color="color"
            size="25px"
          ></clip-loader>

          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.page"
            :per-page="pagination.pageSize"
            :total="total"
            @pagechange="handlePageChange"
          >
          </pagination>
        </md-card-actions>
        <Tag
          ref="AddTag"
          :category="category"
          :currentItem="currentItem"
          :getlist="getArtists"
        />
        <PushNotification
          ref="Notification"
          :category="'artists'"
          :contentId="currentItem"
          :Image="Image"
          :getlist="getArtists"
        />
      </md-card>
    </div>
  </div>
</template>

<script>
import Tag from "../Tags/TagContent.vue";
import PushNotification from "../Notifications/Notification.vue";
import { Pagination, commaSeparator } from "../../../componentItems";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import _ from "lodash";
import * as notifications from "../../../utils/notifications";
import {
  ValidationObserver,
  ValidationProvider,
} from "vee-validate/dist/vee-validate.full";
///import Swal from "sweetalert2";

export default {
  props: {
    profileCard: {
      type: String,
      default: "./img/faces/card-profile1-square.jpg",
    },
  },
  components: {
    Tag,
    Pagination,
    ClipLoader,
    PushNotification,
    ValidationObserver,
    ValidationProvider,
  },
  mixins: [commaSeparator],

  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      return this.tableData;
    },
    to() {
      let highBound = this.from + this.pagination.pageSize;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.pageSize * (this.pagination.page - 1);
    },
    total() {
      return this.pagination.total;
    },
  },
  created() {
    this.getArtists();
  },
  data() {
    return {
      selected: [],
      currentSort: "name",
      currentSortOrder: "asc",
      pagination: {
        pageSize: 5,
        page: 1,
        perPageOptions: [5, 10, 25, 50, 100],
        total: 0,
      },
      searchQuery: "",
      tableData: [],
      searchedData: [],
      fuseSearch: null,
      emptylist: false,
      currentArtist: "",
      editcurrentArtist: false,
      loading: false,
      color: "#3AB982",
      category: "Artist",
      currentItem: "",
      Image: "",
      Title: "",
    };
  },

  methods: {
    onSelect(items) {
      this.selected = items;
    },
    getAlternateLabel(count) {
      let plural = "";

      if (count > 1) {
        plural = "s";
      }

      return `${count} item${plural} selected`;
    },
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "desc") {
          return a[sortBy].localeCompare(b[sortBy]);
        }
        return b[sortBy].localeCompare(a[sortBy]);
      });
    },

    HandleTag(item) {
      this.$refs.AddTag.ShowTagModal(item);
      this.currentItem = item.artistId;
    },
    HandleNotification(item) {
      this.$refs.Notification.ShowNotificationModal(item);
      this.currentItem = item.artistId;
      this.Image = item.profilePhoto.url;
      this.Title = item.title;
    },

    getArtists() {
      this.loading = true;
      const params = {
        page: this.pagination.page - 1,
        pageSize: this.pagination.pageSize,
      };
      this.$store
        .dispatch("getArtists", params)
        .then((res) => {
          if (res.paging.count === 0) {
            this.emptylist = true;
          } else {
            this.tableData = res.data;
            this.pagination.total = res.paging.count;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          if (this.tableData.length > 0) {
            this.emptylist = false;
          }
          this.loading = false;
          this.searchQuery = "";
        });
    },

    searchItem() {
      if (this.searchQuery == "") {
        this.$notify({
          message: "Search field is empty",
          icon: "add_alert",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "danger",
        });
      } else {
        this.loading = true;
        const formData = {
          searchTerm: this.searchQuery,
          category: this.category,
          page: this.pagination.page - 1,
          pageSize: this.pagination.pageSize,
        };
        this.$store
          .dispatch("searchItem", formData)
          .then((res) => {
            if (res.data.length === 0) {
              this.emptylist = true;
            } else {
              this.tableData = res.data;
            }
          })
          .catch((err) => {
            this.$notify({
              message: `<small><b>${err.response.data.message}</b></small>`,
              icon: "add_alert",
              horizontalAlign: "right",
              verticalAlign: "top",
              type: "danger",
            });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    handlePageChange(value) {
      this.pagination.page = value;
      this.getArtists();
    },
    handlePageSizeChange(event) {
      this.pagination.pageSize = event.target.value;
      this.pagination.page = 1;
      this.getArtists();
    },

    AddArtist() {
      this.$router.push({
        name: "Add Artist",
      });
    },
    editArtist(item) {
      this.editcurrentArtist = true;
      this.currentArtist = item.artistId;
      this.$router.push({
        name: "Edit Artist",
        params: { id: item.artistId },
      });
    },

    handleActivate(item) {
      this.loading = true;
      const params = {
        id: item.artistId,
        status: item.isActive,
      };

      this.$store
        .dispatch("activateArtist", params)
        .then((res) => {
          if (!res.message.includes("successfully")) {
            throw res;
          }
          this.$notify({
            message: `<small><b>${res.message}</b></small>`,
            icon: "add_alert",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "success",
          });
        })
        .catch((error) => {
          this.$notify({
            message: `<small><b>${error.message}</b></small>`,
            icon: "add_alert",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "danger",
          });
        })
        .finally(() => {
          this.loading = false;
          this.getArtists();
        });
    },

    handleTopArtist(item) {
      this.loading = true;
      const params = {
        id: item.artistId,
        status: item.isTop,
      };

      this.$store
        .dispatch("TopArtist", params)
        .then((res) => {
          if (!res.message.includes("successfully")) {
            throw res;
          }
          this.$notify({
            message: `<small><b>${res.message}</b></small>`,
            icon: "add_alert",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "success",
          });
        })
        .catch((error) => {
          this.$notify({
            message: `<small><b>${error.message}</b></small>`,
            icon: "add_alert",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "danger",
          });
        })
        .finally(() => {
          this.loading = false;
          this.getArtists();
        });
    },

    handlePopularArtist(item) {
      this.loading = true;
      const params = {
        id: item.artistId,
        status: item.isPopular,
      };

      this.$store
        .dispatch("PopularArtist", params)
        .then((res) => {
          if (!res.message.includes("successfully")) {
            throw res;
          }
          this.$notify({
            message: `<small><b>${res.message}</b></small>`,
            icon: "add_alert",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "success",
          });
        })
        .catch((error) => {
          this.$notify({
            message: `<small><b>${error.message}</b></small>`,
            icon: "add_alert",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "danger",
          });
        })
        .finally(() => {
          this.loading = false;
          this.getArtists();
        });
    },

    deleteArtist() {
      if (this.selected.length > 0) {
        this.loading = true;

        //Populate the selected data
        let indexToDelete = this.selected.map((tableRow) => tableRow.artistId);

        /** Use chunk to creeate an array of elements split into groups the length of size and then send it to a loop */
        var formData = _.chunk(indexToDelete, 1);
        for (var i = 0, j = formData.length; i < j; i++) {
          //Populate the the array to get the artistId
          const Items = formData[i].map((t) => t);

          //call the key value back
          const params = Items[0];

          this.$store
            .dispatch("deleteArtist", params)
            .then((res) => {
              if (!res.message.includes("successfully")) {
                throw res;
              }
              this.$notify({
                message: `${res.message}`,
                icon: "add_alert",
                horizontalAlign: "right",
                verticalAlign: "top",
                type: "success",
              });
            })
            .catch((error) => {
              notifications.Error(error.message);
            })
            .finally(() => {
              this.loading = false;
              this.getArtists();
            });
        }
      } else {
        this.$notify({
          message: "Select an Item from the List",
          icon: "add_alert",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "danger",
        });
      }
    },
  },
};
</script>

<style lang="css">
.truncate:hover {
  position: absolute;
  max-width: none;
  z-index: 100;
  background: #f5f5f5;
  overflow: visible;
  top: -6px;
  padding: 5px 20px 5px 0;
}

.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
</style>
<style lang="css" scoped>
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
.item-status {
  background-color: #4caf50;
  padding: 5px;
  color: white;
  border-radius: 10%;
}
.img-width {
  width: 50px;
}
/* .imgUrl {
  width: 35px;
  height: 35px;
} */

.imgUrl {
  width: 48px;
  height: 48px;
  border-radius: 10%;
}

.md-table[table-header-color="green"] .md-table-head .md-table-head-label {
  font-weight: bold;
}
</style>
<style lang="css">
.md-table-head-label {
  font-weight: bold;
}
</style>


