import {
  Songlists,
  Genrelists,
  NotificationLists,
  Adminlists,
  adminItem,
  planlists,
  planItem,
  userLists,
  userItem,
  roleLists,
  roleItem,
} from "./data";
import axios from "axios";

export const s3Url = "https://media.angazamusic.co.ke/api/uploads/s3";
export const s3Callback = "https://media.angazamusic.co.ke/api/uploads/s3/";
export const searchUrl = axios.create({
  baseURL: "https://angaza-pwa.herokuapp.com/api/web/v2.0",
});

export const getSongs = async () => {
  const { data } = await new Promise((resolve, reject) => {
    try {
      setTimeout(() => {
        resolve({
          data: Songlists,
        });
      }, 200);
    } catch (error) {
      reject(error);
    }
  });
  return data;
};

export const getGenres = async () => {
  const { data } = await new Promise((resolve, reject) => {
    try {
      setTimeout(() => {
        resolve({
          data: Genrelists,
        });
      }, 200);
    } catch (error) {
      reject(error);
    }
  });
  return data;
};

export const getNotifications = async () => {
  const { data } = await new Promise((resolve, reject) => {
    try {
      setTimeout(() => {
        resolve({
          data: NotificationLists,
        });
      }, 200);
    } catch (error) {
      reject(error);
    }
  });
  return data;
};

export const getAdmins = async () => {
  const { data } = await new Promise((resolve, reject) => {
    try {
      setTimeout(() => {
        resolve({
          data: Adminlists,
        });
      }, 200);
    } catch (error) {
      reject(error);
    }
  });
  return data;
};
export const getAdmin = async () => {
  const { data } = await new Promise((resolve, reject) => {
    try {
      setTimeout(() => {
        resolve({
          data: adminItem,
        });
      }, 200);
    } catch (error) {
      reject(error);
    }
  });
  return data;
};

export const getPlans = async () => {
  const { data } = await new Promise((resolve, reject) => {
    try {
      setTimeout(() => {
        resolve({
          data: planlists,
        });
      }, 200);
    } catch (error) {
      reject(error);
    }
  });
  return data;
};
export const getPlanItem = async () => {
  const { data } = await new Promise((resolve, reject) => {
    try {
      setTimeout(() => {
        resolve({
          data: planItem,
        });
      }, 200);
    } catch (error) {
      reject(error);
    }
  });
  return data;
};
export const getUsers = async () => {
  const { data } = await new Promise((resolve, reject) => {
    try {
      setTimeout(() => {
        resolve({
          data: userLists,
        });
      }, 200);
    } catch (error) {
      reject(error);
    }
  });
  return data;
};
export const getUser = async () => {
  const { data } = await new Promise((resolve, reject) => {
    try {
      setTimeout(() => {
        resolve({
          data: userItem,
        });
      }, 200);
    } catch (error) {
      reject(error);
    }
  });
  return data;
};

export const getRoles = async () => {
  const { data } = await new Promise((resolve, reject) => {
    try {
      setTimeout(() => {
        resolve({
          data: roleLists,
        });
      }, 200);
    } catch (error) {
      reject(error);
    }
  });
  return data;
};

export const getRole = async () => {
  const { data } = await new Promise((resolve, reject) => {
    try {
      setTimeout(() => {
        resolve({
          data: roleItem,
        });
      }, 200);
    } catch (error) {
      reject(error);
    }
  });
  return data;
};
