import Swal from "sweetalert2";

export const Success = ({ title }) => {
  Swal.fire({
    title: title,
    buttonsStyling: false,
    confirmButtonClass: "md-button md-success",
  });
};

export const Error = (message) => {
  Swal.fire({
    text: message,
    buttonsStyling: false,
    confirmButtonClass: "md-button md-danger",
  });
};
