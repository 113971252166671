<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-65 mx-auto md-small-size-65">
      <ValidationObserver ref="observer">
        <form
          @submit.prevent="updateSong"
          ref="UpdateSongForm"
          id="UpdateSong-form"
        >
          <md-card md-with-hover>
            <md-card-header class="md-card-header-icon md-card-header-danger">
              <div class="card-icon">
                <md-icon>library_music</md-icon>
              </div>
              <h4 class="title">Update Song</h4>
            </md-card-header>
            <md-card-content>
              <div class="md-layout">
                <div class="md-layout-item md-size-30">
                  <div class="file-input img-size">
                    <h5 class="text-danger" style="font-weight: bold">
                      {{ imageError }}
                    </h5>

                    <template v-if="!imageCircle">
                      <div class="image-container">
                        <img :src="avatarImg" title="" />
                      </div>
                    </template>
                    <div class="image-container" v-else>
                      <img :src="imageCircle" />
                    </div>
                    <div class="button-container">
                      <md-button
                        class="md-danger md-raised md-sm"
                        @click="removeImage('circle')"
                        v-if="imageCircle"
                      >
                        <md-icon>clear</md-icon>Remove</md-button
                      >
                      <md-button
                        class="md-sm md-success md-raised md-fileinput"
                      >
                        <template v-if="!imageCircle">Add Photo</template>
                        <template v-else>Change</template>

                        <input
                          type="file"
                          name="circle"
                          accept="image/*"
                          @change="onFileChange"
                        />
                      </md-button>
                    </div>
                  </div>
                </div>

                <div class="md-layout-item md-size-70 md-small-size-100">
                  <ValidationProvider
                    name="Title"
                    ref="title"
                    :rules="{
                      required: true,
                      regex: /^[a-zA-Z0-9 '.-]*$/,
                    }"
                    v-slot="{ errors, passed, failed }"
                  >
                    <md-field
                      :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                    >
                      <label>Title</label>
                      <md-input
                        v-model="currentTitle"
                        type="text"
                        required
                      ></md-input>
                      <slide-y-down-transition>
                        <md-icon class="error" v-show="failed">close</md-icon>
                      </slide-y-down-transition>
                      <slide-y-down-transition>
                        <md-icon class="success" v-show="passed">done</md-icon>
                      </slide-y-down-transition>
                    </md-field>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>

                  <ValidationProvider
                    name="Author"
                    ref="author"
                    :rules="{
                      required: true,
                      regex: /^[a-zA-Z0-9 '.-]*$/,
                    }"
                    v-slot="{ errors, passed, failed }"
                  >
                    <md-field
                      :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                    >
                      <label>Author</label>
                      <md-input
                        v-model="currentAuthor"
                        type="text"
                        required
                      ></md-input>
                      <slide-y-down-transition>
                        <md-icon class="error" v-show="failed">close</md-icon>
                      </slide-y-down-transition>
                      <slide-y-down-transition>
                        <md-icon class="success" v-show="passed">done</md-icon>
                      </slide-y-down-transition>
                    </md-field>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>

                  <ValidationProvider
                    name="Description"
                    ref="description"
                    :rules="{
                      min: 0,
                      max: 500,
                    }"
                    v-slot="{ errors, passed, failed }"
                  >
                    <md-field
                      :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                    >
                      <label>Description </label>
                      <md-textarea
                        v-model="currentDescription"
                        maxlength="500"
                        type="text"
                      ></md-textarea>
                      <slide-y-down-transition>
                        <md-icon class="error" v-show="failed">close</md-icon>
                      </slide-y-down-transition>
                      <slide-y-down-transition>
                        <md-icon class="success" v-show="passed">done</md-icon>
                      </slide-y-down-transition>
                    </md-field>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </div>

                <div class="md-layout-item md-small-size-100 md-size-100">
                  <br />
                  <ValidationProvider
                    name="Lyrics"
                    ref="Lyrics"
                    v-slot="{ errors, passed, failed }"
                  >
                    <div
                      :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                    >
                      <md-icon>subject</md-icon>
                      <label class="artist-label">Lyrics </label>
                      <ckeditor
                        :editor="editor"
                        v-model="currentLyrics"
                      ></ckeditor>
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </div>
                <br />
                <div class="md-layout-item md-small-size-100 md-size-100">
                  <br />
                  <label class="artist-label">Album </label>
                  <multiselect
                    v-model="currentAlbumId"
                    name="album"
                    :options="albumArray"
                    placeholder="Select Album"
                    label="title"
                    track-by="albumId"
                    :limit="1"
                    :searchable="true"
                    :show-labels="true"
                    :close-on-select="true"
                    :clear-on-select="true"
                    deselect-label="Remove"
                    select-label="Choose Album"
                    open-direction="bottom"
                    @select="addToHiddenInput"
                    @remove="removeFromHiddenInput"
                    ><md-icon>assignment</md-icon>
                    <label>Album</label>
                  </multiselect>
                  <input
                    type="hidden"
                    v-model="selectedAlbumValues"
                    name="selectedalbum"
                  />
                </div>

                <div class="md-layout-item md-small-size-100 md-size-50">
                  <br />
                  <ValidationProvider
                    name="Tone Code"
                    ref="toneCode"
                    :rules="{
                      max: 10,
                      regex: /^[0-9 '.-]*$/,
                    }"
                    v-slot="{ errors, passed, failed }"
                  >
                    <md-field
                      :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                    >
                      <md-icon>code</md-icon>
                      <label>Tone Code</label>
                      <md-input
                        v-model="currentToneCode"
                        data-vv-name="toneCode"
                        type="text"
                        name="tone Code"
                      >
                      </md-input>
                      <slide-y-down-transition>
                        <md-icon class="error" v-show="failed">close</md-icon>
                      </slide-y-down-transition>
                      <slide-y-down-transition>
                        <md-icon class="success" v-show="passed">done</md-icon>
                      </slide-y-down-transition>
                    </md-field>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </div>

                <div class="md-layout-item md-small-size-100 md-size-50">
                  <br />
                  <ValidationProvider
                    name="Type"
                    ref="type"
                    :rules="{
                      required: true,
                    }"
                    v-slot="{ errors, passed, failed }"
                  >
                    <div
                      class="ml-5"
                      :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                    >
                      <span class="song-label"><b>Song Type:</b></span>
                      <div class="song-type">
                        <md-radio v-model="songType" :value="false"
                          >Audio</md-radio
                        >
                        <md-radio v-model="songType" :value="true"
                          >Video</md-radio
                        >
                      </div>
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </div>

                <div
                  class="md-layout-item md-small-size-100 md-size-100"
                  v-if="songType == false"
                >
                  <ValidationProvider
                    name="Audio"
                    ref="audio"
                    :rules="{
                      required: false,
                    }"
                    v-slot="{ errors, passed, failed }"
                  >
                    <md-field
                      :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                    >
                      <label> Audio </label>
                      <md-file
                        type="file"
                        id="audiofile"
                        ref="audiofile"
                        name="audio"
                        placeholder="Upload audio file"
                        accept="audio/*"
                        v-model="audioname"
                        @change="onAudioChange"
                      />
                      <md-button class="md-just-icon md-simple">
                        <md-icon>info</md-icon>
                        <md-tooltip class="md-sm" md-direction="top"
                          >Valid extension: mp3.Valid size:less than (<b
                            >30 M.B</b
                          >)</md-tooltip
                        >
                      </md-button>
                      <md-button
                        class="md-just-icon md-simple md-info"
                        @click.prevent="playSound(audioUrl)"
                      >
                        <md-icon>play_circle</md-icon>
                        <md-tooltip class="md-sm" md-direction="top"
                          >Play Song (3s)</md-tooltip
                        >
                      </md-button>
                      <slide-y-down-transition>
                        <md-icon class="error" v-show="failed">close</md-icon>
                      </slide-y-down-transition>
                      <slide-y-down-transition>
                        <md-icon class="success" v-show="passed">done</md-icon>
                      </slide-y-down-transition>
                    </md-field>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>

                  <br />
                </div>

                <div
                  class="md-layout-item md-small-size-100 md-size-100"
                  v-if="songType == true"
                >
                  <ValidationProvider
                    name="Video"
                    ref="video"
                    :rules="{
                      required: false,
                    }"
                    v-slot="{ errors, passed, failed }"
                  >
                    <md-field
                      :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                    >
                      <label> Video </label>
                      <md-file
                        type="file"
                        id="videofile"
                        ref="videofile"
                        name="video"
                        placeholder="Upload Video file"
                        accept="video/*"
                        v-model="videoname"
                        @change="onVideoChange"
                      />
                      <md-button class="md-just-icon md-simple">
                        <md-icon>info</md-icon>
                        <md-tooltip class="md-sm" md-direction="top"
                          >Valid extension: mp4.Valid size:less than (<b
                            >30 M.B</b
                          >)</md-tooltip
                        >
                      </md-button>

                      <md-button
                        class="md-just-icon md-simple md-danger"
                        @click.prevent="playSound(videoUrl)"
                      >
                        <md-icon>smart_display</md-icon>
                        <md-tooltip class="md-sm" md-direction="top"
                          >Play Song(3s)</md-tooltip
                        >
                      </md-button>

                      <slide-y-down-transition>
                        <md-icon class="error" v-show="failed">close</md-icon>
                      </slide-y-down-transition>
                      <slide-y-down-transition>
                        <md-icon class="success" v-show="passed">done</md-icon>
                      </slide-y-down-transition>
                    </md-field>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </div>

                <div class="md-layout-item md-small-size-100 md-size-100">
                  <label class="artist-label">Main Artist </label>
                  <multiselect
                    v-model="currentArtists"
                    name="artists"
                    :options="artistArray"
                    placeholder="Select Main Artist"
                    label="name"
                    track-by="artistId"
                    :limit="1"
                    :searchable="true"
                    :show-labels="true"
                    :close-on-select="true"
                    :clear-on-select="true"
                    deselect-label="Remove"
                    select-label="Choose Artist"
                    open-direction="bottom"
                    ><md-icon>person</md-icon>
                    <label>Artists</label>
                  </multiselect>
                </div>

                <div class="md-layout-item md-small-size-100 md-size-100">
                  <br />
                  <label class="artist-label">Featured Artist </label>
                  <multiselect
                    v-model="featuredArtistsIds"
                    name="featuredArtistsIds"
                    :options="artistArray"
                    multiple
                    placeholder="Select Featured Artist"
                    label="name"
                    track-by="artistId"
                    :limit="5"
                    :searchable="true"
                    :show-labels="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    deselect-label="Remove"
                    select-label="Select"
                    open-direction="bottom"
                    ><md-icon>people_alt</md-icon>
                    <label>Featured Artists</label>
                  </multiselect>
                </div>
              </div>
            </md-card-content>

            <md-card-actions md-alignment="space-between">
              <md-button
                @click="HideAddData"
                class="md-sm md-dense md-raised md-danger"
                >Cancel</md-button
              >

              <clip-loader
                :loading="loading"
                :color="color"
                size="32px"
              ></clip-loader>

              <md-button
                class="md-sm md-dense md-raised md-success"
                type="submit"
                >Submit</md-button
              >
            </md-card-actions>
          </md-card>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import { SlideYDownTransition } from "vue2-transitions";
import {
  ValidationObserver,
  ValidationProvider,
} from "vee-validate/dist/vee-validate.full";
import * as notifications from "../../../utils/notifications";

export default {
  name: "Add-song",
  components: {
    ClipLoader,
    ValidationProvider,
    ValidationObserver,
    SlideYDownTransition,
  },
  props: {
    profileCard: {
      type: String,
      default: "../../img/no-image-new.png",
    },
    avatarImg: {
      type: String,
      default: "../../img/no-image.png",
    },
  },

  created() {
    this.getSong(this.$route.params.id);
  },
  data() {
    return {
      play: "fas fa-play",
      currentCoverArt: "",
      currentTitle: "",
      currentAuthor: "",
      currentDescription: "",
      currentType: "",
      currentLyrics: "",
      currentToneCode: "",
      currentVideo: "",
      currentAudio: "",
      currentArtists: [],
      currentAlbumId: [],

      artists: [],
      featuredArtistsIds: [],

      editor: ClassicEditor,
      editorData: "",
      editorConfig: {
        // The configuration of the editor.
        height: 800,
      },
      audioname: "",
      artistArray: [],
      albumArray: [],
      selectedArtists: [],
      selectedFeaturedArtistIds: [],

      loading: false,
      color: "#3AB982",
      size: "40px",
      imageCircle: "",
      imageCircleFile: "",
      imageProfile: "",
      avatarFileName: "",
      profileFileName: "",
      videoname: "",
      audio: "",
      audioFile: File,
      audioUrl: "",
      video: "",
      videoUrl: "",
      videoFile: File,
      songType: false,
      imageError: "",
      albumError: "",
      mainArtistError: "",
      featuredArtistError: "",
      selectedAlbumValues: [],
      preselectedAlbumValues: [],
      preselectedArtistValues: [],
      preselectedFeaturedArtistValues: [],
      avatarFileChanged: false,
      audioFileChanged: false,
      videoFileChanged: false,
    };
  },

  methods: {
    addToHiddenInput: function (value) {
      this.selectedAlbumValues.push(value.id);
    },
    removeFromHiddenInput: function (value) {
      const index = this.selectedAlbumValues.indexOf(value.id);
      if (index > -1) {
        this.selectedAlbumValues.splice(index, 1);
      }
    },
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.avatarFileChanged = true;
      if (e.target.name) {
        this.imageCircleFile = files[0];
        this.createImage(files[0], "circle");
        this.avatarFileName = files[0].name;
      } else {
        this.createImage(files[0]);
        this.profileFileName = files[0].name;
      }
    },
    createImage(file, type) {
      let reader = new FileReader();
      let vm = this;

      reader.onload = (e) => {
        if (type === "circle") {
          vm.imageCircle = e.target.result;
        } else {
          vm.imageProfile = e.target.result;
        }
      };
      reader.readAsDataURL(file);
    },
    removeImage: function (type) {
      if (type === "circle") {
        this.imageCircle = "";
      } else {
        this.imageProfile = "";
      }
    },
    onResponsiveInverted() {
      if (window.innerWidth < 768) {
        this.responsive = true;
      } else {
        this.responsive = false;
      }
    },
    playSound(sound) {
      if (sound) {
        var audio = new Audio(sound);
        /////audio.play();
        audio = new Audio(sound);
        audio.play();
        this.play = "fas fa-pause";
        let vm = this;

        setTimeout(function () {
          audio.pause();
          audio.currentTime = 0;
          vm.play = "fas fa-play";
        }, 10000);
      }
    },

    onAudioChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      this.createAudio(files[0]);
      this.audioname = files[0].name;
      this.audioFileChanged = true;
    },
    onVideoChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      this.createVideo(files[0]);
      this.videoname = files[0].name;
      this.videoFileChanged = true;
    },

    createAudio(file) {
      let reader = new FileReader();
      let vm = this;
      reader.onload = (e) => {
        vm.audio = e.target.result;
        vm.audioFile = file;
      };
      reader.readAsDataURL(file);
    },

    createVideo(file) {
      let reader = new FileReader();
      let vm = this;
      reader.onload = (e) => {
        vm.video = e.target.result;
        vm.videoFile = file;
      };
      reader.readAsDataURL(file);
    },

    removeAudio: function () {
      this.currentAudio = "";
    },

    HideAddData() {
      this.$router.push("/song");
    },

    getAllArtists() {
      const params = {
        page: 0,
        pageSize: 10,
      };
      this.$store
        .dispatch("getAllArtists", params)
        .then((res) => {
          this.artistArray = res;
          ///console.log(this.artistArray);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getAlbums() {
      this.loading = true;
      const params = {
        page: 0,
        pageSize: 10,
      };
      this.$store
        .dispatch("getAlbums", params)
        .then((res) => {
          this.albumArray = res.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getSong(id) {
      this.loading = true;
      this.$store
        .dispatch("getSong", id)
        .then((res) => {
          const Items = res.data.data;
          //console.log(Items);
          // Items.artists.map((data) => {

          //   this.imageCircle = data.profilePhoto.url;
          // });

          this.currentTitle = Items.title;
          this.currentAuthor = Items.author;
          this.currentDescription = Items.description;
          this.currentLyrics = Items.lyrics == null ? "" : Items.lyrics;

          //this.currentAudio = Items.music.url;
          this.currentAlbumId = Items.albumId;
          this.currentToneCode =
            Items.toneCode == "NaN" || Items.toneCode == undefined
              ? ""
              : Items.toneCode;
          // this.form.artists = Items.artists[0].artistId;
          this.currentArtists = Items.artists[0].artistId;
          this.imageCircle = Items.coverArt.url;

          let str = `${Items.title} by ${Items.author}`;
          if (Items.type.toLowerCase() == "video") {
            this.songType = true;
            this.videoUrl = Items.music.url;
            this.videoname = Items.music.url
              ? `${str.replace(/\s+/g, "-").toLowerCase()}.mp4`
              : `${str.replace(/\s+/g, "-").toLowerCase()}.mp4`;
            // console.log(Items.type.toLowerCase());
          } else {
            this.songType = false;
            this.audioUrl = Items.music.url;
            this.audioname = Items.music.url
              ? `${str.replace(/\s+/g, "-").toLowerCase()}.mp3`
              : `${str.replace(/\s+/g, "-").toLowerCase()}.mp3`;

            // console.log(this.audioUrl);
            // console.log(Items.type.toLowerCase());
          }

          console.log(Items.coverArt.url);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    updateSong(e) {
      e.preventDefault();

      this.$refs.observer.validate().then((success) => {
        if (!success) {
          return;
        }

        if (this.currentArtists.length < 1) {
          console.log("empty artist name");
          this.mainArtistError = "Artist is required!";
        } else {
          this.loading = true;
          let loader = this.$loading.show({
            // Optional parameters
            container: this.fullPage ? null : this.$refs.formContainer,
            canCancel: false,
            onCancel: this.onCancel,
            color: "#eb4034",
          });
          let filesArray = [];
          let albumId = "";

          let typeSong = "";

          if (this.avatarFileChanged == false) {
            console.log("No file");
          } else {
            filesArray.push(this.imageCircleFile);
            console.log("is file");
          }

          this.selectedArtists.push(Object.values(this.currentArtists)[0]);

          // if audio or video file is changed

          if (this.audioFileChanged || this.videoFileChanged) {
            if (this.songType) {
              typeSong = "video";
              filesArray.push(this.videoFile);
            } else {
              typeSong = "audio";
              filesArray.push(this.audioFile);
            }
          } else {
            typeSong = this.songType == true ? "Video" : "Audio";
          }

          // Featured Artists
          Object.values(this.featuredArtistsIds).forEach((item) => {
            this.selectedFeaturedArtistIds.push(item.artistId);
          });

          // Album if not selected send empty
          console.log(this.currentAlbumId);

          if (this.currentAlbumId == null || this.currentAlbumId == undefined) {
            albumId = "";
          } else {
            albumId = Object.values(this.currentAlbumId)[0];
          }

          const formData = {
            id: this.$route.params.id,
            title: this.currentTitle,
            author: this.currentAuthor,
            toneCode: this.currentToneCode,
            description: this.currentDescription,
            lyrics: this.currentLyrics,
            type: typeSong,
            artists: this.selectedArtists,
            albumId: albumId,
            featuredArtistsIds: this.selectedFeaturedArtistIds,
            files: filesArray,
            category: "Song",
          };

          console.log(formData);

          this.$store
            .dispatch("updateSong", formData)
            .then((res) => {
              console.log(res);

              this.loading = false;
              loader.hide();
              notifications.Success({
                title: "Song Updated successfully",
                buttonsStyling: false,
                confirmButtonClass: "md-button md-success",
              });
            })
            .catch((err) => {
              ///console.log(err);
              notifications.Error(err);
              loader.hide();
            })
            .finally(() => {
              this.loading = false;
              this.HideAddData();
              loader.hide();
            });
        }
        this.$nextTick(() => {
          this.$refs.observer.reset();
        });
      });
    },
  },
  watch: {
    albumArray: function () {
      var elementPos = this.albumArray
        .map(function (option) {
          return option.albumId;
        })
        .indexOf(this.currentAlbumId);

      // console.log(this.albumArray);
      // console.log(this.albumArray[elementPos]);
      this.currentAlbumId = this.albumArray[elementPos];
      // console.log(this.currentAlbumId);
      // console.log(this.albumArray[elementPos]);
    },
    artistArray: function () {
      var artistPos = this.artistArray
        .map(function (option) {
          return option.artistId;
        })
        .indexOf(this.currentArtists);
      // console.log(this.artistArray);
      // console.log(this.artistArray[artistPos]);
      this.currentArtists = this.artistArray[artistPos];
      // console.log(this.currentArtists);
      // console.log(this.artistArray[artistPos]);
    },
  },

  mounted() {
    setTimeout(() => {
      this.getAllArtists();
      this.getAlbums();
    }, 1000);

    this.onResponsiveInverted();
    window.addEventListener("resize", this.onResponsiveInverted);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResponsiveInverted);
  },
};
</script>
<style lang="scss" scoped>
.artist-label {
  font-size: 10px;
  font-weight: 400;
  color: #aaaaaa !important;
  line-height: 1.4;
  top: 26px;
}
.song-label {
  font-size: 12px;
  font-weight: 320;
  color: #474747 !important;

  .song-type {
    font-size: 12px;
    font-weight: 400;
    color: #aaaaaa !important;
  }
}

.text-right {
  display: flex;
}
.Image-Background {
  padding: 15px 20px;
  line-height: 1.75em;
  position: relative;
  height: 26%;
}
.Image-Container {
  padding: 15px 20px;
  line-height: 1.75em;
  position: relative;
  height: 26%;
}

.img-size {
  width: 95%;
  height: 60%;
}
</style>