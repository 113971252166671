const commaSeparator = {
  methods: {
    comma(num) {
      var number = Number(num)
        .toLocaleString()
        .split(".");
      number[0] = number[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return number.join(".");
    },
  },
};

export default commaSeparator;
