import Vue from "vue";
import App from "./App.vue";

import router from "./routes";
import store from "./store/";
import moment from "moment";
import DashboardPlugin from "./material-dashboard";
import VueBlobJsonCsv from "vue-blob-json-csv";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
// Import component
import VueObserveVisibility from "vue-observe-visibility";
import VueLoading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

Vue.use(CKEditor);
Vue.use(VueBlobJsonCsv);
Vue.use(DashboardPlugin);
Vue.use(VueObserveVisibility);
Vue.component("multiselect", Multiselect);
Vue.component("v-select", vSelect);
Vue.use(VueLoading);

Vue.filter("truncate", function (text, length, suffix) {
  if (!text) return "";
  text = text.toString();

  if (text.length <= length) {
    return text;
  }
  return text.substr(0, length) + suffix;
});

Vue.config.productionTip = false;
Vue.prototype.moment = moment;
Vue.material.locale.dateFormat = "dd-MM-yyyy";

Vue.material = {
  ...Vue.material,
  locale: {
    ...Vue.material.locale,
    dateFormat: "dd-MM-yyyy",
    firstDayOfAWeek: 1,
  },
};

// axios.defaults.baseURL =
//   "https://turnout-vue-d79e5-default-rtdb.firebaseio.com";
// // axios.defaults.headers.common['Authorization'] = 'fasfdsa'
// axios.defaults.headers.get["Accepts"] = "application/json";

// const reqInterceptor = axios.interceptors.request.use((config) => {
//   console.log("Request Interceptor", config);
//   return config;
// });
// const resInterceptor = axios.interceptors.response.use((res) => {
//   console.log("Response Interceptor", res);
//   return res;
// });

// axios.interceptors.request.eject(reqInterceptor);
// axios.interceptors.response.eject(resInterceptor);

new Vue({
  el: "#app",
  router,
  store,
  render: (h) => h(App),
});
