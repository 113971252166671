<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-80 mx-auto">
      <ValidationObserver ref="observer">
        <form
          @submit.prevent="updateArtist"
          ref="updateArtistForm"
          id="updateArtist-form"
        >
          <md-card md-with-hover>
            <md-card-header class="md-card-header-icon md-card-header-danger">
              <div class="card-icon">
                <md-icon>perm_identity</md-icon>
              </div>
              <h4 class="title">Edit Artist</h4>
            </md-card-header>
            <md-card-content>
              <div class="md-layout">
                <div class="md-layout-item md-size-25">
                  <!-- <h4 class="card-title">Avatar Image</h4>
                  <small class="text-danger" style="font-weight: bold">{{
                    imageError
                  }}</small> -->

                  <div class="file-input img-size">
                    <template v-if="!imageCircle">
                      <div class="image-container">
                        <img :src="avatarImg" title="" />
                      </div>
                    </template>
                    <div class="image-container" v-else>
                      <img :src="imageCircle" />
                    </div>
                    <div class="button-container">
                      <md-button
                        class="md-danger md-raised md-sm"
                        @click="removeImage('circle')"
                        v-if="imageCircle"
                      >
                        <md-icon>clear</md-icon>Remove</md-button
                      >
                      <md-button
                        class="md-sm md-success md-raised md-fileinput"
                      >
                        <template v-if="!imageCircle">Add Photo</template>
                        <template v-else>Change</template>
                        <input
                          type="file"
                          name="circle"
                          accept="image/*"
                          @change="onFileChange"
                        />
                      </md-button>
                    </div>
                    <h6 class="text-danger">{{ imageError }}</h6>
                  </div>
                </div>
                <div class="md-layout-item md-small-size-100 md-size-75">
                  <ValidationProvider
                    name="Artist Name "
                    ref="Artist Name "
                    data-vv-name="text"
                    rules="required"
                    v-slot="{ errors, passed, failed }"
                  >
                    <md-field
                      :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                    >
                      <label>Artist Name</label>
                      <md-input
                        v-model="artistName"
                        data-vv-name="artistName"
                        type="text"
                        name="artistName"
                      >
                      </md-input>

                      <slide-y-down-transition>
                        <md-icon class="error" v-show="failed">close</md-icon>
                      </slide-y-down-transition>
                      <slide-y-down-transition>
                        <md-icon class="success" v-show="passed">done</md-icon>
                      </slide-y-down-transition>
                    </md-field>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>

                  <ValidationProvider
                    name="Description "
                    ref="Description "
                    :rules="{
                      min: 0,
                      max: 500,
                    }"
                    v-slot="{ errors, passed, failed }"
                    ><md-field
                      :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                    >
                      <label>Description</label>
                      <md-textarea
                        v-model="favDescription"
                        maxlength="500"
                        type="text"
                      ></md-textarea>
                      <slide-y-down-transition>
                        <md-icon class="error" v-show="failed">close</md-icon>
                      </slide-y-down-transition>
                      <slide-y-down-transition>
                        <md-icon class="success" v-show="passed">done</md-icon>
                      </slide-y-down-transition>
                    </md-field>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>

                  <div>
                    <br />
                    <b> <small>Country</small> </b>
                    <multiselect
                      v-model="country"
                      name="country"
                      :options="countryArray"
                      placeholder="Select Country"
                      label="name"
                      track-by="countryId"
                      :limit="1"
                      :searchable="true"
                      :show-labels="true"
                      :close-on-select="true"
                      :clear-on-select="true"
                      deselect-label="Remove"
                      select-label="Choose Country"
                      open-direction="bottom"
                    >
                    </multiselect>

                    <br />
                  </div>

                  <ValidationProvider
                    name="National ID"
                    ref="identityID"
                    :rules="{
                      required: false,
                      min: 5,
                      max: 8,
                      regex: /^[0-9]*$/,
                    }"
                    v-slot="{ errors, passed, failed }"
                  >
                    <md-field
                      :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                    >
                      <label>National Id</label>
                      <md-input v-model="idNumber" type="number"></md-input>
                    </md-field>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>

                  <br />
                  <!-- 
                  <label> <b> Tag(s)</b> </label>
                  <multiselect
                    v-model="tagsId"
                    :options="this.tagsArray"
                    multiple
                    placeholder="Select Tag(s)"
                    label="tag"
                    track-by="id"
                    :limit="5"
                    :searchable="true"
                    :show-labels="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    deselect-label="Remove Tag"
                    select-label="Select Tag"
                    open-direction="top"
                  >
                  </multiselect> -->
                </div>

                <br />
              </div>
            </md-card-content>
            <md-card-actions>
              <md-button
                @click.native="goBack()"
                class="md-sm md-dense md-raised md-danger"
                >Cancel</md-button
              >

              <md-button
                class="md-sm md-dense md-raised md-success"
                @click="$refs.updateArtistForm.requestSubmit()"
                >Submit</md-button
              >
            </md-card-actions>
          </md-card>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import { SlideYDownTransition } from "vue2-transitions";

import {
  ValidationObserver,
  ValidationProvider,
  extend,
} from "vee-validate/dist/vee-validate.full";
import * as notifications from "../../../utils/notifications";

// can customize default error messages
extend("required", {
  message: (field) => "The " + `${field}` + " field is required. ",
});
export default {
  props: {
    profileCard: {
      type: String,
      default: "../../img/no-image-new.png",
    },
    avatarImg: {
      type: String,
      default: "../../img/no-image.png",
    },
  },

  components: {
    ValidationProvider,
    ValidationObserver,
    SlideYDownTransition,
  },

  data() {
    return {
      currentSort: "name",
      currentSortOrder: "asc",
      searchQuery: "",
      tableData: "",
      searchedData: [],
      tagsId: [],
      tagsArray: [],
      fuseSearch: null,
      emptylist: false,
      currentArtist: "",
      editcurrentArtist: false,
      cardUserImage: "",
      artistName: "",
      idNumber: "",
      country: "",
      countryArray: [],
      aboutArtist: "",
      favDescription: "",
      status: "",
      topArtist: true,
      addedOn: "",
      modifiedOn: "",
      loading: false,
      color: "#3AB982",
      imageCircle: "",
      imageCircleFile: "",
      avatarFileChanged: false,
      imageProfile: "",
      notifyUser: true,
      avatarFileName: "",
      profileFileName: "",
      imageError: "",
      pagination: {
        pageSize: 5,
        page: 1,
        perPageOptions: [5, 10, 25, 50, 100],
        total: 0,
      },
    };
  },

  created() {
    this.getArtist();
    this.getCountries();
  },

  methods: {
    getCategories() {
      this.loading = true;
      const params = {
        page: this.pagination.page - 1,
        pageSize: this.pagination.pageSize,
      };
      this.$store
        .dispatch("getCategories", params)
        .then((res) => {
          if (res.data.paging.count === 0) {
            this.emptylist = true;
          } else {
            let tags = [];

            let categories = res.data.data;

            categories.forEach((category) => {
              category["tags"].forEach((tag) => {
                tags.push({ tag: tag.tag, id: tag.tagId });
              });
            });

            this.tagsArray = tags;

            this.pagination.total = res.data.paging.count;
          }
        })

        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getArtist() {
      this.loading = true;

      this.$store
        .dispatch("getArtist", {
          currentId: this.$route.params.id,
        })
        .then((res) => {
          this.loading = false;

          let artist = res.data;

          this.imageCircle = artist.profilePhoto.url;
          const { name, description, idNumber, countryId, isActive, isTop } =
            artist;

          this.artistName = name;
          this.favDescription = description;
          this.idNumber = idNumber;
          this.country = countryId;
          this.topArtist = isTop;
          this.status = isActive;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getCountries() {
      const params = {
        page: 0,
        pageSize: 300,
      };
      this.$store
        .dispatch("getCountries", params)
        .then((res) => {
          // Object.keys(res).forEach((key) => {
          //   console.log(res[key].name);
          // });

          this.countryArray = res;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    goBack() {
      this.$router.back();
    },
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      this.avatarFileChanged = true;
      if (e.target.name) {
        this.createImage(files[0], "circle");
        this.avatarFileName = files[0].name;
      } else {
        this.createImage(files[0]);
        this.profileFileName = files[0].name;
      }
    },
    createImage(file, type) {
      let reader = new FileReader();
      let vm = this;

      reader.onload = (e) => {
        if (type === "circle") {
          vm.imageCircleFile = file;
          vm.imageCircle = e.target.result;
        } else {
          vm.imageProfile = e.target.result;
        }
      };
      reader.readAsDataURL(file);
    },
    removeImage: function (type) {
      if (type === "circle") {
        this.imageCircle = "";
        this.imageCircleFile = "";
      } else {
        this.imageProfile = "";
      }
    },
    HideAddData() {
      this.$router.push("/artists");
    },
    updateArtist() {
      this.$refs.observer.validate().then((success) => {
        if (!success) {
          return;
        }

        if (this.artistName == "") {
          console.log("empty artist name");
        } else {
          // tags
          let tagIdsArray = [];
          this.tagsId.forEach((tag) => {
            tagIdsArray.push(tag.id);
          });

          //
          this.loading = true;
          let loader = this.$loading.show({
            // Optional parameters
            container: this.fullPage ? null : this.$refs.formContainer,
            canCancel: true,
            onCancel: this.onCancel,
            color: "#eb4034",
          });
          let filesArray = [];

          if (this.avatarFileChanged == false) {
            console.log("No file");
          } else {
            filesArray.push(this.imageCircleFile);
            console.log("is file");
          }

          // console.log(" valid here" + this.$route.params.id);

          this.$store
            .dispatch("updateArtist", {
              name: this.artistName,
              description: this.favDescription,
              idNumber: this.idNumber,
              country: Object(this.country).countryId,
              files: filesArray,
              category: "Artist",
              id: this.$route.params.id,
              // tagIds: tagIdsArray,
            })
            .then((res) => {
              console.log(res);

              this.loading = false;

              notifications.Success({
                title: "Artist profile updated successfully",
                buttonsStyling: false,
                confirmButtonClass: "md-button md-success",
              });
            })
            .finally(() => {
              this.loading = false;
              loader.hide();
              this.HideAddData();
            })
            .catch((err) => {
              notifications.Error(err);
              loader.hide();
            });
        }
        this.$nextTick(() => {
          this.$refs.observer.reset();
        });
      });
    },
  },

  watch: {
    countryArray: function () {
      var countryData = this.countryArray
        .map((option) => {
          return option.countryId;
        })
        .indexOf(this.country);

      this.country = this.countryArray[countryData];
    },
  },
};
</script>
<style lang="css">
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
</style>
<style lang="css" scoped>
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
.spacing {
  margin: "10px";
  padding: "10px";
}
.item-status {
  background-color: #4caf50;
  padding: 5px;
  color: white;
  border-radius: 10%;
}
.img-width {
  width: 50px;
}
.img-size {
  width: 80%;
  height: 80%;
}

.md-table[table-header-color="green"] .md-table-head .md-table-head-label {
  font-weight: bold;
}
</style>
