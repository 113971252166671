<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-danger">
          <div class="card-icon">
            <md-icon>category</md-icon>
          </div>

          <h4 class="title">
            Genres
            <clip-loader
              :loading="loading"
              :color="color"
              size="32px"
            ></clip-loader>
            <div class="pull-right">
              <md-button
                class="md-just-icon md-success md-dense md-raised md-simple"
                @click.prevent="OpenAddGenre"
              >
                <md-icon>add</md-icon>
                <md-tooltip class="md-sm" md-direction="top"
                  >Add Genre</md-tooltip
                >
              </md-button>

              <md-button
                v-if="!this.selected == false"
                @click.native="handleDelete"
                class="md-just-icon md-danger md-dense md-raised md-simple"
              >
                <md-icon>delete</md-icon>
                <md-tooltip class="md-sm" md-direction="top">Delete</md-tooltip>
              </md-button>
              <md-button
                :disabled="emptylist || selected === null"
                @click.native="handleActivate"
                class="md-just-icon md-primary md-dense md-raised md-simple"
              >
                <md-icon>lock_open</md-icon>
                <md-tooltip class="md-sm" md-direction="top"
                  >Activate</md-tooltip
                >
              </md-button>

              <md-button
                :disabled="emptylist || selected === null"
                @click.native="handleDeactivate"
                class="md-just-icon md-primary md-dense md-raised md-simple"
              >
                <md-icon>lock</md-icon>
                <md-tooltip class="md-sm" md-direction="top"
                  >Deactivate</md-tooltip
                >
              </md-button>

              <md-button
                :disabled="emptylist"
                @click="getGenres"
                class="md-just-icon md-info md-dense md-raised md-simple"
              >
                <md-icon>autorenew</md-icon>
                <md-tooltip class="md-sm" md-direction="top"
                  >Refresh</md-tooltip
                >
              </md-button>
            </div>
          </h4>
        </md-card-header>

        <md-card-content>
          <md-table-empty-state
            v-if="emptylist"
            md-label="No data found"
            :md-description="`Empty table there's no data`"
          >
          </md-table-empty-state>

          <md-table
            v-else
            :value="queriedData"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
            @md-selected="onSelect"
            table-header-color="green"
            class="table-striped table-hover"
          >
            <md-table-toolbar class="toolbar">
              <md-field>
                <md-input
                  type="search"
                  class="mb-3"
                  clearable
                  style="width: 200px"
                  placeholder="Search records"
                  v-model="searchQuery"
                >
                </md-input>
              </md-field>
            </md-table-toolbar>
            <md-table-toolbar
              slot="md-table-alternate-header"
              slot-scope="{ count }"
              style="margin-top: -20px"
            >
              <div class="md-toolbar-section-start">
                {{ getAlternateLabel(count) }}
              </div>
            </md-table-toolbar>

            <md-table-row
              slot="md-table-row"
              slot-scope="{ item }"
              md-selectable="multiple"
            >
              <md-table-cell md-label="Title">{{ item.title }}</md-table-cell>

              <md-table-cell md-label="Added On">
                {{ moment(item.createdAt).format("DD-MMM-YYYY") }}
              </md-table-cell>
              <md-table-cell md-label="Updated On">
                {{ moment(item.updatedAt).format("DD-MMM-YYYY") }}
              </md-table-cell>
              <md-table-cell md-label="Status">{{ item.status }}</md-table-cell>

              <md-table-cell md-label="Actions">
                <md-button
                  class="md-just-icon md-primary md-simple"
                  @click.native="Updategenre(item)"
                >
                  <md-icon>dvr</md-icon>
                  <md-tooltip class="md-sm" md-direction="top">Edit</md-tooltip>
                </md-button>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          >
          </pagination>
        </md-card-actions>
      </md-card>

      <Add ref="Addgenre" :getGenres="getGenres" />
      <Update ref="Updategenre" :getGenres="getGenres" />
    </div>
  </div>
</template>

<script>
import Add from "./Add";
import Update from "./Update";
import { Pagination } from "@/componentItems";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import Fuse from "fuse.js";
//import Swal from "sweetalert2";

export default {
  name: "SongTable",
  components: {
    Pagination,
    ClipLoader,
    Add,
    Update,
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */

    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
  created() {
    this.getGenres();
  },
  data() {
    return {
      currentSort: "name",
      currentSortOrder: "asc",
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: ["title", "email", "age"],
      tableData: [],
      searchedData: [],
      fuseSearch: null,
      selected: [],

      emptylist: false,
      loading: false,
      color: "#3AB982",
      size: "40px",
    };
  },
  methods: {
    onSelect(items) {
      this.selected = items;
    },
    getAlternateLabel(count) {
      let plural = "";
      if (count > 1) {
        plural = "s";
      }
      return `${count} item${plural} selected`;
    },

    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "desc") {
          return a[sortBy].localeCompare(b[sortBy]);
        }
        return b[sortBy].localeCompare(a[sortBy]);
      });
    },

    OpenAddGenre() {
      this.$refs.Addgenre.ShowAddModal();
    },

    Updategenre(item) {
      this.$refs.Updategenre.ShowUpdateModal(item.id);
      console.log(item.id);
    },

    getGenres() {
      this.loading = true;
      this.$store
        .dispatch("getGenres")
        .then((res) => {
          this.tableData = res;
        })
        .then(() => {
          // Fuse search initialization.
          this.fuseSearch = new Fuse(this.tableData, {
            keys: ["title", "type"],
            threshold: 0.3,
          });
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    handleActivate() {
      if (this.selected.length > 0) {
        this.loading = true;
        this.$store
          .dispatch("deleteArtist")
          .then(() => {
            this.loading = false;
            let code = 200;

            if (code) {
              this.$notify({
                message: "Genre Activated Successfully",
                icon: "add_alert",
                horizontalAlign: "right",
                verticalAlign: "top",
                type: "success",
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.$notify({
          message: "Select an Item from the List",
          icon: "add_alert",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "danger",
        });
      }
    },
    handleDeactivate() {
      if (this.selected.length > 0) {
        this.loading = true;
        this.$store
          .dispatch("deleteArtist")
          .then(() => {
            this.loading = false;
            let code = 200;

            if (code) {
              this.$notify({
                message: "Genre Deactivated Successfully",
                icon: "add_alert",
                horizontalAlign: "right",
                verticalAlign: "top",
                type: "success",
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.$notify({
          message: "Select an Item from the List",
          icon: "add_alert",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "danger",
        });
      }
    },

    handleDelete() {
      if (this.selected.length > 0) {
        this.loading = true;
        this.$store
          .dispatch("deleteArtist")
          .then((res) => {
            this.loading = false;
            let code = res.data.code;

            if (code) {
              this.$notify({
                message: "Genre Deleted Successfully",
                icon: "add_alert",
                horizontalAlign: "right",
                verticalAlign: "top",
                type: "success",
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.$notify({
          message: "Select an Item from the List",
          icon: "add_alert",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "danger",
        });
      }
    },
  },

  mounted() {
    // Fuse search initialization.
    // this.fuseSearch = new Fuse(this.tableData, {
    //   keys: ["title"],
    //   threshold: 0.3,
    // });
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */

    searchQuery(value) {
      let result = this.tableData;
      if (value !== "") {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result;
    },
  },
};
</script>

<style lang="scss" scoped>
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
.toolbar {
  margin-bottom: 10px;
}
.md-field {
  width: 30%;
  min-height: 48px;
  margin: 4px 0 24px;
  padding-top: 16px;
  display: flex;
  position: relative;
  font-family: inherit;
}
.md-table[table-header-color="green"] .md-table-head .md-table-head-label {
  font-weight: bold;
}
.imgUrl {
  width: 28px;
  height: 28px;
}
</style>