import axios from "axios";

const api = axios.create({
  baseURL: "https://angaza-pwa.herokuapp.com/api/crm/v2.0",
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use((config) => {
  config.headers["x-server-key"] = `${localStorage.getItem("token")}`;
  return config;
});

export default api;
