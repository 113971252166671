<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-80 mx-auto">
      <ValidationObserver ref="observer">
        <form
          @submit.prevent="addArtist"
          ref="AddArtistForm"
          id="AddArtist-form"
        >
          <md-card md-with-hover>
            <md-card-header class="md-card-header-icon md-card-header-danger">
              <div class="card-icon">
                <md-icon>perm_identity</md-icon>
              </div>
              <h4 class="title">Add Artist</h4>
            </md-card-header>
            <md-card-content>
              <div class="md-layout">
                <div class="md-layout-item md-size-30">
                  <!-- <h4 class="card-title">Avatar Image</h4>
                  <h5 class="text-danger" style="font-weight: bold">
                    {{ imageError }}
                  </h5> -->
                  <div class="file-input img-size">
                    <template v-if="!imageCircle">
                      <div class="image-container">
                        <img :src="avatarImg" title="" />
                      </div>
                    </template>
                    <div class="image-container" v-else>
                      <img :src="imageCircle" />
                    </div>
                    <div class="button-container">
                      <md-button
                        class="md-danger md-raised md-sm"
                        @click="removeImage('circle')"
                        v-if="imageCircle"
                      >
                        <md-icon>clear</md-icon>Remove</md-button
                      >
                      <md-button
                        class="md-sm md-success md-raised md-fileinput"
                      >
                        <template v-if="!imageCircle">Add Photo</template>
                        <template v-else>Change</template>
                        <input
                          type="file"
                          name="circle"
                          accept="image/*"
                          @change="onFileChange"
                        />
                      </md-button>
                    </div>
                    <h6 class="text-danger">{{ imageError }}</h6>
                  </div>
                </div>
                <div class="md-layout-item md-small-size-100 md-size-70">
                  <ValidationProvider
                    name="Artist Name "
                    ref="Artist Name "
                    data-vv-name="text"
                    :rules="{
                      required: true,
                      regex: /^[a-zA-Z '.-]*$/,
                    }"
                    v-slot="{ errors, passed, failed }"
                  >
                    <md-field
                      :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                    >
                      <label>Artist Name</label>
                      <md-input
                        v-model="artistName"
                        data-vv-name="artistName"
                        type="text"
                        name="artistName"
                      >
                      </md-input>

                      <slide-y-down-transition>
                        <md-icon class="error" v-show="failed">close</md-icon>
                      </slide-y-down-transition>
                      <slide-y-down-transition>
                        <md-icon class="success" v-show="passed">done</md-icon>
                      </slide-y-down-transition>
                    </md-field>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>

                  <ValidationProvider
                    name="Description "
                    ref="Description "
                    data-vv-name="text"
                    :rules="{
                      min: 0,
                      max: 500,
                    }"
                    v-slot="{ errors, passed, failed }"
                    ><md-field
                      :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                    >
                      <label>Description</label>
                      <md-textarea
                        v-model="favDescription"
                        maxlength="500"
                        type="text"
                      ></md-textarea>
                      <slide-y-down-transition>
                        <md-icon class="error" v-show="failed">close</md-icon>
                      </slide-y-down-transition>
                      <slide-y-down-transition>
                        <md-icon class="success" v-show="passed">done</md-icon>
                      </slide-y-down-transition>
                    </md-field>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>

                  <div>
                    <br />
                    <multiselect
                      v-model="country"
                      name="country"
                      :options="countryArray"
                      placeholder="Select Country"
                      label="name"
                      track-by="countryId"
                      :limit="1"
                      :searchable="true"
                      :show-labels="true"
                      :close-on-select="true"
                      :clear-on-select="true"
                      deselect-label="Remove"
                      select-label="Choose Country"
                      open-direction="bottom"
                    >
                    </multiselect>
                    <pre
                      class="language-json text-danger"
                    ><code>{{ countryError }}</code></pre>
                    <br />
                  </div>

                  <ValidationProvider
                    name="National ID"
                    ref="identityID"
                    :rules="{
                      required: false,
                      min: 5,
                      max: 8,
                      regex: /^[0-9]*$/,
                    }"
                    v-slot="{ errors, passed, failed }"
                  >
                    <md-field
                      :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                    >
                      <label>National Id</label>
                      <md-input v-model="idNumber" type="number"></md-input>
                    </md-field>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>

                  <br />
                  <!-- 
                  <label> <b> Tag(s)</b> </label>
                  <multiselect
                    v-model="tagsId"
                    :options="this.tagsArray"
                    multiple
                    placeholder="Select Tag(s)"
                    label="tag"
                    track-by="id"
                    :limit="5"
                    :searchable="true"
                    :show-labels="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    deselect-label="Remove Tag"
                    select-label="Select Tag"
                    open-direction="top"
                  >
                  </multiselect> -->
                </div>
              </div>
            </md-card-content>
            <md-card-actions>
              <md-button
                @click.native="goBack()"
                class="md-sm md-dense md-raised md-danger"
                >Cancel</md-button
              >

              <md-button
                class="md-sm md-dense md-raised md-success"
                @click="$refs.AddArtistForm.requestSubmit()"
                >Submit</md-button
              >
            </md-card-actions>
          </md-card>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
// Import component

// Import stylesheet
import { SlideYDownTransition } from "vue2-transitions";

import {
  ValidationObserver,
  ValidationProvider,
  extend,
} from "vee-validate/dist/vee-validate.full";
import * as notifications from "../../../utils/notifications";

// can customize default error messages
extend("required", {
  message: (field) => "The " + `${field}` + " field is required. ",
});
export default {
  props: {
    profileCard: {
      type: String,
      default: "../../img/no-image-new.png",
    },
    avatarImg: {
      type: String,
      default: "../../img/no-image.png",
    },
  },

  components: {
    ValidationProvider,
    ValidationObserver,
    SlideYDownTransition,
  },

  directives: {
    uppercase: {
      // directive definition
      bind(el, vnode) {
        el.addEventListener("keyup", (e) => {
          e.target.value = e.target.value.toUpperCase();
          vnode.componentInstance.$emit("input", e.target.value.toUpperCase());
        });
      },
    },
  },
  created() {
    this.getCategories();
    this.getCountries();
  },
  data() {
    return {
      currentSort: "name",
      currentSortOrder: "asc",
      searchQuery: "",
      tableData: "",
      searchedData: [],
      tagsId: [],
      tagsArray: [],

      fuseSearch: null,
      emptylist: false,
      currentArtist: "",
      editcurrentArtist: false,
      overlay: true,
      artistName: "",
      idNumber: "",
      country: "",
      countryArray: [],
      favDescription: "",
      facebookUrl: "",
      instagramUrl: "",
      twitterUrl: "",
      status: "",
      topArtist: true,
      addedOn: "",
      modifiedOn: "",
      loading: false,
      color: "#3AB982",
      imageCircle: "",
      imageCircleFile: "",
      imageProfile: "",
      notifyUser: true,
      avatarFileName: "",
      profileFileName: "",
      fullPage: true,
      imageError: "",
      countryError: "",
      pagination: {
        pageSize: 5,
        page: 1,
        perPageOptions: [5, 10, 25, 50, 100],
        total: 0,
      },
    };
  },
  methods: {
    getCategories() {
      this.loading = true;
      const params = {
        page: this.pagination.page - 1,
        pageSize: this.pagination.pageSize,
      };
      this.$store
        .dispatch("getCategories", params)
        .then((res) => {
          if (res.data.paging.count === 0) {
            this.emptylist = true;
          } else {
            let tags = [];

            let categories = res.data.data;

            categories.forEach((category) => {
              category["tags"].forEach((tag) => {
                tags.push({ tag: tag.tag, id: tag.tagId });
              });
            });

            this.tagsArray = tags;

            this.pagination.total = res.data.paging.count;
          }
        })

        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getArtist() {
      this.loading = true;
      this.$store
        .dispatch("getArtist")
        .then((res) => {
          this.loading = false;
          console.log(res.data[0]);
          this.imageProfile = res.data[0].image_url;
          this.artistName = res.data[0].name;
          this.idNumber = res.data[0].idNumber;
          this.favDescription = res.data[0].favDescription;
          this.facebookUrl = res.data[0].facebookUrl;
          this.instagramUrl = res.data[0].instagramUrl;
          this.twitterUrl = res.data[0].twitterUrl;
          this.topArtist = res.data[0].topArtist;
          this.status = res.data[0].status;
          this.addedOn = res.data[0].created_at;
          this.modifiedOn = res.data[0].updated_at;
          ///console.log(res.data[0]);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getCountries() {
      const params = {
        page: 0,
        pageSize: 300,
      };
      this.$store
        .dispatch("getCountries", params)
        .then((res) => {
          // Object.keys(res).forEach((key) => {
          //   console.log(res[key].name);
          // });

          this.countryArray = res;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    goBack() {
      this.$router.back();
    },
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      if (e.target.name) {
        this.createImage(files[0], "circle");
        this.avatarFileName = files[0].name;
        this.imageError = "";
      } else {
        this.createImage(files[0]);
        this.profileFileName = files[0].name;
        this.imageError = "";
      }
    },
    createImage(file, type) {
      let reader = new FileReader();
      let vm = this;

      reader.onload = (e) => {
        if (type === "circle") {
          vm.imageCircleFile = file;
          vm.imageCircle = e.target.result;
        } else {
          vm.imageProfile = e.target.result;
        }
      };
      reader.readAsDataURL(file);
    },
    removeImage: function (type) {
      if (type === "circle") {
        this.imageCircle = "";
        this.imageCircleFile = "";
      } else {
        this.imageProfile = "";
      }
    },
    HideAddData() {
      this.$router.push("/artists");
    },

    addArtist() {
      this.$refs.observer.validate().then((success) => {
        if (!success) {
          return;
        }
        if (this.avatarFileName == "") {
          this.imageError = "Artist Avatar is required!";
        } else if (this.country == "") {
          this.countryError = "Country field is required!";
          setTimeout(() => {
            this.countryError = "";
          }, 2000);
        } else {
          this.loading = true;
          this.isLoading = true;
          // tags
          let tagIdsArray = [];
          this.tagsId.forEach((tag) => {
            tagIdsArray.push(tag.id);
          });

          let loader = this.$loading.show({
            // Optional parameters
            container: this.fullPage ? null : this.$refs.formContainer,
            canCancel: false,
            onCancel: this.onCancel,
            color: "#eb4034",
          });
          let filesArray = [];
          let filesNamesArray = [];

          filesArray.push(this.imageCircleFile);
          filesNamesArray.push(this.avatarFileName);

          this.$store
            .dispatch("addArtist", {
              name: this.artistName,
              description: this.favDescription,
              idNumber: this.idNumber,
              country: Object(this.country).countryId,
              files: filesArray,
              fileNames: filesNamesArray,
              category: "Artist",
              // tagIds: tagIdsArray,
            })
            .then((res) => {
              console.log(res);
              this.loading = false;
              loader.hide();
              notifications.Success({
                title: "Artist Profile created successfully",
                buttonsStyling: false,
                confirmButtonClass: "md-button md-success",
              });
            })
            .finally(() => {
              this.loading = false;
              loader.hide();
              this.HideAddData();
            })
            .catch((err) => {
              notifications.Error(err);
              loader.hide();
            });
        }

        this.$nextTick(() => {
          this.$refs.observer.reset();
        });
      });
    },
  },
};
</script>
<style lang="css">
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
</style>
<style lang="css" scoped>
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
.spacing {
  margin: "10px";
  padding: "10px";
}
.item-status {
  background-color: #4caf50;
  padding: 5px;
  color: white;
  border-radius: 10%;
}
.img-width {
  width: 50px;
}
.img-size {
  width: 75%;
  height: 75%;
}

.md-table[table-header-color="green"] .md-table-head .md-table-head-label {
  font-weight: bold;
}
</style>
