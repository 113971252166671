<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <div class="md-layout-item md-medium-size-100 md-size-100"></div>
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-danger">
          <div class="card-icon">
            <md-icon>perm_identity</md-icon>
          </div>
        </md-card-header>
        <md-card-content>
          <clip-loader
            :loading="loading"
            :color="color"
            size="25px"
          ></clip-loader>
          <div class="md-layout">
            <div class="md-layout-item md-small-size-50 md-size-50">
              <md-field>
                <label>Name</label>
                <md-input v-model="name" type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-50 spacing">
              <md-field>
                <label>Email</label>
                <md-input v-model="emailAddress" type="email"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-50 spacing">
              <md-field>
                <label>Contact Number</label>
                <md-input v-model="phone" type="phone"></md-input>
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100 md-size-50 spacing">
              <md-field>
                <label>Password</label>
                <md-input v-model="password" type="password"></md-input>
              </md-field>
            </div>

            <div class="md-layout-item md-size-50">
              <label>Genre</label>
              <multiselect
                v-model="selectedGenre"
                @input="selectGenreItem"
                :options="businessData"
                multiple
                placeholder="Select a Genre"
                label="name"
                track-by="id"
                :limit="3"
                :searchable="true"
                :show-labels="true"
                :close-on-select="false"
                :clear-on-select="false"
                deselect-label="Remove"
                select-label="Select"
                open-direction="bottom"
              >
              </multiselect>
            </div>

            <div class="md-layout-item md-small-size-100 md-size-50">
              <label>Status</label>
              <md-switch v-model="status"></md-switch>
            </div>
            <br /><br />

            <br />

            <div class="md-layout-item md-size-100 text-right">
              <md-button
                class="md-raised md-default mt-4"
                @click.native="goBack()"
                >Cancel</md-button
              >
              <md-button class="md-raised md-success mt-4">Submit</md-button>
            </div>
          </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

export default {
  components: {
    ClipLoader,
  },
  props: {
    profileCard: {
      type: String,
      default: "./img/faces/card-profile1-square.jpg",
    },
  },

  data() {
    return {
      currentSort: "name",
      currentSortOrder: "asc",
      searchQuery: "",
      tableData: "",
      searchedData: [],
      fuseSearch: null,
      emptylist: false,
      currentadmin: "",
      editcurrentadmin: false,
      cardUserImage: "",
      adminName: "",
      aboutadmin: "",
      favDescription: "",
      facebookUrl: "",
      instagramUrl: "",
      twitterUrl: "",
      status: "",
      topadmin: "",
      addedOn: "",
      modifiedOn: "",
      loading: false,
      color: "#3AB982",
      updatePassword: false,
      phone: "",
      emailAddress: "",
      name: "",
      selectedGenre: [],
      businessData: [
        {
          id: "5c18c391b6a53e070f67c7f2",
          name: "Worship Music",
        },
        {
          id: "5c346580972e1b00c51f93df",
          name: "African Inland Church Choirs",
        },
        {
          id: "5c34658f972e1b00c51f93e2",
          name: "Kamba Gospel Hits",
        },
        {
          id: "5c3465b2972e1b00c51f93e9",
          name: "Catholic Worship Songs",
        },
        {
          id: "5c3465bc972e1b00c51f93ec",
          name: "Kalenjin Hits",
        },
        {
          id: "5c3465c9972e1b00c51f93ef",
          name: "Luo Gospel Hits",
        },
        {
          id: "5c3465d7972e1b00c51f93f2",
          name: "Kigooco Hits",
        },
        {
          id: "5c3465e3972e1b00c51f93f5",
          name: "Reggae",
        },
        {
          id: "5c3465ea972e1b00c51f93f8",
          name: "Rock",
        },
        {
          id: "5c3465f6972e1b00c51f93fb",
          name: "Urban Kenyan Gospel",
        },
        {
          id: "5c491f5f75c3a600cea89184",
          name: "Luo Gospel",
        },
        {
          id: "5c491f7075c3a600cea89187",
          name: "SDA",
        },
        {
          id: "5c7cc0c8dca0cb753c9414fe",
          name: "Swahili Praise",
        },
      ],
    };
  },
  methods: {
    goBack() {
      this.$router.back();
    },
  },

  created() {},
};
</script>
<style lang="css">
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
</style>
<style lang="css" scoped>
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
.spacing {
  margin: "10px";
  padding: "10px";
}
.item-status {
  background-color: #4caf50;
  padding: 5px;
  color: white;
  border-radius: 10%;
}
.img-width {
  width: 50px;
}

.md-table[table-header-color="green"] .md-table-head .md-table-head-label {
  font-weight: bold;
}
</style>

<style lang="scss" scoped>
.multiselect__option--highlight {
  background: darkmagenta;
}
</style>