import axios from "axios";
import api from "../../axios-auth";
import filesAxios from "../../axios-files";
import { s3Url } from "../../utils/api.js";
import { AUTH_REQUEST } from "../actions/types";

const state = {
  loading: true,
  Albums: [],
  AlbumSongs: [],
  error: {},
};

const mutations = {
  request(state) {
    state.loading = true;
  },
  AlbumList(state, ListItems) {
    state.loading = false;
    state.Albums = ListItems.items;
  },
  AlbumSongsList(state, ListItems) {
    state.loading = false;
    state.AlbumSongs = ListItems.items;
  },
  [AUTH_REQUEST](state) {
    state.loading = true;
  },

  error(state, error) {
    state.loading = false;
    state.error = error;
  },
};

const actions = {
  getAllAlbums({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit(AUTH_REQUEST);
      api
        .get(`/albums?page=${params.page}&pageSize=${params.pageSize}`)
        .then((res) => {
          console.log(res.data.paging.count);
          api
            .get(`/albums?page=0&pageSize=${res.data.paging.count}`)
            .then((res) => {
              resolve(res.data.data);
            });
        })

        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },
  getAlbums({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit("request");
      api
        .get(`/albums?page=${params.page}&pageSize=${params.pageSize}`)
        .then((res) => {
          commit("AlbumList", {
            items: res.data.data,
          });
          resolve(res);
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },

  addAlbum({ commit }, formData) {
    return new Promise((resolve, reject) => {
      commit("request");
      api
        .post("/albums", {
          title: formData.title,
          description: formData.description,
          yearOfRelease: formData.yearOfRelease,
          artistId: formData.artistId,
        })
        .then((res) => {
          commit("AlbumList", {
            items: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },
  addAlbumSong({ commit }, formData) {
    return new Promise((resolve, reject) => {
      console.log(formData);
      commit("request");
      api
        .post("/albums", {
          title: formData.title,
          description: formData.description,
          yearOfRelease: formData.yearOfRelease,
          artistId: formData.artistId,
        })
        .then((res) => {
          let albumId = res.data.data.albumId;

          //
          async function processFiles() {
            let result;
            let task_id = [];
            let urls = [];
            let uploadResult = [];
            for (let i = 0; i < formData.fileNames.length; i++) {
              result = await axios.post(s3Url, {
                id: albumId,
                file_name: formData.fileNames[i],
                category: formData.category,
              });

              if (result.status !== 201) {
                reject("Error occurred");
              } else {
                urls.push(result.data.url);
                task_id.push(result.data.task_id);
              }
            }

            for (let i = 0; i < formData.fileNames.length; i++) {
              result = await filesAxios.put(urls[i], formData.files[i]);
              uploadResult[i] = result;
            }
            return uploadResult;
          }

          async function doFilesUpload() {
            let result = await processFiles();
            //console.log(result);
            resolve(result);
          }

          //
          doFilesUpload();
        })

        .catch((err) => {
          reject(err);
        });
    });
  },

  updateAlbum({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit("request");
      console.log(params);
      api
        .put(`/albums/${params.albumId}/edit`, {
          title: params.title,
          description: params.description,
          yearOfRelease: params.yearOfRelease,
          artistId: params.artistId,
          albumId: params.albumId,
        })
        .then((res) => {
          commit("AlbumList", {
            items: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },

  getAlbum({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit("request");
      api
        .get(`/albums/${id}`)
        .then((res) => {
          commit("AlbumList", {
            items: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },

  getAlbumSongs({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit("request");
      api
        .get(`/albums/${params.id}/songs`)
        .then((res) => {
          commit("AlbumSongsList", {
            items: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },

  TopAlbum({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit("request");
      api
        .put(`/albums/${params.id}/markAsTop`, {
          status: params.status,
        })
        .then((res) => {
          commit("AlbumList", {
            items: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },
  PopularAlbum({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit("request");
      api
        .put(`/albums/${params.id}/markAsPopular`, {
          status: params.status,
        })
        .then((res) => {
          commit("AlbumList", {
            items: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },

  deleteAlbum({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit("request");
      api
        .delete(`/albums/${params}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          commit("error", err);
          reject(err);
        });
    });
  },
};

const getters = {};

export default { state, mutations, actions, getters };
